import { useEffect } from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../../app/store/store";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { PagingParams } from "../../../../../app/models/pagination";
import { PageWrapper } from "../../../administrator.style";

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const VendorList = () => {
  const {
    vendorStore: {
      VendorsAll: vendorsByTitle,
      loadVendors,
      loadingInitial,
      pagination,
      setPagingParams,
    },
    commonStore: { setAdminPageTitle },
  } = useStore();

  const navigate = useNavigate();

  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "35px", maxWidth: "35px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "35px", maxWidth: "35px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableData: any, updatedValue: any) => {
          return value === null ? "" : value.name;
        },
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "published",
      label: "Published",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "deleted",
      label: "Deleted",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <Button
              icon="eye"
              basic
              circular
              size="mini"
              onClick={() => handleViewButtonClick(tableMeta)}
            />
          );
        },
        customHeadLabelRender: headerLabelRenderer,
      },
    },
  ];

  let page = !!pagination ? pagination.page - 1 : 0;

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  useEffect(() => {
    if (vendorsByTitle.length === 0) {
      loadVendors();
    }
    setAdminPageTitle("Vendor List");
  }, [vendorsByTitle, loadVendors, setAdminPageTitle]);

  function handleTableChange(action: string, tableState: any) {
    if (action === "changePage") {
      let currentPage = tableState.page + 1;
      console.log("Go to page", currentPage);
      setPagingParams(new PagingParams(currentPage));
      loadVendors();
    }
  }

  function handleRowsDelete(rowsDeleted: any, newTableData: any) {
    console.log(rowsDeleted);
    console.log(newTableData);
    alert("Row Deleted");
  }

  function handleViewButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    // console.log(tableMeta);

    if (vendorsByTitle && vendorsByTitle.length > rowIndex) {
      const vendorId = vendorsByTitle[rowIndex].id;
      navigate(`/administrator/vendors/${vendorId}/info`, {
        replace: true,
      });
    }
  }

  return (
    <PageWrapper>
      <div>
        <Segment vertical loading={loadingInitial}>
          <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={null}
              data={vendorsByTitle}
              columns={columns}
              options={{
                filter: "false",
                download: "false",
                print: "false",
                viewColumns: "false",
                selectableRows: "none",
                responsive: "standard",
                rowsPerPage: 10,
                rowsPerPageOptions: [10],
                serverSide: true,
                count: pagination?.total,
                page: page,
                onRowsDelete: handleRowsDelete,
                onTableChange: handleTableChange,
                setTableProps: () => {
                  return { size: "small" };
                },
              }}
            />
          </MuiThemeProvider>
        </Segment>
      </div>
    </PageWrapper>
  );
};

export default observer(VendorList);
