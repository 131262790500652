import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const RefundEmptyPanel = () => {
  return (
    <div>
      <Chops2uEmptyRecord
        title="No refunds"
        subtitle="There is no refunds for this query. Please try another query or clear the filter."
        color="green"
      />
    </div>
  );
};

export default RefundEmptyPanel;
