import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Grid, Header, Segment, Message, Image } from "semantic-ui-react";
import useQuery from "../../../../app/common/util/hooks";

const ResetPasswordSuccess = () => {
  const email = useQuery().get("email") as string;
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = (location.state as { from: Location })?.from?.pathname || "/";

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Segment raised stacked style={{ marginTop: "1em" }}>
          <Header
            size="tiny"
            disabled
            content={"Reset Your Password".toUpperCase()}
          />

          <div
            style={{ margin: "25px 0px 10px", textAlign: "left" }}
            className="field"
          >
            An email with a link to reset your password has been sent to {email}
          </div>

          <div
            style={{
              margin: "5px 0px 10px",
              fontSize: "0.9em",
              textAlign: "left",
            }}
            className="field"
          >
            <Link to="/account/login">Return to login</Link>
          </div>
        </Segment>
        <Message>
          Problem logging in?{" "}
          <Link to="/account/contact-us">Technical Support</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ResetPasswordSuccess);
