import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import OpeningHoursForm from "./forms/OpeningHoursForm";
import PayoutAccountForm from "./forms/PayoutAccountForm";
import ProfileForm from "./forms/ProfileForm";
import UploadPhotoForm from "./forms/UploadPhotoForm";
import StaffList from "./list/StaffList";
import SettingsTopMenu from "./SettingsTopMenu";
import SecurityForm from "./forms/SecurityForm";
import { ContentWrapper, SettingsWrapper } from "../../store.style";

interface Props {
  setPageTitle: (title: string) => void;
}

const SettingsPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Store Settings");
  }, [setPageTitle]);

  return (
    <SettingsWrapper>
      <div>
        <SettingsTopMenu />
      </div>
      <ContentWrapper>
        <Routes>
          <Route index element={<ProfileForm />} />
          <Route path="openinghours" element={<OpeningHoursForm />} />
          <Route path="photo" element={<UploadPhotoForm />} />
          <Route path="staff" element={<StaffList />} />
          <Route path="account" element={<PayoutAccountForm />} />
          <Route path="security" element={<SecurityForm />} />
        </Routes>
      </ContentWrapper>
    </SettingsWrapper>
  );
};

export default SettingsPageLayout;
