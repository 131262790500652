import { List, Segment } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";
import OrderDetailsItem from "./OrderDetailsItem";

interface Props {
  order: Order;
}

const OrderDetailsBodyContent = ({ order }: Props) => {
  return (
    <Segment vertical>
      <List divided verticalAlign="middle">
        {order?.items.length > 0 &&
          order.items.map((item, index) => (
            <OrderDetailsItem item={item} key={index} />
          ))}
      </List>
    </Segment>
  );
};

export default OrderDetailsBodyContent;
