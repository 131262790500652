import { GroupingOption } from "./menu";

export default interface Food {
  id?: string;
  title: string;
  description?: string;
  price: number;
  otherCharges: number;
  weight: number;
  published: boolean;
  deleted: boolean;
  type: FoodType;
  visibleIndividually: boolean;
  image?: string;
  updatedOnUtc?: string;
  menuTitle?: string;
  menuId?: string;
  groupingOption?: GroupingOption;
  vendorTitle?: string;
  vendorId?: string;
  associatedFoods?: FoodGrouping[];
  parentGroupedFoods?: FoodGrouping[];
}

export interface FoodPhoto {
  id: string;
  url: string;
}

export enum FoodType { simple = 5, grouped = 10 }

export interface FoodGrouping {
  parentGroupedFood?: string;
  parentGroupedFoodId?: string;
  associatedFood?: string;
  associatedFoodId?: string;
}

export const FoodTypeOptions = [
  { key: "1", text: "Single", value: 5 },
  { key: "2", text: "Grouped", value: 10 }
];

export interface AssociatedFoodsTemplateProps {
  [key: string]: Food[];
}
