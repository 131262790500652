import { createContext, useContext } from "react";
import BikeStore from "./bike-store";
import CategoryStore from "./category-store";
import CityStore from "./city-store";
import CommonStore from "./common-store";
import CountyStore from "./county-store";
import FoodStore from "./food-store";
import LocationStore from "./location-store";
import MenuStore from "./menu-store";
import ModalStore from "./modal-store";
import ProfileStore from "./profile-store";
import RestaurantStore from "./restaurant-store";
import ShoppingCartStore from "./shopping-cart-store";
import UserStore from "./user-store";
import VendorStore from "./vendor-store";
import ChopperStore from "./chopper-store";
import OrderStore from "./order-store";
import ShipmentStore from "./shipment-store";
import BankStore from "./bank-store";
import PaymentStore from "./payment-store";
import AccountManagerStore from "./account-manager-store";

interface Store {
  accountManagerStore: AccountManagerStore;
  vendorStore: VendorStore;
  categoryStore: CategoryStore;
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  profileStore: ProfileStore;
  cityStore: CityStore;
  locationStore: LocationStore;
  countyStore: CountyStore;
  menuStore: MenuStore;
  foodStore: FoodStore;
  shoppingCartStore: ShoppingCartStore;
  restaurantStore: RestaurantStore;
  orderStore: OrderStore;
  bikeStore: BikeStore;
  bankStore: BankStore;
  chopperStore: ChopperStore;
  shipmentStore: ShipmentStore;
  paymentStore: PaymentStore;
}

export const store: Store = {
  accountManagerStore: new AccountManagerStore(),
  vendorStore: new VendorStore(),
  categoryStore: new CategoryStore(),
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  profileStore: new ProfileStore(),
  cityStore: new CityStore(),
  locationStore: new LocationStore(),
  countyStore: new CountyStore(),
  menuStore: new MenuStore(),
  foodStore: new FoodStore(),
  shoppingCartStore: new ShoppingCartStore(),
  restaurantStore: new RestaurantStore(),
  bikeStore: new BikeStore(),
  bankStore: new BankStore(),
  chopperStore: new ChopperStore(),
  orderStore: new OrderStore(),
  shipmentStore: new ShipmentStore(),
  paymentStore: new PaymentStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
