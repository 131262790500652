import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const OrderEmptyPanel = () => {
  return (
    <div>
      <Chops2uEmptyRecord
        title="No orders yet"
        subtitle="This is where you will see and manage orders as they come."
        color="green"
      />
    </div>
  );
};

export default OrderEmptyPanel;
