import { Button } from "semantic-ui-react";
import { styled } from "styled-components";

/** CartItem Section */
//////////////////////////////////////////////

//    display: "flex",
//         flexDirection: "column",
//         marginBottom: "0.25em",
//         paddingBottom: "0.5em",
//         borderBottom: "1px solid #eee",

// export const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex-wrap: nowrap;
//   justify-content: space-between;
//   border-Bottom: 1px solid #eee;
//   padding: .45rem 0 .45rem 0;
//   margin: .15rem 0 .25rem .15rem ;
// `;

// export const ContentWrapper = styled.div`
//   display: flex;
//   align-items: center ;
//   justify-content: space-between;
//   width: 100%;
//   /* border: 1px solid #333; */
//   overflow: hidden;
//   padding-left: .15rem;
// `;

// export const ImageWrapper = styled.div`
//   display: flex;
//   align-content: center;
//   width: 2.75rem;
//   /* border: 1px solid #333; */
//   text-align: center;
// `;

// export const ItemImage = styled(Image)`
//   width: 100%;
//   margin: auto !important;
//   margin-top: auto !important;
//   margin-bottom: auto !important;
// `;

export const DisplayPanel = styled.div`
  align-self: center;
  text-align: center;
  flex: 0 0 25%;
  border: 0.5px solid grey;
`;

export const CartButton = styled(Button).attrs({ basic: true, size: "mini" })`
  align-self: center !important;
  margin: none !important;
  box-shadow: none !important;
  /* height: 30px; */
  align-items: center;
  align-content: center;
  display: flex;
  text-align: center;
  margin: .15rem .15rem .15rem .15rem !important;
  padding:.25rem .25rem .1rem .25rem !important;
  border: none !important;

  &:hover {
    align-self: center !important;
    box-shadow: none !important;
    border: none !important;
    margin: .15rem .15rem .15rem .15rem;
  }

  &::mouse-over {
  }
`;

export const CartQuantity = styled.span`
  color: ${(props) => props.theme.Colors.text.primary};
  margin: .15rem .15rem .15rem .15rem !important;
  text-align: center;
`;

export const CartPrice = styled.span`
  color: ${(props) => props.theme.Colors.text.primary};
  font-weight: 600;
  margin: .15rem .15rem .15rem .15rem !important;
  text-align: center;
`;