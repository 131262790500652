import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import GraphPanel from "./GraphPanel";
import StatsPanel from "./StatsPanel";
import WelcomePanel from "./WelcomePanel";

interface Props {
  setPageTitle: (title: string) => void;
}

const DashboardPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Dashboard");
  }, [setPageTitle]);

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "1em",
        padding: "1em",
      }}
    >
      <WelcomePanel />
      <StatsPanel />
      <GraphPanel />
    </section>
  );
};

export default observer(DashboardPageLayout);
