import { observer } from "mobx-react-lite";
import { Button } from "semantic-ui-react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../../app/store/store";
import Checkout from "../../../../../app/models/checkout";
import Payment, { PaymentStatus } from "../../../../../app/models/payment";
import Address from "../../../../../app/models/address";

interface Props {
  editState: boolean;
  paymentMethodId: string;
  deliveryAddress: Address | null;
}

const FlutterwaveButton = ({
  editState,
  paymentMethodId,
  deliveryAddress,
}: Props) => {
  // =============================================================
  // store
  // =============================================================
  const {
    shoppingCartStore: {
      OrderTotalAmount,
      OtherCharges,
      OrderSubTotal,
      OrderDiscount,
      IsCartEmpty,
      IsIdentifierEmpty,
      DeliveryFee,
      SalesTax,
      cartIdentifier,
      createHubConnect,
      stopHubConnection,
      checkoutOrder,
    },
    paymentStore: { getTxRef, begin },
    userStore: { User },
  } = useStore();

  // =============================================================
  // states
  // =============================================================
  const [txRef, setTxRef] = useState<string | undefined>(undefined);
  const [processPayment, setProcessPayment] = useState(false);

  const navigate = useNavigate();

  // =============================================================
  // useEffect
  // =============================================================
  useEffect(() => {
    getTxRef()
      .then((txRef) => setTxRef(txRef!))
      .catch((error) => console.log(error));
  }, [getTxRef, setTxRef]);

  useEffect(() => {
    createHubConnect();

    return () => stopHubConnection();
  }, [createHubConnect, stopHubConnection]);

  const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY!,
    tx_ref: txRef!,
    amount: OrderTotalAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      name: User!.fullName,
      email: User!.email,
      phone_number: User!.phoneNumber,
    },
    customizations: {
      title: "chops2u",
      description: "Food Purchase",
      logo: "/images/logo-bike.png",
    },
  };

  let handleFlutterPayment = useFlutterwave(config);

  const handleButtonClick = () => {
    setProcessPayment(true);
    handleFlutterPayment({
      callback: (response) => {
        if (response.status === "successful" && OrderTotalAmount > 0) {
          // console.log("Checkout Values", data);
          let payment: Payment = {
            transactionReference: response.tx_ref,
            cartId: cartIdentifier,
            gatewayId: paymentMethodId,
            requestedOnUtc: response.created_at,
            orderTotalAmount: response.amount,
            amountPaid: response.charged_amount,
            commissionFee: 0,
            vendorSales: 0,
            orderSubTotal: OrderSubTotal,
            deliveryFee: DeliveryFee,
            otherCharges: OtherCharges,
            salesTax: SalesTax,
            orderDiscount: OrderDiscount,
            currency: "NGN",
            title: "Food Purchase",
            status: PaymentStatus.Successful,
            type: "P",
            statusChangedOnUtc: response.created_at,
            gatewayTransactionId: response.transaction_id.toString(),
            gatewayTransactionReference: response.flw_ref,
            additionalInfo: response.charge_response_message,
            payerId: User!.email,
            payerName: User!.fullName,
            payerEmail: User!.email,
            payerTelephone: User!.phoneNumber,
          };

          // console.log(payment);
          begin(payment)
            .then((result) => {
              // checkout cart if payment is successful
              // console.log("Create Payment Response: ", result);
              if (result?.status === "successful") {
                let checkoutValues: Checkout = {
                  cartId: cartIdentifier,
                  orderSubTotal: OrderSubTotal,
                  otherCharges: OtherCharges,
                  deliveryFee: DeliveryFee,
                  salesTax: SalesTax,
                  orderDiscount: OrderDiscount,
                  orderTotal: OrderTotalAmount,
                  deliveryAddress: deliveryAddress!,
                };

                // checkout cart and navigate to /account/orders
                checkoutOrder(checkoutValues)
                  .finally(() => {
                    setProcessPayment(false);
                    navigate("/account");
                  })
                  .catch((error) => {
                    console.log(error);
                    setProcessPayment(false);
                    navigate("checkout-failure");
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              setProcessPayment(false);
              navigate("checkout-failure");
            });
        }
        // console.log(response);
        closePaymentModal();
      },
      onClose: () => {},
    });

    setProcessPayment(false);
  };

  return (
    <Button
      disabled={IsIdentifierEmpty || IsCartEmpty || editState === false}
      loading={processPayment}
      onClick={() => handleButtonClick()}
      size="large"
      fluid
      color="olive"
    >
      Process
    </Button>
  );
};

export default observer(FlutterwaveButton);
