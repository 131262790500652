import { Link } from "react-router-dom";
import { Grid, Header, Icon, Image } from "semantic-ui-react";

interface Props {
  mobile?: boolean
}

const PartWithUs = ({ mobile } : Props) => {
  return (
    <div>
      <Image
        src="/images/front/banner/partner-with-us.png"
        centered
        style={{ height: "120px", width: "auto" }}
        size="small"
      />
      <Header inverted as="h3" textAlign="center" style={{ marginTop: "0px", fontSize: mobile ? "1.1em" : "1em"  }}>
        <Header.Content>Partner With US</Header.Content>
        <Header.Subheader>
          <Grid>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={12}>
              Grow your business and connect to new customers by registering
              with us.
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid>
        </Header.Subheader>
      </Header>
      <div style={{ textAlign: "center" }}>
        <Header
          as={Link}
          to="/store/register"
          inverted
          style={{
            textDecoration: "underline",
            fontSize: mobile ? "1em" : "16px",
            marginRight: "5px",
          }}
          content="Register as a Vendor"
        />
        <Icon inverted size="tiny" name="arrow right" />
      </div>
    </div>
  );
};

export default PartWithUs;
