import { useState } from "react";
import VendorDetailsPage from "../detailedPages/VendorDetailsPage";
import VendorForm from "../forms/VendorForm";

const VendorDetails = () => {
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      {editMode ? (
        <VendorForm setEditMode={setEditMode} />
      ) : (
        <VendorDetailsPage setEditMode={setEditMode} />
      )}
    </>
  );
};

export default VendorDetails;
