import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Icon, ItemHeader } from "semantic-ui-react";
import { useStore } from "../../store/store";

interface Props {
  mobile?: boolean;
}

const CartButton = ({ mobile }: Props) => {
  const buttonStyle = {
    position: "fixed" as "fixed",
    marginTop: "-14px",
    marginLeft: "15px",
    color: "#FFFFFF",
    background: "#FAAB05",
    padding: "4px 7px",
    borderRadius: "12px",
    fontSize: "14px",
  }
  
  const mobileButtonStyle = {
    position: "fixed" as "fixed",
    marginTop: "-13px",
    marginLeft: "12px",
    color: "#FFFFFF",
    background: "#FAAB05",
    padding: "1px 7.4px",
    borderRadius: "11px",
    fontSize: "14px",
    }
    // store 
    const { shoppingCartStore: { ItemsCount }} = useStore();
    return (
      <ItemHeader>
        <div>
          {ItemsCount > 0 && (
            <div
              style={mobile ? mobileButtonStyle : buttonStyle}
            >
              <Link to="/checkout" style={{ color: "#ffffff"}}>{ItemsCount}</Link>
            </div>
          )}
          <Icon color="olive" name="cart" />
        </div>
      </ItemHeader>
    );
}

export default observer(CartButton);