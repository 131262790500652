import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Label } from "semantic-ui-react";

interface Props {
  setFiles: (files: any[]) => void;
}

export default function PhotoDropzone({ setFiles }: Props) {
  // const [dropped, setDropped] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      // setDropped(true);
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dzStyles = {
    position: "absolute" as "absolute",
    top: "0px",
    left: "0px",
    textAlign: "center" as "center",
    width: "100%",
    height: "162px",
    paddingTop: "75px",
  };

  const dzActivestyle = {
    borderColor: "olive",
  };

  return (
    <div
      {...getRootProps()}
      style={isDragActive ? { ...dzStyles, ...dzActivestyle } : dzStyles}
    >
      <input {...getInputProps()} />
      <Label size="large" color={isDragActive ? "olive" : "grey"}>
        <Icon name="upload" /> Drag & Drop File
      </Label>
    </div>
  );
}
