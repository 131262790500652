export const Spacing = [
    "1px",
    "1.5px",
    "2px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "128px",
    "256px"
];