import { observer } from "mobx-react-lite";
import { Segment, Header, Button } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import { UserRole } from "../../../../../app/models/user-account";

interface Props {
  role: UserRole;
}

const DeleteRoleForm = ({ role }: Props) => {
  const { accountManagerStore, modalStore } = useStore();

  const handleDeleteCommand = () => {
    accountManagerStore
      .deleteRole(role.name)
      .then(() => modalStore.closeModal());
  };

  return (
    <Segment.Group>
      <Segment style={{ padding: "1.2em 1em" }}>
        <Header as="h3" color="olive" content="Delete Role" />
      </Segment>
      <Segment style={{ padding: "1.5em" }}>
        <p>Are you sure you want to delete the following record?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {role.name}
        </Header>
      </Segment>
      <Segment
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
          padding: "1em",
        }}
      >
        <Button
          icon="cancel"
          labelPosition="left"
          negative
          content="No"
          onClick={modalStore.closeModal}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          positive
          loading={accountManagerStore.deleting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(DeleteRoleForm);
