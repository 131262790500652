import { observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";
import {
  Visibility,
  Segment,
  Menu,
  Container,
  Button,
  Image,
} from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import LoginForm from "../../frontpage/account/login/LoginForm";
import RegistrationForm from "../../frontpage/account/registration/RegistrationForm";
import HomePageHeading from "../HomePageHeading";
import { Media } from "../../..";
import { Link } from "react-router-dom";

const desktopHeaderStyle = {
  minHeight: 700,
  padding: "1em 0em",
  borderBottom: "1px solid #888",
  background: "url('/images/home-background.png')",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#f0f7c6",
  backgroundPosition: "-200px center",
};

const tableHeaderStyle = {
  minHeight: "50vh",
  padding: "1em 0em",
  borderBottom: "1px solid #888",
  background: "url('/images/home-background.png')",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#f0f7c6",
  backgroundPosition: "center 420px",
  backgroundSize: "350px",
};

interface Props {
  children: ReactNode;
}

const DescktopContainerHomePage = ({ children }: Props) => {
  const {
    modalStore: { openModal },
    userStore: { IsLogedIn },
  } = useStore();

  const [fixed, setFixed] = useState(false);

  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);

  return (
    <>
      <Media at="computer">
        <Visibility
          once={false}
          onBottomPassed={showFixedMenu}
          onBottomPassedReverse={hideFixedMenu}
        >
          <Segment textAlign="center" style={desktopHeaderStyle} vertical>
            <Menu
              fixed={fixed ? "top" : undefined}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item>
                  <Image as={Link} to="/" src="/images/logo.png" size="small" />
                </Menu.Item>
                {/* <Menu.Item as="a">Work</Menu.Item>
                  <Menu.Item as="a">Company</Menu.Item>
                  <Menu.Item as="a">Careers</Menu.Item> */}
                {!IsLogedIn && (
                  <Menu.Item position="right">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        columnGap: "1px",
                      }}
                    >
                      <Button
                        as="a"
                        circular
                        size="small"
                        onClick={() => openModal(<LoginForm />)}
                        style={{ minWidth: "95px", background: "transparent" }}
                      >
                        <span
                          style={{
                            color: fixed ? "#b5cc18" : "#454545",
                            fontWeight: 400,
                            letterSpacing: "1px",
                            fontSize: "1.2em",
                          }}
                        >
                          Login
                        </span>
                      </Button>
                      <Button
                        as="a"
                        circular
                        size="small"
                        onClick={() => openModal(<RegistrationForm />)}
                        style={{
                          minWidth: "95px",
                          background: "#fff",
                          color: "gray",
                          border: fixed
                            ? "1.5px solid #b5cc18"
                            : "1.5px solid #454545",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 400,
                            color: fixed ? "#b5cc18" : "#454545",
                            letterSpacing: "1px",
                            fontSize: "1.1em",
                          }}
                        >
                          Sign Up
                        </span>
                      </Button>
                    </div>
                  </Menu.Item>
                )}
              </Container>
            </Menu>
            <HomePageHeading mobile={false} />
          </Segment>
        </Visibility>
        {children}
      </Media>
      <Media at="tablet">
        <Visibility
          once={false}
          onBottomPassed={showFixedMenu}
          onBottomPassedReverse={hideFixedMenu}
        >
          <Segment textAlign="center" style={tableHeaderStyle} vertical>
            <Menu
              fixed={fixed ? "top" : undefined}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item>
                  <Image as={Link} to="/" src="/images/logo.png" size="small" />
                </Menu.Item>
                {/* <Menu.Item as="a">Work</Menu.Item>
                  <Menu.Item as="a">Company</Menu.Item>
                  <Menu.Item as="a">Careers</Menu.Item> */}
                {!IsLogedIn && (
                  <Menu.Item position="right">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        columnGap: "1px",
                      }}
                    >
                      <Button
                        as="a"
                        circular
                        size="small"
                        onClick={() => openModal(<LoginForm />)}
                        style={{ minWidth: "95px", background: "transparent" }}
                      >
                        <span
                          style={{
                            color: fixed ? "#b5cc18" : "#454545",
                            fontWeight: 400,
                            letterSpacing: "1px",
                            fontSize: "1.2em",
                          }}
                        >
                          Login
                        </span>
                      </Button>
                      <Button
                        as="a"
                        circular
                        size="small"
                        onClick={() => openModal(<RegistrationForm />)}
                        style={{
                          minWidth: "95px",
                          background: "#fff",
                          color: "gray",
                          border: fixed
                            ? "1.5px solid #b5cc18"
                            : "1.5px solid #454545",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 400,
                            color: fixed ? "#b5cc18" : "#454545",
                            letterSpacing: "1px",
                            fontSize: "1.1em",
                          }}
                        >
                          Sign Up
                        </span>
                      </Button>
                    </div>
                  </Menu.Item>
                )}
              </Container>
            </Menu>
            <HomePageHeading mobile={false} />
          </Segment>
        </Visibility>
        {children}
      </Media>
    </>
  );
};

export default observer(DescktopContainerHomePage);
