import { Menu, Dropdown } from "semantic-ui-react";
import PageTopMenu from "../../common/PageTopMenu";

const OrdersTopMenu = () => {
  return (
    <PageTopMenu>
      <Menu.Item>
        <Dropdown labeled text={"Filter"} icon="filter">
          <Dropdown.Menu></Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </PageTopMenu>
  );
};

export default OrdersTopMenu;
