import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button, Header } from "semantic-ui-react";
import { timeLaps, toCurrency } from "../../../../app/common/util/util";
import { useStore } from "../../../../app/store/store";
import AssignChopperForm from "./forms/AssignChopperForm";
import CancelOrderForm from "./forms/CancelOrderForm";
import DeleteOrderForm from "./forms/DeleteOrderForm";
import { PageWrapper } from "../../administrator.style";

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

const OrderList = () => {
  const {
    modalStore: { openModal },
    orderStore: { OrdersByDate },
  } = useStore();

  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "orderNumber",
      label: "Order No.",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
      },
    },
    {
      name: "orderTotal",
      label: "Total",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "90px", maxWidth: "90px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "90px", maxWidth: "90px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        filter: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "90px", maxWidth: "90px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "90px", maxWidth: "90px" },
        }),
      },
    },
    {
      name: "createdOnUtc",
      label: "Time",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return timeLaps(value);
        },
      },
    },
    {
      name: "customerName",
      label: "Customer",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
      },
    },
    {
      name: "customerTelephone",
      label: "Customer Phone",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
      },
    },
    {
      name: "vendorTelephone",
      label: "Vendor Phone",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
      },
    },
    {
      name: "vendorLocation",
      label: "Location",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
      },
    },
    {
      name: "deleted",
      label: "Deleted",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          const { rowIndex } = tableMeta;
          return (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2em" }}
            >
              <Button
                icon="linkify"
                basic
                circular
                disabled={OrdersByDate[rowIndex].status === "Placed"}
                size="mini"
                onClick={() => handleAssignOrder(rowIndex)}
              />
              <Button
                icon="window close"
                basic
                color="yellow"
                disabled={OrdersByDate[rowIndex].chopperAssigned}
                circular
                size="mini"
                onClick={() => handleCancelOrder(rowIndex)}
              />
              <Button
                icon="times"
                basic
                color="red"
                disabled={OrdersByDate[rowIndex].chopperAssigned}
                circular
                size="mini"
                onClick={() => handleDeleteOrder(rowIndex)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  const handleAssignOrder = (rowIndex: any) => {
    openModal(<AssignChopperForm order={OrdersByDate[rowIndex]} />);
  };

  const handleCancelOrder = (rowIndex: any) => {
    openModal(<CancelOrderForm order={OrdersByDate[rowIndex]} />);
  };

  const handleDeleteOrder = (rowIndex: any) => {
    openModal(<DeleteOrderForm order={OrdersByDate[rowIndex]} />);
  };

  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={OrdersByDate}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "vertical",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: OrdersByDate.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default observer(OrderList);
