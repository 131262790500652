import { store } from "./store";
import { UserFormValues, RegisterVendorFormValues } from "../models/user";
import { makeAutoObservable, runInAction } from "mobx";
import User from "../models/user";
import agent from "../api/agent";
import Location from "../models/location";
import { ChopperRegistrationFormValues } from "../../features/chopper/ChopperRegistrationPage";
import Order from "../models/order";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ForgotFormValues } from "../models/forgot";
import { ResetPasswordFormValues } from "../models/reset-password";
import { ChangePasswordFormValues } from "../models/change-password";
import { ChangeEmailFormValues } from "../models/change-email";

export default class UserStore {
  user: User | null = null;
  hubConnection: HubConnection | null = null;
  deliveryLocation: Location | null = null;
  orderRegistry = new Map<string, Order>();
  selectedOrder: null | Order = null;
  logining: boolean = false;
  fbLoading = false;
  registering: boolean = false;
  loadingOrders = false;
  loadingOrder = false;
  submitting = false;
  refreshTokenTimeout: any;
  registeringVendor = false;

  constructor() {
    makeAutoObservable(this);
  }

  get User() {
    return this.user;
  }

  get DeliveryLocation() {
    return this.deliveryLocation;
  }

  get IsLogedIn() {
    return !!this.user;
  }

  get IsLocationSet() {
    return !!this.deliveryLocation;
  }

  get IsRestaurantStaff() {
    return !!this.user;
  }

  get IsLocationEqualDefaultAddress() {
    return (!this.IsLocationSet && store.profileStore.IsDefaultAddressSet) || (this.deliveryLocation && store.profileStore.defaultAddress);
  }

  get Orders() {
    return Array.from(this.orderRegistry.values());
  }

  get ActiveOrders() {
    return Array.from(this.orderRegistry.values())
      .filter(a => (a.status !== "Cancelled") && (a.status !== "Delivered"))
      .sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  get OrdersByDate() {
    return Array.from(this.orderRegistry.values())
      .filter(a => (a.status === "Cancelled") || (a.status === "Delivered"))
      .sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  get OrdersHistory() {
    return Array.from(this.orderRegistry.values())
      .filter(a => (a.status === "Cancelled") || (a.status === "Delivered"))
      .sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  //#region OrderHub

  createHubConnection = () => {
    const baseUrl = process.env.REACT_APP_ORDERHUB_URL!;
    if (this.user) {
      this.loadingOrders = true;
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(baseUrl, {
          accessTokenFactory: () => this.user?.token!
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      this.hubConnection
        .start()
        .catch(error => console.log("Error Establishing hub connection: ", error));

      this.hubConnection.on("LoadOrders", (orders: Order[]) => {
        runInAction(() => {
          this.orderRegistry.clear();
          orders.forEach((order) => {
            this.orderRegistry.set(order.id, order);
          })
          this.loadingOrders = false;
        })
      })
      // ReceiveOrder
      this.hubConnection.on("ReceiveOrder", (order: Order) => {
        runInAction(() => {
          this.orderRegistry.set(order.id, order);
          this.loadingOrder = false;
        })
      })

      this.hubConnection.on("BroadcastOrderDelete", (order: Order) => {
        runInAction(() => {
          this.orderRegistry.delete(order.id);
        })
      })
    }
  }

  stopHubConnection = () => {
    this.hubConnection?.stop()
      .catch(error => console.log("Error Stopping connection: ", error));
  }

  clearOrders = () => {
    this.orderRegistry.clear();
    this.stopHubConnection();
  }

  //#endregion

  getDeliveryLocation = async () => {
    this.deliveryLocation = store.commonStore.getDeliveryLocation();
  };

  setDeliveryLocation = async (location: Location | null) => {
    if (location) {
      store.commonStore.setDeliveryLocation(JSON.stringify(location));
    } else {
      store.commonStore.setDeliveryLocation(null);
    }
    this.getDeliveryLocation();
  };

  //#region Account 
  getUser = async () => {
    try {
      var user = await agent.Account.getCurrentUser();
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      // store.profileStore.LoadDefaultAddress();
      runInAction(() => (this.user = user));
    } catch (error) {
      console.log(error);
    }
  };

  login = async (credentials: UserFormValues) => {
    try {
      this.logining = true;
      var user = await agent.Account.login(credentials);
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      runInAction(() => {
        this.user = user;
        this.logining = false;
      });
    } catch (error) {
      runInAction(() => (this.logining = false));
      throw error;
    }
  };

  facebookLogin = async (accessToken: string) => {
    try {
      this.fbLoading = true;
      let user = await agent.Account.fbLogin(accessToken);
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      runInAction(() => {
        this.user = user;
        this.fbLoading = false;
      })
    } catch (error) {
      console.log(error);
      runInAction(() => this.fbLoading = false);
    }
  }

  logout = async () => {
    store.commonStore.setToken(null);
    this.user = null;
  };

  forgot = async (values: ForgotFormValues) => {
    this.submitting = true;
    try {
      await agent.Account.forgotPassword(values.email);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => this.submitting = false);
      throw error;
    }
  }

  resetPassword = async (values: ResetPasswordFormValues) => {
    this.logining = true;
    try {
      let user = await agent.Account.resetPassword(values);
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      runInAction(() => {
        this.user = user;
        this.logining = false;
      });
    } catch (error) {
      runInAction(() => this.logining = false);
      throw error;
    }
  }

  adminResetPassword = async (values: ResetPasswordFormValues) => {
    this.submitting = true;
    try {
      await agent.Account.adminResetPassword(values);
      runInAction(() => this.submitting = false);
    } catch (error) {
      runInAction(() => this.submitting = false);
      // console.log(error);
      throw error;
    }
  }

  changePassword = async (values: ChangePasswordFormValues) => {
    this.submitting = true;
    try {
      await agent.Account.changePassword(values);
      runInAction(() => this.submitting = false);
    } catch (error) {
      runInAction(() => this.submitting = false);
      throw error;
    }
  }

  changeEmail = async (values: ChangeEmailFormValues) => {
    this.submitting = true;
    try {
      await agent.Account.changeEmail(values);
      runInAction(() => this.submitting = false);
    } catch (error) {
      runInAction(() => this.submitting = false);
      throw error;
    }
  }

  register = async (credentials: UserFormValues) => {
    try {
      this.registering = true;
      var user = await agent.Account.register(credentials);
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      runInAction(() => {
        this.user = user;
        this.registering = false;
        // this.deliveryLocation = null;
      });
      // store.commonStore.deliveryLocation = null;
    } catch (error) {
      runInAction(() => (this.registering = false));
      throw error;
    }
  };

  registerVendor = async (credentials: RegisterVendorFormValues) => {
    this.registeringVendor = true;
    try {
      await agent.Account.registerVendor(credentials);
      runInAction(() => {
        this.user = null;
        this.registeringVendor = false;
        this.deliveryLocation = null;
      });
      store.commonStore.deliveryLocation = null;
    } catch (error) {
      runInAction(() => (this.registeringVendor = false));
      throw error;
    }
  };

  registerChopper = async (credentials: ChopperRegistrationFormValues) => {
    this.registering = true;
    try {
      await agent.Account.registerChopper(credentials);
      runInAction(() => {
        this.deliveryLocation = null;
        this.registering = false;
      });
    } catch (error) {
      runInAction(() => (this.registering = false));
      throw error;
    }
  };

  setPhoto = (image: string) => {
    if (this.user) this.user.image = image;
  };

  refreshToken = async () => {
    this.stopRefreshTokenTimer();
    try {
      const user = await agent.Account.refreshToken();
      runInAction(() => (this.user = user));
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
    } catch (error) {
      console.log(error);
    }
  };

  private startRefreshTokenTimer(user: User) {
    const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  //#endregion
}
