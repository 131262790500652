import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/store/store";
import PaymentList from "./PaymentList";
import PaymentsEmptyPage from "./PaymentsEmptyPage";
import PaymentsTopMenu from "./PaymentsTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const PaymentsLayoutPage = ({ setPageTitle }: Props) => {
  const {
    paymentStore: { PaymentsByDate, loadPayments, loading },
  } = useStore();

  useEffect(() => {
    setPageTitle("Payments");
    loadPayments();
  }, [setPageTitle, loadPayments]);

  if (loading) <LoadingComponent />;

  return (
    <section>
      <PaymentsTopMenu />
      <section>
        {PaymentsByDate && PaymentsByDate.length > 0 ? (
          <PaymentList data={PaymentsByDate} />
        ) : (
          <PaymentsEmptyPage />
        )}
      </section>
    </section>
  );
};

export default observer(PaymentsLayoutPage);
