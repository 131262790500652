import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Grid, Input, List } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import Location from "../../../app/models/location";

interface Props {
  mobile?: boolean;
}

const desktopStyle = {
  width: "100%",
  margin: "3em 0em 2em",
};

const mobileStyle = {
  width: "100%",
  margin: "1em 0em 2em",
};

const inputDesktopStyle = {
  borderColor: "#b5cd15",
  fontSize: "1.3em",
  lineHeight: "1.4em",
};

const inputMobileStyle = {
  borderColor: "#b5cd15",
  fontSize: "1em",
  lineHeight: "1.1em",
};

const SelectLocationForm = ({ mobile }: Props) => {
  const {
    locationStore: { loadLocations, LocationsByName: locationsByName },
    userStore: { setDeliveryLocation },
  } = useStore();
  const [suggestions, setSuggestions] = useState<Location[]>([]);
  const [text, setText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    text: string
  ) => {
    let matches: Location[] = [];

    if (text.length > 0) {
      matches = locationsByName.filter((location) => {
        const regex = new RegExp(`${text}`, "gi");
        return location.name.match(regex);
      });

      setSuggestions(matches);
      setText(text);
    } else if (text.length === 0) {
      setText("");
      setSuggestions([]);
    }
  };

  const onSuggestHandler = (text: string) => {
    setText(text);
    setSuggestions([]);
    const location = locationsByName.filter(
      (location) => location.name === text
    )[0];
    setDeliveryLocation(location)
      .then(() => navigate("/vendors"))
      .catch((error) => console.log(error));
  };

  return (
    <div style={mobile ? mobileStyle : desktopStyle}>
      <Grid textAlign="left">
        <Grid.Column width={16}>
          <Form autocomplete="off">
            <Input
              icon="point"
              type="text"
              autocomplete="off"
              iconPosition="left"
              fluid
              name="location"
              style={mobile ? inputMobileStyle : inputDesktopStyle}
              action={{
                color: "olive",
                content: "Find Food",
              }}
              placeholder="Enter your location"
              size="huge"
              onChange={(e, d) => onChangeHandler(e, d.value)}
              onBlur={() => {
                setTimeout(() => {
                  setSuggestions([]);
                }, 1000);
              }}
              value={text}
            />
            {suggestions && (
              <List
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  width: mobile ? "71.5%" : "84%",
                  backgroundColor: "#fff",
                  color: "#888",
                  fontSize: mobile ? "1em" : "1.3em",
                  lineHeight: mobile ? "1em" : "1.5em",
                  marginTop: "0.15em",
                  paddingLeft: mobile ? "5px" : "10px",
                  borderRight: "solid 1px #b5cd15",
                }}
              >
                {suggestions.map((v, i) => (
                  <List.Item
                    className="suggestion"
                    key={i}
                    onClick={() => onSuggestHandler(v.name)}
                  >
                    {v.name}
                  </List.Item>
                ))}
              </List>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default observer(SelectLocationForm);
