import React from "react";

interface Props {
  fieldTitle: string;
  fieldContent: string;
}

const titleStyle = {
  fontSize: "14px",
  fontWeight: 300,
  color: "grey",
};

const contentStyle = {
  fontSize: "15px",
  fontWeight: 500,
  color: "black",
  marginBottom: "0.5em",
};

const Chops2uFieldDisplay = ({ fieldTitle, fieldContent }: Props) => {
  return (
    <div>
      <span style={titleStyle}>{fieldTitle}</span>
      <div style={contentStyle}>{fieldContent}</div>
    </div>
  );
};

export default Chops2uFieldDisplay;
