import { Media } from "../../..";
import AdminContainer from "../../../app/layout/container/AdminContainer";
import StoreSideNav from "../nav/StoreSideNav";
import StoreTopMenu from "../nav/StoreTopMenu";

interface IProps {
  title: string;
  children: React.ReactNode;
}
// const mainContentStyle = {
//   position: "fixed" as "fixed",
//   top: 0,
//   bottom: 0,
//   right: 0,
//   width: "calc(100% - 250px)",
//   minHeight: "100vh",
//   marginLeft: "250px",
//   display: "flex",
//   flexDirection: "column" as "column",
//   backgroundColor: "rgba(0,0,0,0.05)",
// };

// const sidebarStyles = {
//   position: "fixed" as "fixed",
//   top: 0,
//   left: 0,
//   bottom: 0,
//   width: "250px",
//   float: "left" as "left",
//   // background: "#767676",
//   display: "flex",
//   flex: "0 0 250px",
//   flexDirection: "column" as "column",
// };

const StoreDesktopContainer = ({ title, children }: IProps) => {
  return (
    <Media greaterThan="mobile">
      {/* <div style={{ display: "flex" }}>
          <div style={sidebarStyles}>
            <StoreSideNav />
          </div>
          <div style={mainContentStyle}>
            <div style={{ overflowY: "auto"}}>
              <StoreTopMenu title={title} />
              <div>{children}</div>
            </div>
          </div>
        </div> */}
      <AdminContainer
        sidebar={<StoreSideNav />}
        header={<StoreTopMenu title={title} />}
      >
        {children}
      </AdminContainer>
    </Media>
  );
};

export default StoreDesktopContainer;
