import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import isValid from "date-fns/isValid";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { BankAccountFormValues } from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import { observer } from "mobx-react-lite";

const validate = Yup.object({
  bankId: Yup.string().required(),
  accountName: Yup.string().required(),
  accountNumber: Yup.string().matches(
    /^[0-9]{10}$/,
    "'account number' must be 10 characters number"
  ),
});

const PayoutAccountForm = () => {
  const {
    restaurantStore: {
      restaurant,
      addOrUpdateBankAccount,
      submittingBankAccount,
    },
    bankStore: { banksByName, loadBanks, loading },
  } = useStore();

  const [formData, setFormData] = useState<BankAccountFormValues>({
    bankId: "",
    accountNumber: "",
    accountName: "",
    error: null,
  });

  useEffect(() => {
    loadBanks();
  }, [loadBanks]);

  useEffect(() => {
    if (restaurant?.bankAccount) {
      setFormData((prev) => ({ ...prev, ...restaurant.bankAccount }));
    }
  }, [restaurant?.bankAccount]);

  const handleFormSubmit = (
    values: BankAccountFormValues,
    { setErrors }: FormikHelpers<BankAccountFormValues>
  ) => {
    if (restaurant?.id) {
      addOrUpdateBankAccount(restaurant.id, values)
        .then((account) => setFormData((prev) => ({ ...prev, ...account })))
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Grid>
      <Grid.Column
        style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validate}
          onSubmit={handleFormSubmit}
        >
          {({ values, dirty, handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit} className="ui form">
              <Segment color="green" attached="top">
                <div>
                  <Header
                    textAlign="center"
                    size="small"
                    disabled
                    content={"Payout Account".toUpperCase()}
                    style={{ padding: "10px 0 5px 0" }}
                  />
                </div>
              </Segment>
              <Segment attached clearing>
                {/* Bank Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Bank Name
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uSelectInput
                      name="bankId"
                      options={banksByName}
                      loading={loading}
                      placeholder="Bank"
                    />
                  </div>
                </div>

                {/* Account Name Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Account Name
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput
                      name="accountName"
                      placeholder="Name on Account"
                    />
                  </div>
                </div>

                {/* Account Number */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Account Number
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput
                      name="accountNumber"
                      placeholder="Account Number"
                    />
                  </div>
                </div>

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
              </Segment>
              <Segment attached="bottom" clearing secondary>
                <div>
                  <Button
                    disabled={(!isValid && dirty) || submittingBankAccount}
                    loading={submittingBankAccount}
                    positive
                    type="submit"
                    floated="right"
                    content="Save Changes"
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(PayoutAccountForm);
