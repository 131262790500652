import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import County from "../models/county";

export default class CountyStore {
  countyRegistry = new Map<string, County>();
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get CountyByName() {
    return Array.from(this.countyRegistry.values()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  loadCounties = async () => {
    this.loading = true;
    try {
      let counties = await agent.Counties.list();
      runInAction(() => {
        counties.forEach((county) => {
          county = { ...county, text: county.name, value: county.id };
          this.countyRegistry.set(county.id, county);
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };
}
