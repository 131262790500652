import { List, Image } from "semantic-ui-react";
import { styled } from "styled-components";

/** Checkout Cart List Item */

export const EmptyCartImage = styled(Image)`
  height: 200px;
  margin: auto;
`;

export const CartTitle = styled.div`
  text-align: center;
  color: lightgrey;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 0.5em;
`;

export const CartText = styled.div`
  text-align: center;
  color: lightgrey;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const ListItem = styled(List.Item)`
  display: grid !important;
  grid-template-columns: max-content 1fr;
  padding-left: 1em !important;
  padding-right: 1em !important;
`;

export const ListItemDescription = styled.div`
  display: block !important;
  /* width: 22em; */
  width: 100%;
  position: relative;
  right: 0;
`;

export const Wrapper = styled.div`
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: flex !important;
  justify-content: space-between;
  width: inherit;
`;

export const TitleWrapper = styled.div`
  width: 52%;
`;

export const FoodTitle = styled.div`
  /* width: 52%; */
  line-height: 1.14em;
  max-height: 2.5em;
  overflow: hidden;
`;

export const FoodPrice = styled.div`
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 20%;
  text-align: right;
`;

export const CartButtonWrapper = styled.div`
  /* display: block; */
  padding: 2px;
  border: 0.5px solid lightgray;
  /* margin: .15rem .15rem .15rem .15rem !important; */
  /* float: right; */

  &:hover {
    border: 0.5px solid lightgray;
  }
`;

export const ButtonWrapperCircular = styled.div`
  width: auto;
  padding: 2px;
  border: 0.5px solid lightgray;
  border-radius: 1rem;
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.2);
  float: right;
  /* margin: .15rem .15rem .15rem .15rem !important; */
  /* z-index: 100000; */

  &:hover {
    border: 0.5px solid lightgray;
  }
`;