import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link, useNavigate, useLocation, Location } from "react-router-dom";
import {
  Grid,
  Header,
  Segment,
  Button,
  Message,
  Image,
} from "semantic-ui-react";
import Chops2uTextInputIcon from "../../../../app/common/forms/Chops2uTextInputIcon";
import { useStore } from "../../../../app/store/store";
import ValidationErrors from "../../../frontpage/errors/ValidationErrors";
import * as Yup from "yup";
import { ForgotFormValues } from "../../../../app/models/forgot";

const ForgotPassword = () => {
  const {
    userStore: { forgot, submitting, IsLogedIn },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from: Location })?.from?.pathname || "/";

  const initValues: ForgotFormValues = {
    email: "",
    error: "",
  };

  const validate = Yup.object({
    email: Yup.string().trim().email().required(),
  });

  useEffect(() => {
    if (IsLogedIn) navigate(from, { replace: true });
  }, [IsLogedIn, navigate, from]);

  const handleFormSubmit = (
    values: ForgotFormValues,
    { setErrors }: FormikHelpers<ForgotFormValues>
  ) => {
    forgot(values)
      .then(() =>
        navigate(`/account/reset-success?email=${values.email}`, {
          replace: true,
        })
      )
      .catch((error) => setErrors({ error: error.response.data }));
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Formik
          validationSchema={validate}
          onSubmit={handleFormSubmit}
          initialValues={initValues}
        >
          {({ handleSubmit, isSubmitting, dirty, errors, isValid }) => (
            <Form className="ui form error" onSubmit={handleSubmit}>
              <Segment raised stacked style={{ marginTop: "1em" }}>
                <Header
                  size="tiny"
                  disabled
                  content={"Forgot Password".toUpperCase()}
                />
                <Chops2uTextInputIcon
                  name="email"
                  icon="mail"
                  placeholder="Enter your email"
                />

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>

                <div style={{ padding: "10px 0px 5px" }} className="field">
                  <Button
                    loading={isSubmitting && submitting}
                    color="olive"
                    fluid
                    size="large"
                  >
                    Reset Password
                  </Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
        <Message>
          <Link to="/account/login" state={{ from: location }}>
            Back to login
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ForgotPassword);
