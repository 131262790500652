import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Header,
  Icon,
  Segment,
  Container,
  Button,
} from "semantic-ui-react";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      style={{ height: "calc(100vh - 150px)" }}
    >
      <Grid.Column style={{ maxWidth: "500px" }}>
        <Segment stacked>
          <Container textAlign="center">
            <Header as="h2" icon>
              <Icon name="hand paper" />
              Stop
              <Header.Subheader>
                Unauthorized: Your request could not be processed.
              </Header.Subheader>
            </Header>
            <Segment vertical>
              <Button
                color="olive"
                content="Go Back"
                onClick={() => goBack()}
              />
            </Segment>
          </Container>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Unauthorized;
