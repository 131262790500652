import { observer } from "mobx-react-lite";
import { Segment } from "semantic-ui-react";
import Order from "../../../../../../../app/models/order";
import OrderCellItem from "./OrderCellItem";

interface Props {
  title: string;
  orders: Order[];
  selectedOrder?: Order;
  loadOrder: (orderId: string) => void;
}

const OrderCellList = ({ title, orders, selectedOrder, loadOrder }: Props) => {
  return (
    <Segment
      vertical
      style={{
        borderRadius: "0",
        margin: "0.5em 0",
        background: "rgba(34,36,38,0.08)",
        paddingBottom: "0.05em",
      }}
    >
      <div>
        <div
          style={{
            width: "100%",
            paddingLeft: "1em",
            paddingRight: "1em",
          }}
        >
          <p
            style={{
              padding: "1em 0",
              borderBottom: "2px solid rgba(34,36,38,.15)",
              // backgroundColor: "#c4c3c5",
              fontSize: "12px",
              fontWeight: 600,
              letterSpacing: "2px",
              lineHeight: "1.5em",
              color: "GrayText",
              width: "100%",
            }}
          >
            {title.toUpperCase()}
          </p>
        </div>
        {orders.map((order) => (
          <OrderCellItem
            order={order}
            selectedOrder={selectedOrder}
            loadOrder={loadOrder}
          />
        ))}
      </div>
    </Segment>
  );
};

export default observer(OrderCellList);
