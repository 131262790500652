import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/store/store";
import CheckoutCart from "./cart/CheckoutCart";
import EmptyCart from "./cart/EmptyCart";

function Cart() {
  const {
    shoppingCartStore: {
      ShoppingCart,
      ItemsCount,
      OrderSubTotal,
      OtherCharges,
      DeliveryFee,
      OrderTotalAmount,
    },
  } = useStore();

  return (
    <>
      {ItemsCount > 0 ? (
        <CheckoutCart
          cart={ShoppingCart}
          itemsTotal={OrderSubTotal}
          otherCharges={OtherCharges}
          deliveryFee={DeliveryFee}
          total={OrderTotalAmount}
          mobile={false}
        />
      ) : (
        <EmptyCart />
      )}
    </>
  );
}
export default observer(Cart);
