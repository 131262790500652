import { observer } from "mobx-react-lite";
import { Grid, Header, Icon, SemanticCOLORS } from "semantic-ui-react";

interface Props {
  title: string;
  subtitle: string;
  color: SemanticCOLORS;
}

const OrderEmptyPanel = ({
  title,
  subtitle,
  color,
}: Props) => {
  return (
    <>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{
          minHeight: "285px",
        }}
      >
        <Grid.Column width={16}>
          <Header as="h4" icon>
            <Icon name="folder outline" size="huge" color={color} />
            <Header.Content>{title}</Header.Content>
            <Header.Subheader style={{ padding: "10px 0 5px 0" }}>
              {subtitle}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(OrderEmptyPanel);
