import { Container, Grid } from "semantic-ui-react";
import styled from "styled-components";

export const CheckoutContainer = styled(Container)`
  padding-top: 1px;
  padding-bottom: 1em;
`;

export const GridRow = styled(Grid.Row)`
  padding-top: 0px !important;
`;

export const GridColumn = styled(Grid.Column)`
  padding-top: 0px !important;
`;