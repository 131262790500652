import { Link } from "react-router-dom";
import { Button, Icon, ItemHeader, Menu, Sidebar, Image } from "semantic-ui-react";
import { useStore } from "../../store/store";

interface Props {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
}

const MobileSidebarNav = ({ visible, setVisibility }: Props) => {
  const { userStore: { IsLogedIn }} = useStore();
  return (
    <Sidebar
      as={Menu}
      onHide={() => setVisibility(false)}
      vertical
      animation="overlay"
      visible={visible}
    >
      <Menu.Item>
        <ItemHeader>
          <div
            style={{
              display: "flex",
              alignContent: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Image
              as={Link}
              to="/"
              src="/images/logo.png"
              size="small"
              style={{ width: "120px", height: "auto" }}
            />
            <Button
              icon="cancel"
              name="cancel"
              onClick={() => setVisibility(false)}
              style={{
                float: "right",
                backgroundColor: "transparent",
                padding: "2px 0px",
              }}
            />
          </div>
        </ItemHeader>
      </Menu.Item>

      <Menu.Item as={Link} to="/vendors">
        <Icon
          color="olive"
          style={{
            float: "left",
            marginBottom: "0px",
            marginRight: "5px",
            padding: "0px",
          }}
          name="utensils"
        />
        <span style={{ color: "#a0b605" }}>Vendors</span>
      </Menu.Item>
      {IsLogedIn && (
        <>
          <Menu.Item as={Link} to="/account/profile">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="user"
            />
            <span style={{ color: "#a0b605" }}>My Profile</span>
          </Menu.Item>
          <Menu.Item as={Link} to="/account">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="cart arrow down"
            />
            <span style={{ color: "#a0b605" }}>Orders</span>
          </Menu.Item>
          <Menu.Item as={Link} to="/account/favorites">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="like"
            />
            <span style={{ color: "#a0b605" }}>Favorites</span>
          </Menu.Item>
          <Menu.Item as={Link} to="/account/address-book">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="address book"
            />
            <span style={{ color: "#a0b605" }}>Address Book</span>
          </Menu.Item>
        </>
      )}
      <Menu.Item as={Link} to="/pages/helpandsupport">
        <Icon
          color="olive"
          style={{
            float: "left",
            marginBottom: "0px",
            marginRight: "5px",
            padding: "0px",
          }}
          name="shield alternate"
        />
        <span style={{ color: "#a0b605" }}>Help & Support</span>
      </Menu.Item>
    </Sidebar>
  );
}
export default MobileSidebarNav;