import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Segment, Header, Grid, Button } from "semantic-ui-react";
import { timeList } from "../../../../../app/common/options/time-list";
import { OpeningInfo } from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import DeleteVendorOpeningInfoForm from "../forms/DeleteVendorOpeningInfoForm";

interface Props {
  setEditMode: (state: boolean) => void;
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const VendorOpeningHoursDetailsPage = ({ setEditMode }: Props) => {
  const {
    vendorStore: { loadOpeningInfos },
    modalStore: { openModal },
  } = useStore();
  const [openingInfos, setOpeningInfos] = useState<OpeningInfo[]>([]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadOpeningInfos(id).then((result) => setOpeningInfos(result!));
    }
  }, [id, loadOpeningInfos]);

  const handleDeleteButton = async () => {
    console.log("Handle Delete Opening Hours Info");
    openModal(<DeleteVendorOpeningInfoForm id={id!} />);
  };

  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Segment attached="top">
        <Header color="olive" as="h2" content="Vendor Opening Hours" />
      </Segment>
      <Segment attached>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={3}></Grid.Column>
            <Grid.Column width={3}>Open</Grid.Column>
            <Grid.Column width={9}>Opening {"  :  "} Closing</Grid.Column>
          </Grid.Row>
          {openingInfos.length > 0 &&
            openingInfos.map((openingInfo) => (
              <Grid.Row columns={4}>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={3}>
                  <div className="field">
                    <label>{daysOfWeek[openingInfo.dayOfWeek!]}</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={3}>
                  {openingInfo.open ? "Open" : "Close"}
                </Grid.Column>
                <Grid.Column width={9}>
                  {openingInfo.open ? (
                    <span>
                      {timeList.find((x) => x.value === openingInfo.openAt)!
                        .text +
                        " : " +
                        timeList.find((x) => x.value === openingInfo.closeAt)!
                          .text}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid.Column>
              </Grid.Row>
            ))}
        </Grid>
      </Segment>
      <Segment secondary attached="bottom" clearing>
        <Button
          onClick={() => handleDeleteButton()}
          basic
          disabled={openingInfos.filter((x) => x.open === "true").length === 0}
          color="orange"
          content="Delete"
          floated="left"
          style={{ width: "10em" }}
        />

        <Button
          onClick={() => setEditMode(true)}
          basic
          color="olive"
          content="Edit"
          floated="right"
          style={{ width: "10em" }}
        />
      </Segment>
    </Container>
  );
};

export default observer(VendorOpeningHoursDetailsPage);
