import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import Menu, { GroupingOptions } from "../../../../../app/models/menu";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import { useStore } from "../../../../../app/store/store";
import { observer } from "mobx-react-lite";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";

interface Props {
  menuId?: string;
}

interface FormData extends Menu {
  error: any;
}

const MenuForm = ({ menuId }: Props) => {
  const {
    modalStore: { closeModal },
    restaurantStore: { restaurant, createMenu, updateMenu },
    menuStore: { loadMenu },
  } = useStore();

  const validate = Yup.object({
    title: Yup.string().required(),
  });

  const [formTitle, setFormTitle] = useState("Create Menu");
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState<FormData>({
    title: "",
    description: "",
    displayOrder: 0,
    published: true,
    groupingOption: undefined,
    deleted: false,
    foods: [],
    error: "",
  });

  useEffect(() => {
    if (menuId) {
      setFormTitle("Update Menu");
      setLoading(true);
      loadMenu(menuId).then((result) => {
        setMenu({ ...result!, error: "" });
        setLoading(false);
      });
    }
  }, [menuId, setFormTitle, setLoading, loadMenu, setMenu]);

  return (
    <Container>
      <Formik
        initialValues={menu}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { setErrors }) => {
          let vendorId = restaurant?.id;
          if (vendorId) {
            let newMenu = { ...values, vendorId };
            if (newMenu.id) {
              updateMenu(newMenu)
                .then(() => closeModal())
                .catch((error) => setErrors({ error }));
            } else {
              createMenu(newMenu)
                .then(() => closeModal())
                .catch((error) => setErrors({ error }));
            }
          }
        }}
      >
        {({ values, handleSubmit, dirty, isSubmitting, isValid, errors }) => (
          <Form onSubmit={handleSubmit} className="ui form">
            <Segment loading={loading} attached="top" color="green">
              <div>
                <Header
                  textAlign="center"
                  color="grey"
                  as="h4"
                  content={formTitle.toUpperCase()}
                  style={{ padding: "10px 0 5px 0" }}
                />
              </div>
            </Segment>
            <Segment attached clearing>
              <Chops2uTextInput
                name="title"
                label="Title"
                placeholder="Menu Title"
              />

              <Chops2uTextAreaInput
                name="description"
                rows={2}
                label="Description"
                placeholder="Menu Description"
              />

              <Chops2uTextInput
                name="displayOrder"
                label="Display Order"
                placeholder="Display order"
              />

              <Chops2uSelectInput
                name="groupingOption"
                options={GroupingOptions}
                label="Grouping Option"
                placeholder="Select grouping option"
              />

              <Chops2uCheckbox
                name="published"
                label="Publish menu to display on vendor page"
              />

              <div className="field">
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              </div>

              {/* <div className="field">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div> */}
            </Segment>
            <Segment attached="bottom" clearing secondary>
              <div>
                <Button
                  floated="left"
                  type="button"
                  content="Cancel"
                  style={{ width: "150px" }}
                  onClick={() => closeModal()}
                />
                <Button
                  positive
                  disabled={(!isValid && dirty) || isSubmitting}
                  loading={isSubmitting}
                  floated="right"
                  type="submit"
                  content="Submit"
                  style={{ width: "150px" }}
                />
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(MenuForm);
