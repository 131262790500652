import { Sidebar } from "semantic-ui-react";
import { Media } from "../../..";
import MobileSidebarNav from "../nav/MobileSidebarNav";
import MobileTopNav from "../nav/MobileTopNav";
import { useState } from "react";
import MobileFooterMenu from "../nav/MobileFooterMenu";

interface Props {
  children: React.ReactNode;
}

const MobileContainer = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <Media at="mobile">
      <MobileFooterMenu />
      <Sidebar.Pushable>
        <MobileSidebarNav setVisibility={setVisible} visible={visible} />
        <Sidebar.Pusher>
          <div>
            <MobileTopNav setVisibility={setVisible} />
            <div style={{ marginTop: "3.15em" }}>{children}</div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
};
export default MobileContainer;
