import React from "react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const MakesEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="No Registered Motorcycle Model Yet."
      subtitle="Add motorcycle model to start registering bikes."
      color="olive"
    />
  );
};

export default MakesEmptyPanel;
