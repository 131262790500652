import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import SectionFooter from "../home/SectionFooter";
import DesktopContainer from "../../app/layout/container/DesktopContainer";
import MobileContainer from "../../app/layout/container/MobileContainer";

const FrontpageLayout = () => {
  return (
    <>
      <DesktopContainer>
        <div>
          <Outlet />
        </div>
        <div>
          <SectionFooter />
        </div>
      </DesktopContainer>
      <MobileContainer>
        <div>
          <Outlet />
        </div>
        <div>
          <SectionFooter mobile={true} />
        </div>
      </MobileContainer>
    </>
  );
};

export default observer(FrontpageLayout);
