import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Header, Segment, Button } from "semantic-ui-react";
import Chops2uFieldDisplay from "../../../../../app/common/components/Chops2uFieldDisplay";
import Vendor from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";

interface Props {
  setEditMode: (state: boolean) => void;
}

const VendorDetailsPage = ({ setEditMode }: Props) => {
  const {
    commonStore: { setAdminPageTitle },
    vendorStore: { loadVendor },
  } = useStore();
  const [vendor, setVendor] = useState<Vendor>({
    id: "",
    title: "",
    description: "",
    address: "",
    state: "",
    city: "",
    active: false,
    published: false,
    telephoneNumber: "",
    email: "",
    location: undefined,
    isOpenNow: false,
    categories: [],
    categoryIds: [],
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadVendor(id).then((result) => {
        setVendor(result!);
        setAdminPageTitle("Vendor: " + vendor.title);
      });
    }
  }, [setAdminPageTitle, loadVendor, id, vendor.title]);
  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Header as="h2" attached="top" color="olive">
        Vendor Details
      </Header>
      <Segment attached className="display" clearing>
        <Chops2uFieldDisplay fieldTitle="Title" fieldContent={vendor.title} />

        <Chops2uFieldDisplay
          fieldTitle="Description"
          fieldContent={vendor.description}
        />

        <Chops2uFieldDisplay
          fieldTitle="Category"
          fieldContent={vendor.categories.map((v) => v.name).join(", ")}
        />

        <Chops2uFieldDisplay
          fieldTitle="Address"
          fieldContent={vendor.address!}
        />

        <Chops2uFieldDisplay fieldTitle="City" fieldContent={vendor.city!} />

        <Chops2uFieldDisplay
          fieldTitle="Location"
          fieldContent={
            vendor.location && vendor.location!.name
              ? vendor.location!.name
              : ""
          }
        />

        <Chops2uFieldDisplay
          fieldTitle="Telephone Number"
          fieldContent={vendor.telephoneNumber!}
        />

        <Chops2uFieldDisplay fieldTitle="Email" fieldContent={vendor.email!} />

        <Chops2uFieldDisplay
          fieldTitle="Satus"
          fieldContent={vendor.active ? "Active" : "Not Active"}
        />

        <Chops2uFieldDisplay
          fieldTitle="Published"
          fieldContent={vendor.published ? "Published" : "Not Published"}
        />
      </Segment>
      <Segment secondary attached="bottom" clearing>
        <Button
          onClick={() => setEditMode(true)}
          basic
          color="olive"
          content="Edit"
          floated="right"
          style={{ width: "10em" }}
        />
      </Segment>
    </Container>
  );
};

export default observer(VendorDetailsPage);
