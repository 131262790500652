import { Formik, Form, FormikHelpers, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import {
  VendorCategory,
  VendorFormValues,
} from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import * as Yup from "yup";
import "yup-phone";
import { v4 as uuid } from "uuid";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { isValid } from "date-fns";

interface Props {
  setEditMode?: (state: boolean) => void;
}

export default observer(function VendorForm({ setEditMode }: Props) {
  const {
    vendorStore,
    categoryStore,
    locationStore,
    commonStore: { setAdminPageTitle },
  } = useStore();
  const { loadVendor, create, update, submitting } = vendorStore;
  const { categoriesByTitle, loadCategories } = categoryStore;
  const { LocationsByName: locationsByName, loadLocations } = locationStore;

  const { id } = useParams();

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(3, "The Title field most contain 5 or more characters")
      .required(),
    description: Yup.string().min(5).required(),
    categoryIds: Yup.array().min(1, "category field must have at least 1 item"),
    address: Yup.string().required(),
    city: Yup.string().required(),
    email: Yup.string().required().email(),
    telephoneNumber: Yup.string().required().phone(),
    active: Yup.bool().notRequired(),
    published: Yup.bool().notRequired(),
  });

  const [vendor, setVendor] = useState<VendorFormValues>({
    id: "",
    title: "",
    description: "",
    address: "",
    state: "",
    city: "",
    active: false,
    published: false,
    telephoneNumber: "",
    email: "",
    locationId: undefined,
    isOpenNow: false,
    categories: [],
    categoryIds: [],
    error: null,
  });

  useEffect(() => {
    loadCategories();
    loadLocations();
    if (id)
      loadVendor(id).then((vendor) => {
        setVendor((prev) => ({ ...prev, ...vendor }));
      });
    setAdminPageTitle("Add Vendor");
  }, [id, loadVendor, loadCategories, setAdminPageTitle, loadLocations]);

  const handleFormSubmit = (
    values: VendorFormValues,
    { setErrors }: FormikHelpers<VendorFormValues>
  ) => {
    if (values.id) {
      values.categories = values.categoryIds!.map<VendorCategory>(
        (categoryId) => Object.assign({ categoryId, vendorId: values.id })
      );
      console.log(values);
      update(values.id, values)
        .then(() => setEditMode!(false))
        .catch((error) => setErrors({ error }));
    } else {
      const vendorId = uuid();
      values.categories = values.categoryIds!.map<VendorCategory>(
        (categoryId) => Object.assign({ categoryId, vendorId })
      );
      var newVendor = {
        ...values,
        id: vendorId,
      };
      create(newVendor)
        .then(() => setEditMode!(false))
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={vendor}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleSubmit, dirty, errors }) => (
          <Segment vertical>
            <Form className="form ui" onSubmit={handleSubmit}>
              <Header as="h2" attached="top" color="olive">
                Vendor Form
              </Header>
              <Segment attached>
                <Chops2uTextInput
                  name="title"
                  label="Title"
                  placeholder="Provide a name for your business"
                />

                <Chops2uTextAreaInput
                  rows={4}
                  name="description"
                  label="Description"
                  placeholder="Give a brief description of your store"
                />

                <Chops2uSelectInput
                  name="categoryIds"
                  options={categoriesByTitle}
                  placeholder="Please state the type of food you provide"
                  multiple={true}
                  label="Category"
                />

                <Chops2uTextInput
                  name="address"
                  label="Address"
                  placeholder="Address"
                />

                <Chops2uTextInput name="city" label="City" placeholder="City" />

                <Chops2uSelectInput
                  name="locationId"
                  options={locationsByName}
                  placeholder="Select location"
                  label="Location"
                />

                <Chops2uTextInput
                  name="telephoneNumber"
                  label="Telephone"
                  placeholder="Telephone"
                />

                <Chops2uTextInput
                  name="email"
                  label="Email"
                  placeholder="Email"
                />

                <Chops2uCheckbox
                  name="active"
                  label="Set Store Active to Receive Order"
                />

                <Chops2uCheckbox
                  name="published"
                  label="Set True to publish store to front page"
                />

                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />

                {/* <div className="field">{JSON.stringify(vendor, null, 2)}</div> */}
              </Segment>
              <Segment secondary clearing attached="bottom">
                <Button
                  loading={submitting}
                  disabled={(!isValid && dirty) || submitting}
                  color="olive"
                  basic
                  type="submit"
                  content="Submit"
                  floated="right"
                  style={{ width: "10em" }}
                />
                <Button
                  color="orange"
                  type="button"
                  basic
                  content="Cancel"
                  floated="left"
                  onClick={() => setEditMode!(false)}
                  style={{ width: "10em", marginRight: "2em" }}
                />
              </Segment>
            </Form>
          </Segment>
        )}
      </Formik>
    </Container>
  );
});
