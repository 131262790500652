import { Segment, Header } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";

interface Props {
  order: Order;
}

const Statements = [
  "Please confirm order so that chopper can be assign for pickup",
  "Please confirm order is finish for chopper to pickup",
  "Please confirm that order has been pcked-up by chopper",
];

const orderStatus = [
  "Prepared",
  "Preparing",
  "Ready",
  "InTransit",
  "Delivered",
  "Cancelled"
]

const OrderDetailsBodyHeader = ({ order }: Props) => {
  return (
    <Segment vertical>
      <Header as="h4">
        <Header.Subheader style={{ fontSize: "22px", letterSpacing: "2px" }}>
          Order {order.status}
        </Header.Subheader>
        <Header.Content
          style={{ fontSize: "15px", color: "#777", letterSpacing: "1px" }}
        >
          {Statements[orderStatus.indexOf(order.status)]}
        </Header.Content>
      </Header>
    </Segment>
  );
};

export default OrderDetailsBodyHeader;
