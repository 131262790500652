import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Bike from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";

interface Props {
  bike: Bike;
}

const DeleteBikeForm = ({ bike }: Props) => {
  const {
    modalStore: { closeModal },
    bikeStore: { delete: deleteBike, deleting },
  } = useStore();

  const handleDeleteCommand = () => {
    deleteBike(bike.id!).then(() => closeModal());
  };

  return (
    <>
      <Modal.Header>
        <Header as="h4" content="Delete Bike" />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following Bike?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {bike.make?.name + " " + bike.chasisNumber}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          color="orange"
          labelPosition="left"
          floated="left"
          content="No"
          onClick={closeModal}
          style={{ width: "110px" }}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          color="olive"
          loading={deleting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
          style={{ width: "110px" }}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteBikeForm);
