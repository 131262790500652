import { useEffect } from "react";
import SupportPanel from "./SupportPanel";

interface Props {
  setPageTitle: (title: string) => void;
}

const SupportPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Technical Support");
  }, [setPageTitle]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <SupportPanel />
    </div>
  );
};

export default SupportPageLayout;
