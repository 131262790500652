import { observer } from "mobx-react-lite";
import { Image } from "semantic-ui-react";
import VendorLocation from "../vendors/common/VendorLocation";
import Location from "../../../app/models/location";
import { CartHeaderTitle } from "./cartStyles";
import { Link } from "react-router-dom";

interface Props {
  vendorId: string;
  title: string;
  location: Location;
  image?: string;
}

const CartHeader = ({ vendorId, title, location, image }: Props) => {
  return (
    <div title={title}>
      <Link to={`/vendors/${vendorId}`}>
        <Image
          floated="left"
          size="tiny"
          src={image || "/images/placeholder.png"}
        />
        <CartHeaderTitle as="h5">
          {title}
          <VendorLocation location={location} />
        </CartHeaderTitle>
      </Link>
    </div>
  );
};

export default observer(CartHeader);
