import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
  Image,
} from "semantic-ui-react";

export default function NotFound() {
  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ height: "80vh" }}>
      <Grid.Column style={{ maxWidth: "500px" }}>
        <Image
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Segment size="tiny" placeholder stacked>
          <Container textAlign="center">
            <Header as="h2" icon>
              <Icon name="search" />
              Ooops!
              <Header.Subheader>
                Error 404: We couldn't find what you where looking for.
              </Header.Subheader>
            </Header>
            <Segment vertical>
              <Button
                as={Link}
                to="/vendors"
                bsic
                color="olive"
                content="Go back to vendors"
              />
            </Segment>
          </Container>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
