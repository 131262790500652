import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import AppUser from "../models/app-user";
import { UserAccount, UserRole } from "../models/user-account";

export default class AccountManagerStore {
    userRegistry = new Map<string, AppUser>();
    accountTypeRegistery = new Map<string, UserAccount>();
    roleRegistry = new Map<string, UserRole>();
    loading = false;
    submitting = false;
    deleting = false;
    loadingUsers = false;

    constructor() {
        makeAutoObservable(this);
    }

    get Users() {
        return Array.from(this.userRegistry.values());
    }

    get AccountTypes() {
        return Array.from(this.accountTypeRegistery.values());
    }

    get roles() {
        return Array.from(this.roleRegistry.values());
    }

    get systemRoles() {
        return Array.from(this.roleRegistry.values()).filter(
            (role) => role.isSystem
        );
    }

    get userRoles() {
        return Array.from(this.roleRegistry.values()).filter(
            (role) => role.isSystem === false
        );
    }

    private getAccountType = (id: string) => {
        return this.accountTypeRegistery.get(id);
    };

    loadUsers = async () => {
        this.loadingUsers = true;
        try {
            let users = await agent.AccountManager.list();
            runInAction(() => {
                users.forEach((user) => {
                    this.userRegistry.set(user.id!, user);
                })
                this.loadingUsers = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => this.loadingUsers = false);
        }
    }

    loadAccountTypes = async () => {
        this.loading = true;
        try {
            const accountTypes = await agent.AccountTypes.list();
            runInAction(() => {
                accountTypes.forEach((accountType) => {
                    this.accountTypeRegistery.set(accountType.id, accountType);
                });
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    loadAccountType = async (accountTypeId: string) => {
        this.loading = true;
        try {
            let accountType = await agent.AccountTypes.details(accountTypeId);
            if (accountType) {
                runInAction(() => {
                    this.accountTypeRegistery.set(accountType.id, accountType);
                    this.loading = false;
                });
            }
            return accountType;
        } catch (error) {
            console.log(error);
        }
    };

    addRoleToAccount = async (accountTypeId: string, roleName: string) => {
        this.submitting = true;
        try {
            await agent.AccountTypes.addRole(accountTypeId, roleName);
            let accountType = this.getAccountType(accountTypeId);
            if (accountType) {
                accountType.roles.push({ name: roleName, isSystem: false });
                runInAction(() => {
                    this.accountTypeRegistery.set(accountType!.id, accountType!);
                    this.submitting = false;
                });
            }
        } catch (error) {
            console.log(error);
            runInAction(() => (this.submitting = false));
        }
    };

    removeRoleFromAccount = async (accountTypeId: string, roleName: string) => {
        this.deleting = true;
        try {
            await agent.AccountTypes.removeRole(accountTypeId, roleName);
            let accountType = this.getAccountType(accountTypeId);
            if (accountType) {
                accountType.roles = accountType.roles.filter(
                    (acc) => acc.name.toLocaleLowerCase() !== roleName.toLocaleLowerCase()
                );
                runInAction(() => {
                    this.accountTypeRegistery.set(accountType!.id, accountType!);
                    this.deleting = false;
                });
            }
        } catch (error) {
            console.log(error);
            runInAction(() => (this.deleting = false));
        }
    };

    loadRoles = async () => {
        this.loading = true;
        try {
            let roles = await agent.AccountManager.roleList();
            runInAction(() => {
                this.roleRegistry.clear();
                roles.forEach((role) => {
                    this.roleRegistry.set(role.name, role);
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    loadRole = async (name: string) => {
        this.loading = true;
        try {
            let role = await agent.AccountManager.roleDetails(name);
            if (role) {
                runInAction(() => {
                    this.roleRegistry.set(role.name, role);
                    this.loading = false;
                });
            }
            return role;
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    createRole = async (name: string) => {
        this.submitting = true;
        try {
            let newRole = await agent.AccountManager.createRole(name);
            if (newRole) {
                runInAction(() => {
                    this.roleRegistry.set(newRole.name, newRole);
                    this.submitting = false;
                });
            }
            return newRole;
        } catch (error) {
            // console.log(error);
            runInAction(() => (this.submitting = false));
            throw error;
        }
    };

    deleteRole = async (name: string) => {
        this.deleting = true;
        try {
            await agent.AccountManager.deleteRole(name);
            runInAction(() => {
                this.roleRegistry.delete(name);
                this.deleting = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => (this.deleting = false));
        }
    };
}