import Vendor from "../../../../app/models/vendor";

interface Props {
  vendor: Vendor;
}

const VendorAddress = ({ vendor }: Props) => {
  return (
    <div style={{ color: "#ccc" }}>
      {vendor?.location
        ? vendor.address +
          ", " +
          vendor.location?.name +
          ", " +
          vendor.location.city?.name
        : vendor.address}
    </div>
  );
};

export default VendorAddress;
