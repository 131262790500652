import { Dropdown, Grid, Header, List, Menu } from "semantic-ui-react";
import { styled } from "styled-components";

/** Navigation **/
export const StoreMenu = styled(Menu)`
  margin: 0;
  border-radius: 0px;
  z-index: 1000;
`;

/** Side Navigation */
export const SideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
`;

export const SideNavMenu = styled(Menu)`
  border: none;
  box-shadow: none;
  height: auto;
  border-radius: 0;
`;

export const SideNavBannerWrapper = styled.div`
  flex: 0 0 90px;
  background-color: gray;
  border-bottom: solid 1.5px green;
`;

export const SideNavMenuWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  scrollbar-color: "darkgray gray";
  scrollbar-width: thin;
  background-color: #767676;
`;

export const SideNavFooterWrapper = styled.div`
  flex: 0 0 60px;
  margin-top: 0;
  padding-top: 1em;
  padding-left: 1.5em;
  padding-bottom: 3em;
  height: auto;
  overflow-y: auto;
  scrollbar-color: "darkgray gray";
  scrollbar-width: thin;
  background-color: gray;
  border-top: solid 1.5px green;
`;

export const SideNavBannerHeader = styled(Header)`
  padding-left: 15px; 
  margin-bottom: 0; 
  line-height: 40px;
`;

//         style={{ marginTop: "0", paddingLeft: "15px", width: "230px" }}
export const RestaurentName = styled(Header)`
  margin-top: 0;
  padding-left: 15px; 
  width: 230px;
  color: #eee;
`;

/** Top Menu Pannel */
export const MenuList = styled(List)`
  padding: 1em 0 1em 0 !important;
  margin-top: -1em !important;
  font-size: 1.2em !important;
  z-index: 1001;
`;

export const DropdownHeader = styled(Dropdown.Header)`
  background: #ecf9ee !important;
  padding-top: 0;
`;

/** Support */

/** Ticket */
export const TicketWrapper = styled(Grid)`
  min-height: calc(100vh - 100px) !important;
`;

export const Container = styled.div`
  width: 600px;
  padding: 10px;
  margin: auto;
`;

export const Text = styled(Header)`
  padding: 10px 0 5px 0; 
  font-size: 1em;
`;

export const SubText = styled(Header.Subheader)`
  padding: 10px 0 5px 0 !important; 
  font-size: 1.1em !important;
`;

export const Wrapper700 = styled.div`
  max-width: 700px;
  margin: auto;
`;

export const Wrapper100 = styled.div`
  width: 100% !important;
`;

/** Settings */
export const SettingsWrapper = styled.div`
  display: flex; 
  flex-direction: column;
`;

export const MenuItem = styled(Menu.Item)`
  padding: 20px 0 20px 0;
`;

export const ContentWrapper = styled.div`
  padding-top: 25px !important;
`;

export const StaffListWrapper = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-Direction: column;
  row-gap: 1em;
`;

export const StaffGridColumn = styled(Grid.Column)`
  width: 80% !important;
`;

/** Settings - Photo Upload */
export const PhotoSection = styled.div`
  position: relative !important;
  width: 100% !important;
`;

export const PhotoPreview = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px dashed grey;
  overflow: hidden;
`;