import { observer } from "mobx-react-lite";
import { Grid, Header, Icon, Image, Label, Segment } from "semantic-ui-react";
import { timeLaps } from "../../../../app/common/util/util";
import Order from "../../../../app/models/order";

interface UserOrderProps {
  order: Order;
  mobile?: boolean;
}

// const lineContainer = {
//   display: "flex",
//   alignItems: "baseline"
// }

// const lineStye = {
//   borderBottom: "6px solid #767676",
//   width: "50px",
//   margin: "auto",
// };

// const lineStyeActive = {
//   borderBottom: "6px solid #b5cc18",
//   width: "50px",
//   margin: "auto",
// }

const OrderItem = ({ order, mobile }: UserOrderProps) => {
  return (
    <Segment style={{ borderRadius: "0px", padding: "0px" }} clearing>
      <Grid padded>
        <Grid.Row
          style={{
            background: "rgba(0,0,0,.05)",
            paddingTop: mobile ? "10px" : "",
            paddingBottom: mobile ? "5px" : "",
          }}
          columns={1}
        >
          <Grid.Column>
            <Grid width={3}>
              <Grid.Column computer={4} tablet={4} mobile={4}>
                <div>
                  <Header
                    as="h5"
                    style={{
                      letterSpacing: mobile ? "" : "1px",
                      fontSize: mobile ? "0.85em" : "",
                    }}
                  >
                    {"Order: #" + order.orderNumber}
                  </Header>
                </div>
                <div style={mobile ? { fontSize: "0.85em" } : {}}>
                  {timeLaps(order.createdOnUtc)}
                </div>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={8}>
                <div>
                  <Header as="h5" style={{ fontSize: mobile ? "0.85em" : "" }}>
                    {order.vendorName}
                  </Header>
                </div>
                <div
                  style={{
                    color: "GrayText",
                    fontSize: mobile ? "0.85em" : "",
                  }}
                >
                  {order.vendorAddress}
                </div>
                <div
                  style={{
                    color: "GrayText",
                    fontSize: mobile ? "0.85em" : "",
                  }}
                >
                  {order.vendorLocation}
                </div>
              </Grid.Column>
              <Grid.Column computer={4} tablet={4} mobile={4}>
                <div>
                  <Header as="h5" style={{ fontSize: mobile ? "0.85em" : "" }}>
                    Status
                  </Header>
                </div>
                <div>
                  <Label
                    color="olive"
                    style={{
                      fontSize: mobile ? "0.75em" : "0.8em",
                      letterSpacing: mobile ? "1px" : "2px",
                    }}
                  >
                    {order.status}
                  </Label>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: "0px" }} columns={1}>
          <Grid.Column>
            <div style={{ color: "GrayText", marginBottom: "5px" }}>
              <Header as="h5" style={{ fontSize: mobile ? "0.85em" : "" }}>
                Delivery Address
              </Header>
            </div>
            <div>
              <Header
                as="h5"
                style={{
                  letterSpacing: mobile ? "1px" : "2px",
                  fontSize: mobile ? "0.85em" : "",
                }}
              >
                {order.deliveryAddress?.name}
              </Header>
            </div>
            <div style={{ fontSize: mobile ? "0.85em" : "" }}>
              <Icon name="phone" />
              {order.deliveryAddress?.telephone}
            </div>
            <div style={{ fontSize: mobile ? "0.85em" : "" }}>
              <Icon name="point" />
              {order.deliveryAddress}
            </div>
            <div style={{ fontSize: mobile ? "0.85em" : "" }}>
              <Icon /> {order.deliveryAddress?.location?.name}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: "0px" }} columns={1}>
          <Grid.Column>
            <div style={{ color: "GrayText" }}>
              <Header as="h5" style={{ fontSize: mobile ? "0.85em" : "" }}>
                Order Summary
              </Header>
            </div>
            <div
              style={{
                padding: "5px 0px",
                width: mobile ? "" : "70%",
                fontSize: mobile ? "0.86em" : "",
              }}
            >
              {order.items &&
                order.items.map((orderItem) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "5px",
                      padding: mobile ? "1px" : "5px 0px",
                    }}
                  >
                    <div style={{ flex: "0 1 10%" }}>
                      <Image
                        size="mini"
                        src={orderItem.image || "/images/food-placeholder.jpg"}
                      />
                    </div>
                    <div style={{ textAlign: "left", flex: "0 0 70%" }}>
                      {orderItem.title}
                    </div>
                    <div style={{ flex: "0 0 20%", textAlign: "end" }}>
                      {"N " +
                        orderItem.price.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </div>
                  </div>
                ))}
            </div>
            <div
              style={{
                width: mobile ? "" : "70%",
                padding: mobile ? "" : "5px 0px",
                borderTop: "1.5px solid #767676",
                fontSize: mobile ? "0.85em" : "",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    flex: "0 0 80%",
                    textAlign: "end",
                    padding: "0px 5px",
                  }}
                >
                  Items Subtotal
                </div>
                <div style={{ flex: "0 0 20%", textAlign: "end" }}>
                  {"N " +
                    order.orderSubTotal.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    flex: "0 0 80%",
                    textAlign: "end",
                    padding: "0px 5px",
                  }}
                >
                  Delivery Fee
                </div>
                <div style={{ flex: "0 0 20%", textAlign: "end" }}>
                  {"N " +
                    order.deliveryFee.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                </div>
              </div>
              {order.otherCharges > 0 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      flex: "0 0 80%",
                      textAlign: "end",
                      padding: "0px 5px",
                    }}
                  >
                    Container Charges
                  </div>
                  <div style={{ flex: "0 0 20%", textAlign: "end" }}>
                    {"N " +
                      order.otherCharges.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: 600,
                }}
              >
                <div
                  style={{
                    flex: "0 0 80%",
                    textAlign: "end",
                    padding: "0px 5px",
                  }}
                >
                  Order Total
                </div>
                <div style={{ flex: "0 0 20%", textAlign: "end" }}>
                  {"N " +
                    order.orderTotal.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(OrderItem);
