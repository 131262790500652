import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Food, { AssociatedFoodsTemplateProps } from "../models/food";

export default class FoodStore {
  foodRegistry = new Map<string, Food>();
  associatedFoodRegistry = new Map<string, Food>();
  loading = false;
  loadingAssociatedFoods = false;
  submitting = false;
  deleting = false;
  uploading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get AssociatedFoods() {
    return Array.from(this.associatedFoodRegistry.values());
  }

  get AssociatedFoodsTemplate() {
    return Array.from(this.associatedFoodRegistry.values())
      .reduce((group: AssociatedFoodsTemplateProps, current) => {
        let menuTitle = current.menuTitle!;
        group[menuTitle] = group[menuTitle] ?? [];
        group[menuTitle].push(current);

        return group;
      }, {});
  }

  get FoodsByMenu() {
    return Array.from(this.foodRegistry.values()).sort((a, b) => {
      if (a.menuId! > b.menuId!) return 1;
      if (a.menuId! < b.menuId!) return -1;
      return 0;
    });
  }

  get FoodsByTitle() {
    return Array.from(this.foodRegistry.values())
      .sort((a, b) => (a.title.localeCompare(b.title)));
  }

  get FoodOptions() {
    return Array.from(this.foodRegistry.values())
      .sort((a, b) => {
        if (a.menuId! > b.menuId!) return 1;
        if (a.menuId! < b.menuId!) return -1;
        return 0;
      })
      .map((food) => ({ key: food.id!, text: food.title, value: food.id! }));
  }

  get foodMenu() {
    return Array.from(this.foodRegistry.values())
      .map((food) => food.menuTitle)
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  clearAssociatedFood = () => {
    this.associatedFoodRegistry.clear();
  }

  loadAssociatedFoods = async (groupedParentFoodId: string) => {
    this.loadingAssociatedFoods = true;
    try {
      let results = await agent.Foods.getAssociatedFoods(groupedParentFoodId);
      runInAction(() => {
        this.associatedFoodRegistry.clear();
        if (results && results.length) {
          results.forEach((food) => {
            this.associatedFoodRegistry.set(food.id!, food)
          });
        }
        this.loadingAssociatedFoods = false;
      })
      return results;
    } catch (error) {
      console.log(error);
      runInAction(() => this.loadingAssociatedFoods = false);
    }
  }

  getAssociatedFoods = async (parentGroupedFoodId: string) => {
    this.loadingAssociatedFoods = true;
    try {
      let associatedFoods = await agent.Foods.getAssociatedFoods(parentGroupedFoodId);
      runInAction(() => this.loadingAssociatedFoods = false);
      return associatedFoods;
    } catch (error) {
      console.log(error);
      runInAction(() => this.loadingAssociatedFoods = false);
    }
  }

  loadFoods = async (vendorId: string, menuId?: string) => {
    const params = new URLSearchParams();
    let filter = { vendorId };
    if (menuId) Object.assign(filter, { ...filter, menuId });
    params.append("filter", JSON.stringify(filter));
    this.loading = true;
    try {
      let foods = await agent.Foods.list(params);
      runInAction(() => {
        this.foodRegistry.clear();
        if (foods && foods.length > 0) {
          foods.forEach((food) => {
            this.foodRegistry.set(food.id!, food);
          });
        }
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  private getFood = (id: string) => {
    return this.foodRegistry.get(id);
  };

  loadFood = async (id: string) => {
    let food = this.getFood(id);
    if (food) {
      return food;
    } else {
      this.loading = true;
      try {
        food = await agent.Foods.details(id);
        runInAction(() => {
          if (food) {
            this.foodRegistry.set(food.id!, food);
          }
          this.loading = false;
        });
        return food;
      } catch (error) {
        console.log(error);
        runInAction(() => (this.loading = false));
      }
    }
  };

  create = async (food: Food) => {
    this.submitting = true;
    try {
      let result = await agent.Foods.create(food);
      runInAction(() => {
        if (result) this.foodRegistry.set(result.id!, result);
        this.submitting = false;
      });
      return result;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  update = async (id: string, food: Food) => {
    this.submitting = true;
    try {
      let result = await agent.Foods.update(id, food);
      runInAction(() => {
        if (result) this.foodRegistry.set(result.id!, result);
        this.submitting = false;
      });
      return result;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  delete = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Foods.delete(id);
      runInAction(() => {
        this.foodRegistry.delete(id);
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };

  deleteMany = async (ids: string[]) => {
    this.deleting = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ id: ids }));
    try {
      await agent.Foods.deleteMany(params);
      runInAction(() => {
        ids.forEach((id) => this.foodRegistry.delete(id));
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };

  uploadPhoto = async (id: string, file: Blob) => {
    this.uploading = true;
    try {
      let photo = await agent.Foods.uploadPhoto(id, file);
      let food = await this.loadFood(id);
      food!.image = photo.url;
      runInAction(() => {
        this.foodRegistry.set(food!.id!, food!);
        this.uploading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.uploading = false));
    }
  };

  deletePhoto = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Foods.deletePhoto(id);
      let food = await this.loadFood(id);
      food!.image = undefined;
      runInAction(() => {
        this.foodRegistry.set(food!.id!, food!);
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };
}
