import { useEffect, useState } from "react";
import { useStore } from "../../app/store/store";
import { observer } from "mobx-react-lite";
import StoreDesktopContainer from "./container/StoreDesktopContainer";
import StoreMobileContainer from "./container/StoreMobileContainer";
import StoreRoutes from "./StoreRoutes";

const StoreLayoutPage = () => {
  const {
    restaurantStore: { loadStore },
  } = useStore();

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    loadStore();
  }, [loadStore]);

  return (
    <>
      <StoreDesktopContainer title={pageTitle}>
        <StoreRoutes setPageTitle={setPageTitle} />
      </StoreDesktopContainer>
      <StoreMobileContainer title={pageTitle}>
        <StoreRoutes setPageTitle={setPageTitle} />
      </StoreMobileContainer>
    </>
  );
};

export default observer(StoreLayoutPage);
