import { observer } from "mobx-react-lite";
import { Button, Icon, Item, Label } from "semantic-ui-react";
import Food, { FoodType } from "../../../../../app/models/food";
import { useStore } from "../../../../../app/store/store";
import { useState } from "react";
import CustomiseOrder from "../customise/CustomiseOrder";
import { toCurrency } from "../../../../../app/common/util/util";
import { FoodAmountLabel } from "./foodStyle";

interface Props {
  food: Food;
  isOpenNow: boolean;
  target: string;
  setTarget: (target: string) => void;
}

const VendorDetailsFoodListItem = ({
  food,
  isOpenNow,
  target,
  setTarget,
}: Props) => {
  const {
    shoppingCartStore: { addToCart, isFoodForVendor, openConfirmRefreshCart },
    modalStore: { openModal },
  } = useStore();

  const [adding, setAdding] = useState(false);

  const handleAddToCart = (foodId: string) => {
    setAdding(true);
    setTarget(foodId);
    isFoodForVendor(foodId).then((state) => {
      if (state) {
        if (
          food.type === FoodType.grouped &&
          food.associatedFoods!.length > 0
        ) {
          openModal(
            <CustomiseOrder foodId={foodId} associatedItems={[]} />,
            "tiny"
          );
          setAdding(false);
        } else {
          addToCart(foodId).then(() => {
            setTarget("");
            setAdding(false);
          });
        }
      } else {
        setTarget("");
        setAdding(false);
        openConfirmRefreshCart(foodId);
      }
    });
  };

  return (
    <Item key={food.id} style={{ display: "flex" }}>
      <Item.Image
        className="chops2u-item-image"
        src={food.image || "/images/food-placeholder.jpg"}
        size="small"
      />

      <Item.Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Item.Header>{food.title}</Item.Header>
        <Item.Description style={{ height: "45%", overflow: "auto" }}>
          {food.description}
        </Item.Description>
        <Item.Extra>
          <FoodAmountLabel size="small" color="olive" horizontal>
            {toCurrency(food.price, 0)}
          </FoodAmountLabel>
          {isOpenNow ? (
            <Button
              name={food.id}
              basic
              loading={adding && target === food.id!.toString()}
              color="olive"
              size="mini"
              floated="right"
              onClick={(e) => handleAddToCart(e.currentTarget.name)}
            >
              <Icon name="plus" /> Add
            </Button>
          ) : (
            <Label
              color="olive"
              basic
              size="small"
              style={{ float: "right", fontWeight: 100 }}
            >
              <Icon name="ban" color="red" />
              Unavailable
            </Label>
          )}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

export default observer(VendorDetailsFoodListItem);
