import { observer } from "mobx-react-lite";
import { Container, Header, Icon, List } from "semantic-ui-react";
import Vendor from "../../../../../app/models/vendor";
import VendorRating from "../../common/VendorRating";
import VendorAddress from "../../common/VendorAddress";

interface Props {
  vendor: Vendor;
}

export default observer(function VendorBannerDashboard({ vendor }: Props) {
  return (
    <Container>
      <Header as="h1" style={{ marginTop: "5px", marginBottom: "0px" }}>
        <Header.Content style={{ color: "#fff", fontWeight: 200 }}>
          {vendor.title}
        </Header.Content>
        <Header.Subheader
          style={{
            color: "#ccc",
            fontSize: "1em",
            paddingTop: "0px",
            marginTop: "0px",
            lineHeight: "0.5em",
          }}
        >
          <span>
            <List horizontal verticalAlign="middle">
              {vendor.categories.map((category, index) => (
                <List.Item key={index}>
                  <span>
                    {index > 0 && (
                      <Icon
                        name="circle"
                        size="mini"
                        style={{
                          color: "#ffffff",
                        }}
                      />
                    )}
                    {category.name}
                  </span>
                </List.Item>
              ))}
            </List>
          </span>
        </Header.Subheader>
      </Header>
      {vendor.location && (
        <div style={{ display: "flex", gap: "10px" }}>
          {/* <VendorLocation location={vendor.location} /> */}
          <VendorAddress vendor={vendor} />
          <span>{"|"}</span>
          <span style={{ display: "flex", color: "#ccc" }}>
            <Icon name="phone square" /> 09167666680
          </span>
        </div>
      )}
      <VendorRating isNowOpen={vendor.isOpenNow!} />
    </Container>
  );
});
