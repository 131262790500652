import { observer } from "mobx-react-lite";
import { Confirm, Button, Header } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";

const ConfirmRefreshCart = () => {
  const {
    shoppingCartStore: {
      confirmModalOpenState,
      closeConfirmRefreshCart,
      addToCartWithRefresh,
      selectedFoodIdentifier,
      refreshing,
    },
  } = useStore();

  const handleConfirmAction = () => {
    if (selectedFoodIdentifier !== "") {
      addToCartWithRefresh(selectedFoodIdentifier).then(() =>
        closeConfirmRefreshCart()
      );
    } else {
      closeConfirmRefreshCart();
    }
  };

  const handleCancelAction = () => {
    closeConfirmRefreshCart();
  };

  return (
    <Confirm
      open={confirmModalOpenState}
      size="tiny"
      header={<Header content="Items already in cart" />}
      cancelButton={
        <Button
          icon="ban"
          color="red"
          content="NO"
          floated="left"
          style={{ width: "15em" }}
        />
      }
      confirmButton={
        <Button
          icon="check"
          color="green"
          loading={refreshing}
          content="YES, START A FRESH"
          style={{ width: "15em" }}
        />
      }
      onCancel={handleCancelAction}
      onConfirm={handleConfirmAction}
      content="Your cart contains items from other food vendors. Would you like to reset
      your cart to add items from this vendor."
    />
  );
};

export default observer(ConfirmRefreshCart);
