import { observer } from "mobx-react-lite";
import { Container, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";

export default observer(function ServerError() {
  const { commonStore } = useStore();

  const pageStyle = {
    marginTop: "1.5em",
    marginBottom: "0px",
  };

  return (
    <Segment vertical style={pageStyle}>
      <Container>
        <Header as="h2" content="Server Error" />
        {commonStore.error && (
          <Header as="h4" color="red" content={commonStore.error.message} />
        )}
        {commonStore.error && (
          <Segment>
            <Header color="teal" as="h5" content="Stack Trace" />
            {commonStore.error.details && (
              <code>{commonStore.error.details}</code>
            )}
          </Segment>
        )}
      </Container>
    </Segment>
  );
});
