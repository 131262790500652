import { observer } from "mobx-react-lite";
import Vendor from "../../../../../../app/models/vendor";
import { Card, Dimmer, Header, Image, Label, Rating } from "semantic-ui-react";

interface Props {
  vendor: Vendor;
}

const VendorCard = ({ vendor }: Props) => {
  return (
    <Card raised key={vendor.title} href={`/vendors/${vendor.id}`}>
      <Dimmer.Dimmable dimmed={!vendor.isOpenNow}>
        <Image src={vendor.image || "/images/placeholder.png"} wrapped fluid />
        <Dimmer active={!vendor.isOpenNow} inverted>
          <Label color="red">Closed</Label>
        </Dimmer>
      </Dimmer.Dimmable>

      <Card.Content>
        <Card.Header>{vendor.title}</Card.Header>
        <Card.Meta>
          <span className="date">
            {vendor.categories.map((category) => category.name).join(", ")}
          </span>
        </Card.Meta>
        <Card.Description>
          <Rating icon="star" defaultRating={3} maxRating={5} />
          <Header as="h5" floated="right">
            35-45min
          </Header>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
export default observer(VendorCard);
