import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Header,
  Image,
  Label,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uTextInput from "../../../app/common/forms/Chops2uTextInput";
import { useStore } from "../../../app/store/store";
import { useNavigate } from "react-router-dom";
import VendorRegistrationForm from "../register/VendorRegistrationForm";

export default observer(function VendorLoginForm() {
  const {
    userStore: { login, logining },
    modalStore: { openModal, closeModal },
  } = useStore();

  const validate = Yup.object({
    email: Yup.string().trim().email().required(),
    password: Yup.string().trim().required()
  })

  const navigate = useNavigate();

  const handleLinkClick = () => {
    openModal(<VendorRegistrationForm />);
  };

  return (
    <Modal.Content>
      <Segment vertical>
        <Container>
          <Formik
            validationSchema={validate}
            initialValues={{ email: "", password: "", error: null }}
            onSubmit={(values, { setErrors }) => {
              login(values)
                .then(() => {
                  closeModal();
                  navigate("/store");
                })
                .catch((error) => setErrors({ error: error }));
            }}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <>
                <Image
                  src="/images/logo.png"
                  alt="chops2u"
                  size="small"
                  style={{
                    margin: "auto",
                    height: "auto",
                    width: "120px",
                    paddingBottom: "10px",
                  }}
                />
                <Form
                  className="ui form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Header
                    disabled
                    color="grey"
                    textAlign="center"
                    size="tiny"
                    content={"Store Login".toUpperCase()}
                  />
                  <Chops2uTextInput
                    name="email"
                    placeholder="username@domain.com"
                    label="Email"
                  />
                  <Chops2uTextInput
                    name="password"
                    placeholder="your secret pass"
                    label="Password"
                    type="password"
                  />

                  <ErrorMessage
                    name="error"
                    render={() => (
                      <Label
                        style={{ marginBottom: "15px" }}
                        basic
                        color="red"
                        content={errors.error}
                      />
                    )}
                  />

                  <div className="field">
                    <Button
                      loading={isSubmitting && logining}
                      color="olive"
                      content="Login"
                      type="submit"
                      fluid
                    />
                  </div>
                  <Message style={{ textAlign: "center" }} size="small">
                    New to us?{" "}
                    <button
                      onClick={handleLinkClick}
                      style={{
                        color: "#4183c4",
                        cursor: "pointer",
                        border: "none",
                        padding: "0",
                        borderRadius: "0",
                        boxShadow: "0 0 0 0",
                        backgroundColor: "transparent",
                      }}
                    >
                      Sign Up
                    </button>
                    .
                  </Message>
                </Form>
              </>
            )}
          </Formik>
        </Container>
      </Segment>
    </Modal.Content>
  );
});
