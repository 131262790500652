import React, { useEffect } from "react";

interface SupportLayoutPageProps {
  setPageTitle: (title: string) => void;
}

const SupportLayoutPage = ({ setPageTitle }: SupportLayoutPageProps) => {
  useEffect(() => {
    setPageTitle("Settings");
  }, [setPageTitle]);

  return <div>SupportLayoutPage</div>;
};

export default SupportLayoutPage;
