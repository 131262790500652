import React from "react";
import { Header, SemanticCOLORS } from "semantic-ui-react";

interface Props {
  text: string;
  color: SemanticCOLORS;
}

const itemStyle = {
  fontSize: "1rem",
  lineHeight: 1,
  fontWeight: 550,
};

const MenuItemText = (props: Props) => {
  return (
    <Header color={props.color} style={itemStyle}>
      {props.text}
    </Header>
  );
};

export default MenuItemText;
