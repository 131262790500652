import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Chopper from "../models/chopper";
import Order from "../models/order";

export default class ChopperStore {
  chopperRegistry = new Map<string, Chopper>();
  unassignedChopperRegistry = new Map<string, Chopper>();
  orderRegistry = new Map<string, Order>();
  selectedOrder: Order | undefined = undefined;

  loading = false;
  loadingUnassigned = false;
  loadingOrders = false;
  loadingOrder = false;
  loadingShipments = false;
  submitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get choppers() {
    return Array.from(this.chopperRegistry.values());
  }

  get UnassignedChoppers() {
    return Array.from(this.unassignedChopperRegistry.values());
  }

  get ordersByDate() {
    return Array.from(this.orderRegistry.values());
  }

  get ordersForCollection() {
    return Array.from(this.orderRegistry.values()).filter(
      (o) => o.status === "Preparing"
    );
  }

  get ordersForDelivery() {
    return Array.from(this.orderRegistry.values()).filter(
      (o) => o.chopperAssigned && o.status === "InTransit"
    );
  }

  private getOrder = (orderId: string) => {
    return this.orderRegistry.get(orderId);
  };

  loadChoppers = async () => {
    this.loading = true;
    try {
      let riders = await agent.Choppers.list();
      runInAction(() => {
        // this.chopperRegistry.clear();
        riders.forEach((rider) => {
          rider = { ...rider, text: rider.fullName, value: rider.id };
          this.chopperRegistry.set(rider.id!, rider);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadUnassignedChoppers = async () => {
    try {
      this.loadingUnassigned = true;
      let riders = await agent.Choppers.listUnassigned();
      runInAction(() => {
        riders.forEach((rider) => {
          rider = { ...rider, text: rider.fullName, value: rider.id };
          this.unassignedChopperRegistry.set(rider.id, rider);
        })
        this.loadingUnassigned = false;
      })
    } catch (error) {
      runInAction(() => this.loadingUnassigned = false);
      console.log(error);
    }
  }

  private getSelectedOrder = () => {
    if (this.ordersForCollection.length > 0) {
      return this.ordersForCollection[0];
    } else if (this.ordersForDelivery.length > 0) {
      return this.ordersForDelivery[0];
    } else {
      return undefined;
    }
  };

  loadOrders = async () => {
    this.loadingOrders = true;
    try {
      let orders = await agent.Choppers.orders();
      runInAction(() => {
        this.orderRegistry.clear();
        orders.forEach((o) => {
          this.orderRegistry.set(o.id, o);
        });
        this.selectedOrder = this.getSelectedOrder();
        this.loadingOrders = false;
      });
    } catch (error) {
      runInAction(() => (this.loadingOrders = false));
      console.log(error);
    }
  };

  loadOrder = async (orderId: string) => {
    let order = this.getOrder(orderId);
    if (order) {
      this.selectedOrder = order;
    } else {
      this.loadingOrder = true;
      try {
        order = await agent.Orders.details(orderId);
        runInAction(() => {
          this.selectedOrder = order;
          this.loadingOrder = false;
        });
      } catch (error) {
        runInAction(() => (this.loadingOrder = false));
        console.log(error);
      }
    }
  };

  collectOrder = async (orderId: string) => {
    this.submitting = true;
    try {
      await agent.Orders.collect(orderId);
      let order = this.orderRegistry.get(orderId);
      if (order) order.status = "InTransit";
      runInAction(() => {
        if (order) this.orderRegistry.set(order.id, order);
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      console.log(error);
    }
  };

  deliverOrder = async (orderId: string) => {
    this.submitting = true;
    try {
      await agent.Orders.delivered(orderId);
      let order = this.orderRegistry.get(orderId);
      if (order) order.status = "Delivered";
      runInAction(() => {
        if (order) this.orderRegistry.set(order.id, order);
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      console.log(error);
    }
  };
}
