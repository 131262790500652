import { observer } from "mobx-react-lite";
import { Button, Header, Icon, Label, List, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import ShoppingCart from "../../../app/models/shopping-cart";
import CartHeader from "./CartHeader";
import CartItem from "./CartItem";
import { toCurrency } from "../../../app/common/util/util";
import { CartName } from "./cartStyles";

interface Props {
  shoppingCart: ShoppingCart | null;
}

const StockedCart = ({ shoppingCart }: Props) => {
  // use Navigate
  //=============================================================
  const navigate = useNavigate();

  return (
    <div>
      <CartName>Shopping Cart</CartName>
      {shoppingCart && (
        <>
          <Segment vertical basic>
            <CartHeader
              vendorId={shoppingCart.vendor!.id}
              title={shoppingCart.vendor.title}
              location={shoppingCart.vendor.location!}
              image={shoppingCart.vendor.image}
            />
          </Segment>
          <Segment vertical basic>
            <List celled relaxed="very">
              {shoppingCart!.items.map((item) => (
                <CartItem item={item} />
              ))}
            </List>
          </Segment>
          <Segment vertical>
            <Header
              style={{
                fontSize: "1em",
                fontWeight: "600",
                paddingBottom: ".85rem",
              }}
            >
              <span style={{ float: "left" }}>{"Item Total: "}</span>
              <span style={{ float: "right" }}>
                {toCurrency(shoppingCart.itemsSubTotal)}
              </span>
            </Header>
          </Segment>
          <Segment vertical>
            {shoppingCart.vendor.isOpenNow ? (
              <Button
                fluid
                color="olive"
                content="Checkout"
                onClick={() => navigate("/checkout")}
              />
            ) : (
              <Label
                basic
                icon={<Icon color="red" name="ban" />}
                size="big"
                color="olive"
                content="Unavailable"
                style={{ width: "100%", textAlign: "center", fontWeight: 100 }}
              />
            )}
          </Segment>
        </>
      )}
    </div>
  );
};

export default observer(StockedCart);
