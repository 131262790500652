import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import Vendor from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import PhotoCropper from "./PhotoCropper";
import PhotoDropzone from "./PhotoDropzone";

interface Props {
  vendor: Vendor;
  setEditState: (state: boolean) => void;
}

const VendorImageUpload = ({ vendor, setEditState }: Props) => {
  const {
    vendorStore: { updatePhoto, updatingPhoto },
  } = useStore();
  const [files, setFiles] = useState<any[]>([]);
  const [cropper, setCropper] = useState<Cropper>();

  function OnCrop() {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((file) => {
        updatePhoto(vendor, file!).then(() => setEditState(false));
      });
    }
  }

  return (
    <Container text>
      <Segment>
        <Grid>
          <Grid.Column width={16}>
            <Grid.Row>
              <Header
                as="h5"
                color="olive"
                icon="image"
                content={vendor.title + " Photo Upload"}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header as="h5" color="grey" content="Image" />
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Image
                  src={vendor.image || "/Images/placeholder.png"}
                  style={
                    files && files.length > 0
                      ? { display: "none" }
                      : { display: "block" }
                  }
                />
                <PhotoDropzone setFiles={setFiles} />
                {files && files.length > 0 && (
                  <PhotoCropper
                    setCropper={setCropper}
                    imagePreview={files[0].preview}
                    initialAspectRatio={2 / 1}
                    aspectRation={2 / 1}
                  />
                )}
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header color="grey" as="h5" content="Preview" />
              <div
                className="img-preview"
                style={{
                  width: "100%",
                  minHeight: "163px",
                  border: "1px dashed grey",
                  overflow: "hidden",
                }}
              ></div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Button
                onClick={() => setEditState(false)}
                disabled={updatingPhoto}
                basic
                fluid
                color="orange"
                content="Cancel"
                size="large"
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Button
                onClick={OnCrop}
                loading={updatingPhoto}
                basic
                fluid
                color="olive"
                content="Save"
                size="large"
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
};

export default observer(VendorImageUpload);
