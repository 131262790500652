import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { Button, Container, Icon, Menu } from "semantic-ui-react";
import AccountCard from "./AccountCard";

const AccountSideNav = () => {
  // react router location
  const location = useLocation();

  return (
    <Menu fluid pointing vertical>
      <Container>
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 0px",
          }}
        >
          <AccountCard />
        </Container>
        <Menu.Item
          as={Link}
          to="/account/profile"
          name="profile"
          icon
          key="profile"
          active={location.pathname === "/account/profile"}
        >
          <Icon color="olive" style={{ float: "left" }} name="user" />
          <span style={{ marginLeft: "5px" }}>My Profile</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/account/photo"
          name="photo"
          icon
          key="photo"
          active={location.pathname === "/account/photo"}
        >
          <Icon color="olive" style={{ float: "left" }} name="photo" />
          <span style={{ marginLeft: "5px" }}>Photo</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/account"
          name="orders"
          key="orders"
          content="Orders"
          active={location.pathname === "/account"}
        >
          <Icon
            color="olive"
            style={{ float: "left" }}
            name="cart arrow down"
          />
          <span style={{ marginLeft: "5px" }}>Orders</span>
        </Menu.Item>
        {/* <Menu.Item
                as={Link}
                to="/account/vendors"
                name='vendors'
                key="vendors"
                content="Vendors"
                active={ location.pathname === "/account/vendors" }
            >
                <Icon color="olive" style={{ float: "left"}} name="location arrow" />
                <span style={{ marginLeft: "5px"}}>Vendors</span>
            </Menu.Item> */}
        <Menu.Item
          as={Link}
          to="/account/favorites"
          name="favorites"
          key="favorites"
          content="Favorites"
          active={location.pathname === "/account/favorites"}
        >
          <Icon color="olive" style={{ float: "left" }} name="heart" />
          <span style={{ marginLeft: "5px" }}>Favorites</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/account/address-book"
          name="address"
          kay="address"
          content="Address Book"
          active={location.pathname === "/account/address-book"}
        >
          <Icon color="olive" style={{ float: "left" }} name="address book" />
          <span style={{ marginLeft: "5px" }}>Address Book</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/account/security"
          name="security"
          key="security"
          content="Change Password"
          active={location.pathname === "/account/security"}
        >
          <Icon color="olive" style={{ float: "left" }} name="lock" />
          <span style={{ marginLeft: "5px" }}>Change Password</span>
        </Menu.Item>
        {/* <Menu.Item
            as={Link}
            to="/account/close-account"
            name="close-account"
            key="close-account"
            content="Close Account"
            active={location.pathname === "/account/close-account"}
          >
            <Icon color="olive" style={{ float: "left" }} name="delete" />
            <span style={{ marginLeft: "5px" }}>Close Account</span>
          </Menu.Item> */}
        <Menu.Item name="logout" key="loutout">
          <Button
            color="olive"
            icon
            name="logout"
            size="small"
            styly={{ backgroundColor: "#ffffff" }}
          >
            <Icon name="log out" style={{ paddingRight: "20px" }} />
            Logout
          </Button>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(AccountSideNav);
