import { Container, Menu } from "semantic-ui-react";

const PendingPayoutTopMenu = () => {
  return (
    <Menu
      attached="top"
      borderless
      fluid
      secondary
      style={{ borderRadius: "0", height: "65px" }}
    >
      <Container style={{ width: "95%" }}>
        <Menu.Item></Menu.Item>
      </Container>
    </Menu>
  );
};

export default PendingPayoutTopMenu;
