import { Container, Grid, Segment } from "semantic-ui-react";
import PartWithUs from "./banner/PartWithUs";
import RideWithUs from "./banner/RideWithUs";
import TryOurApp from "./banner/TryOurApp";

interface Props {
  mobile?: boolean;
}


const SectionBanner = ({ mobile }: Props) => {
  return (
    <Segment
      vertical
      inverted
      style={{
        paddingBottom: mobile ? "30px" : "90px",
        paddingTop: mobile ? "0px" : "70px",
      }}
    >
      <Container>
        <Grid>
          <Grid.Row columns={3} only="computer tablet">
            <Grid.Column>
              <RideWithUs mobile={mobile} />
            </Grid.Column>
            <Grid.Column>
              <PartWithUs mobile={mobile} />
            </Grid.Column>
            <Grid.Column>
              <TryOurApp mobile={mobile} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} only="mobile">
            <Grid.Column>
              <RideWithUs mobile={mobile} />
            </Grid.Column>
            <Grid.Column>
              <PartWithUs mobile={mobile} />
            </Grid.Column>
            <Grid.Column>
              <TryOurApp mobile={mobile} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default SectionBanner;
