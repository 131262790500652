import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import ServerError from "../models/server-error";

export default class CommonStore {
  error: ServerError | null = null;
  token: string | null = window.localStorage.getItem("_c2usid"); // app session id
  deliveryLocation: string | null = window.localStorage.getItem("_c2ulv"); // app user location value
  adminPageTitle: string = "";
  appLoaded = false;
  loadingTimeZones = false;
  mobileMenuVisibility = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem("_c2usid", token);
        } else {
          window.localStorage.removeItem("_c2usid");
        }
      }
    );

    reaction(
      () => this.deliveryLocation,
      (deliveryLocation) => {
        if (deliveryLocation) {
          window.localStorage.setItem("_c2ulv", deliveryLocation);
        } else {
          window.localStorage.removeItem("_c2ulv");
        }
      }
    );
  }

  setToggleVisibility = () => {
    this.mobileMenuVisibility = !this.mobileMenuVisibility;
  }

  setMobileMenuVisibility = (visibility: boolean) => {
    this.mobileMenuVisibility = visibility;
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setToken = (token: string | null) => {
    this.token = token;
  };

  setDeliveryLocation = (location: string | null) => {
    this.deliveryLocation = location;
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };

  // private setLocation = (location: string | null) => {
  //   this.deliveryLocation = location;
  // }

  // private getLocation = () => {
  //   return this.deliveryLocation;
  // }

  getDeliveryLocation = () => {
    let location = this.deliveryLocation;
    let locationObj = null;

    if (location) locationObj = JSON.parse(location);
    return locationObj;
  }

  // setDeliveryLocation = (location: Location | null) => {
  //   if (location.id) {
  //     this.setLocation(JSON.stringify(location));
  //   } else {
  //     this.setLocation(null);
  //   }
  // }

  setAdminPageTitle = (title: string) => {
    this.adminPageTitle = title;
  };

  // loadUserLocation = async () => {
  //   try {
  //     let locationStr = this.location;
  //     if (!locationStr) {
  //       this.setLocation(null);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  loadTimeZones = async () => {
    this.loadingTimeZones = true;
    try {
      const timeZones = await agent.TimeZones.list();
      runInAction(() => (this.loadingTimeZones = false));
      return timeZones;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loadingTimeZones = false));
    }
  };
}
