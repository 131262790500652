import { Header } from "semantic-ui-react";

const PendingPayoutEmptyPanel = () => {
  return (
    <div>
      <Header textAlign="center" as="h4" style={{ paddingTop: "3em" }}>
        <Header.Content>Pending Payout</Header.Content>
        <Header.Subheader style={{ padding: "5px 0 5px 0" }}>
          There is no pending payout for your store.
        </Header.Subheader>
      </Header>
    </div>
  );
};

export default PendingPayoutEmptyPanel;
