import { observer } from "mobx-react-lite";
import {
  Accordion,
  AccordionTitleProps,
  Segment,
  Header,
  Radio,
} from "semantic-ui-react";
import { MouseEvent, useEffect, useState } from "react";
import { useStore } from "../../../../app/store/store";
import { toCurrency } from "../../../../app/common/util/util";

interface Props {
  setButtonIndex: (index: number | string | undefined) => void;
  setPaymentId: (methodId: string) => void;
  editState: boolean;
  paymentOption: number | string | undefined;
  mobile?: boolean;
}

const PaymentOption = ({
  setButtonIndex,
  setPaymentId,
  editState,
  paymentOption,
  mobile,
}: Props) => {
  // =========================================================
  // store
  // =========================================================
  const {
    shoppingCartStore: { OrderTotalAmount },
    paymentStore: { Gateways, loadGateways },
  } = useStore();

  // =========================================================
  // states
  // =========================================================
  const [activeIndex, setActiveIndex] = useState<string | number | undefined>(
    0
  );

  useEffect(() => {
    loadGateways();
    if (!editState) {
      setButtonIndex("1");
      setPaymentId("Payment.Flutterwave");
    } else {
      setButtonIndex("0");
    }
  }, [setButtonIndex, setPaymentId, editState, loadGateways]);

  const handleClick = (e: MouseEvent, titleProps: AccordionTitleProps) => {
    const { index, name } = titleProps;
    const newIndex = activeIndex === index ? activeIndex : index;

    setActiveIndex(newIndex);
    setButtonIndex(newIndex);
    setPaymentId(name);
  };

  return (
    <Segment disabled={editState}>
      <Header
        style={{
          fontSize: mobile ? "0.9em" : "",
          letterSpacing: mobile ? "1px" : "3px",
        }}
        as="h5"
        color="grey"
        content="PAYMENT METHOD"
      />
      <Accordion>
        {Gateways &&
          Gateways.map((gateway) => (
            <>
              <Accordion.Title
                name={gateway.systemName}
                active={paymentOption === gateway.key}
                onClick={handleClick}
                index={gateway.key}
                style={{
                  backgroundColor: "#DDD",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                  borderRadius: "2.5px",
                }}
              >
                <Radio checked={paymentOption === gateway.key} />{" "}
                <span style={{ fontWeight: 700, letterSpacing: 2 }}>
                  {gateway.friendlyName}
                </span>
              </Accordion.Title>
              <Accordion.Content active={paymentOption === gateway.key}>
                <span
                  style={{
                    fontSize: mobile ? "13px" : "",
                    letterSpacing: mobile ? 1 : 2,
                  }}
                >
                  {gateway.description}
                </span>
                <p
                  style={{
                    fontSize: mobile ? "13px" : "",
                    letterSpacing: mobile ? 1 : 2,
                    marginTop: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {`The amount your card will be charged is ${toCurrency(
                    OrderTotalAmount
                  )}.`}
                </p>
              </Accordion.Content>
            </>
          ))}
      </Accordion>
    </Segment>
  );
};

export default observer(PaymentOption);
