import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import OrderList from "./OrderList";
import OrdersEmptyPanel from "./OrdersEmptyPanel";
import OrdersTopMenu from "./OrdersTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const OrdersLayoutPage = ({ setPageTitle }: Props) => {
  const {
    orderStore: { OrdersByDate, createHubConnection, clearOrders },
  } = useStore();

  useEffect(() => {
    setPageTitle("Orders");
  }, [setPageTitle]);

  useEffect(() => {
    createHubConnection();
  
    return () => {
      clearOrders();
    }
  }, [createHubConnection, clearOrders])
  

  return (
    <section>
      <div>
        <OrdersTopMenu />
      </div>
      <section>
        {OrdersByDate?.length > 0 ? (
          <OrderList />
        ) : (
          <OrdersEmptyPanel />
        )}
      </section>
    </section>
  );
};

export default observer(OrdersLayoutPage);
