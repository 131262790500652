import { Message } from "semantic-ui-react";

interface Props {
  header?: string;
  errors: any;
}

export default function ValidationErrors({ header, errors }: Props) {
  return (
    <Message error style={{ display: "flex", flexDirection: "column" }}>
      {header && <Message.Header>{header}</Message.Header>}
      {errors && Array.isArray(errors) ? (
        <Message.List>
          {errors.map((error: any, index: any) => (
            <Message.Item key={index}>{error}</Message.Item>
          ))}
        </Message.List>
      ) : (
        <p style={{ textAlign: "left" }}>{errors.toString()}</p>
      )}
    </Message>
  );
}
