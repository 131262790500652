import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Menu } from "semantic-ui-react";

interface Props {
  menus: string[];
  setActiveMenu: (name: string) => void;
  activeMenu: string;
}

const menuStyle = {
  border: "none",
};

const VendorDetailsFoodMenu: React.FC<Props> = ({
  menus,
  activeMenu,
  setActiveMenu,
}: Props) => {
  useEffect(() => {
    setActiveMenu(menus[0]);
  }, [setActiveMenu, menus]);

  return (
    <Menu pointing secondary vertical fluid floated="right" style={menuStyle}>
      {menus &&
        menus.length > 0 &&
        menus.map((menu) => (
          <Menu.Item
            name={menu}
            color="olive"
            active={activeMenu === menu}
            key={menu}
            onClick={() => setActiveMenu(menu)}
            as="a"
            href={`#${menu}`}
          >
            <p style={{ display: "flex", justifyContent: "flex-end" }}>
              {menu}
            </p>
          </Menu.Item>
        ))}
    </Menu>
  );
};

export default observer(VendorDetailsFoodMenu);
