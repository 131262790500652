import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Food, {
  FoodGrouping,
  FoodType,
  FoodTypeOptions,
} from "../../../../../app/models/food";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface FormData extends Food {
  parentGroupedFoodIds?: string[];
  error: any;
}

interface Props {
  data?: Food;
}

const FoodForm = ({ data }: Props) => {
  const {
    restaurantStore: {
      restaurant,
      createFood,
      updateFood,
      submittingFood,
      ParentGroupedFoods,
    },
    modalStore: { closeModal },
    menuStore: { loadMenus, MenuOptions, loading: loadingMenus },
  } = useStore();

  const validate = Yup.object({
    menuId: Yup.number().required().moreThan(0),
    title: Yup.string().required(),
    description: Yup.string().required(),
    price: Yup.number().moreThan(1),
    type: Yup.number().required().moreThan(0),
  });

  const [food, setFood] = useState<FormData>({
    menuId: "",
    title: "",
    description: "",
    price: 0,
    otherCharges: 0,
    weight: 0,
    published: true,
    deleted: false,
    visibleIndividually: false,
    type: FoodType.simple,
    associatedFoods: [],
    parentGroupedFoods: [],
    parentGroupedFoodIds: [],
    error: null,
  });

  useEffect(() => {
    if (restaurant?.id) {
      loadMenus(restaurant.id);
    }
  }, [restaurant?.id, loadMenus]);

  useEffect(() => {
    if (data)
      setFood({
        ...data,
        parentGroupedFoodIds: data.parentGroupedFoods!.map(
          (a) => a.parentGroupedFoodId!
        ),
        error: "",
      });
  }, [data]);

  const handleFormSubmit = async (
    values: FormData,
    { setErrors }: FormikHelpers<FormData>
  ) => {
    // console.log(values);

    if (restaurant?.id) {
      let parentGroupedFoods = values.parentGroupedFoodIds!.map<FoodGrouping>(
        (parentGroupedFoodId) =>
          Object.assign({
            parentGroupedFoodId: parentGroupedFoodId,
            associatedFoodId: values.id,
          })
      );
      values = { ...values, vendorId: restaurant.id, parentGroupedFoods };
      if (values.id) {
        updateFood(values)
          .then(() => closeModal())
          .catch((error) => setErrors({ error }));
      } else {
        createFood(values)
          .then(() => closeModal())
          .catch((error) => setErrors({ error }));
      }
    }
  };

  return (
    <Container>
      <Formik
        initialValues={food}
        validationSchema={validate}
        enableReinitialize
        onSubmit={handleFormSubmit}
      >
        {({ values, dirty, isSubmitting, handleSubmit, errors, isValid }) => (
          <Form onSubmit={handleSubmit} className="ui mini form">
            <Segment color="green" attached="top">
              <Header
                textAlign="center"
                size="tiny"
                color="grey"
                content={"Create Food".toUpperCase()}
                style={{ padding: "8px 0px 0px 5px" }}
              />
            </Segment>
            <Segment attached>
              <Chops2uSelectInput
                name="menuId"
                loading={loadingMenus}
                options={MenuOptions}
                label="Menu"
                placeholder="Select a menu"
              />

              <Chops2uTextInput
                name="title"
                label="Title"
                placeholder="Food Name"
              />

              <Chops2uTextAreaInput
                name="description"
                rows={2}
                label="Description"
                placeholder="Give a compelling description of the food"
              />

              <div className="equal width fields">
                <Chops2uTextInput name="price" label="Price" placeholder="0" />

                <Chops2uTextInput
                  name="otherCharges"
                  label="Other Charges"
                  hints="Use this field to add container amount"
                  placeholder="0"
                />
              </div>

              <Chops2uSelectInput
                name="type"
                options={FoodTypeOptions}
                label="Food Type"
                placeholder="Select a food type"
              />

              <Chops2uSelectInput
                name="parentGroupedFoodIds"
                loading={loadingMenus}
                options={ParentGroupedFoods}
                label="Grouped Parent Food"
                placeholder="Select a grouped parent food"
                multiple={true}
                disabled={values.type !== FoodType.simple}
              />

              <Chops2uCheckbox
                name="visibleIndividually"
                label="Visible in menu (Or through grouped food details page)"
              />

              <Chops2uCheckbox
                name="published"
                label="Publish food to display on vendor page"
              />

              {/* <div className="field">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div> */}

              <div className="field">
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              </div>
            </Segment>
            <Segment secondary attached="bottom" clearing>
              <div
                style={{
                  display: "flex",
                  columnGap: "1em",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  floated="right"
                  type="button"
                  basic
                  color="orange"
                  content="Cancel"
                  style={{ width: "150px" }}
                  onClick={() => closeModal()}
                />

                <Button
                  disabled={(!isValid && dirty) || isSubmitting}
                  loading={isSubmitting || submittingFood}
                  type="submit"
                  positive
                  content="Submit"
                  style={{ width: "150px" }}
                />
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(FoodForm);
