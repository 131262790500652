import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { deleteCookie, getCookieValue } from "../common/util/util";
import ShoppingCart, { ShoppingCartItem } from "../models/shopping-cart";
import Vendor from "../models/vendor";
import { store } from "./store";
import Checkout from "../models/checkout";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default class ShoppingCartStore {
  cartIdName: string = "__cartId";
  shoppingCart: ShoppingCart | null = null;
  shoppingCartItems = new Map<string, ShoppingCartItem>();
  associatedItemsRegistry = new Map<number, ShoppingCartItem>();
  cartIdentifier: string = getCookieValue(this.cartIdName);
  hubConnection: HubConnection | null = null;
  systemDeliveryFee = 500;
  salesTaxPercentage = 0;
  orderDiscount = 0;
  selectedFoodIdentifier: string = "";

  loading = false;
  loadingAssociatedItems = false;

  addingToCart = false;
  removingFromCart = false;
  deletingCart = false;
  submitting = false;

  confirmModalOpenState = false;
  refreshing = false;
  checkingout = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.shoppingCart?.items,
      (items) => {
        if (items && items.length > 0) {
          console.log(this.shoppingCart);
          this.shoppingCart!.itemsSubTotal = items.reduce(
            (prev, cur) => (prev + cur.totalPrice),
            0
          );
        }
      }
    );
  }

  get ShoppingCart() {
    return this.shoppingCart;
  }

  get IsCartEmpty() {
    return this.ItemsCount === 0 ? true : false;
  }

  get IsIdentifierEmpty() {
    return this.cartIdentifier === "";
  }

  get DeliveryFee() {
    return this.systemDeliveryFee;
  }

  get OrderDiscount() {
    return this.orderDiscount;
  }

  get OtherCharges() {
    return this.shoppingCart
      ? this.shoppingCart.items.reduce(
        (prev, cur) => {
          return prev + cur.otherChargesTotal;
        },
        0
      )
      : 0.0;
  }

  get OrderSubTotal() {
    return this.shoppingCart
      ? this.shoppingCart.items.reduce(
        (prev, cur) => {
          return prev + cur.totalPrice;
        },
        0
      )
      : 0.0;
  }

  get SalesTax() {
    return (this.salesTaxPercentage / 100) * this.OrderSubTotal;
  }

  get OrderTotalAmount() {
    return this.DeliveryFee + this.OrderSubTotal + this.SalesTax + this.OtherCharges;
  }

  get ItemsCount() {
    return this.shoppingCart ? this.shoppingCart.items.length : 0;
  }

  get AssociatedItems() {
    return Array.from(this.associatedItemsRegistry.values());
  }

  clearAssociatedItems = () => {
    this.associatedItemsRegistry.clear();
  }

  createHubConnect = () => {
    if (store.userStore.user) {
      const baseUrl = process.env.REACT_APP_ORDERHUB_URL!;

      this.hubConnection = new HubConnectionBuilder()
        .withUrl(baseUrl, {
          accessTokenFactory: () => store.userStore.user?.token!
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      this.hubConnection
        .start()
        .catch(error => console.log("Error Establishing hub connection: ", error));
    }
  }

  stopHubConnection = () => {
    this.hubConnection?.stop()
      .catch(error => console.log("Error Stopping connection: ", error));
  }

  setSalesTaxPacentage = () => {
    this.salesTaxPercentage = 0;
  }

  setDeliveryFee = () => {
    this.systemDeliveryFee = 500;
  }

  setCartToEmpty = () => {
    this.shoppingCart = null;
    this.shoppingCartItems.clear();
    this.associatedItemsRegistry.clear();
    this.cartIdentifier = "";
    deleteCookie(this.cartIdName);
  };

  setFoodId = async (foodId: string) => {
    this.selectedFoodIdentifier = foodId;
  };

  getFoodId = async () => {
    return this.selectedFoodIdentifier;
  };

  isFoodForVendor = async (foodId: string) => {
    let food = await store.foodStore.loadFood(foodId);
    return !(
      this.shoppingCart !== null &&
      this.shoppingCart.vendor.id !== food!.vendorId
    );
  };

  initializeCart = (vendor: Vendor, items: ShoppingCartItem[] = []) => {
    this.shoppingCart = {
      vendor: vendor,
      items: items,
      otherCharges: this.OtherCharges,
      itemsSubTotal: this.OrderSubTotal,
      deliveryFee: this.DeliveryFee,
    };
  };

  setRefreshing = (state: boolean) => {
    this.refreshing = state;
  };

  openConfirmRefreshCart = async (foodId: string) => {
    this.setFoodId(foodId);
    this.confirmModalOpenState = true;
  };

  closeConfirmRefreshCart = () => {
    this.confirmModalOpenState = false;
  };

  getIdentifyer = async () => {
    try {
      let cartId = await agent.Carts.getCartId();
      // console.log(cartId);
      if (cartId !== "" && cartId !== null) {
        this.cartIdentifier = cartId;
      }
    } catch (error) {
      console.log(error);
    }
  };

  loadShoppingCart = async () => {
    if (!this.IsIdentifierEmpty && this.IsCartEmpty) {
      await this.getCartItems(this.cartIdentifier);
    }
  };

  loadIncompletedCart = async () => {
    if (!this.cartIdentifier) return;
    this.loading = true;
    try {
      let cartItems = await agent.Carts.getItems(this.cartIdentifier);
      if (cartItems.length > 0) {
        let vendor = await store.vendorStore.loadVendor(cartItems[0].vendorId);
        this.initializeCart(vendor!, cartItems);
      }
      runInAction(() => (this.loading = false));;
    } catch (error) {
      runInAction(() => this.loading = false);
      console.log(error);
    }
  }

  loadAssociatedItems = async (id: string) => {
    this.loadingAssociatedItems = true;
    try {
      let associatedItems = await agent.Carts.getAssociatedItems(id);
      runInAction(() => {
        associatedItems.forEach((item) => {
          this.associatedItemsRegistry.set(item.id, item);
        })
        this.loadingAssociatedItems = false
      });
    } catch (error) {
      runInAction(() => this.loadingAssociatedItems = false);
      console.log(error);
    }
  }

  private getCartItems = async (cardId: string) => {
    try {
      this.loading = true;
      let cartItems = await agent.Carts.getItems(cardId);
      console.log(cartItems);
      if (cartItems.length > 0) {
        let vendor = await store.vendorStore.loadVendor(cartItems[0].vendorId);
        this.initializeCart(vendor!, cartItems);
      }
      runInAction(() => (this.loading = false));
      return this.shoppingCart;
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  addToCartWithRefresh = async (foodId: string) => {
    this.setRefreshing(true);
    await this.deleteCart(this.shoppingCart!.items[0].cartId);
    await this.addToCart(foodId);
    this.setRefreshing(false);
  };

  // addExistingItem = async (id: string) => {
  //   this.addingToCart = true;
  //   try {
  //     let cartItem = await agent.Carts.updateCart(id, []);
  //     await this.addItemToCart(cartItem);
  //     runInAction(() => {
  //       if ((this.cartIdentifier === "") || (this.cartIdentifier !== "" && this.cartIdentifier !== cartItem.cartId)) {
  //         this.cartIdentifier = cartItem.cartId;
  //       }
  //       this.addingToCart = false;
  //     });
  //   } catch (error) {
  //     runInAction(() => (this.addingToCart = false));
  //     console.log(error);
  //   }
  // }

  addToCart = async (foodId: string, associatedFoodIds: string[] = []) => {
    this.addingToCart = true;
    try {
      let cartItem = await agent.Carts.addToCart(foodId, associatedFoodIds);
      await this.addItemToCart(cartItem);
      runInAction(() => {
        if ((this.cartIdentifier === "") || (this.cartIdentifier !== "" && this.cartIdentifier !== cartItem.cartId)) {
          this.cartIdentifier = cartItem.cartId;
        }
        this.addingToCart = false;
      });
    } catch (error) {
      runInAction(() => (this.addingToCart = false));
      console.log(error);
    }
  };

  updateCartItem = async (cartId: string, foodIds: string[] = []) => {
    this.addingToCart = true;
    try {
      let cartItem = await agent.Carts.updateCart(cartId, foodIds);
      await this.addItemToCart(cartItem);
      runInAction(() => {
        if ((this.cartIdentifier === "") || (this.cartIdentifier !== "" && this.cartIdentifier !== cartItem.cartId)) {
          this.cartIdentifier = cartItem.cartId;
        }
        this.addingToCart = false;
      })
    } catch (error) {
      runInAction(() => this.addingToCart = false);
      console.log(error);
    }
  }

  removeFromCart = async (id: number) => {
    this.removingFromCart = true;
    try {
      await agent.Carts.removeFromCart(id);
      await this.removeItemFromCart(id);
      if (this.shoppingCart!.items.length === 0) this.setCartToEmpty();
      runInAction(() => (this.removingFromCart = false));
    } catch (error) {
      runInAction(() => (this.removingFromCart = false));
      console.log(error);
    }
  };

  deleteCart = async (cartId: string) => {
    this.deletingCart = true;
    try {
      await agent.Carts.delete(cartId);
      this.setCartToEmpty();
      runInAction(() => (this.deletingCart = false));
    } catch (error) {
      runInAction(() => (this.deletingCart = false));
      console.log(error);
    }
  };

  private addItemToCart = async (cartItem: ShoppingCartItem) => {
    if (this.shoppingCart && this.shoppingCart.items.length > 0) {
      const isItemInCart = this.shoppingCart.items.find(
        (item) => item.id === cartItem.id
      );

      if (isItemInCart) {
        this.shoppingCart.items = this.shoppingCart.items.map((item) =>
          item.id === cartItem.id ? cartItem : item
        );
      } else {
        this.shoppingCart.items = [...this.shoppingCart.items, cartItem];
      }
    } else {
      let vendor = await store.vendorStore.loadVendor(cartItem.vendorId);
      this.initializeCart(vendor!, [cartItem]);
    }
  };

  private removeItemFromCart = async (id: number) => {
    this.shoppingCart!.items = this.shoppingCart!.items.reduce(
      (accumulator, item) => {
        if (item.id === id) {
          if (item.quantity === 1) return accumulator;
          let newQuantity = item.quantity - 1;
          let sumAssoPrice = item.associatedItems.reduce(((prev, curr) => (prev + curr.price)), 0);
          return [...accumulator, { ...item, quantity: newQuantity, totalPrice: (item.price + sumAssoPrice) * newQuantity }];
        } else {
          return [...accumulator, item];
        }
      },
      [] as ShoppingCartItem[]
    );
  };

  checkoutOrder = async (data: Checkout) => {
    this.checkingout = true;
    try {
      // await agent.Carts.checkout(data);
      await this.hubConnection?.invoke("CheckoutOrder", data);
      this.setCartToEmpty();
      runInAction(() => (this.checkingout = false));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.checkingout = false));
    }
  };
}
