import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import Checkout from "../../../../../app/models/checkout";
import Payment, { PaymentStatus } from "../../../../../app/models/payment";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import Address from "../../../../../app/models/address";

interface Props {
  editState: boolean;
  paymentMethodId: string;
  deliveryAddress: Address | null;
}

const PayOnDeliveryButton = ({
  editState,
  paymentMethodId,
  deliveryAddress,
}: Props) => {
  // store
  const {
    shoppingCartStore: {
      OrderTotalAmount,
      OrderSubTotal,
      SalesTax,
      DeliveryFee,
      OtherCharges,
      OrderDiscount,
      cartIdentifier,
      IsCartEmpty,
      IsIdentifierEmpty,
      checkoutOrder,
      createHubConnect,
      stopHubConnection,
    },
    paymentStore: { getTxRef, begin, submitting },
    userStore: { User },
  } = useStore();

  // state
  const [txRef, setTxRef] = useState<string>("");
  const [processing, setProcessing] = useState(false);

  // on load
  useEffect(() => {
    getTxRef()
      .then((ref) => setTxRef(ref!))
      .catch((error) => console.log(error));
    // console.log(data);
  }, [getTxRef, setTxRef]);

  useEffect(() => {
    createHubConnect();

    return () => stopHubConnection();
  }, [createHubConnect, stopHubConnection]);

  // usenavigate
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setProcessing(true);

    // checkout data
    let checkoutDate: Checkout = {
      cartId: cartIdentifier,
      orderSubTotal: OrderSubTotal,
      otherCharges: OtherCharges,
      deliveryFee: DeliveryFee,
      salesTax: SalesTax,
      orderDiscount: OrderDiscount,
      orderTotal: OrderTotalAmount,
      deliveryAddress: deliveryAddress!,
    };

    let today = new Date();

    let payment: Payment = {
      transactionReference: txRef,
      cartId: cartIdentifier,
      gatewayId: paymentMethodId,
      requestedOnUtc: today.toISOString(),
      commissionFee: 0,
      vendorSales: 0,
      orderSubTotal: OrderSubTotal,
      deliveryFee: DeliveryFee,
      otherCharges: OtherCharges,
      salesTax: SalesTax,
      orderDiscount: OrderDiscount,
      orderTotalAmount: OrderTotalAmount,
      amountPaid: 0,
      currency: "NGN",
      title: "Food Purchase",
      status: PaymentStatus.Pending,
      type: "P",
      statusChangedOnUtc: today.toISOString(),
      additionalInfo: "Pay on delivery, Payment confirmation is required.",
      payerId: User!.email,
      payerName: User!.fullName,
      payerEmail: User!.email,
      payerTelephone: User!.phoneNumber,
    };

    // console.log(payment);
    begin(payment)
      .then((response) => {
        if (response?.status === "pending") {
          checkoutOrder(checkoutDate)
            .finally(() => {
              navigate("/account");
              setProcessing(false);
            })
            .catch((error) => {
              console.log(error);
              setProcessing(false);
              navigate("/checkout-failure");
            });
        } else {
          console.log(response);
          setProcessing(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setProcessing(false);
        navigate("/checkout-failure");
      });
  };

  if (processing && submitting) <LoadingComponent />;

  return (
    <Button
      disabled={IsCartEmpty || IsIdentifierEmpty || editState === false}
      onClick={handleButtonClick}
      size="large"
      fluid
      color="olive"
    >
      Complete Checkout
    </Button>
  );
};

export default observer(PayOnDeliveryButton);
