import { Button, Grid, Header } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import FoodForm from "./forms/FoodForm";

const FoodEmptyPanel = () => {
  const {
    modalStore: { openModal },
  } = useStore();
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      style={{ minHeight: "calc(100vh - 120px)" }}
    >
      <Grid.Column width={16}>
        <Header as="h4">
          <Header.Content>The fastest way to start selling</Header.Content>
          <Header.Subheader style={{ padding: "10px 0 5px 0" }}>
            Add a food, give a compelling description of your food and manage
            your orders and shipments.
          </Header.Subheader>
        </Header>
        <Button
          positive
          icon="plus"
          labelPosition="left"
          content="New Food"
          onClick={() => openModal(<FoodForm />, "tiny")}
        />
      </Grid.Column>
    </Grid>
  );
};

export default FoodEmptyPanel;
