import React, { useEffect } from "react";

interface SettingsLayoutPageProps {
  setPageTitle: (title: string) => void;
}

const SettingsLayoutPage = ({ setPageTitle }: SettingsLayoutPageProps) => {
  useEffect(() => {
    setPageTitle("Settings");
  }, [setPageTitle]);

  return <div>SettingsPageLayout</div>;
};

export default SettingsLayoutPage;
