import { Link } from "react-router-dom";
import { Container, Header, List, Segment } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const Pricing = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">
        Pricing
      </Header>
      <p>
        At Chops2u, we believe in transparent and straightforward pricing for
        restaurants. Our pricing is designed to ensure a seamless experience for
        both restaurants and customers.
      </p>
      <Header as="h5">Restuarants</Header>
      <List as="ul">
        <List.Item as="li">10% commission on every order</List.Item>
        <List.Item as="li">No additional fees</List.Item>
      </List>
      <Segment info>
        We charge a flat 10% commission on every order processed through our
        platform. This fee covers the operational costs associated with
        providing businesses with a seamless food delivery experience.
      </Segment>
      <Segment message>
        We also charge customers a delivery fee separates from the menu prices
        set by restaurants. The delivery fee is determined by customer location.
      </Segment>
      <Header as="h3">Frequently Ask Questions</Header>
      <List>
        <List.Item>
          <List.Header>Q: How does the commission works?</List.Header>
          <br />
          <List.Content>
            A: We charge a flat 10% commission on every order processed on our
            platform. This fee covers the operational cost associated with
            providing restaurants with a seamless food delivery experience.
          </List.Content>
        </List.Item>
        <p></p>
        <List.Item>
          <List.Header>Q: Are there any hidden fees?</List.Header>
          <br />
          <List.Content>
            A: No, there are no hidden fee. The 10% commission is the only cost
            you’ll incur for using our platform.
          </List.Content>
        </List.Item>
        <p></p>
        <List.Item>
          <List.Header>Q: How do I receive payments for orders?</List.Header>
          <br />
          <List.Content>
            A: We handle the payment process on your behalf and provide regular
            payouts for the orders processed through our platform. <Link to="/payoutpolicy">Checkout our Payout Policy.</Link>
          </List.Content>
        </List.Item>
        <p></p>
        <List.Item>
          <List.Header>Q: How does the delivery fee work?</List.Header>
          <br />
          <List.Content>
            A: The delivery fee is determined by customer location and may vary.
            When placing an order, the delivery fee will be clearly displayed
            along with the total cost.
          </List.Content>
        </List.Item>
        <p></p>
        <List.Item>
          <List.Header>
            Q: Is the delivery fee included in the restaurant prices?
          </List.Header>
          <br />
          <List.Content>
            A: No, the delivery fee is separate from restaurant prices. It
            covers the cost of delivering the food to customer location.
          </List.Content>
        </List.Item>
      </List>
    </Container>
  );
};

export default Pricing;
