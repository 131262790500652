import PayoutEmptyPanel from "./PayoutEmptyPanel";
import PayoutTopMenu from "./PayoutTopMenu";

const PayoutLayout = () => {
  return (
    <div>
      <div>
        <PayoutTopMenu />
      </div>
      <section>
        <PayoutEmptyPanel />
      </section>
    </div>
  );
};

export default PayoutLayout;
