import React from "react";
import { Link } from "react-router-dom";
import { Segment, Image } from "semantic-ui-react";

const ChopperSidebarBanner = () => {
  return (
    <Segment vertical>
      <Image
        as={Link}
        to="/"
        src="/images/logo.png"
        size="small"
        style={{ height: "auto", width: "120px" }}
      />
    </Segment>
  );
};

export default ChopperSidebarBanner;
