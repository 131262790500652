import { useField } from "formik";
import { Form, Label, TextArea } from "semantic-ui-react";

interface Props {
  name: string;
  rows: number;
  label?: string;
  placeholder?: string;
}

export default function Chops2uTextAreaInput(props: Props) {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {props.label && (
        <label style={{ textAlign: "left" }}>{props.label}</label>
      )}
      <TextArea {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label pointing style={{ float: "left" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
