import { useEffect } from "react";
import PayoutLayout from "./paid/PayoutLayout";
import PendingPayoutLayout from "./pending/PendingPayoutLayout";

interface Props {
  setPageTitle: (title: string) => void;
}

const PayoutPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Payouts");
  }, [setPageTitle]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "1 0 70%", backgroundColor: "white" }}>
        <PayoutLayout />
      </div>
      <div style={{ flex: "1 0 30%" }}>
        <PendingPayoutLayout />
      </div>
    </div>
  );
};

export default PayoutPageLayout;
