import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { Header, Segment, Button, Message } from "semantic-ui-react";
import ValidationErrors from "../../frontpage/errors/ValidationErrors";
import Chops2uSelectInput from "../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../app/common/forms/Chops2uTextInput";
import Chops2uMultiSelectInput from "../../../app/common/forms/Chops2uMultiSelectInput";
import { useStore } from "../../../app/store/store";
import * as Yup from "yup";
import "yup-phone";
import { RegisterVendorFormValues } from "../../../app/models/user";
import { VendorCategory } from "../../../app/models/vendor";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VendorRegistrationPage = () => {
  const {
    userStore: { registerVendor, registeringVendor },
    categoryStore: { loadCategories, categoriesByTitle },
    locationStore: { loadCityLocations, LocationsByName: locationsByName },
  } = useStore();
  const vendor: RegisterVendorFormValues = {
    cityId: "1",
    locationId: "",
    businessName: "",
    categoryIds: [],
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    password: "",
    confirmPassword: "",
    categories: [],
    error: null,
  };

  const navigate = useNavigate();

  useEffect(() => {
    loadCategories();
    loadCityLocations("1");
  }, [loadCategories, loadCityLocations]);

  const validationSchema = Yup.object({
    locationId: Yup.number()
      .required("Location is required.")
      .positive()
      .integer(),
    businessName: Yup.string().required(),
    categoryIds: Yup.array().min(1, "Store Category is required."),
    fullName: Yup.string().required(),
    email: Yup.string().trim().email().required(),
    phoneNumber: Yup.string().trim().phone().required(),
    address: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Password must match."),
  });

  const handleFormSubmit = (
    values: RegisterVendorFormValues,
    { setErrors }: FormikHelpers<RegisterVendorFormValues>
  ) => {
    values.categories = values.categoryIds!.map<VendorCategory>((categoryId) =>
      Object.assign({ categoryId })
    );

    registerVendor(values)
      .then(() =>
        navigate(`/store/register-success?email=${values.email}`, {
          replace: true,
        })
      )
      .catch((error) => setErrors({ error }));
  };

  return (
    <div style={{ maxWidth: 550, margin: "auto" }}>
      <Formik
        validationSchema={validationSchema}
        initialValues={vendor}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
          <Form className="form ui error large" onSubmit={handleSubmit}>
            <Segment stacked style={{ marginTop: "1em" }}>
              <Header
                textAlign="center"
                disabled
                size="tiny"
                content={"Vendor Registration".toUpperCase()}
              />
              <Chops2uSelectInput
                name="locationId"
                label="Location"
                placeholder="Choose your location"
                options={locationsByName}
              />

              <Chops2uTextInput
                name="businessName"
                label="Store Name"
                placeholder="Name of your restaurant"
              />

              <Chops2uMultiSelectInput
                name="categoryIds"
                label="Store Category"
                value={[]}
                options={categoriesByTitle}
                placeholder="Store Categories"
              />

              <Chops2uTextInput
                name="fullName"
                label="Full Name"
                placeholder="Full name"
              />

              <div className="two fields">
                <Chops2uTextInput
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Telephone"
                />

                <Chops2uTextInput
                  name="email"
                  label="Email"
                  placeholder="Email"
                />
              </div>

              <Chops2uTextInput
                name="address"
                label="Store Address"
                placeholder="Address"
              />

              <div className="two fields">
                <Chops2uTextInput
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password"
                />
                <Chops2uTextInput
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>

              <div className="field">
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              </div>
              <div className="field">
                <Button
                  disabled={(!isValid && dirty) || isSubmitting}
                  loading={isSubmitting && registeringVendor}
                  color="olive"
                  type="submit"
                  fluid
                  size="large"
                >
                  Register
                </Button>
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
      <Message>
        Already have a store? <a href="/store">Login</a>
      </Message>
    </div>
  );
};

export default observer(VendorRegistrationPage);
