import { observer } from "mobx-react-lite";
import { Header, List, Message, Segment } from "semantic-ui-react";
import CheckoutCartItem from "./CheckoutCartItem";
import ShoppingCart from "../../../../app/models/shopping-cart";
import CartHeader from "../../cart/CartHeader";
import { toCurrency } from "../../../../app/common/util/util";

interface Props {
  cart: ShoppingCart | null;
  itemsTotal?: number;
  otherCharges: number;
  deliveryFee?: number;
  total?: number;
  mobile?: boolean;
}

const CheckoutCart = ({
  cart,
  itemsTotal,
  otherCharges,
  deliveryFee,
  total,
  mobile,
}: Props) => {
  return (
    <>
      <Segment attached="top">
        <Header
          style={{
            fontSize: mobile ? "0.9em" : "",
            letterSpacing: mobile ? "1px" : "3px",
          }}
          as="h5"
          color="grey"
          content="ORDER SUMMARY"
        />
        {cart && (
          <CartHeader
            vendorId={cart.vendor!.id}
            title={cart.vendor!.title}
            location={cart.vendor!.location!}
            image={cart.vendor!.image}
          />
        )}
      </Segment>
      <Segment vertical attached>
        <List divided relaxed="very">
          {cart && cart.items.map((item) => <CheckoutCartItem item={item} />)}
        </List>
      </Segment>
      <Message attached="bottom" style={{ padding: "0", marging: "0" }}>
        <div
          style={{
            width: "90%",
            border: "none",
            margin: "auto",
          }}
        >
          <Segment
            color="grey"
            vertical
            attached="top"
            style={{ border: "none", borderRadius: "0" }}
          >
            <div>
              <span>Items Subtotal</span>
              <span style={{ float: "right" }}>
                {itemsTotal && toCurrency(itemsTotal)}
              </span>
            </div>
            {otherCharges > 0 && (
              <div>
                <span>Packaging</span>
                <span style={{ float: "right" }}>
                  {otherCharges && toCurrency(otherCharges)}
                </span>
              </div>
            )}
            <div>
              <span>Delivery Fee</span>
              <span style={{ float: "right" }}>
                {deliveryFee && toCurrency(deliveryFee)}
              </span>
            </div>
            <div style={{ marginTop: "0.35em" }}>
              <span style={{ fontWeight: "bold" }}>Total</span>
              <span style={{ fontWeight: "bold", float: "right" }}>
                {total && toCurrency(total)}
              </span>
            </div>
          </Segment>
        </div>
      </Message>
      <div style={{ fontSize: "12px" }}>
        <a href={`/vendors/${cart?.vendor?.id}`}>Back to Shopping</a>
      </div>
    </>
  );
};

export default observer(CheckoutCart);
