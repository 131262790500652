import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Menu, ItemHeader } from "semantic-ui-react";
import StoreSidebarBanner from "../StoreSidebarBanner";
import {
  SideNavBannerWrapper,
  SideNavFooterWrapper,
  SideNavMenu,
  SideNavMenuWrapper,
  SideNavWrapper,
} from "../store.style";
import { SideNavMenuIcon } from "../../features.style";

const StoreSideNav = () => {
  const [target, setTarget] = useState("home");

  const location = useLocation();

  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name?: string
  ) => {
    if (name !== undefined) setTarget(name);
  };

  return (
    <SideNavWrapper>
      <SideNavBannerWrapper>
        <StoreSidebarBanner />
      </SideNavBannerWrapper>
      <SideNavMenuWrapper>
        <SideNavMenu
          fluid
          borderless
          vertical
          inverted
          size="small"
          color="grey"
        >
          <Container>
            <Menu.Item
              as={Link}
              to="/store/getStarted"
              name="getStarted"
              active={
                target === "getStarted" ||
                location.pathname === "/store/getStarted"
              }
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <SideNavMenuIcon name="globe" />
              Get Started
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store"
              name="home"
              active={location.pathname === "/store"}
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <SideNavMenuIcon name="dashboard" />
              Dashboard
            </Menu.Item>
            {/* COMMERCE SECTION */}
            <Menu.Item>
              <ItemHeader>COMMERCE</ItemHeader>
              <Menu.Item
                as={Link}
                to="/store/menus"
                name="menus"
                active={
                  target === "menus" || location.pathname === "/store/menus"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="list alternate outline" />
                Menus
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/foods"
                name="foods"
                active={
                  target === "foods" || location.pathname === "/store/foods"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="food" />
                Foods
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/orders"
                name="orders"
                active={
                  target === "orders" || location.pathname === "/store/orders"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="cart" />
                Orders
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/deliveries"
                name="deliveries"
                active={
                  target === "deliveries" ||
                  location.pathname === "/store/deliveries"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="shipping fast" />
                Deliverises
              </Menu.Item>
              {/* <Menu.Item
                as={Link}
                to="/store/order-history"
                name="order-history"
                active={
                  target === "order-history" || location.pathname === "/store/order-history"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <Icon
                  name="cart arrow down"
                  style={{ float: "left", marginRight: "0.6em" }}
                />
                History
              </Menu.Item> */}
            </Menu.Item>
            {/* PAYMENTS SECTION */}
            <Menu.Item>
              <ItemHeader>PAYMENTS</ItemHeader>
              <Menu.Item
                as={Link}
                to="/store/sales"
                name="sales"
                active={
                  target === "sales" || location.pathname === "/store/sales"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="signal" />
                Sales
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/refunds"
                name="refunds"
                active={
                  target === "refunds" || location.pathname === "/store/refunds"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="undo alternate" />
                Refunds
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/payouts"
                name="payouts"
                active={
                  target === "payouts" || location.pathname === "/store/payouts"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="box" />
                Payouts
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/balance"
                name="balance"
                active={
                  target === "balance" || location.pathname === "/store/balance"
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="trophy" />
                Balance
              </Menu.Item>
            </Menu.Item>
            <Menu.Item>
              <ItemHeader>SHOP</ItemHeader>
              <Menu.Item
                as={Link}
                to="/store/settings"
                name="settings"
                active={
                  target === "settings" ||
                  location.pathname.indexOf("/store/settings") >= 0
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="settings" />
                Settings
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/store/support"
                name="support"
                active={
                  target === "support" ||
                  location.pathname.indexOf("/store/support") >= 0
                }
                onClick={(e, { name }) => handleItemClick(e, name)}
              >
                <SideNavMenuIcon name="shield" />
                Support
              </Menu.Item>
              {/* <Menu.Item
      as={Link}
      to="/store/logs"
      name="logs"
      active={
        target === "logs" || location.pathname === "/store/logs"
      }
      onClick={(e, { name }) => handleItemClick(e, name)}
    >
      <Icon
        name="chess rock"
        style={{ float: "left", marginRight: "0.6em" }}
      />
      Audit Logs
    </Menu.Item> */}
            </Menu.Item>
          </Container>
        </SideNavMenu>
      </SideNavMenuWrapper>
      <SideNavFooterWrapper></SideNavFooterWrapper>
    </SideNavWrapper>
  );
};

export default StoreSideNav;
