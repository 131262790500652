import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Photo, Profile, ProfileFormValues } from "../models/profile";
import Order from "../models/order";
import Address from "../models/address";

export default class ProfileStore {
  profile: Profile | null = null;
  defaultAddress: Address | null = null;
  addressRegistry = new Map<string, Address>();
  selectedOrder: Order | null = null;
  orderRegistry = new Map<string, Order>();
  loadingProfile = false;
  loadingOrders = false;
  loadingOrder = false;
  uploading = false;
  loading = false;
  deleting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get IsCurrentUser() {
    if (store.userStore.user && this.profile) {
      return store.userStore.user.email === this.profile.email;
    }

    return false;
  }

  get IsDefaultAddressSet() {
    return !!this.defaultAddress;
  }

  get DefaultAddress() {
    return this.defaultAddress;
  }

  get Addresses() {
    return Array.from(this.addressRegistry.values());
  }

  get OrdersById() {
    return Array.from(this.orderRegistry.values());
  }

  loadProfile = async (email: string) => {
    this.loadingProfile = true;
    try {
      const profile = await agent.Profiles.get(email);
      runInAction(() => {
        this.profile = profile;
        this.loadingProfile = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loadingProfile = false));
    }
  };

  loadDefaultAddress = async () => {
    this.loading = true;
    try {
      let address = await agent.Profiles.getDefaultAddress();
      runInAction(() => {
        if (address) this.defaultAddress = address;
        this.loading = false;
      })
      // return address;
    } catch (error) {
      runInAction(() => this.loading = false);
      console.log(error);
    }
  }

  update = async (values: ProfileFormValues) => {
    this.loadingProfile = true;
    try {
      let profile = await agent.Profiles.update(values);
      runInAction(() => {
        this.profile = profile;
        this.loadingProfile = false;
      })
    } catch (error) {
      runInAction(() => this.loadingProfile = false);
      throw error;
    }
  }

  loadAddresses = async () => {
    this.loading = true;
    try {
      let addresses = await agent.Profiles.getAddresses();
      runInAction(() => {
        addresses.forEach((address) => {
          this.addressRegistry.set(address.id!.toString(), address);
        })
        this.loading = false;
      })
    } catch (error) {
      console.log(error);
      runInAction(() => this.loading = false);
    }
  }

  uploadPhoto = async (file: Blob) => {
    this.uploading = true;
    try {
      const photo = await agent.Profiles.uploadPhoto(file);
      runInAction(() => {
        if (this.profile) {
          this.profile.photos?.push(photo);
          if (photo.isMain && store.userStore.user) {
            this.profile.image = photo.url;
            store.userStore.setPhoto(photo.url);
          }
        }
        this.uploading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.uploading = false));
    }
  };

  addUpdatePhoto = async (file: Blob) => {
    this.uploading = true;
    try {
      const photo = await agent.Profiles.addUpdatePhoto(file);
      runInAction(() => {
        if (this.profile && this.profile.photos) {
          this.profile.photos = this.profile.photos.filter(
            (p) => p.id !== photo.id
          );
          this.profile.photos.push(photo);
          if (photo.isMain && store.userStore.user) {
            this.profile.image = photo.url;
            store.userStore.setPhoto(photo.url);
          }
          this.deleting = false;
        }
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.uploading = false));
    }
  };

  setMainPhoto = async (photo: Photo) => {
    this.loading = true;
    try {
      await agent.Profiles.setMainPhoto(photo.id);
      runInAction(() => {
        if (this.profile && this.profile.photos) {
          this.profile.photos.find((p) => p.isMain)!.isMain = false;
          this.profile.photos.find((p) => p.id === photo.id)!.isMain = true;
          store.userStore.user!.image = photo.url;
          this.profile.image = photo.url;
          this.loading = false;
        }
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  deletePhoto = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Profiles.deletePhoto(id);
      runInAction(() => {
        if (this.profile && this.profile.photos) {
          this.profile.photos = this.profile.photos.filter((p) => p.id !== id);
          this.deleting = false;
        }
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };
}
