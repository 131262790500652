import { observer } from "mobx-react-lite";
import { Grid, Header, Icon, Segment } from "semantic-ui-react";
import Address from "../../../../app/models/address";
import { GridRow } from "../checkout-styles";

interface Props {
  mobile?: boolean;
  deliveryAddress: Address;
  setEditState: (state: boolean) => void;
}

const DeliveryDetails = ({ mobile, deliveryAddress, setEditState }: Props) => {
  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={10} tablet={10} mobile={9}>
            <Header
              as="h5"
              color="grey"
              style={{
                letterSpacing: mobile ? "3px" : "3px",
                fontSize: mobile ? "" : "",
              }}
            >
              DELIVERY DETAILS
            </Header>
          </Grid.Column>
          <Grid.Column computer={6} tablet={6} mobile={7}>
            <Header
              onClick={() => setEditState(true)}
              as="h5"
              color="olive"
              floated="right"
              style={{
                fontWeight: "ligther",
                fontSize: mobile ? "13.5px" : "14px",
                letterSpacing: mobile ? "0.2px" : "1px",
                cursor: "pointer",
              }}
            >
              CHANGE ADDRESS
            </Header>
          </Grid.Column>
        </Grid.Row>
        <GridRow>
          <Grid.Column width={16}>
            <div
              data-name="Customer Name"
              style={{
                fontWeight: 600,
                letterSpacing: mobile ? 1 : 2,
                fontSize: mobile ? "15px" : "15px",
              }}
            >
              {deliveryAddress?.name}
            </div>
            <div
              data-name="Customer Telephone"
              style={{
                letterSpacing: mobile ? 1 : 2,
                marginTop: mobile ? 2 : 2,
              }}
            >
              <Icon color="grey" name="phone" />
              {deliveryAddress?.telephone}
            </div>
            <div
              data-name="Customer Address"
              style={{
                marginTop: mobile ? 1 : 2,
                width: "400px",
              }}
            >
              <Icon color="grey" name="point" />
              {deliveryAddress?.street}
            </div>
            <div
              data-name="Customer location"
              style={{
                marginTop: mobile ? 1 : 2,
              }}
            >
              <Icon />
              {deliveryAddress?.location?.name}
            </div>
            {deliveryAddress?.company && (
              <div
                style={{
                  marginTop: mobile ? 1 : 2,
                }}
              >
                <Icon />
                {deliveryAddress?.company + ", " + deliveryAddress?.floor}
              </div>
            )}
          </Grid.Column>
        </GridRow>
      </Grid>
    </Segment>
  );
};

export default observer(DeliveryDetails);
