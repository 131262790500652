import { observer } from "mobx-react-lite";
import { Feed, Image } from "semantic-ui-react";
import Offer from "../../../../../app/models/offer";

interface Props {
  offer: Offer;
}

export default observer(function VendorBannerOfferItem({ offer }: Props) {
  return (
    <Feed>
      <Feed.Event>
        <Feed.Label>
          <Image src="/images/icons/offer-ash.png" avatar />
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary style={{ color: "#fff" }}>
            {offer.title.toUpperCase()}
          </Feed.Summary>
          <Feed.Extra text style={{ color: "#fff" }}>
            {offer.description}
          </Feed.Extra>
        </Feed.Content>
      </Feed.Event>
    </Feed>
  );
});
