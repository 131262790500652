export default interface Pagination {
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export class PaginatedResult<T> {
  data: T;
  pagination: Pagination;

  constructor(data: T, pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class PagingParams {
  page: number;
  perPage: number;

  constructor(page: number = 1, perPage: number = 10) {
    this.page = page;
    this.perPage = perPage;
  }
}
