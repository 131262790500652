import React from "react";
import { Route, Routes } from "react-router-dom";
import AccountTypeDetailedPage from "./AccountTypeDetailedPage";
import AccountTypeList from "./AccountTypeList";

const AccountTypesPage = () => {
  return (
    <>
      <Routes>
        <Route
          path={`/administrator/settings/accountTypes/:id`}
          element={AccountTypeDetailedPage}
        />
        <Route
          path={`/administrator/settings/accountTypes`}
          element={AccountTypeList}
        />
      </Routes>
    </>
  );
};

export default AccountTypesPage;
