import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Header } from "semantic-ui-react";
import Shipment from "../../../../app/models/shipment";
import { observer } from "mobx-react-lite";
import { timeLaps, toCurrency } from "../../../../app/common/util/util";
import { PageWrapper } from "../../administrator.style";

interface Props {
  data: Shipment[];
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

const ShipmentList = ({ data }: Props) => {
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "orderNumber",
      label: "Order No",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.orderNumber;
        // },
      },
    },
    {
      name: "orderTotal",
      label: "Order Amount",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    {
      name: "numberOfItems",
      label: "Items",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.items.length;
        // },
      },
    },
    {
      name: "creadedOnUtc",
      label: "Time",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return timeLaps(value);
        },
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.fullName;
        // },
      },
    },
    {
      name: "chopperName",
      label: "Chopper",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.fullName;
        // },
      },
    },
    {
      name: "chopperPhone",
      label: "Chopper Phone",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    // {
    //   name: "cancel",
    //   label: "Cancel",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customHeadLabelRender: headerLabelRenderer,
    //   },
    // },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.status;
        // },
      },
    },
    {
      name: "deleted",
      label: "Deleted",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: checkboxRender,
      },
    },
    {
      name: "deletedOnUtc",
      label: "Deleted On",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default observer(ShipmentList);
