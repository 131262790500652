import { observer } from "mobx-react-lite";
import { Card, Segment } from "semantic-ui-react";
import Vendor from "../../../../../app/models/vendor";
import VendorCard from "./items/VendorCard";

interface Props {
  vendors: Vendor[];
}

const VendorList = ({ vendors }: Props) => {
  return (
    <Segment style={{ minHeight: "33.5vh" }} vertical>
      <Card.Group itemsPerRow={3} stackable>
        {vendors && vendors.map((vendor) => <VendorCard vendor={vendor} />)}
      </Card.Group>
    </Segment>
  );
};

export default observer(VendorList);
