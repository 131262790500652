import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Button,
} from "semantic-ui-react";

interface Props {
  setPageTitle: (title: string) => void;
}

const GetStarted = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Get Started");
  }, [setPageTitle]);

  return (
    <Container style={{ width: "95%", marginTop: "1em" }}>
      <Header textAlign="center" style={{ padding: "2em 0" }}>
        <Header.Content
          style={{
            fontSize: "36px",
            lineHeight: "40px",
            fontWeight: 500,
            color: "#555",
          }}
        >
          Welcome to Chops2u
        </Header.Content>
        <Header.Subheader style={{ fontSize: "18px", margin: "10px 0 0 0" }}>
          Complete your restaurant settings to start receiving orders.
        </Header.Subheader>
      </Header>
      <Segment vertical>
        <Grid stackable columns={3}>
          <Grid.Column>
            <Segment placeholder color="green">
              <Header textAlign="center" as="h4" icon>
                <Icon name="tasks" size="huge" color="green" />
                <Header.Subheader
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Start by building your restaurant menu.
                </Header.Subheader>
              </Header>
              <Segment.Inline>
                <Button as={Link} to="/store/menus">
                  Go To Menus
                </Button>
              </Segment.Inline>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment placeholder color="green">
              <Header textAlign="center" as="h4" icon>
                <Icon name="food" size="huge" color="green" />
                <Header.Subheader
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Then add foods to menu listing.
                </Header.Subheader>
              </Header>
              <Segment.Inline>
                <Button as={Link} to="/store/food">
                  Configure Foods
                </Button>
              </Segment.Inline>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment placeholder color="green">
              <Header textAlign="center" as="h4" icon>
                <Icon name="cart" size="huge" color="green" />
                <Header.Subheader
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Go to orders to take action.
                </Header.Subheader>
              </Header>
              <Segment.Inline>
                <Button as={Link} to="/store/orders">
                  Take Action
                </Button>
              </Segment.Inline>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
};

export default GetStarted;
