import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { Form, Input, InputOnChangeData, List } from "semantic-ui-react";
import Location from "../models/location";
import { useStore } from "../store/store";

interface Props {
  setOpen: (open: boolean) => void;
}

const LocationTypeahead = ({ setOpen }: Props) => {
  const {
    locationStore: { LocationsByName: locationsByName, loadLocations },
    userStore: { setDeliveryLocation },
  } = useStore();

  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState<Location[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    loadLocations();

    return () => {
      setText("");
      setSuggestions([]);
      setSelectedIndex(-1);
    };
  }, [loadLocations]);

  const onChangeHanler = (
    event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    let matches: Location[] = [];
    let { value } = data;

    if (value.length > 0) {
      matches = locationsByName.filter((location) => {
        const regex = new RegExp(`${value}`, "gi");
        return location.name.match(regex);
      });
      setSuggestions(matches);
      setText(value);
    } else if (value.length === 0) {
      setText("");
      setSuggestions([]);
      setSelectedIndex(-1);
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === "ArrowDown") {
      setSelectedIndex((prev) => {
        let newValue = prev;
        let condition =
          suggestions.length > 0 && suggestions.length - 1 > selectedIndex;
        if (condition) ++newValue;
        return newValue;
      });
    } else if (key === "ArrowUp") {
      setSelectedIndex((prev) => {
        let newValue = prev;
        let condition = suggestions.length > 0 && prev > 0;
        if (condition) --newValue;
        return newValue;
      });
    } else if (key === "Enter") {
      let text = "";
      if (selectedIndex >= 0) text = suggestions[selectedIndex].name;
      if (text.length > 0) selectListHandler(text);
    }
  };

  // Need to work on onBlur handler
  //===========================================
  // const onBlurHandler = () => {
  //   setText("");
  //   setSuggestions([]);
  // }

  const selectListHandler = (text: string) => {
    const location = locationsByName.filter(
      (location) => location.name.toUpperCase() === text.toUpperCase()
    )[0];

    if (location) {
      setDeliveryLocation(location)
        .then(() => {
          setText("");
          setSuggestions([]);
          setSelectedIndex(-1);
          setOpen(false);
        })
        .catch((error) => console.log(error));
    } else {
      setText("");
      setSuggestions([]);
      setSelectedIndex(-1);
    }
  };

  return (
    <Form>
      <Input
        onChange={onChangeHanler}
        onKeyDown={onKeyDownHandler}
        style={{ minWidth: "250px" }}
        size="mini"
        placeholder="Select location"
        value={text}
      />
      {suggestions && suggestions.length > 0 && (
        <List selection>
          {suggestions.map((suggestion, index) => (
            <List.Item
              active={index === selectedIndex}
              onClick={() => selectListHandler(suggestion.name)}
              key={suggestion.id}
            >
              {suggestion.name}
            </List.Item>
          ))}
        </List>
      )}
    </Form>
  );
};
export default observer(LocationTypeahead);
