import { observer } from "mobx-react-lite";
import { Button, Header, Label, Modal } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import Payment from "../../../../../app/models/payment";
import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";

interface Props {
  payment: Payment;
}

interface FormValues {
  reason: string;
  error: any;
}

const CancelOrderForm = ({ payment }: Props) => {
  const {
    modalStore: { closeModal },
    paymentStore: { cancel, submitting },
  } = useStore();

  const initValues = {
    reason: "",
    error: null,
  };

  const handleCencelOrder = (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    cancel(payment.transactionReference, values.reason)
      .then(() => closeModal())
      .catch((error) => setErrors({ error }));
  };

  return (
    <>
      <Modal.Header color="yellow">
        <Header as="h4" color="yellow" content="Cancel Order" />
      </Modal.Header>
      <Modal.Content>
        <Formik initialValues={initValues} onSubmit={handleCencelOrder}>
          {({ handleSubmit, isValid, isSubmitting, errors }) => (
            <Form
              className="form ui"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <p>Are you sure you want to cancel the following payment?</p>
              <Header as="h5" style={{ marginTop: "0em" }}>
                {payment.orderNumber + " " + payment.orderTotalAmount}
              </Header>
              <Chops2uTextInput
                name="reason"
                placeholder="Give for calcellation"
              />
              <ErrorMessage
                name="error"
                render={() => (
                  <Label
                    style={{ marginBottom: "15px" }}
                    basic
                    color="red"
                    content={errors.error}
                  />
                )}
              />
              <div className="fields">
                <Button
                  icon="cancel"
                  color="yellow"
                  labelPosition="left"
                  floated="left"
                  content="No"
                  onClick={closeModal}
                  style={{ width: "110px" }}
                />
                <Button
                  icon="checkmark"
                  labelPosition="left"
                  color="olive"
                  type="submit"
                  loading={submitting}
                  content="Yes"
                  // onClick={() => handleCencelOrder()}
                  style={{ width: "110px" }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </>
  );
};

export default observer(CancelOrderForm);
