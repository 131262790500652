import { observer } from "mobx-react-lite";
import { Container, Grid, Header, Segment } from "semantic-ui-react";

const PaymentsTopMenu = () => {
  return (
    <Segment attached="top" borderless>
      <Container>
        <Grid>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Count</Header.Subheader>
              <Header.Content>20</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Subtotal</Header.Subheader>
              <Header.Content>N5,000.00</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Commission</Header.Subheader>
              <Header.Content>N5,000.00</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Delivery Fees</Header.Subheader>
              <Header.Content>N5,000.00</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Estimated Payout</Header.Subheader>
              <Header.Content>N5,000.00</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header color="grey" as="h5">
              <Header.Subheader>Paid Out</Header.Subheader>
              <Header.Content>N5,000.00</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
};

export default observer(PaymentsTopMenu);
