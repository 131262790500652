import { Link } from "react-router-dom";
import { Menu, Container, Dropdown, Image } from "semantic-ui-react";

const PageMenu = () => {
  return (
    <Menu fixed="top">
      <Container>
        <Menu.Item header as={Link} to="/">
          <Image src="/images/logo.png" size="small" style={{ width: "120px", height: "auto" }} />
        </Menu.Item>
        <Menu.Item as={Link} to="/vendors">
          Vendors
        </Menu.Item>

        <Menu.Menu position="right">
          <Dropdown item simple text="Company">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="aboutus">
                About Us
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="pricing">
                Pricing
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="aboutus">
                Career
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Contact</Dropdown.Header>
              <Dropdown.Item as={Link} to="contactus">
                Contact Us
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="partnerwithus">
                Partner With Us
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="ridewithus">
                Ride With Us
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="helpandsupport">
                Help & Support
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default PageMenu;
