import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord"

const PayoutsEmptyPage = () => {
    return (
      <Chops2uEmptyRecord
        title="No Payouts Application Yet!"
        subtitle="See all payout apllications for process and necessary actions."
        color="olive"
      />
    );
}

export default PayoutsEmptyPage;