import { Form, Formik, FormikHelpers } from "formik";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { ContactFormValues } from "../../../app/models/contact-us";
import Chops2uTextInput from "../../../app/common/forms/Chops2uTextInput";
import Chops2uTextAreaInput from "../../../app/common/forms/Chops2uTextAreaInput";

const ContactUs = () => {
  const validate = Yup.object({
    fullName: Yup.string().trim().required(),
    email: Yup.string().trim().email().required(),
    message: Yup.string().trim().required(),
  });

  const initValues: ContactFormValues = {
    fullName: "",
    email: "",
    message: "",
    error: null,
  };

  const handleLoginFormSubmit = (
    values: ContactFormValues,
    { setErrors }: FormikHelpers<ContactFormValues>
  ) => {
    // login(values)
    //   .then(() => navigate(from, { replace: true }))
    //   .catch((error) => setErrors({ error: error }));
    console.log(values);
  };
  return (
    <div style={{ backgroundColor: "rgba(0,0,0,0.05)" }}>
      <Header
        color="olive"
        textAlign="center"
        size="huge"
        style={{ paddingTop: "3em" }}
      >
        Contact Us
      </Header>
      <Grid
        textAlign="center"
        style={{ minHeight: "470px" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: "620px" }}>
          <div>
            Use the form below to get in touch with the{" "}
            <b style={{ color: "#B5CC18" }}>Chops2u</b> team.
          </div>
          <Formik
            validationSchema={validate}
            onSubmit={handleLoginFormSubmit}
            initialValues={initValues}
          >
            {({ handleSubmit, isSubmitting, dirty, errors, isValid }) => (
              <Form className="ui form error" onSubmit={handleSubmit}>
                <Segment
                  stacked
                  style={{ marginTop: "1em", paddingTop: "2em" }}
                >
                  <Chops2uTextInput
                    name="fullName"
                    label="Name"
                    placeholder="Enter your name"
                  />

                  <Chops2uTextInput
                    name="email"
                    label="Email"
                    placeholder="Enter a valid email"
                  />

                  <Chops2uTextAreaInput
                    name="message"
                    label="Message"
                    rows={5}
                    placeholder="Type your message here"
                  />

                  <div className="field">
                    <Button
                      // loading={isSubmitting && logining}
                      color="olive"
                      fluid
                      type="submit"
                      size="large"
                    >
                      Send a Message
                    </Button>
                  </div>
                </Segment>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ContactUs;
