import { observer } from "mobx-react-lite";
import { Grid, Header, Button } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import MenuForm from "./forms/MenuForm";

const MenuEmptyPanel = () => {
  const {
    modalStore: { openModal },
  } = useStore();
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      style={{ minHeight: "calc(100vh - 120px)" }}
    >
      <Grid.Column width={16}>
        <Header as="h4">
          <Header.Content>You have not add your menu yet</Header.Content>
          <Header.Subheader style={{ padding: "10px 0 5px 0" }}>
            Add a menu, add food(s) to your menu and start selling on the
            <b> chops2u</b> platform.
          </Header.Subheader>
        </Header>
        <Button
          positive
          icon="plus"
          labelPosition="left"
          content="New Menu"
          onClick={() => openModal(<MenuForm />, "mini")}
        />
      </Grid.Column>
    </Grid>
  );
};

export default observer(MenuEmptyPanel);
