import { observer } from "mobx-react-lite";
import { useEffect } from "react";

interface Props {
    setPageTitle: (title: string) => void;
}

const VehiclePage = ({ setPageTitle }: Props) => {
    
    // use effect
    useEffect(() => {
        setPageTitle("My Vehicle");
    }, [setPageTitle]);
    
    return (<h1>Vehicle Page</h1>)
}

export default observer(VehiclePage);