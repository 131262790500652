import { Card, Header, Statistic } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import { observer } from "mobx-react-lite";

const StatsPanel = () => {
  const { restaurantStore: { NetSales, TotalOrders, AverageOrderSize } } = useStore();
  return (
    <Card.Group itemsPerRow={4} stackable>
      <Card color="teal">
        <Card.Content>
          <Card.Header style={{ fontSize: "1em", color: "gray" }}>
            Net sales
          </Card.Header>
          <Card.Description>
            <Statistic color="teal" size="mini">
              <Statistic.Value>
                N{" "}
                {NetSales.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Statistic.Value>
            </Statistic>
          </Card.Description>
        </Card.Content>
      </Card>
      <Card color="orange">
        <Card.Content>
          <Card.Header style={{ fontSize: "1em", color: "gray" }}>
            Total Orders
          </Card.Header>
          <Card.Description>
            <Statistic color="orange" size="mini">
              <Statistic.Value>{TotalOrders}</Statistic.Value>
            </Statistic>
          </Card.Description>
        </Card.Content>
      </Card>
      <Card color="purple">
        <Card.Content>
          <Card.Header style={{ fontSize: "1em", color: "gray" }}>
            Average Order Size
          </Card.Header>
          <Card.Description>
            <Statistic color="purple" size="mini">
              <Statistic.Value>
                N{" "}
                {AverageOrderSize.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Statistic.Value>
            </Statistic>
          </Card.Description>
        </Card.Content>
      </Card>
      <Card color="yellow">
        <Card.Content>
          <Card.Header style={{ fontSize: "1em", color: "gray" }}>
            Popular Menu
          </Card.Header>
          <Card.Description>
            <Header color="yellow">Shawarma</Header>
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

export default observer(StatsPanel);
