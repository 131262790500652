import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Category, { CategoryFormValues } from "../models/category";

export default class CategoryStore {
  category: Category | undefined = undefined;
  categoryRegistry = new Map<string, Category>();
  loading = false;
  loadingCategories = false;
  submitting = false;
  deleting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get categoriesByTitle() {
    return Array.from(this.categoryRegistry.values()).sort((a, b) =>
      a.name.localeCompare(b?.name)
    );
  }

  private getCategory = (id: string) => {
    return this.categoryRegistry.get(id);
  };

  loadCategories = async () => {
    this.loading = true;
    try {
      let categories = await agent.Categories.list();
      runInAction(() => {
        this.categoryRegistry.clear();
        categories.forEach((category) => {
          category = {
            ...category,
            text: category.name,
            value: category.id,
            key: category.id,
          };
          this.categoryRegistry.set(category.id!, category);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadCategory = async (id: string) => {
    let category = this.getCategory(id);
    if (category) {
      return category;
    } else {
      try {
        this.loading = true;
        category = await agent.Categories.details(id);
        if (category) {
          category = { ...category, text: category.name, value: category.id };
          runInAction(() => {
            this.categoryRegistry.set(category!.id!, category!);
            this.loading = false;
          });
        }
        return category;
      } catch (error) {
        runInAction(() => (this.loading = false));
        console.log(error);
      }
    }
  };

  create = async (category: CategoryFormValues) => {
    try {
      this.submitting = true;
      let newRecord = await agent.Categories.create(category);
      if (newRecord) {
        newRecord = {
          ...newRecord,
          text: newRecord.name,
          value: newRecord.id,
        };
        runInAction(() => {
          this.categoryRegistry.set(newRecord.id!, newRecord);
          this.submitting = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  update = async (id: string, category: CategoryFormValues) => {
    try {
      this.submitting = true;
      let updatedRecord = await agent.Categories.update(id, category);
      if (updatedRecord) {
        updatedRecord = {
          ...updatedRecord,
          text: updatedRecord.name,
          value: updatedRecord.id,
        };
        runInAction(() => {
          this.categoryRegistry.set(updatedRecord.id!, updatedRecord);
          this.submitting = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  delete = async (id: string) => {
    try {
      this.deleting = false;
      await agent.Categories.delete(id);
      runInAction(() => {
        this.categoryRegistry.delete(id);
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };
}
