import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button, Header, Segment } from "semantic-ui-react";
import Food from "../../../../app/models/food";
import { useStore } from "../../../../app/store/store";
import FoodImageUploadForm from "../../../administrator/commerce/vendors/ImageUploads/FoodImageUploadForm.tsx";
import DeleteFoodForm from "./forms/DeleteFoodForm";
import FoodForm from "./forms/FoodForm";

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

interface Props {
  data: Food[];
  loading: boolean;
}

const FoodList = ({ data, loading }: Props) => {
  const {
    modalStore: { openModal },
  } = useStore();

  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "menuTitle",
      label: "Menu",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "220px", maxWidth: "220px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "220px", maxWidth: "220px" },
        }),
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "otherCharges",
      label: "Charges",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "published",
      label: "Published",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "visibleIndividually",
      label: "Visibility",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "1em" }}
            >
              <Button
                icon="edit"
                basic
                color="green"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />

              <Button
                icon="image"
                basic
                color="olive"
                circular
                size="mini"
                onClick={() => handlePhotoUploadButtonClick(tableMeta)}
              />

              <Button
                icon="delete"
                basic
                color="red"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 30,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  function handleEditButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;

    if (rowIndex <= data.length) {
      openModal(<FoodForm data={data[rowIndex]} />, "tiny");
    }
  }

  function handlePhotoUploadButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;

    if (rowIndex <= data.length) {
      openModal(
        <FoodImageUploadForm
          food={data[rowIndex]}
          color="olive"
          setEditMode={function (state: boolean) {}}
        />,
        "tiny"
      );
    }
  }

  function handleDeleteButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;

    if (rowIndex <= data.length) {
      openModal(<DeleteFoodForm food={data[rowIndex]} />, "mini");
    }
  }

  return (
    <Segment
      loading={loading}
      vertical
      style={{ width: "96%", margin: "auto" }}
    >
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            responsive: "standard",
            selectableRows: "none",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </Segment>
  );
};

export default observer(FoodList);
