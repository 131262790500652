import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import CustomerDetails from "./customer/CustomerDetails";
import LoginPanel from "./LoginPanel";
import Cart from "./Cart";
import Address from "../../../app/models/address";
import PaymentOption from "./payment/PaymentOption";
import CheckoutButton from "./CheckoutButton";
import { CheckoutContainer, GridRow } from "./checkout-styles";
import { Media } from "../../..";

const Checkout = () => {
  // ==================================================
  // app store
  // ==================================================
  const {
    userStore: { IsLogedIn },
    shoppingCartStore: { checkingout },
  } = useStore();

  // ===================================================
  // use state
  // ===================================================
  const [paymentOption, setPaymentOption] = useState<
    number | string | undefined
  >("0");
  const [paymentId, setPaymentId] = useState("Payment.Flutterwave");
  const [editState, setEditState] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState<Address | null>(null);

  return (
    <>
      <Media greaterThan="mobile">
        <CheckoutContainer>
          <Dimmer inverted active={checkingout}>
            <Loader active />
          </Dimmer>
          <Header as="h3" color="olive" content="Secure Checkout" />
          <Grid>
            <Grid.Column width={10}>
              {IsLogedIn ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <CustomerDetails
                        editState={editState}
                        setEditState={setEditState}
                        deliveryAddress={deliveryAddress}
                        setDeliveryAddress={setDeliveryAddress}
                        mobile={false}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <GridRow>
                    <Grid.Column width={16}>
                      <PaymentOption
                        paymentOption={paymentOption}
                        setButtonIndex={setPaymentOption}
                        setPaymentId={setPaymentId}
                        editState={editState}
                      />
                    </Grid.Column>
                  </GridRow>
                  <GridRow only="computer tablet">
                    <Grid.Column computer={16} tablet={16}>
                      <CheckoutButton
                        paymentMethodId={paymentId}
                        paymentOption={paymentOption}
                        editState={!editState}
                        deliveryAddress={deliveryAddress}
                      />
                    </Grid.Column>
                  </GridRow>
                </Grid>
              ) : (
                <LoginPanel />
              )}
            </Grid.Column>
            <Grid.Column width={6}>
              <Cart />
            </Grid.Column>
          </Grid>
        </CheckoutContainer>
      </Media>
      <Media at="mobile">
        <CheckoutContainer>
          <Dimmer inverted active={checkingout}>
            <Loader active />
          </Dimmer>
          <Header as="h3" color="olive" content="Secure Checkout" />
          <Grid>
            <Grid.Column width={16}>
              {IsLogedIn ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <CustomerDetails
                        editState={editState}
                        setEditState={setEditState}
                        deliveryAddress={deliveryAddress}
                        setDeliveryAddress={setDeliveryAddress}
                        mobile={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <GridRow>
                    <Grid.Column width={16}>
                      <PaymentOption
                        paymentOption={paymentOption}
                        setButtonIndex={setPaymentOption}
                        setPaymentId={setPaymentId}
                        editState={editState}
                      />
                    </Grid.Column>
                  </GridRow>
                  <GridRow only="computer tablet">
                    <Grid.Column computer={16} tablet={16}>
                      <CheckoutButton
                        paymentMethodId={paymentId}
                        paymentOption={paymentOption}
                        editState={!editState}
                        deliveryAddress={deliveryAddress}
                      />
                    </Grid.Column>
                  </GridRow>
                </Grid>
              ) : (
                <LoginPanel />
              )}
            </Grid.Column>
            <Grid.Column width={16}>
              <GridRow>
                <Cart />
              </GridRow>
              {IsLogedIn && (
                <GridRow>
                  <CheckoutButton
                    paymentMethodId={paymentId}
                    paymentOption={paymentOption}
                    editState={!editState}
                    deliveryAddress={deliveryAddress}
                  />
                </GridRow>
              )}
            </Grid.Column>
          </Grid>
        </CheckoutContainer>
      </Media>
    </>
  );
};

export default observer(Checkout);
