import { observer } from "mobx-react-lite";
import React from "react";
import Offer from "../../../../../app/models/offer";
import VendorBannerOfferItem from "./VendorBannerOfferItem";

interface Props {
  userOffers: Offer[];
}

export default observer(function VendorBannerOffer({ userOffers }: Props) {
  return (
    <fieldset>
      <legend>{"  Offer  "}</legend>
      {userOffers.map((offer) => (
        <VendorBannerOfferItem key={offer.title} offer={offer} />
      ))}
    </fieldset>
  );
});
