import { Header, List, ListDescription } from "semantic-ui-react";
import { styled } from "styled-components";

/** Cart */
export const CartWrapper = styled.div`
  padding: 0 1em;
  overflow: auto;
  scroll-behavior: smooth;
  max-height: "550px";
`;

export const CartName = styled.h1`
  color: #ccc;
  margin-top: 0px;
`;

export const EmptyCartText = styled(Header)`
  color: #ccc;
  text-align: center;
  margin: 0;
  padding: 0;
`;

/** Cart Header */

export const CartHeaderTitle = styled(Header)`
  color: black;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


/** Cart Item Section */
export const ListItem = styled(List.Item)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;
export const Container = styled.div``;

export const CartButtonWrapper = styled.span`
  /* display: block; */
  padding: 2px;
  border: 0.5px solid lightgray;
  /* margin: .15rem .15rem .15rem .15rem !important; */
  /* float: right; */

  &:hover {
    border: 0.5px solid lightgray;
  }
`;

export const ButtonWrapperCircular = styled.span`
  /* width: auto; */
  padding: 2px;
  border: 0.5px solid lightgray;
  border-radius: 1rem;
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.2);
  float: right;
  /* margin: .15rem .15rem .15rem .15rem !important; */
  /* z-index: 100000; */

  &:hover {
    border: 0.5px solid lightgray;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
`;

export const CartItemDescription = styled(ListDescription)`
`;

export const Description = styled.div`
  width: 10em;
  float: left;
`;

export const Title = styled.span`
  font-weight: 550;  
  max-width: 3rem;
  line-height: 1.2em;
  /* height: 1.2em !important; */
  max-height: 2.4em;
  white-space: normal;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  z-index: 1001;
`;

export const CartItemPrice = styled.div`
  font-weight: 600; 
  margin-top: .2em;
`;

export const CartItemHeader = styled.div`
  display: flex !important;
  justify-content: flex-end;
  color: ${(props) => props.theme.Colors.text.primary};
  /* line-height: 16px; */
  font-size: ${(props) => props.theme.Size[3]} !important;
  font-weight: 500 !important;
  /* padding-bottom: 0px;
  margin-bottom: 0px; */
  /* font-family: "Arial, sans-serif"; */
  /* border: 1px solid gray !important; */
  /* margin-bottom: 0.25rem !important;
  width: 100%; */
`;

export const CartItemTitle = styled.div`
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  margin-bottom: .2rem;
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
  /* text-overflow: ellipsis; */
`;

export const Customise = styled.span`
  color: rgb(250, 171, 5);
  font-size: .85em;
  margin-top: 0;
  padding-top: 0;
  /* padding-bottom: .35rem; */
  padding-top: none;
  margin-top: -5px;
  margin-bottom: .2rem;
  cursor: pointer;
`;