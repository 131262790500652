import { toast } from "react-toastify";
import agent from "../../../../app/api/agent";
import useQuery from "../../../../app/common/util/hooks";
import { Button, Grid, Header, Icon, Segment, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

const RegisterSuccess = () => {
  const email = useQuery().get("email") as string;

  const handleConfirmEmailResend = () => {
    agent.Account.resendEmailConfirm(email)
      .then(() => {
        toast.success("Verification email resent - please check your email.");
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 550 }}>
          <Image
            as={Link}
            to="/"
            src="/images/logo.png"
            alt="chops2u"
            size="small"
            style={{
              margin: "auto",
              paddingBottom: "10px",
            }}
          />
          <Segment placeholder textAlign="center">
            <Header icon color="olive">
              <Icon name="check" />
              Successfully registered!
            </Header>
            <p>
              Please check your email (including junk email) for the
              verification email.
            </p>
            {email && (
              <>
                <p>
                  Didn't receive the email? Click the below button to resend.
                </p>
                <Button
                  primary
                  onClick={handleConfirmEmailResend}
                  content="Resent Email"
                />
              </>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};
export default RegisterSuccess;
