import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Address from "../../../../../app/models/address";
import { useStore } from "../../../../../app/store/store";

import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";

interface IProps {
  setEditState: (value: boolean) => void;
  deliveryAddress: Address;
  setDeliveryAddress: (address: Address) => void;
}

const DeliveryAddressForm = ({
  deliveryAddress,
  setDeliveryAddress,
  setEditState,
}: IProps) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("'Full name' is a required field"),
    street: Yup.string().required("'Delivery Address' is a required field"),
    locationId: Yup.number().required("Location is a required field"),
    telephone: Yup.string()
      .phone("Field must be a valid phone number")
      .required("Telephone is a required field"),
  });

  // store
  const {
    locationStore: { loadLocations, Locations },
  } = useStore();

  // state
  const [mobile, setMobile] = useState(false);

  // use Effect
  useEffect(() => {
    setMobile(false);
    loadLocations();
  }, [loadLocations]);

  const handleFormSubmit = (values: Address) => {
    setDeliveryAddress({
      ...values,
      location: Locations.filter((loc) => loc.id === values.locationId)[0],
    });
    setEditState(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={deliveryAddress}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, isValid, dirty, isSubmitting }) => (
        <Form className="form tiny ui" onSubmit={handleSubmit}>
          <Segment>
            <Header
              as="h5"
              color="grey"
              style={{
                letterSpacing: mobile ? "1px" : "3px",
                fontSize: mobile ? "0.9em" : "",
              }}
            >
              DELIVERY ADDRESS
            </Header>

            <div className="equal width fields">
              <Chops2uTextInput
                name="name"
                label="Full name *"
                placeholder="Full Name"
              />

              <Chops2uTextInput
                name="telephone"
                label="Phone Number *"
                placeholder="In case questions arise"
              />
            </div>

            <div className="equal width fields">
              <Chops2uTextInput
                name="street"
                label="Address *"
                placeholder="street address"
              />

              <Chops2uSelectInput
                name="locationId"
                label="Location *"
                placeholder="location"
                options={Locations}
              />
            </div>

            <div className="equal width fields">
              <Chops2uTextInput
                name="company"
                label="Building"
                placeholder="Building"
              />

              <Chops2uTextInput
                name="floor"
                label="Floor"
                placeholder="Floor"
              />
            </div>

            {/* <Chops2uTextInput
              name="deliveryInstruction"
              label="Delivery Instruction"
              placeholder="Leave food by the door"
            /> */}

            <div className="field">
              <Button
                fluid
                disabled={(!isValid && dirty) || isSubmitting}
                size="large"
                color="grey"
                type="submit"
              >
                Save Details
              </Button>
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(DeliveryAddressForm);
