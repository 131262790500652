import AdminSidebar from "./AdminSidebar";
import AdminTopMenu from "./AdminTopMenu";
import { useState } from "react";
import AdminContainer from "../../app/layout/container/AdminContainer";
import AdminRoutes from "./AdminRoutes";

const AdminLayout = () => {
  // let match = useRouteMatch();
  const [pageTitle, setPageTitle] = useState("");

  return (
    <AdminContainer
      sidebar={<AdminSidebar />}
      header={<AdminTopMenu pageTitle={pageTitle} />}
    >
      <AdminRoutes setPageTitle={setPageTitle} />
    </AdminContainer>
  );
};

export default AdminLayout;
