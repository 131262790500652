import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import MenuEmptyPanel from "./MenuEmptyPanel";
import MenuList from "./MenuList";
import MenusTopMenu from "./MenusTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const MenusPageLayout = ({ setPageTitle }: Props) => {
  const {
    restaurantStore: { restaurant, loadMenus, loadingMenus, MenusByTitle: menusByTitle },
  } = useStore();

  useEffect(() => {
    setPageTitle("Menus");
  }, [setPageTitle]);

  useEffect(() => {
    if (restaurant?.id) loadMenus(restaurant.id);
  }, [restaurant?.id, loadMenus]);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "space-evenly", overflow: "hidden"}}>
      <div>
        <MenusTopMenu />
      </div>
      <div>
        {menusByTitle.length > 0 ? (
          <MenuList data={menusByTitle} loadingMenu={loadingMenus} />
        ) : (
          <MenuEmptyPanel />
        )}
      </div>
    </div>
  );
};

export default observer(MenusPageLayout);
