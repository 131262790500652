import { useEffect } from "react";
import Address from "../../../../app/models/address";
import { useStore } from "../../../../app/store/store";
import DeliveryAddressForm from "./form/DeliveryAddressForm";
import DeliveryDetails from "./DeliveryDetails";
import { observer } from "mobx-react-lite";

interface IProps {
  editState: boolean;
  setEditState: (value: boolean) => void;
  deliveryAddress: Address | null;
  setDeliveryAddress: (address: Address) => void;
  mobile: boolean;
}

const CustomerDetails = ({
  deliveryAddress,
  setDeliveryAddress,
  editState,
  setEditState,
  mobile,
}: IProps) => {
  const {
    profileStore: { loadDefaultAddress, DefaultAddress },
    userStore: { User, DeliveryLocation },
  } = useStore();

  useEffect(() => {
    loadDefaultAddress().catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (DefaultAddress) {
      setDeliveryAddress(DefaultAddress);
      setEditState(false);
    } else if (DeliveryLocation) {
      setDeliveryAddress({
        name: User!.fullName,
        street: "",
        email: User!.email,
        telephone: User!.phoneNumber,
        locationId: DeliveryLocation.id!,
        location: DeliveryLocation!,
        isMain: false,
      });
    } else {
      setDeliveryAddress({
        name: User!.fullName,
        email: User!.email,
        telephone: User!.phoneNumber,
        street: "",
        locationId: undefined,
        location: undefined,
        isMain: false,
      });
    }
  }, [
    User,
    DefaultAddress,
    DeliveryLocation,
    setDeliveryAddress,
    setEditState,
  ]);

  return (
    <div>
      {editState || !deliveryAddress ? (
        <DeliveryAddressForm
          setDeliveryAddress={setDeliveryAddress}
          deliveryAddress={deliveryAddress!}
          setEditState={setEditState}
        />
      ) : (
        <DeliveryDetails
          deliveryAddress={deliveryAddress!}
          setEditState={setEditState}
          mobile={mobile}
        />
      )}
    </div>
  );
};
export default observer(CustomerDetails);
