import {
  Menu,
  Icon,
  Button,
  Container,
  Dropdown,
  Image,
} from "semantic-ui-react";
import { useStore } from "../../store/store";
import LoginForm from "../../../features/frontpage/account/login/LoginForm";
import RegistrationForm from "../../../features/frontpage/account/registration/RegistrationForm";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

interface Props {
  setVisibility: (visible: boolean) => void;
}

const MobileTopNav = ({ setVisibility }: Props) => {
  const {
    modalStore: { openModal },
    userStore: { IsLogedIn, logout },
  } = useStore();

  const navigate = useNavigate();

  return (
    <Menu
      fixed="top"
      size="huge"
      compact
      style={{
        zIndex: 1000,
      }}
    >
      <Container>
        <Menu.Item onClick={() => setVisibility(true)}>
          <Icon color="olive" name="sidebar" />
        </Menu.Item>
        <Menu secondary>
          <Menu.Item style={{ margin: "auto" }}>
            <Image
              as={Link}
              to="/"
              src="/images/logo.png"
              size="small"
              style={{ height: "auto", width: "120px" }}
            />
          </Menu.Item>
        </Menu>
        {IsLogedIn ? (
          <Menu.Item
            position="right"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <Icon
              name="user circle outline"
              color="olive"
              size="large"
              style={{ padding: "0px" }}
            />
            <Dropdown direction="left">
              <Dropdown.Menu
                style={{ width: "200px", marginTop: "10px", fontSize: "14px" }}
              >
                <Dropdown.Item as={Link} to={"/account/profile"}>
                  <Icon name="user" color="olive" />
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/account"}>
                  <Icon name="cart" color="olive" />
                  Orders
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/account/manage-account"}>
                  <Icon name="user plus" color="olive" />
                  Manage Account
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    logout();
                    navigate("/vendors");
                  }}
                >
                  <Icon name="power" color="olive" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        ) : (
          <Menu.Item position="right">
            <Button
              as="a"
              circular
              size="mini"
              onClick={() => openModal(<LoginForm />)}
              style={{
                fontSize: "16px",
                background: "transparent",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  // color: "#b5cc18",
                  fontWeight: 400,
                  letterSpacing: "0.5px",
                }}
              >
                Login
              </span>
            </Button>
            <Button
              as="a"
              circular
              color="olive"
              size="tiny"
              onClick={() => openModal(<RegistrationForm />)}
              style={{ padding: "10px 15px" }}
            >
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                Sign Up
              </span>
            </Button>
          </Menu.Item>
        )}
      </Container>
    </Menu>
  );
};

export default observer(MobileTopNav);
