import { ColorProps, Colors } from "./colors";
import { Size } from "./sizes";
import { Spacing } from "./spacing";

interface ThemeProps {
    Colors: ColorProps;
    Spacing: string[];
    Size: string[];
}

export const theme: ThemeProps = {
    Colors,
    Spacing,
    Size
};