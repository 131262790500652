import { Link } from "react-router-dom";
import { Grid, Header, Icon, Image } from "semantic-ui-react";

interface Props {
  mobile?: boolean;
}

const RideWithUs = ({ mobile }: Props) => {
  return (
    <div>
      <Image
        src="/images/front/banner/ride-with-us.png"
        centered
        style={{ height: "120px", width: "auto" }}
        size="small"
      />
      <Header inverted as="h3" textAlign="center" style={{ marginTop: "0px", fontSize: mobile ? "1.1em" : "1em" }}>
        <Header.Content>Ride With Us</Header.Content>
        <Header.Subheader>
          <Grid>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={12}>
              Start a career with us and make income as a pickup and delivery
              driver.
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid>
        </Header.Subheader>
      </Header>
      <div style={{ textAlign: "center" }}>
        <Header
          as={Link}
          to="/chopper/register"
          inverted
          style={{
            textDecoration: "underline",
            fontSize: mobile ? "1em" : "16px",
            marginRight: "5px",
          }}
          content="Register as a Rider"
        />
        <Icon inverted size="tiny" name="arrow right" />
      </div>
    </div>
  );
};

export default RideWithUs;
