import { observer } from "mobx-react-lite";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord"

const PaymentsEmptyPage = () => {
    return (
      <Chops2uEmptyRecord
        color="olive"
        title="No Payment Record Found."
        subtitle="Use this section to see all payment record by customers."
      />
    );
}

export default observer(PaymentsEmptyPage);