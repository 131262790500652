interface IProps {
  sidebar: React.ReactNode;
  header: React.ReactNode;
  children: React.ReactNode;
}

const AdminContainer = ({ sidebar, header, children }: IProps) => {
  return (
    <div className="admin-body">
      <div className="admin-sidebar">{sidebar}</div>
      <section className="admin-content">
        <header className="admin-header">{header}</header>
        <section className="admin-main">{children}</section>
      </section>
    </div>
  );
};
export default AdminContainer;
