import { observer } from "mobx-react-lite";
import { Button, Grid, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import InviteStaffForm from "../forms/InviteStaffForm";
import { StaffGridColumn, StaffListWrapper } from "../../../store.style";

const StaffList = () => {
  const {
    restaurantStore: { restaurant },
    modalStore: { openModal },
  } = useStore();
  return (
    <Grid textAlign="center">
      <StaffGridColumn>
        <Segment color="green" clearing>
          <StaffListWrapper>
            <div>
              <Button
                labelPosition="left"
                icon="plus"
                positive
                floated="right"
                content="Invite a staff"
                onClick={() => openModal(<InviteStaffForm />, "tiny")}
              />
            </div>

            <div>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {restaurant?.staff?.map((employee, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{employee.fullName}</Table.Cell>
                      <Table.Cell>{employee.email}</Table.Cell>
                      <Table.Cell>{employee.role}</Table.Cell>
                      <Table.Cell>Enabled</Table.Cell>
                      <Table.Cell>None</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </StaffListWrapper>
        </Segment>
      </StaffGridColumn>
    </Grid>
  );
};

export default observer(StaffList);
