import { useEffect } from "react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";
import RefundsTopMenu from "./RefundsTopMenu";

interface Props {
    setPageTitle: (title: string) => void;
}

const RefundsLayoutPage = ({ setPageTitle }: Props) => {
    
    useEffect(() => {
        setPageTitle("Refunds");
    }, [setPageTitle]);
    
    return (
      <section>
        <RefundsTopMenu />
        <Chops2uEmptyRecord
          color="olive"
          title="No Refund Application Yest!"
          subtitle="See all refunds application by customers for necessary actions."
        />
      </section>
    );
}

export default RefundsLayoutPage;