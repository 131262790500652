import { Menu, Button } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import CategoryForm from "./forms/CategoryForm";
import PageTopMenu from "../../common/PageTopMenu";

const CategoryTopMenu = () => {
  const {
    modalStore: { openModal },
  } = useStore();

  const handleButtonClick = () => {
    console.log("Button Click");
    openModal(<CategoryForm />, "mini");
  };

  return (
    <PageTopMenu>
      <Menu.Item position="right">
        <div>
          <Button
            color="olive"
            icon="plus"
            labelPosition="left"
            content="New Category"
            onClick={handleButtonClick}
          />
        </div>
      </Menu.Item>
    </PageTopMenu>
  );
};

export default CategoryTopMenu;
