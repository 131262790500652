import { observer } from "mobx-react-lite";
import { Segment } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";
import { useStore } from "../../../../../../app/store/store";
import OrderDetailsBody from "./OrderDetailsBody";
import OrderDetailsTop from "./OrderDetailsTop";

interface Props {
  order: Order;
}

const OrderDetails = ({ order }: Props) => {
  const {
    restaurantStore: { loadingOrder },
  } = useStore();
  return (
    <Segment loading={loadingOrder} vertical style={{ paddingTop: "0px" }}>
      <OrderDetailsTop order={order} />
      <OrderDetailsBody order={order} />
    </Segment>
  );
};

export default observer(OrderDetails);
