import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Order from "../../../../../app/models/order";
import { useStore } from "../../../../../app/store/store";

interface Props {
  order: Order;
}

const CancelOrderForm = ({ order }: Props) => {
  const {
    modalStore: { closeModal },
    orderStore: { cancel, submitting },
  } = useStore();

  const handleCencelOrder = () => {
    cancel(order.id).then(() => closeModal());
  };

  return (
    <>
      <Modal.Header color="yellow">
        <Header as="h4" color="yellow" content="Cancel Order" />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to cancel the following order?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {order.orderNumber + " " + order.orderTotal}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          color="yellow"
          labelPosition="left"
          floated="left"
          content="No"
          onClick={closeModal}
          style={{ width: "110px" }}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          color="olive"
          loading={submitting}
          content="Yes"
          onClick={() => handleCencelOrder()}
          style={{ width: "110px" }}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(CancelOrderForm);
