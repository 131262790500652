import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const DeliveriesEmptyPanel = () => {
  return (
    <div>
      <Chops2uEmptyRecord
        title="No deliveries yet"
        subtitle="This is where you will see orders shipment status."
        color="green"
      />
    </div>
  );
};

export default DeliveriesEmptyPanel;
