import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Header, Segment } from "semantic-ui-react";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../app/store/store";
import { CategoryFormValues } from "../../../../../app/models/category";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

const validate = Yup.object({
  name: Yup.string().required(),
});

interface Props {
  id?: string;
}

const CategoryForm = ({ id }: Props) => {
  const {
    categoryStore: { create, update, submitting, loadCategory, loading },
    modalStore: { closeModal },
  } = useStore();
  const [category, setCategory] = useState<CategoryFormValues>({
    name: "",
    error: null,
  });

  useEffect(() => {
    if (id) {
      loadCategory(id).then((result) => {
        setCategory({ ...result!, error: null });
      });
    }
  }, [id, loadCategory]);

  const handleFormSubmit = (
    values: CategoryFormValues,
    { setErrors }: FormikHelpers<CategoryFormValues>
  ) => {
    if (id) {
      // update category
      update(id, values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    } else {
      // create category
      create(values)
        .then(() => closeModal())
        .catch((error) => {
          console.log(error);
          setErrors({ error });
        });
    }
  };

  return (
    <Formik
      initialValues={category}
      onSubmit={handleFormSubmit}
      validationSchema={validate}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isSubmitting, isValid, errors }) => (
        <Form onSubmit={handleSubmit} className="ui form">
          <Segment color="olive" attached="top" loading={loading}>
            <Header
              as="h4"
              content={id ? "Edit Category" : "Add Category"}
              style={{ padding: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment
            attached
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Chops2uTextInput
              name="name"
              label="Name"
              placeholder="Category Title"
            />
            <div className="field">
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
            </div>
          </Segment>
          <Segment secondary attached="bottom">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "1em",
              }}
            >
              <Button
                icon="cancel"
                labelPosition="left"
                color="orange"
                content="Cancel"
                onClick={closeModal}
              />

              <Button
                disabled={!isValid && dirty}
                loading={isSubmitting && submitting}
                icon="check"
                labelPosition="left"
                color="olive"
                type="submit"
                content="Submit"
              />
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(CategoryForm);
