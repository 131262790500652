const SuperAdmin = "SuperAdmin";
const Admin = "Admin";
const Manager = "Manager";
const Chopper = "Chopper";
const StoreOwner = "StoreOwner";
const Staffer = "Staffer";
const Foodie = "Foodie";

const AccountType = {
  SuperAdmin,
  Admin,
  Manager,
  Chopper,
  StoreOwner,
  Staffer,
  Foodie,
};

export default AccountType;

export interface UserRole {
  name: string;
  isSystem: boolean;
}

export interface UserAccount {
  id: string;
  name: string;
  roles: UserRole[];
}
