import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import FoodEmptyPanel from "./FoodEmptyPanel";
import FoodList from "./FoodList";
import FoodTopMenu from "./FoodTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const FoodsPageLayout = ({ setPageTitle }: Props) => {
  const {
    restaurantStore: { restaurant, loadFoods, FoodsByTitle, loadingFoods },
  } = useStore();

  useEffect(() => {
    setPageTitle("Foods");
  }, [setPageTitle]);

  useEffect(() => {
    if (restaurant?.id) loadFoods(restaurant.id);
  }, [restaurant?.id, loadFoods]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <div>
        <FoodTopMenu />
      </div>
      <div>
        {FoodsByTitle?.length > 0 ? (
          <FoodList data={FoodsByTitle} loading={loadingFoods} />
        ) : (
          <FoodEmptyPanel />
        )}
      </div>
    </div>
  );
};

export default observer(FoodsPageLayout);
