export const getCookieValue = (cookieName: string) => {
  let name = cookieName + "=";
  let ca = document.cookie.split(";");
  // console.log(ca);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00.00.00 UTC; path=/;`;
};

export const combinedDateAndTime = (date: Date, time: Date) => {
  const timeString = time.getHours() + ":" + time.getMinutes() + ":00";

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateString = `${year}-${month}-${day}`;

  return new Date(dateString + " " + timeString);
};

export const getLocalTime = (date: string) => {
  var utcdate = new Date(date);
  utcdate.setMinutes(utcdate.getMinutes() - utcdate.getTimezoneOffset());
  return utcdate;
};

export const timeLaps = (date: string) => {
  var createdOn = getLocalTime(date);
  createdOn = new Date(date);
  var now = new Date();
  var timeDiff = now.getTime() - createdOn.getTime();
  timeDiff /= 1000;
  if (timeDiff < 60) return Math.round(timeDiff) === 1 ? (Math.round(timeDiff) + " second ago") : (Math.round(timeDiff) + " seconds ago");
  if (timeDiff < 3600) return Math.round(timeDiff / 60) === 1 ? (Math.round(timeDiff / 60) + " minute ago") : (Math.round(timeDiff / 60) + " minutes ago");
  if (timeDiff / 3600 < 24) return Math.round(timeDiff / 3600) === 1 ? (Math.round(timeDiff / 3600) + " hour ago") : (Math.round(timeDiff / 3600) + " hours ago");
  return Math.round(timeDiff / 86400) === 1 ? (Math.round(timeDiff / 86400) + " day ago") : (Math.round(timeDiff / 86400) + " days ago");
};

export const toCurrency = (value: number, decimalPoint: number = 0) => {
  var naira = `\u20A6`;
  return naira + value.toLocaleString("en-US", {
    minimumFractionDigits: decimalPoint,
    maximumFractionDigits: decimalPoint,
  });
};

export const stringIsNullOrEmpty = (value: string) => {
  return (!value || value === undefined || value === "" || value.length === 0);
}
