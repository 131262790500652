import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";
import { Container, Header, Item, Visibility } from "semantic-ui-react";
import Menu from "../../../../../app/models/menu";
import VendorDetailsFoodListItem from "./VendorDetailsFoodListItem";

interface Props {
  menus: Menu[];
  isOpenNow: boolean;
  setActiveMenu: (name: string) => void;
}

const VendorDetailsFoodList = ({ menus, isOpenNow, setActiveMenu }: Props) => {
  const [target, setTarget] = useState("");
  const handleTopPass = (data: any) => {
    const { name } = data;
    setActiveMenu(name);
  };

  const handleBottomVisible = (data: any) => {
    const { name } = data;
    setActiveMenu(name);
  };

  return (
    <Container>
      {menus &&
        menus.length > 0 &&
        menus.map((menu) => (
          <Visibility
            name={menu.title}
            key={menu.title}
            as={Fragment}
            once={false}
            offset={75}
            onTopPassed={(e, d) => handleTopPass(d)}
            onBottomPassedReverse={(e, d) => handleBottomVisible(d)}
          >
            <Header id={menu.title} as="h2">
              <span>{menu.title}</span>
              <Header.Subheader>{menu.foods.length} ITEMS</Header.Subheader>
            </Header>
            <Item.Group divided unstackable>
              {menu.foods.map((food) => (
                <VendorDetailsFoodListItem
                  key={food.id}
                  food={food}
                  isOpenNow={isOpenNow}
                  target={target}
                  setTarget={setTarget}
                />
              ))}
            </Item.Group>
          </Visibility>
        ))}
    </Container>
  );
};

export default observer(VendorDetailsFoodList);
