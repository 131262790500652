import Food from "./food";

export default interface Menu {
  id?: string;
  title: string;
  description: string;
  displayOrder: number;
  groupingOption?: GroupingOption,
  published: boolean;
  deleted: boolean;
  updatedOnUtc?: string;
  vendorId?: string;
  foods: Food[];
}

export enum GroupingOption {
  requiredSingle = 1,
  OptionalMultiple = 3
}

export const GroupingOptions = [
  { key: "1", text: "Required Single", value: 1 },
  { key: "2", text: "Optional Multiple", value: 3 }
];
