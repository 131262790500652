import { observer } from "mobx-react-lite";
import { Container, Dropdown, Menu } from "semantic-ui-react"

const menuStyle = {
  borderRadius: "0",
  height: "60px",
};

const RefundsTopMenu = () => {
    return (
      <Menu attached="top" borderless style={menuStyle}>
        <Container style={{ width: "95%" }}>
          <Menu.Item>
            <Dropdown labeled text={"Filter"} icon="filter">
              <Dropdown.Menu></Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Container>
      </Menu>
    );
}

export default observer(RefundsTopMenu);