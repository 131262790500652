import { observer } from "mobx-react-lite";
import {
  Button,
  Header,
  Icon,
  Label,
  Segment,
  SemanticCOLORS,
} from "semantic-ui-react";
import { timeLaps } from "../../../app/common/util/util";
import Order from "../../../app/models/order";

interface Props {
  order: Order;
}

const OrderDetails = ({ order }: Props) => {
  const colors: SemanticCOLORS[] = [
    "orange",
    "teal",
    "purple",
    "grey",
    "green",
  ];

  const orderStatus = [
    "Prepared",
    "Preparing",
    "Ready",
    "InTransit",
    "Delivered",
    "Cancelled",
  ];

  return (
    <Segment.Group>
      <Segment
        color={colors[orderStatus.indexOf(order.status)]}
        style={{ borderRadius: "0px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "2" }}>
            <Header as="h6">Order Number:</Header> {"#"} {order.orderNumber}
          </div>
          <div style={{ flex: "4" }}>
            <div>
              <Header textAlign="center">{order.status}</Header>
            </div>
          </div>
          <div style={{ flex: "2" }}>
            <div style={{ float: "right" }}>
              <Header as="h6">Time</Header>
              {timeLaps(order.createdOnUtc)}
            </div>
          </div>
        </div>
      </Segment>
      <Segment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: "1" }}>
            <Label circular>1</Label>
          </div>
          <div style={{ flex: "10" }}>
            <Header as="h5">Pickup Information</Header>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: "1" }}></div>
          <div style={{ flex: "10" }}>
            <div>
              <Header color="grey" as="h5">
                {order.vendorName}
              </Header>
            </div>
            <div>
              <Icon name="point" color="grey" inverted />
              {order.vendorAddress}
            </div>
            <div>
              <Icon />
              {order.vendorLocation}
            </div>
            <div>
              <Icon name="phone" color="grey" inverted />
              {order.vendorTelephone}
            </div>
          </div>
        </div>
      </Segment>
      <Segment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: "1" }}>
            <Label circular>2</Label>
          </div>
          <div style={{ flex: "10" }}>
            <Header as="h5">Customer Information</Header>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: "1" }}></div>
          <div style={{ flex: "10" }}>
            <div>
              <Header color="grey" as="h5">
                {order.deliveryAddress?.name}
              </Header>
            </div>
            <div>
              <Icon name="point" color="grey" inverted />
              {order.deliveryAddress?.street}
            </div>
            <div>
              <Icon />
              {order.deliveryAddress?.location?.name}
            </div>
            <div>
              <Icon name="phone" color="grey" inverted />
              {order.vendorTelephone}
            </div>
          </div>
        </div>
      </Segment>
      <Button
        color={colors[orderStatus.indexOf(order.status)]}
        size="huge"
        style={{ borderRadius: "0px" }}
        fluid
      >
        Confirm Pickup
      </Button>
    </Segment.Group>
  );
};

export default observer(OrderDetails);
