import { observer } from "mobx-react-lite";
import { Segment, Image } from "semantic-ui-react";
import Vendor from "../../../../app/models/vendor";
import VendorClosedLabel from "./VendorClosedLabel";

interface Props {
  vendor: Vendor;
}

const parentStyle = {
  position: "relative",
  padding: "5px",
  border: "none",
};

const childStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const overlayStyle = {
  position: "absolute" as "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  border: "none",
  padding: "none",
  backgroundColor: "rgba(255,255,255,0.6)",
  cursor: "pointer",
};

const VendorImage = ({ vendor }: Props) => {
  return (
    <Segment compact vertical attached="top" style={parentStyle}>
      <Image src={vendor.image || "/images/placeholder.png"} wrapped fluid />
      {vendor.isOpenNow === false && (
        <>
          <div style={overlayStyle}></div>
          <VendorClosedLabel style={childStyle} />
        </>
      )}
    </Segment>
  );
};

export default observer(VendorImage);
