import { observer } from "mobx-react-lite";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import LoginForm from "../../frontpage/account/login/LoginForm";
import RegistrationForm from "../../frontpage/account/registration/RegistrationForm";

const LoginPanel = () => {
  const {
    modalStore: { openModal },
  } = useStore();
  return (
    <Segment>
      <Grid style={{ margin: "2em 0" }} stackable>
        <Grid.Column width={16}>
          <Header
            as="h1"
            color="olive"
            content="Account"
            style={{ marginBottom: "0.1em" }}
          />
          <p style={{ color: "grey" }}>
            To place an order, login to your account or register as a new user
            to continue.
          </p>
        </Grid.Column>
        <Grid.Column width={16}>
          <div style={{ display: "flex" }}>
            <Button
              basic
              size="large"
              color="olive"
              content="Login"
              style={{ width: "10em", margin: "1em", marginLeft: "0" }}
              onClick={() => openModal(<LoginForm />, "mini")}
            />
            <Button
              color="olive"
              size="large"
              content="Register"
              style={{ width: "10em", margin: "1em" }}
              onClick={() => openModal(<RegistrationForm />)}
            />
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(LoginPanel);
