import { Route, Routes } from "react-router-dom";
import LogsPageLayout from "./shop/logs/LogsPageLayout";
import BalancePageLayout from "./payments/balance/BalancePageLayout";
import GetStarted from "./getStarted/GetStarted";
import SalesPageLayout from "./payments/sales/SalesPageLayout";
import OrdersPageLayout from "./commerce/orders/OrdersPageLayout";
import PayoutPageLayout from "./payments/payouts/PayoutPageLayout";
import RefundPageLayout from "./payments/refunds/RefundPageLayout";
import DeliveriesPageLayout from "./commerce/deliveries/DeliveriesPageLayout";
import FoodsPageLayout from "./commerce/foods/FoodsPageLayout";
import MenusPageLayout from "./commerce/menus/MenusPageLayout";
import SettingsPageLayout from "./shop/settings/SettingsPageLayout";
import SupportPageLayout from "./shop/support/SupportPageLayout";
import DashboardPageLayout from "./dashboard/DashboardPageLayout";
import OrderList from "./commerce/orders/OrderList";

interface IProps {
  setPageTitle: (titile: string) => void;
}

const StoreRoutes = ({ setPageTitle }: IProps) => {
  return (
    <Routes>
      <Route
        index
        element={<DashboardPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="getStarted"
        element={<GetStarted setPageTitle={setPageTitle} />}
      />
      <Route
        path="menus"
        element={<MenusPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="foods"
        element={<FoodsPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="orders"
        element={<OrdersPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="order-history"
        element={<OrderList setPageTitle={setPageTitle} />}
      />
      <Route
        path="deliveries"
        element={<DeliveriesPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="sales"
        element={<SalesPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="refunds"
        element={<RefundPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="payouts"
        element={<PayoutPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="balance"
        element={<BalancePageLayout setPageTitle={setPageTitle} />}
      />
      {/* shop configurations */}
      <Route
        path="logs"
        element={<LogsPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="support"
        element={<SupportPageLayout setPageTitle={setPageTitle} />}
      />
      <Route
        path="settings/*"
        element={<SettingsPageLayout setPageTitle={setPageTitle} />}
      />
    </Routes>
  );
};
export default StoreRoutes;
