import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/store/store";
import OrderItem from "./OrderItem";
import OrderBlock from "./OrderBlock";

interface Props {
  mobile?: boolean;
}

const OrderList = ({ mobile }: Props) => {
  // useStore
  const {
    userStore: { ActiveOrders, OrdersHistory },
  } = useStore();

  return (
    <section>
      {ActiveOrders && (
        <section>
          {ActiveOrders?.map((order) => (
            <OrderBlock mobile={mobile} key={order.id} order={order} />
          ))}
        </section>
      )}
      {OrdersHistory && (
        <section>
          {OrdersHistory?.map((order) => (
            <OrderItem mobile={mobile} key={order.id} order={order} />
          ))}
        </section>
      )}
    </section>
  );
};

export default observer(OrderList);
