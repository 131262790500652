import { Container, Header } from "semantic-ui-react";
import SelectLocationForm from "./forms/SelectLocationForm";

interface Props {
  mobile?: boolean;
}

const HomePageHeading = ({ mobile }: Props) => {
  return (
    <Container text>
      <Header
        as="h1"
        content="Get Food Deliver to your doorsteps."
        style={{
          fontSize: mobile ? "1.5em" : "2.7em",
          fontWeight: "normal",
          color: "#555",
          marginBottom: mobile ? "15px" : 0,
          marginTop: mobile ? "1em" : "3em",
        }}
      />
      <Header
        as="h2"
        content="Find vendors around you and experience the best of your neighbourhood."
        style={{
          fontSize: mobile ? "0.9em" : "1.3em",
          fontWeight: "normal",
          color: "#888",
          marginBottom: mobile? "30px" : 0,
          marginTop: mobile ? "0.5em" : "0.5em",
        }}
      />
      <SelectLocationForm mobile={mobile} />
    </Container>
  );
};

export default HomePageHeading;
