import { Card, Grid, Placeholder } from "semantic-ui-react";

interface Props {
  key: string;
}

const VendorPlaceholderCard = ({ key }: Props) => {
  return (
    <Card key={key}>
      <Placeholder style={{ height: 150, width: "100%" }}>
        <Placeholder.Image />
      </Placeholder>
      <Card.Content>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Grid columns={3}>
              <Grid.Column width={12}>
                <Placeholder.Line length="short" />
              </Grid.Column>
              <Grid.Column width={4}>
                <Placeholder.Line
                  length="very short"
                  style={{ float: "right" }}
                />
              </Grid.Column>
            </Grid>
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
    </Card>
  );
};
export default VendorPlaceholderCard;
