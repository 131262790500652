import { useState } from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import axios from "axios";
import ValidationErrors from "./ValidationErrors";

export default function TestErrors() {
  const baseUrl = process.env.REACT_APP_API_URL;

  const [errors, setErrors] = useState<any | null>(null);
  const [index, setIndex] = useState(0);

  function handleNotFound() {
    console.log(index);
    setErrors(null);
    setIndex(1);
    axios
      .get(baseUrl + "/buggy/not-found")
      .catch((err) => console.log(err.response));
  }

  function handleBadRequest() {
    setErrors(null);
    setIndex(2);
    axios
      .get(baseUrl + "/buggy/bad-request")
      .catch((err) => console.log(err.response));
  }

  function handleServerError() {
    setErrors(null);
    setIndex(3);
    axios.get(baseUrl + "/buggy/server-error").catch((err) => {
      // setErrors(err.response);
      console.log(err.response);
    });
  }

  function handleUnauthorised() {
    setErrors(null);
    axios
      .get(baseUrl + "/buggy/unauthorised")
      .catch((err) => console.log(err.response));
  }

  function handleBadGuid() {
    setErrors(null);
    axios.get(baseUrl + "/vendors/notaguid").catch((err) => console.log(err));
  }

  function handleValidationError() {
    setErrors(null);
    axios.post(baseUrl + "/vendors", {}).catch((err) => setErrors(err));
  }

  return (
    <Segment style={{ paddingTop: "3em" }} vertical>
      <Container>
        <Header color="olive" as="h2" content="Test Error component" />
        <Segment>
          <Button.Group widths="7">
            <Button
              onClick={() => handleNotFound()}
              content="Not Found"
              color="olive"
              basic
            />
            <Button
              color="olive"
              onClick={handleBadRequest}
              content="Bad Request"
              basic
            />
            <Button
              onClick={handleValidationError}
              content="Validation Error"
              basic
              color="olive"
            />
            <Button
              onClick={handleServerError}
              content="Server Error"
              basic
              color="olive"
            />
            <Button
              onClick={handleUnauthorised}
              content="Unauthorised"
              basic
              color="olive"
            />
            <Button
              onClick={handleBadGuid}
              content="Bad Guid"
              basic
              color="olive"
            />
          </Button.Group>
        </Segment>
        {errors && <ValidationErrors errors={errors} />}
      </Container>
    </Segment>
  );
}
