import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import MakeList from "./MakeList";
import MakesEmptyPanel from "./MakesEmptyPanel";
import MakesTopMenu from "./MakesTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const MakesLayoutPage = ({ setPageTitle }: Props) => {
  const {
    bikeStore: { makes, loadMakes },
  } = useStore();

  useEffect(() => {
    setPageTitle("Bike Makes");
    loadMakes();
  }, [setPageTitle, loadMakes]);

  return (
    <section>
      <div>
        <MakesTopMenu />
      </div>
      <section>
        {makes && makes.length > 0 ? (
          <MakeList data={makes} />
        ) : (
          <MakesEmptyPanel />
        )}
      </section>
    </section>
  );
};

export default observer(MakesLayoutPage);
