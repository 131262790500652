import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import {
  Button,
  Header,
  Label,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import Chops2uTextInput from "../../../../app/common/forms/Chops2uTextInput";
import { useStore } from "../../../../app/store/store";
import RegistrationForm from "../registration/RegistrationForm";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

type LoginFormValues = {
  email: string;
  password: string;
  error: any;
};

export default observer(function LoginForm() {
  const {
    userStore: { login, logining },
    modalStore: { openModal, closeModal },
  } = useStore();

  const location = useLocation();
  // const from = location.pathname;

  const initValues: LoginFormValues = {
    email: "",
    password: "",
    error: "",
  };

  const validate = Yup.object({
    email: Yup.string().trim().email().required(),
    password: Yup.string().trim().required(),
  });

  const handleLinkClick = () => {
    openModal(<RegistrationForm />);
  };

  const handleLoginFormSubmit = (
    values: LoginFormValues,
    { setErrors }: FormikHelpers<LoginFormValues>
  ) => {
    login(values)
      .then(() => closeModal())
      .catch((error) => {
        console.log(error.response);
        setErrors({ error: error.response.data });
      });
  };

  return (
    <Modal.Content style={{ padding: "1rem 1.5rem" }}>
      <Segment vertical>
        <Formik
          validationSchema={validate}
          initialValues={initValues}
          onSubmit={handleLoginFormSubmit}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <>
              <Form
                className="ui form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Header
                  disabled
                  color="grey"
                  textAlign="center"
                  size="tiny"
                  content={"Login".toUpperCase()}
                />
                <Chops2uTextInput
                  name="email"
                  placeholder="username@domain.com"
                  label="Email"
                />
                <Chops2uTextInput
                  name="password"
                  placeholder="your secret pass"
                  label="Password"
                  type="password"
                />

                <ErrorMessage
                  name="error"
                  render={() => (
                    <Label
                      style={{ marginBottom: "15px" }}
                      basic
                      color="red"
                      content={errors.error}
                    />
                  )}
                />

                <div className="field">
                  <Button
                    loading={isSubmitting && logining}
                    color="olive"
                    content="Login"
                    type="submit"
                    fluid
                  />
                </div>
                {/* <Divider horizontal>Or</Divider>
                <Button
                  as={FacebookLogin}
                  appId="3308516196128686"
                  fluid
                  color="facebook"
                  loading={fbLoading}
                  onSuccess={(response: any) =>
                    facebookLogin(response.accessToken)
                    // console.log("Successful login: ", response)
                  }
                  onFail={(response: any) =>
                    console.log("Login fail, logout: ", response)
                  }
                  onProfileSuccess={(response: any) =>
                    console.log("Profile success: ", response)
                  }
                >
                  <Icon name="facebook" />
                  Login with facebook
                </Button> */}
                <Message
                  size="small"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    New to us?{" "}
                    <button
                      onClick={handleLinkClick}
                      style={{
                        color: "#4183c4",
                        cursor: "pointer",
                        border: "none",
                        padding: "0",
                        borderRadius: "0",
                        boxShadow: "0 0 0 0",
                        backgroundColor: "transparent",
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                  <Link
                    onClick={() => closeModal()}
                    to="/account/forgot"
                    state={{ from: location }}
                  >
                    Forgot Password
                  </Link>
                </Message>
              </Form>
            </>
          )}
        </Formik>
      </Segment>
    </Modal.Content>
  );
});
