import { makeAutoObservable } from "mobx";

interface Modal {
  open: boolean;
  content: JSX.Element | null;
  size: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    content: null,
    size: "mini",
  };

  constructor() {
    makeAutoObservable(this);
  }

  openModal = (
    content: JSX.Element,
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen"
  ) => {
    this.modal.open = true;
    this.modal.content = content;
    if (size) this.modal.size = size;
  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.content = null;
  };
}
