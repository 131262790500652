import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Vendor from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import VendorPhotoDetailsPage from "../detailedPages/VendorPhotoDetailsPage";
import VendorImageUpload from "../ImageUploads/VendorImageUpload";

const VendorPhoto = () => {
  const {
    commonStore: { setAdminPageTitle },
    vendorStore: { loadVendor },
  } = useStore();
  const [edit, setEdit] = useState(false);
  const [vendor, setVendor] = useState<Vendor>({
    id: "",
    title: "",
    description: "",
    address: "",
    state: "",
    city: "",
    active: false,
    published: false,
    telephoneNumber: "",
    email: "",
    location: undefined,
    isOpenNow: false,
    categories: [],
    categoryIds: [],
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadVendor(id).then((result) => {
        setVendor(result!);
        setAdminPageTitle("Vendor: " + vendor.title);
      });
    }
  }, [loadVendor, setAdminPageTitle, id, vendor.title]);

  return (
    <Container text>
      {edit === false ? (
        <VendorPhotoDetailsPage setEditMode={setEdit} vendor={vendor} />
      ) : (
        <VendorImageUpload setEditState={setEdit} vendor={vendor} />
      )}
    </Container>
  );
};

export default observer(VendorPhoto);
