import { ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Container, Header, Segment, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../app/store/store";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { LocationFormValues } from "../../../../../app/models/location";

interface Props {
  id?: string;
}

const containerStyle = {
  margin: "none",
  padding: "none",
};

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "The Title field most have atleast 3 characters.")
    .required(),
  cityId: Yup.string().required("'city' is required"),
});

const LocationForm = ({ id }: Props) => {
  // store
  const {
    locationStore: {
      submitting,
      create,
      update,
      loadLocation,
      loading: loadingLocation,
    },
    cityStore: { Cities, loading, loadCities },
    modalStore: { closeModal },
  } = useStore();

  // useState
  const [location, setLocation] = useState<LocationFormValues>({
    id: undefined,
    name: "",
    cityId: "",
    city: undefined,
    error: null,
  });

  // useEffect
  useEffect(() => {
    loadCities();

    if (id) {
      loadLocation(id).then((location) => {
        let newFormValues: LocationFormValues = {
          ...location!,
          error: null,
        };

        setLocation(newFormValues);
      });
    }
  }, [loadCities, id, loadLocation, setLocation]);

  const handleFormSubmit = (
    values: LocationFormValues,
    { setErrors }: FormikHelpers<LocationFormValues>
  ) => {
    console.log(values);
    if (id) {
      update(id, values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    } else if (values.id === undefined) {
      create(values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Container style={containerStyle}>
      <Header as="h3" attached="top" color="olive">
        Location Form
      </Header>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={location}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleSubmit, errors, touched }) => (
          <Form className="form ui" onSubmit={handleSubmit}>
            <Segment attached clearing loading={loadingLocation}>
              <Chops2uSelectInput
                name="cityId"
                options={Cities}
                placeholder="Cities"
                label="City"
                loading={loading}
              />

              <Chops2uTextInput
                name="name"
                label="Name"
                placeholder="Location name"
              />

              <div className="field">
                {errors.error ? (
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                ) : null}
              </div>
            </Segment>
            <Segment attached="bottom" clearing>
              <Button
                loading={submitting}
                color="olive"
                basic
                type="submit"
                content="Submit"
                floated="right"
              />
              <Button
                color="orange"
                type="button"
                basic
                content="Cancel"
                floated="right"
                onClick={closeModal}
              />
            </Segment>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(LocationForm);
