import { observer } from "mobx-react-lite";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useStore } from "../../store/store";

interface Props {
  allowedRoles?: string[];
}

const RequireAuth = ({ allowedRoles }: Props) => {
  const {
    userStore: { user },
  } = useStore();
  const location = useLocation();
  

  return user && allowedRoles && allowedRoles.includes(user.accountType) ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorize" state={{ from: location }} replace />
    ) :  (
    <Navigate to="/account/login" state={{ from: location }} replace />
  );
};

export default observer(RequireAuth);
