import { isValid } from "date-fns";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { Grid, Segment, Button } from "semantic-ui-react";
import * as Yup from "yup";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { ChangePasswordFormValues } from "../../../../../app/models/change-password";
import { useStore } from "../../../../../app/store/store";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";

const SecurityForm = () => {
  const {
    restaurantStore: { restaurant },
    userStore: { changePassword, submitting },
  } = useStore();
  const validate = Yup.object({
    currentPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    confirmNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formData: ChangePasswordFormValues = {
    email: restaurant?.email,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: "",
  };

  const handleFormSubmit = (
    values: ChangePasswordFormValues,
    { setErrors }: FormikHelpers<ChangePasswordFormValues>
  ) => {
    if (restaurant) {
      changePassword(values)
        .then(() => toast.success("Password changed succesfully"))
        .catch((error) => setErrors({ error: error.response.data }));
    }
  };

  return (
    <Grid>
      <Grid.Column
        style={{ maxWidth: "550px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validate}
          onSubmit={handleFormSubmit}
        >
          {({ dirty, isSubmitting, handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit} className="ui form">
              <Segment color="green" attached="top" clearing>
                {/* Current Password Field */}
                <div className="fields">
                  <div className="six wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Current Password
                    </label>
                  </div>
                  <div className="ten wide field">
                    <Chops2uTextInput
                      name="currentPassword"
                      type="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>

                {/* New Password Field */}
                <div className="fields">
                  <div className="six wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      New Password
                    </label>
                  </div>
                  <div className="ten wide field">
                    <Chops2uTextInput
                      name="newPassword"
                      type="password"
                      placeholder="Enter your new password"
                    />
                  </div>
                </div>

                {/* Current Password Field */}
                <div className="fields">
                  <div className="six wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Confirm New Password
                    </label>
                  </div>
                  <div className="ten wide field">
                    <Chops2uTextInput
                      name="confirmNewPassword"
                      type="password"
                      placeholder="Confirm new password"
                    />
                  </div>
                </div>

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
              </Segment>
              <Segment attached="bottom" clearing secondary>
                <div>
                  <Button
                    positive
                    disabled={(!isValid && dirty) || isSubmitting}
                    loading={isSubmitting && submitting}
                    type="submit"
                    floated="right"
                    content="Change Password"
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(SecurityForm);
