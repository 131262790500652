import { makeAutoObservable, runInAction } from "mobx";
import agent, { sleep } from "../api/agent";
import Food, { FoodType } from "../models/food";
import Menu from "../models/menu";
import Order from "../models/order";
import Shipment from "../models/shipment";
import Vendor, {
  BankAccountFormValues,
  OpeningInfo,
  VendorFormValues,
} from "../models/vendor";
import { store } from "./store";
import Payment from "../models/payment";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default class RestaurantStore {
  restaurant: Vendor | null = null;
  hubConnection: HubConnection | null = null;
  selectedOrder: Order | undefined = undefined;
  menuRegistry = new Map<string, Menu>();
  foodRegistry = new Map<string, Food>();
  ordersRegistry = new Map<string, Order>();
  paymentRegistry = new Map<string, Payment>();
  shipmentRegistry = new Map<string, Shipment>();

  loadingStore = false;
  loadingStoreOpeningHours = false;
  loadingOrders = false;
  submittingOrderStatus = false;

  loadingOrder = false;
  uploading = false;
  loadingMenus = false;
  loadingFoods = false;

  submittingFood = false;
  deletingFood = false;

  loadingShipments = false;

  submittingBankAccount = false;

  constructor() {
    makeAutoObservable(this);
  }

  get Balance() {
    return 0;
  }

  get NetSales() {
    return 0;
  }

  get TotalOrders() {
    return 0;
  }

  get AverageOrderSize() {
    return 0;
  }

  get HasActiveOrder() {
    return this.ordersRegistry.size > 0;
  }

  get MenusByTitle() {
    return Array.from(this.menuRegistry.values());
  }

  get FoodsByTitle() {
    return Array.from(this.foodRegistry.values());
  }

  get ParentGroupedFoods() {
    return Array.from(this.foodRegistry.values())
      .filter((a) => a.type === FoodType.grouped)
      .map((food) => ({ key: food.id!, text: food.title, value: food.id! }));
  }

  get FoodOptions() {
    return Array.from(this.foodRegistry.values())
      .sort((a, b) => {
        if (a.menuId! < b.menuId!) return -1;
        if (a.menuId! > b.menuId!) return 1;
        return 0;
      })
      .map((food) => ({ key: food.id!, text: food.title, value: food.id! }));
  }

  get OrdersPlaced() {
    return Array.from(this.ordersRegistry.values()).filter(
      (a) => a.status === "Placed"
    ).sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  get OrdersInKitchen() {
    return Array.from(this.ordersRegistry.values()).filter(
      (o) => o.status === "Preparing"
    ).sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  get OrdersForPickup() {
    return Array.from(this.ordersRegistry.values()).filter(
      (o) => o.status === "Ready"
    ).sort((a, b) => (new Date(b.createdOnUtc).getTime() - new Date(a.createdOnUtc).getTime()));
  }

  get ShipmentsByDate() {
    return Array.from(this.shipmentRegistry.values());
  }

  private getOrder = (id: string) => {
    return this.ordersRegistry.get(id);
  }

  private getSelectedOrder = () => {
    if (this.OrdersPlaced.length > 0) {
      return this.OrdersPlaced[0];
    } else if (this.OrdersInKitchen.length > 0) {
      return this.OrdersInKitchen[0];
    } else if (this.OrdersForPickup.length > 0) {
      return this.OrdersForPickup[0];
    } else {
      return undefined;
    }
  }

  createHubConnection = (vendorId: string) => {
    const baseUrl = process.env.REACT_APP_ORDERHUB_URL;
    if (this.restaurant) {
      this.loadingOrders = true;
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(baseUrl + "?vendorId=" + vendorId, {
          accessTokenFactory: () => store.userStore.user?.token!
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      this.hubConnection
        .start()
        .catch(error => console.log("Error Establishing hub connection: ", error));

      this.hubConnection.on("LoadOrders", (orders: Order[]) => {
        runInAction(() => {
          this.ordersRegistry.clear();
          orders.forEach((order) => {
            this.ordersRegistry.set(order.id, order);
          });
          this.loadingOrders = false;
          this.selectedOrder = this.getSelectedOrder();
          // console.log(this.selectedOrder);
        });
      })

      this.hubConnection.on("ReceiveOrder", (order: Order) => {
        runInAction(() => {
          this.ordersRegistry.set(order.id, order);
          this.loadingOrder = false;
          this.submittingOrderStatus = false;
        })
      })

      this.hubConnection.on("BroadcastOrderDelete", (order: Order) => {
        runInAction(() => this.ordersRegistry.delete(order.id));
      })
    }
  }

  stopHubConnection = () => {
    this.hubConnection?.stop()
      .catch(error => console.log("Error Stopping connection: ", error));
  }

  clearOrders = () => {
    this.ordersRegistry.clear();
    this.stopHubConnection();
  }

  loadStore = async () => {
    this.loadingStore = true;
    try {
      var restaurant = await agent.Account.getUserRestaurant();
      if (restaurant) {
        restaurant.categoryIds = restaurant.categories.map(
          (category) => category.categoryId!
        );
        runInAction(() => {
          this.restaurant = restaurant;
          this.loadingStore = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.loadingStore = false));
      console.log(error);
    }
  }

  loadStoreOpeningHours = async (restuarantId: string) => {
    this.loadingStoreOpeningHours = true;
    try {
      let openingHours = await store.vendorStore.loadOpeningInfos(restuarantId);
      runInAction(() => (this.loadingStoreOpeningHours = false));
      return openingHours;
    } catch (error) {
      runInAction(() => (this.loadingStoreOpeningHours = false));
      throw error;
    }
  }

  loadStoreOrders = async (storeId: string) => {
    this.loadingOrders = true;
    try {
      let orders = await agent.Orders.StoreList(storeId);
      runInAction(() => {
        this.ordersRegistry.clear();
        orders.forEach((order) => {
          this.ordersRegistry.set(order.id, order);
        });
        this.loadingOrders = false;
        this.selectedOrder = this.getSelectedOrder();
        // console.log(this.selectedOrder);
      });
    } catch (error) {
      runInAction(() => (this.loadingOrders = false));
      console.log(error);
    }
  }

  loadOrder = async (orderId: string) => {
    let order = this.getOrder(orderId);
    if (order) {
      this.selectedOrder = order;
    } else {
      this.loadingOrder = true;
      try {
        order = await agent.Orders.details(orderId);
        runInAction(() => {
          this.selectedOrder = order;
          this.loadingOrder = false;
        });
      } catch (error) {
        runInAction(() => (this.loadingOrder = false));
        console.log(error);
      }
    }
  }

  loadShipments = async (storeId: string) => {
    // let params = new URLSearchParams();
    // params.append("filter", JSON.stringify({ "vendorId": storeId }));
    this.loadingShipments = true;
    try {
      let shipments = await agent.Shipments.StoreList(storeId);
      runInAction(() => {
        this.shipmentRegistry.clear();
        shipments.forEach((shipment) => {
          this.shipmentRegistry.set(shipment.id!, shipment);
        });
        this.loadingShipments = false;
      });
    } catch (error) {
      runInAction(() => (this.loadingShipments = false));
      console.log(error);
    }
  };

  confirmOrder = async (orderId: string) => {
    this.submittingOrderStatus = true;
    try {
      await this.hubConnection?.invoke("ConfirmOrder", orderId);
      runInAction(() => {
        this.selectedOrder = this.getSelectedOrder();
        this.submittingOrderStatus = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submittingOrderStatus = false));
    }
  };

  finishOrder = async (orderId: string) => {
    this.submittingOrderStatus = true;
    try {
      await this.hubConnection?.invoke("finishOrder", orderId);
      runInAction(() => {
        this.selectedOrder = this.getSelectedOrder();
        this.submittingOrderStatus = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submittingOrderStatus = false));
    }
  };

  confirmOrderPickup = async (orderId: string) => {
    this.submittingOrderStatus = true;
    try {
      await sleep(1000);
      runInAction(() => {
        this.ordersRegistry.delete(orderId);
        this.selectedOrder = this.getSelectedOrder();
        this.submittingOrderStatus = false;
      });
    } catch (error) {
      runInAction(() => (this.submittingOrderStatus = false));
      console.log(error);
    }
  };

  updateStore = async (restaurantId: string, restaurant: VendorFormValues) => {
    this.submittingFood = true;
    try {
      let result = await agent.Vendors.update(restaurantId, restaurant);
      runInAction(() => {
        this.restaurant = result;
        this.submittingFood = false;
      });
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  updateOpeningInfo = async (storeId: string, openingInfos: OpeningInfo[]) => {
    this.submittingFood = true;
    try {
      await store.vendorStore.addOrUpdateOpeningInfos(storeId, openingInfos);
      runInAction(() => (this.submittingFood = false));
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  updateStorePhoto = async (myStore: Vendor, file: Blob) => {
    this.uploading = true;
    try {
      let photo = await agent.Vendors.updatePhoto(myStore.id, file);
      myStore.image = photo.url;
      runInAction(() => {
        this.restaurant!.image = photo.url;
        this.uploading = false;
      });
    } catch (error) {
      runInAction(() => (this.uploading = false));
      console.log(error);
    }
  };

  loadMenus = async (vendorId: string) => {
    this.loadingMenus = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ vendorId: vendorId }));
    try {
      let menus = await agent.Menus.list(params);
      runInAction(() => {
        this.menuRegistry.clear();
        menus.forEach((menu) => {
          this.menuRegistry.set(menu.id!, menu);
        });
        this.loadingMenus = false;
      });
    } catch (error) {
      runInAction(() => (this.loadingMenus = false));
      console.log(error);
    }
  };

  createMenu = async (menu: Menu) => {
    this.submittingFood = true;
    try {
      let newMenu = await agent.Menus.create(menu);
      if (newMenu) {
        runInAction(() => {
          this.menuRegistry.set(newMenu.id!, newMenu);
          this.submittingFood = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  updateMenu = async (menu: Menu) => {
    this.submittingFood = true;
    try {
      let updatedMenu = await agent.Menus.update(menu.id!, menu);
      if (updatedMenu) {
        runInAction(() => {
          this.menuRegistry.set(updatedMenu.id!, updatedMenu);
          this.submittingFood = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  deleteMenu = async (id: string) => {
    this.deletingFood = true;
    try {
      await agent.Menus.delete(id);
      runInAction(() => {
        this.menuRegistry.delete(id);
        this.deletingFood = false;
      });
    } catch (error) {
      runInAction(() => (this.deletingFood = false));
      throw error;
    }
  };

  loadFoods = async (vendorId: string) => {
    this.loadingFoods = true;
    try {
      const params = new URLSearchParams();
      params.append("filter", JSON.stringify({ vendorId }));
      let foods = await agent.Foods.list(params);
      if (foods) {
        runInAction(() => {
          this.foodRegistry.clear();
          foods.forEach((food) => {
            this.foodRegistry.set(food.id!, food);
          });
          this.loadingFoods = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.loadingFoods = false));
      console.log(error);
    }
  };

  loadAssociatedFoods = async (groupedParentFoodId: string) => {
  }

  createFood = async (food: Food) => {
    this.submittingFood = true;
    try {
      let newFood = await agent.Foods.create(food);
      if (newFood) {
        runInAction(() => {
          this.foodRegistry.set(newFood.id!, newFood);
          this.submittingFood = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  updateFood = async (food: Food) => {
    this.submittingFood = true;
    try {
      let updatedFood = await agent.Foods.update(food.id!, food);
      if (updatedFood) {
        runInAction(() => {
          this.foodRegistry.set(updatedFood.id!, updatedFood);
          this.submittingFood = false;
        });
      }
    } catch (error) {
      runInAction(() => (this.submittingFood = false));
      throw error;
    }
  };

  deleteFood = async (foodId: string) => {
    this.deletingFood = true;
    try {
      await agent.Foods.delete(foodId);
      runInAction(() => {
        this.foodRegistry.delete(foodId);
        this.deletingFood = false;
      });
    } catch (error) {
      runInAction(() => (this.deletingFood = false));
      throw error;
    }
  };

  addOrUpdateBankAccount = async (
    vendorId: string,
    bankAccount: BankAccountFormValues
  ) => {
    this.submittingBankAccount = true;
    console.log(bankAccount);
    try {
      let account = await agent.Vendors.addOrUpdateBankAccount(
        vendorId,
        bankAccount
      );
      runInAction(() => {
        if (this.restaurant) {
          this.restaurant.bankAccount = account;
        }
        this.submittingBankAccount = false;
      });
      return account;
    } catch (error) {
      runInAction(() => (this.submittingBankAccount = false));
      throw error;
    }
  };
}
