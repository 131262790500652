import { Sidebar } from "semantic-ui-react";
import { Media } from "../../..";
import { useState } from "react";
import StoreSideNavMobile from "../nav/StoreSideNavMobile";
import StoreTopMenuMobile from "../nav/StoreTopMenuMobile";

interface IProps {
    title: string;
    children: React.ReactNode;
}

const StoreMobileContainer = ({ title, children }: IProps) => {
    const [visibility, setVisibility] = useState(false);

    return (
      <Media at="mobile">
        <Sidebar.Pushable>
          <StoreSideNavMobile
            visibility={visibility}
            setVisibility={setVisibility}
          />
          <Sidebar.Pusher>
            <section style={{ backgroundColor: "rgba(0,0,0,0.05)", marginTop: "5em", minHeight: "calc(100vh - 5em)" }}>
              <StoreTopMenuMobile
                setVisibility={setVisibility}
                title={title}
              />
              <section>{children}</section>
            </section>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
}

export default StoreMobileContainer;