import { Grid, Header } from "semantic-ui-react";
import SupportTicket from "./SupportTicket";
import { Container, SubText, TicketWrapper } from "../../store.style";

const SupportPanel = () => {
  return (
    <div>
      <TicketWrapper
        textAlign="center"
        verticalAlign="middle"
        // style={{ minHeight: "calc(100vh - 60px)" }}
      >
        <Grid.Column>
          <Container>
            <Header as="h4">
              <SubText>
                If you are reporting a problem, please describe it in detail -
                this will help to solve it as soon as possible.
              </SubText>
            </Header>
            <SupportTicket />
          </Container>
        </Grid.Column>
      </TicketWrapper>
    </div>
  );
};

export default SupportPanel;
