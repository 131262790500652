import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Food from "../../../../../app/models/food";
import { useStore } from "../../../../../app/store/store";

interface Props {
  food: Food;
}

const DeleteFoodForm = ({ food }: Props) => {
  const {
    modalStore: { closeModal },
    restaurantStore: { deleteFood, deletingFood: deleting },
  } = useStore();

  const handleDeleteFood = () => {
    deleteFood(food.id!)
      .then(() => closeModal())
      .catch((error) => closeModal());
  };

  return (
    <>
      <Modal.Header>
        <Header
          textAlign="center"
          color="grey"
          as="h4"
          content={"Delete Food".toUpperCase()}
          style={{ padding: "10px 0 5px 0" }}
        />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following food?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {food.title}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          labelPosition="left"
          floated="left"
          type="button"
          negative
          content="No"
          onClick={() => closeModal()}
          style={{ width: "130px" }}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          loading={deleting}
          positive
          content="Yes"
          type="button"
          onClick={handleDeleteFood}
          style={{ width: "130px" }}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteFoodForm);
