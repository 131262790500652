import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import "yup-phone";
import { Button, Header, Segment } from "semantic-ui-react";
import Chops2uDateInput from "../../../../../app/common/forms/Chops2uDateInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { BikeRegistrationFormValues } from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface Props {
  id: string;
}

const validate = Yup.object({
  registeredOnUtc: Yup.date().required(),
  expiresOnUtc: Yup.date().required(),
  vioStation: Yup.string().required(),
  telephone: Yup.string().phone().required(),
  plateNumber: Yup.string().required(),
});

const BikeRegistrationForm = ({ id }: Props) => {
  const {
    modalStore: { closeModal },
    bikeStore: { register, submitting },
  } = useStore();

  const registration: BikeRegistrationFormValues = {
    expiresOnUtc: undefined,
    registeredOnUtc: undefined,
    vioStation: "",
    telephone: "",
    plateNumber: "",
    active: false,
    error: null,
  };

  const handleFormSubmit = (
    values: BikeRegistrationFormValues,
    { setErrors }: FormikHelpers<BikeRegistrationFormValues>
  ) => {
    if (id) {

      register(id, values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Formik
      initialValues={registration}
      validationSchema={validate}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid, isSubmitting, errors, values }) => (
        <Form onSubmit={handleSubmit} className="ui form">
          <Segment attached="top" color="olive">
            <Header
              as="h4"
              content="Bike Registration"
              style={{ margin: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment attached>
            <div className="fields equal">
              <Chops2uDateInput name="registeredOnUtc" title="Register Date" />
              <Chops2uDateInput name="expiresOnUtc" title="Expiry" />
            </div>

            <Chops2uTextInput
              name="vioStation"
              label="VIO Station"
              placeholder="Registration Place"
            />

            <Chops2uTextInput
              name="telephone"
              label="Telephone"
              placeholder="Registered Phone Number"
            />

            <Chops2uTextInput
              name="plateNumber"
              label="Plate Number"
              placeholder="Place Number"
            />

            <div className="field">
              {errors.error ? (
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              ) : null}
            </div>

            {/* <div className="field">
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </div> */}
          </Segment>
          <Segment attached="bottom" secondary>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon="cancel"
                labelPosition="left"
                color="orange"
                content="Close"
                onClick={closeModal}
              />

              <Button
                icon="check"
                disabled={(!isValid && dirty) || (isSubmitting && submitting)}
                labelPosition="left"
                loading={submitting}
                color="olive"
                type="submit"
                content="Submit"
              />
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(BikeRegistrationForm);
