import { Route, Routes } from "react-router-dom";
import AccountTypesPage from "./accountTypes/AccountTypesPage";
import RoleList from "./roles/RoleList";
// import UsersLayoutPage from "./users/UsersLayoutPage";
// import UsersEmptyPanel from "./users/UsersEmptyPanel";
import UsersLayoutPage from "./users/UsersLayoutPage";

const AccountManagerLayout = () => {
  return (
    <Routes>
      <Route path="users" element={<UsersLayoutPage />} />
      <Route path="roles" element={<RoleList />} />
      <Route path="accountTypes" element={<AccountTypesPage />} />
    </Routes>
  );
};

export default AccountManagerLayout;
