import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Header } from "semantic-ui-react";
import AppUser from "../../../../app/models/app-user";
import { format } from "date-fns";
import { useStore } from "../../../../app/store/store";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import { PageWrapper } from "../../administrator.style";

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

interface Props {
  data: AppUser[];
}

const UsersList = ({ data }: Props) => {
  const {
    modalStore: { openModal },
  } = useStore();
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "180px", maxWidth: "180px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "180px", maxWidth: "180px" },
        }),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "emailConfirmed",
      label: "Confirmed Email",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: checkboxRender,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "phoneNumberConfirmed",
      label: "Confirmed Phone",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: checkboxRender,
      },
    },
    {
      name: "accountType",
      label: "Account Type",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value.name;
        },
      },
    },
    {
      name: "registeredOnUtc",
      label: "Register",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return format(new Date(value), "dd-MM-yyyy");
        },
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignContent: "center",
              }}
            >
              <Button
                icon="eye"
                basic
                circular
                size="mini"
                onClick={() => handleViewButtonClick(tableMeta)}
              />

              <Button
                icon="unlock alternate"
                basic
                color="purple"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />
              <Button
                icon="delete"
                basic
                color="orange"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  function handleViewButtonClick(tableMeta: any) {
    // const { rowIndex } = tableMeta;
    // openModal(<BikeDetails bike={data[rowIndex]} />, "small");
    console.log(tableMeta);
  }

  function handleEditButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    openModal(<ResetPasswordForm user={data[rowIndex]} />, "tiny");
  }

  function handleDeleteButtonClick(tableMeta: any) {
    // const { rowIndex } = tableMeta;
    console.log(tableMeta);
    // openModal(<DeleteCategoryForm category={data[rowIndex]} />);
    // openModal(<DeleteBikeForm bike={data[rowIndex]} />, "mini");
  }

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });
  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default UsersList;
