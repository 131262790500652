import { Container, Header, List } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const CookiePolicy = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">Cookie Policy</Header>
      <p>Effective Date: 8th December 2023</p>
      <List ordered>
        <List.Item>
          <List.Header>Introduction</List.Header>
          This Cookie Policy ("Policy") explains how our food ordering and
          delivery platform ("Platform") uses cookies and similar tracking
          technologies to provide you with a better user experience and to
          ensure the proper functioning of our services. This Policy applies to
          all users of our Platform.
        </List.Item>
        <List.Item>
          <List.Header>What are Cookies?</List.Header>
          Cookies are small text files that are placed on your device (such as a
          computer or mobile device) when you visit our Platform. These files
          enable us to recognize your device and store certain information about
          your preferences or actions.
        </List.Item>
        <List.Item>
          <List.Header>Types of Cookies Used</List.Header>
          <List>
            <List.Item>
              Essential Cookies: These cookies are necessary for the operation
              of our Platform and enable you to access its features, such as
              logging in, making orders, and using payment options. Without
              these cookies, the Platform may not function properly.
            </List.Item>
            <List.Item>
              Analytical/Performance Cookies: We use these cookies to analyze
              how users interact with our Platform, gather statistical
              information, and improve its performance. These cookies do not
              collect personally identifiable information. We use this data to
              enhance the user experience and optimize our services.
            </List.Item>
            <List.Item>
              Functionality Cookies: These cookies allow the Platform to
              remember your preferences and choices, such as language selection
              and customized settings. They enhance your browsing experience by
              providing personalized features and may enable us to provide you
              with relevant recommendations.
            </List.Item>
            <List.Item>
              Advertising/Targeting Cookies: We may use these cookies to deliver
              targeted advertisements and promotional content based on your
              interests and browsing behavior. These cookies may also be used by
              our advertising partners to track the effectiveness of their
              campaigns.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Third-Party Cookies</List.Header>
          Our Platform may contain cookies from third parties, such as analytics
          providers and advertisers. These third parties may use cookies to
          collect information about your interactions with our Platform and
          other websites or applications. We do not control the use of these
          cookies and encourage you to review the respective privacy policies of
          these third parties.
        </List.Item>
        <List.Item>
          <List.Header>Your Cookie Choices</List.Header>
          By using our Platform, you consent to the use of cookies in accordance
          with this Policy. However, you have the option to manage or delete
          cookies based on your preferences. Most web browsers allow you to
          control cookies through their settings. Please note that disabling
          certain cookies may impact your ability to use certain features of our
          Platform.
        </List.Item>
        <List.Item>
          <List.Header>Data Security and Privacy</List.Header>
          We are committed to protecting your personal information and
          maintaining its confidentiality. Please refer to our Privacy Policy
          for more information about how we collect, use, and protect your data.
        </List.Item>
        <List.Item>
          <List.Header>Updates to this Policy</List.Header>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or legal requirements. We encourage you to
          periodically review this Policy to stay informed about how we use
          cookies.
        </List.Item>
        <List.Item>
          <List.Header>Contact Us</List.Header>
          If you have any questions or concerns regarding this Cookie Policy,
          please contact us at +23491 676666 80.
        </List.Item>
      </List>
      <p>
        Please note that this Cookie Policy should be tailored to the specific
        requirements of your food ordering and delivery platform and comply with
        applicable laws and regulations in your jurisdiction.
      </p>
    </Container>
  );
};

export default CookiePolicy;
