import { Button, Header, Segment, Statistic } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import { observer } from "mobx-react-lite";

const BalancePanel = () => {
  const { restaurantStore: { Balance } } = useStore();

  return (
    <Segment textAlign="center" secondary style={{ width: "300px" }}>
        <Header style={{ padding: "40px 0 20px 0" }}>Balance</Header>
        <Statistic>
          <Statistic.Value>
            {Balance.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Statistic.Value>
          <Statistic.Label style={{ padding: "5px 0 20px 0" }}>
            Available
          </Statistic.Label>
        </Statistic>
        <div style={{ padding: "5px 0 30px 0" }}>
          <Button circular basic content="Withdraw" />
        </div>
      </Segment>
  );
};

export default observer(BalancePanel);
