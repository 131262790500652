import { Header } from "semantic-ui-react";
import Location from "../../../../app/models/location";

interface Props {
  location: Location;
}

const VendorLocation = ({ location }: Props) => {
  return (
    <Header.Subheader style={{ color: "#ccc" }}>
      {location.name + ", " + location.city!.name}
    </Header.Subheader>
  );
};

export default VendorLocation;
