import { Header } from "semantic-ui-react";

const WelcomePanel = () => {
  return (
    <div>
      <Header as="h3">Welcome back!</Header>
    </div>
  );
};

export default WelcomePanel;
