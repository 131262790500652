import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Header, Segment, Select } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import DeleteFoodForm from "../forms/DeleteFoodForm";
import FoodImageUploadForm from "../ImageUploads/FoodImageUploadForm.tsx";
import { PageWrapper } from "../../../administrator.style";

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

interface Props {
  setEditMode?: (state: boolean) => void;
  setFoodId: (foodId?: string) => void;
}

const FoodList = ({ setEditMode, setFoodId }: Props) => {
  const {
    foodStore: { loadFoods, FoodsByMenu: getFoods },
    menuStore: { loadMenus, Menus: getMenus },
    modalStore: { openModal },
  } = useStore();

  const { id } = useParams();

  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "weight",
      label: "Weight",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2em" }}
            >
              <Button
                icon="edit"
                basic
                color="olive"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />
              <Button
                icon="photo"
                basic
                circular
                size="mini"
                onClick={() => handlePhotoUploadButtonClick(tableMeta)}
              />
              <Button
                icon="delete"
                basic
                color="orange"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  useEffect(() => {
    if (id) {
      loadMenus(id);
      loadFoods(id);
    }
  }, [id, loadMenus, loadFoods]);

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  function handleEditButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;

    if (getFoods && getFoods.length > rowIndex) {
      const foodId = getFoods[rowIndex].id;
      setFoodId(foodId);
      setEditMode!(true);
    }
  }

  function handlePhotoUploadButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;

    if (getFoods && getFoods.length > rowIndex) {
      openModal(
        <FoodImageUploadForm
          food={getFoods[rowIndex]}
          color="olive"
          setEditMode={setEditMode!}
        />,
        "small"
      );
    }
  }

  function handleDeleteButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    console.log(tableMeta);

    if (getFoods && getFoods.length > rowIndex) {
      openModal(
        <DeleteFoodForm food={getFoods[rowIndex]} setEditMode={setEditMode!} />,
        "mini"
      );
    }
  }

  const handleSelectionChange = (d: any) => {
    console.log(d);
    loadFoods(id!, d.value);
  };

  return (
    <PageWrapper>
      <div className="ui form">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="filter">Filter</label>
            <Select
              label="Menu"
              options={getMenus}
              onChange={(e, d) => handleSelectionChange(d)}
              style={{ zIndex: 1000, width: "300px" }}
            />
          </div>
          <div>
            <Button
              fluid
              icon="plus"
              color="olive"
              content="Add Food"
              floated="right"
              onClick={() => setEditMode!(true)}
            />
          </div>
        </div>
        <div className="field">
          <Segment vertical>
            <MuiThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                title={null}
                data={getFoods}
                columns={columns}
                options={{
                  filter: "false",
                  download: "false",
                  print: "false",
                  viewColumns: "false",
                  selectableRows: "none",
                  responsive: "standard",
                  rowsPerPage: 10,
                  rowsPerPageOptions: [10, 15, 20],
                  count: getFoods.length,
                  page: 0,
                  // onRowsDelete: handleRowsDelete,
                  // onTableChange: handleTableChange,
                  setTableProps: () => {
                    return { size: "small" };
                  },
                }}
              />
            </MuiThemeProvider>
          </Segment>
        </div>
      </div>
    </PageWrapper>
  );
};

export default observer(FoodList);
