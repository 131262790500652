import { Grid, Header, Icon } from "semantic-ui-react";

const PayoutEmptyPanel = () => {
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      style={{
        minHeight: "calc(100vh - 100px)",
      }}
    >
      <Grid.Column style={{ width: "500px" }}>
        <Icon name="folder outline" size="huge" color="green" />
        <Header textAlign="center" as="h4">
          <Header.Content>{"No payouts yet"}</Header.Content>
          <Header.Subheader style={{ padding: "10px 0 5px 0" }}>
            We haven't pay money to this account. This is where you'will be able
            to see your payouts and the transactions you were paid for.
          </Header.Subheader>
        </Header>
      </Grid.Column>
    </Grid>
  );
};

export default PayoutEmptyPanel;
