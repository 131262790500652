import { observer } from "mobx-react-lite";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../store/store";

export default observer(function ModalComponent() {
  const { modalStore } = useStore();

  return (
    <Modal
      open={modalStore.modal.open}
      onClose={modalStore.closeModal}
      size={modalStore.modal.size}
    >
      {modalStore.modal.content}
    </Modal>
  );
});
