import { Media } from "../../..";
import DesktopTopNav from "../nav/DesktopTopNav";

interface Props {
  children: React.ReactNode;
}

const DesktopContainer = ({ children }: Props) => {
  const pageStyle = {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
  };

  return (
    <Media greaterThan="mobile">
      <div style={pageStyle}>
        <DesktopTopNav />
        <div style={{ marginTop: "3.45em" }}>{children}</div>
      </div>
    </Media>
  );
};
export default DesktopContainer;
