import { observer } from "mobx-react-lite";
import { Modal, Header, Button } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";

interface Props {
  id: string;
}

const DeleteVendorOpeningInfoForm = ({ id }: Props) => {
  const { modalStore, vendorStore } = useStore();

  const handleDeleteCommand = async () => {
    vendorStore.deleteOpeningInfos(id).then(() => {
      modalStore.closeModal();
    });
  };

  return (
    <>
      <Modal.Header>
        <Header as="h3" color="olive" content="Delete Vendor Opening Hours" />
      </Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete the following Vendor Opening
          Information?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          labelPosition="left"
          floated="left"
          negative
          content="No"
          onClick={() => modalStore.closeModal()}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          positive
          loading={vendorStore.deletingOpeningInfos}
          content="Yes"
          onClick={() => handleDeleteCommand()}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteVendorOpeningInfoForm);
