import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import "yup-phone";
import {
  Button,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import { UserFormValues } from "../../app/models/user";
import { useStore } from "../../app/store/store";
import Chops2uTextInput from "../../app/common/forms/Chops2uTextInput";
import Chops2uSelectInput from "../../app/common/forms/Chops2uSelectInput";
import ValidationErrors from "../frontpage/errors/ValidationErrors";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

export interface ChopperRegistrationFormValues extends UserFormValues {
  address: string;
  locationId?: string;
  guarantorsFullName: string;
  guarantorsAddress: string;
  guarantorsPhoneNumber: string;
}

const validate = Yup.object({
  fullName: Yup.string().required("'full name' is a required field"),
  phoneNumber: Yup.string()
    .required("'phone number' is a required field")
    .phone(),
  email: Yup.string().required().email(),
  address: Yup.string().required(),
  locationId: Yup.number().required("location is a required field").moreThan(0),
  guarantorsFullName: Yup.string().required(
    "'guarantor's full name' is a required field"
  ),
  guarantorsPhoneNumber: Yup.string()
    .required("'guarantor's phone number' is a required field")
    .phone(),
  guarantorsAddress: Yup.string().required(
    "'guarantor's address' is a required field"
  ),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "password must match"),
});

const ChopperRegistrationPage = () => {
  const {
    locationStore: { loadLocations, LocationsByName: locationsByName, loading },
    userStore: { registerChopper, registering },
  } = useStore();

  const navigate = useNavigate();

  const chopper: ChopperRegistrationFormValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    locationId: "",
    password: "",
    confirmPassword: "",
    guarantorsFullName: "",
    guarantorsAddress: "",
    guarantorsPhoneNumber: "",
    error: null,
  };

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  const handleFormSubmit = (
    values: ChopperRegistrationFormValues,
    { setErrors }: FormikHelpers<ChopperRegistrationFormValues>
  ) => {
    registerChopper(values)
      .then(() =>
        navigate(`/account/register-success?email=${values.email}`, {
          replace: true,
        })
      )
      .catch((error) => setErrors({ error }));
  };

  return (
    <Grid
      textAlign="center"
      style={{ minHeight: "845px", paddingTop: "10px" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 550 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Formik
          initialValues={chopper}
          validationSchema={validate}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleSubmit, errors, isSubmitting, dirty, isValid }) => (
            <Form className="ui form error" onSubmit={handleSubmit}>
              <Segment stacked style={{ marginTop: "1em" }}>
                <Header
                  disabled
                  size="tiny"
                  content={"Chopper Registration".toUpperCase()}
                />

                <Chops2uTextInput
                  name="fullName"
                  label="Full Name"
                  placeholder="Full Name"
                />

                <div className="equal width fields">
                  <Chops2uTextInput
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="Telephone"
                  />

                  <Chops2uTextInput
                    name="email"
                    label="Email"
                    placeholder="username@chops2u.com"
                  />
                </div>

                <Chops2uTextInput
                  name="address"
                  label="Address"
                  placeholder="Address"
                />

                <Chops2uSelectInput
                  name="locationId"
                  label="Location"
                  loading={loading}
                  options={locationsByName}
                />

                <Chops2uTextInput
                  name="guarantorsFullName"
                  label="Guarantor's Name"
                  placeholder="Guarantor's Full Name"
                />

                <div className="equal width fields">
                  <Chops2uTextInput
                    name="guarantorsPhoneNumber"
                    label="Guarantor's Mobile"
                    placeholder="Guarantor's Telephone"
                  />

                  <Chops2uTextInput
                    name="guarantorsAddress"
                    label="Guarantor's Address"
                    placeholder="Contact Address"
                  />
                </div>

                <div className="two fields">
                  <Chops2uTextInput
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                  />
                  <Chops2uTextInput
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </div>

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
                <div className="field">
                  <Button
                    disabled={!isValid && dirty}
                    loading={registering && isSubmitting}
                    fluid
                    color="olive"
                    type="submit"
                    content="Register"
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
        <Message>
          Already have an account? <a href="/chopper">Login</a>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ChopperRegistrationPage);
