import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Food from "../../../../../app/models/food";
import { useStore } from "../../../../../app/store/store";

interface Props {
  food: Food;
  setEditMode: (state: boolean) => void;
}

const DeleteFoodForm = ({ food, setEditMode }: Props) => {
  const { modalStore, foodStore } = useStore();

  const handleDeleteCommand = () => {
    foodStore.delete(food.id!).then(() => {
      setEditMode(false);
      modalStore.closeModal();
    });
  };

  return (
    <>
      <Modal.Header>
        <Header as="h3" color="olive" content="Delete Food" />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following food?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {food.title}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          labelPosition="left"
          floated="left"
          negative
          content="No"
          onClick={() => modalStore.closeModal()}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          positive
          loading={foodStore.deleting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteFoodForm);
