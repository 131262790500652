import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import {
  Segment,
  Menu,
  Icon,
  Button,
  Sidebar,
  ItemHeader,
  Image,
} from "semantic-ui-react";
import { useStore } from "../../../app/store/store";

// component import
import HomePageHeading from "../HomePageHeading";
import { Media } from "../../..";
import LoginForm from "../../frontpage/account/login/LoginForm";
import RegistrationForm from "../../frontpage/account/registration/RegistrationForm";
import { Link } from "react-router-dom";

const mobileHeaderStyle = {
  minHeight: 280,
  padding: "1em 0em",
  borderBottom: "1px solid #888",
  background: "url('/images/home-background.png')",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#f0f7c6",
  backgroundSize: "238px 243.5px",
  backgroundPosition: "center 125px",
};

interface Props {
  children: ReactNode;
}

const MobileContainerHomePage = ({ children }: Props) => {
  const {
    modalStore: { openModal },
    commonStore: { mobileMenuVisibility, setMobileMenuVisibility },
  } = useStore();
  return (
    <Media at="mobile">
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={() => setMobileMenuVisibility(false)}
          vertical
          // inverted
          // color="olive"
          visible={mobileMenuVisibility}
        >
          <Menu.Item>
            <ItemHeader>
              <div
                style={{
                  display: "flex",
                  alignContent: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <Image
                  as={Link}
                  to="/"
                  src="/images/logo.png"
                  size="small"
                  style={{ height: "auto", width: "120px" }}
                />
                <Button
                  icon="cancel"
                  name="cancel"
                  onClick={() => setMobileMenuVisibility(false)}
                  style={{
                    float: "right",
                    backgroundColor: "transparent",
                    padding: "8px 0px",
                  }}
                />
              </div>
            </ItemHeader>
          </Menu.Item>

          <Menu.Item as={Link} to="/">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="home"
            />
            <span style={{ color: "#a0b605" }}>Home</span>
          </Menu.Item>
          <Menu.Item as={Link} to="/pages/career">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="suitcase"
            />
            <span style={{ color: "#a0b605" }}>Career</span>
          </Menu.Item>
          <Menu.Item as={Link} to="/pages/helpandsupport">
            <Icon
              color="olive"
              style={{
                float: "left",
                marginBottom: "0px",
                marginRight: "5px",
                padding: "0px",
              }}
              name="shield alternate"
            />
            <span style={{ color: "#a0b605" }}>Help & Support</span>
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher>
          <>
            <Menu
              size="large"
              secondary
              style={{
                borderRadius: "0px",
                padding: "8px 0px",
                paddingRight: "10px",
                margin: "0px",
                background: "rgb(240, 247, 198)",
              }}
            >
              <Menu.Item onClick={() => setMobileMenuVisibility(true)}>
                <Icon color="olive" name="sidebar" />
              </Menu.Item>
              <Menu.Item style={{ padding: "0px", marging: "0px" }}>
                <Image
                  as={Link}
                  to="/"
                  src="/images/logo.png"
                  size="small"
                  style={{ height: "auto", width: "120px" }}
                />
              </Menu.Item>
              {/* <Menu secondary style={{ width: "100%" }}>
                <Menu.Item style={{ margin: "auto" }}>
                  <Icon name="shipping fast" size="large" color="olive" />
                  <Header
                    color="olive"
                    content="Chops2u"
                    style={{ margin: "0px", fontWeight: 500 }}
                  />
                </Menu.Item>
              </Menu> */}
              <Menu.Item className="remove-margin-left" position="right">
                <Button
                  as="a"
                  circular
                  size="mini"
                  onClick={() => openModal(<LoginForm />)}
                  style={{
                    padding: "8px 14px",
                    minWidth: "95px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      color: "#454545",
                      fontSize: "0.9em",
                      fontWeight: 400,
                      letterSpacing: "0.5px",
                    }}
                  >
                    Login
                  </span>
                </Button>
                <Button
                  as="a"
                  circular
                  size="tiny"
                  onClick={() => openModal(<RegistrationForm />)}
                  style={{
                    //minWidth: "45px",
                    padding: "8px 14px",
                    background: "#fff",
                    border: "1.5px solid #454545",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9em",
                      fontWeight: 400,
                      color: "#454545",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Sign Up
                  </span>
                </Button>
              </Menu.Item>
            </Menu>
            <div style={mobileHeaderStyle}>
              <HomePageHeading mobile={true} />
            </div>
            {children}
          </>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
};

export default observer(MobileContainerHomePage);
