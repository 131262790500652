import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ChopperSidebar from "./ChopperSidebar";
import ChopperTopMenu from "./ChopperTopMenu";
import OrdersLayoutPage from "./orders/OrdersLayoutPage";
import PayoutsLayoutPage from "./payouts/PayoutsLayoutPage";
import SettingsLayoutPage from "./settings/SettingsLayoutPage";
import ShipmentsLayoutPage from "./shipments/ShipmentsLayoutPage";
import SupportLayoutPage from "./support/SupportLayoutPage";
import VehiclePage from "./vehicle/VehiclePage";

const mainContentStyle = {
  width: "calc(100% - 250px)",
  minHeight: "100vh",
  marginLeft: "250px",
  // display: "flex",
  // flexDirection: "column" as "column",
  backgroundColor: "rgba(0,0,0,0.05)",
};

const sidebarStyles = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  width: "250px",
  // background: "#767676",
  display: "flex",
  flexDirection: "column" as "column",
};

const ChopperLayoutPage = () => {
  const [pageTitle, setPageTitle] = useState("");
  return (
    <div>
      <div style={sidebarStyles}>
        <ChopperSidebar />
      </div>
      <div style={mainContentStyle}>
        <ChopperTopMenu title={pageTitle} />
        <div>
          <Routes>
            <Route
              index
              element={<OrdersLayoutPage setPageTitle={setPageTitle} />}
            />
            <Route
              path="shipments"
              element={<ShipmentsLayoutPage setPageTitle={setPageTitle} />}
            />
            <Route
              path="payments"
              element={<PayoutsLayoutPage setPageTitle={setPageTitle} />}
            />
            {/* shop configurations */}
            <Route
              path="vehicle"
              element={<VehiclePage setPageTitle={setPageTitle} />}
            />
            <Route
              path="support"
              element={<SupportLayoutPage setPageTitle={setPageTitle} />}
            />
            <Route
              path="settings/*"
              element={<SettingsLayoutPage setPageTitle={setPageTitle} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ChopperLayoutPage;
