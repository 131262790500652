import { Container, Header, List } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const TermsAndConditions = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">
        Terms and Conditions
      </Header>
      <List ordered>
        <List.Item>
          <List.Header>Platform Services</List.Header>
          <List>
            <List.Item>
              The Platform provides an online marketplace where users can browse
              menus, place food orders, and arrange for delivery from
              participating restaurants and food establishments ("Vendors").
            </List.Item>
            <List.Item>
              The Platform facilitates the delivery of food orders by connecting
              users with our delivery riders or independent delivery partners
              ("Choppers") who are responsible for picking up the orders from
              the Vendors and delivering them to the designated delivery
              address.
            </List.Item>
            <List.Item>
              Chops2u may also offer additional services, such as customer
              support, ratings, and reviews, loyalty programs, and promotional
              offers.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>User Obligations</List.Header>
          <List>
            <List.Item>
              By using the Platform, you represent and warrant that you are at
              least 18 years old and have the legal capacity to enter into a
              binding agreement.
            </List.Item>
            <List.Item>
              You agree to provide accurate, current, and complete information
              during the registration process and to keep your account
              information updated.
            </List.Item>
            <List.Item>
              You are responsible for maintaining the confidentiality of your
              account login credentials and for all activities that occur under
              your account.
            </List.Item>
            <List.Item>
              You agree to use the Platform only for lawful purposes and in
              compliance with all applicable laws and regulations.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Orders and Payments</List.Header>
          <List>
            <List.Item>
              By placing an order through the Platform, you make an offer to
              purchase the selected items from the Vendor.
            </List.Item>
            <List.Item>
              The prices listed on the Platform for the food items are provided
              by the Vendors and is subject to change without notice.
            </List.Item>
            <List.Item>
              You agree to pay the total amount specified in the order,
              including the cost of the food items, applicable taxes, delivery
              fees, and any other charges disclosed at the time of the order.
            </List.Item>
            <List.Item>
              Payment for orders shall be made through the payment methods
              available on the Platform, such as credit cards, debit cards, or
              other electronic payment options.
            </List.Item>
            <List.Item>
              The Platform may retain your payment information in compliance
              with applicable laws and regulations.
            </List.Item>
          </List>
        </List.Item>
        {/* <List.Item>
          <List.Header>Delivery and Service</List.Header>
          <List>
            <List.Item>
              The Platform endeavors to provide accurate delivery time
              estimates, but such estimates are not guaranteed. Delays may occur
              due to factors beyond the Platform's control, including traffic
              conditions and weather.
            </List.Item>
            <List.Item>
              You acknowledge that the availability and quality of the food
              items are the sole responsibility of the Vendors. Any issues or
              complaints regarding the food should be directed to the respective
              Vendors.
            </List.Item>
            <List.Item>
              Some of our choppers are independent contractors, and their
              services are not under the control or responsibility of the
              Chops2u. Chops2u does not guarantee the actions, conduct, or
              performance of the delivery partners.
            </List.Item>
          </List>
        </List.Item> */}
        <List.Item>
          <List.Header>Delivery Service</List.Header>
          <List>
            <List.Item>
              Chops2u delivery riders, along with independent delivery partners
              ("Choppers"), facilitates the delivery of food orders from the
              Vendors to the designated delivery address.
            </List.Item>
            <List.Item>
              Chops2u strives to provide accurate delivery time estimates;
              however, please note that these estimates are subject to various
              factors, including traffic conditions and weather. Delays may
              occur, and Chops2u cannot guarantee precise delivery times.
            </List.Item>
            <List.Item>
              Chops2u reserves the right to assign a Chopper based on
              availability and other relevant factors to fulfill the delivery of
              your order.
            </List.Item>
            <List.Item>
              The Choppers are sometimes independent contractors who provide
              delivery services on behalf of Chops2u. The Platform does not
              guarantee the availability, actions, conduct, or performance of
              the independent Choppers. Chops2u shall not be liable for any
              loss, damage, or inconvenience caused by the independent delivery
              partners during the delivery process.
            </List.Item>
            <List.Item>
              If you have any concerns or complaints regarding the delivery
              service, please contact our customer support, and we will endeavor
              to address the issue promptly.
            </List.Item>
            <List.Item>
              The Choppers will make reasonable efforts to deliver the food
              order to the specified delivery address. If the Chopper is unable
              to deliver the order to the provided address due to reasons beyond
              their control, such as incorrect address information or
              unavailability of the recipient, the Chopper may attempt to
              contact you for further instructions. If the delivery is
              unsuccessful, the order may be canceled, and you may be charged
              for any applicable fees.
            </List.Item>
            <List.Item>
              In case of any missing or incorrect items in the delivered order,
              please contact our customer support within a reasonable timeframe
              from the delivery completion for assistance and resolution.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>User Reviews and Content</List.Header>
          <List>
            <List.Item>
              The Platform may allow users to post reviews, ratings, comments,
              and other content related to the Vendors and their food items. By
              submitting such content, you grant the Chops2u a non-exclusive,
              royalty-free, perpetual, and worldwide license to use, display,
              reproduce, modify, and distribute the content.
            </List.Item>
            <List.Item>
              You agree not to submit any content that is unlawful, defamatory,
              abusive, obscene, or violates the rights of third parties.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Intellectual Property</List.Header>
          <List>
            <List.Item>
              All intellectual property rights in the Platform, including its
              design, logos, trademarks, and content, are owned by or licensed
              to the Platform. You are not granted any rights or licenses to use
              such intellectual property except as expressly authorized by these
              Terms.
            </List.Item>
          </List>
        </List.Item>
      </List>
    </Container>
  );
};

export default TermsAndConditions;
