import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Shipment from "../models/shipment";

export default class ShipmentStore {
  shimentsRegistry = new Map<string, Shipment>();
  pulledShipment: null | Shipment = null;

  loading = false;
  loadingShipment = false;
  submitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get ShipmentsByDate() {
    return Array.from(this.shimentsRegistry.values());
  }

  get Shipment() {
    return this.pulledShipment;
  }

  loadShipments = async () => {
    this.loading = true;
    try {
      let shipments = await agent.Shipments.list();
      runInAction(() => {
        this.shimentsRegistry.clear();
        shipments.forEach((shipment) => {
          this.shimentsRegistry.set(shipment.id!, shipment);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadShipment = async (id: string) => {
    try {
      this.loadingShipment = true;
      let shipment = await agent.Shipments.details(id);
      runInAction(() => {
        if (shipment) this.pulledShipment = shipment;
        this.loadingShipment = false;
      })
    } catch (error) {
      runInAction(() => this.loadingShipment = false);
      console.log(error);
    }
  }

  loadOrderShipment = async (orderId: string) => {
    try {
      this.loadingShipment = true;
      let shipment = await agent.Shipments.orderShipment(orderId);
      runInAction(() => {
        // if (shipment) this.pulledShipment = shipment;
        this.loadingShipment = false;
      })
      return shipment;
    } catch (error) {
      runInAction(() => this.loadingShipment = false)
      console.log(error);
    }
  }

  cancel = async (id: string, comment: string) => {
    try {
      this.submitting = true;
      await agent.Shipments.cancent(id, comment);
      this.loadShipment(id);
      this.submitting = false;
    } catch (error) {
      runInAction(() => this.submitting = false);
      console.log(error);
    }
  }

  delete = async (id: string, comment: string) => {
    try {
      this.submitting = true;
      await agent.Shipments.delete(id, comment);
      runInAction(() => {
        this.pulledShipment = null;
        this.submitting = false;
      })
    } catch (error) {
      runInAction(() => this.submitting = false);
      console.log(error);
    }
  }
}
