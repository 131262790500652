import { observer } from "mobx-react-lite";
import { Grid, Segment } from "semantic-ui-react";
import { Offers } from "../../../../../app/common/options/offers";
import Vendor from "../../../../../app/models/vendor";
import VendorImage from "../../common/VendorImage";
import VendorBannerDashboard from "./VendorBannerDashboard";
import VendorBannerOffer from "./VendorBannerOffer";

interface Props {
  vendor: Vendor;
}

export default observer(function VendorBanner({ vendor }: Props) {
  return (
    <Segment
      vertical
      style={{
        padding: "20px",
        marginBottom: "10px",
        backgroundColor: "#3d3850",
        color: "#ffffff",
      }}
      fluid
    >
      <Grid fluid stackable>
        <Grid.Column computer={4} tablet={5} mobile={16}>
          <VendorImage vendor={vendor} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={7} mobile={16}>
          <VendorBannerDashboard vendor={vendor} />
        </Grid.Column>
        <Grid.Column computer={4} tablet={4} mobile={16}>
          <VendorBannerOffer userOffers={Offers} />
        </Grid.Column>
      </Grid>
    </Segment>
  );
});
