import React from "react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const ShipmentsEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="No Shipment Record Found!"
      subtitle="Use this section to see order shipments and perform actions on shipment."
      color="olive"
    />
  );
};

export default ShipmentsEmptyPanel;
