import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Menu from "../../../../../app/models/menu";
import { useStore } from "../../../../../app/store/store";

interface Props {
  menu: Menu;
  setEditMode: (state: boolean) => void;
}

const DeleteMenuForm = (props: Props) => {
  const { modalStore, menuStore } = useStore();

  // const sleep = (delay: number) => {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, delay);
  //   });
  // };

  function handleDeleteCommand() {
    menuStore.delete(props.menu.id!).then(() => {
      props.setEditMode(false);
      modalStore.closeModal();
    });
  }

  return (
    <>
      <Modal.Header>
        <Header as="h3" color="olive" content="Delete Menu" />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following menu?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {props.menu.title}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          labelPosition="left"
          floated="left"
          negative
          content="No"
          onClick={() => modalStore.closeModal()}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          positive
          loading={menuStore.deleting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteMenuForm);
