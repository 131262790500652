import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button, Header, Segment } from "semantic-ui-react";
import Menu from "../../../../app/models/menu";
import { useStore } from "../../../../app/store/store";
import DeleteMenuForm from "./forms/DeleteMenuForm";
import MenuForm from "./forms/MenuForm";

interface Props {
  data: Menu[];
  loadingMenu: boolean;
}

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return (
    <input
      type="checkbox"
      checked={value}
      onChange={() => console.log(tableData)}
    />
  );
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const MenuList = ({ data, loadingMenu }: Props) => {
  const {
    modalStore: { openModal },
  } = useStore();
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "45px", maxWidth: "45px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "45px", maxWidth: "45px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "displayOrder",
      label: "Display Order",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "published",
      label: "Published",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "2em",
                justifyContent: "center",
              }}
            >
              <Button
                icon="edit"
                basic
                color="olive"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />
              <Button
                icon="delete"
                basic
                color="orange"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 40,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  const handleEditButtonClick = (tableMeta: any) => {
    const { rowIndex } = tableMeta;
    // console.log(tableMeta);
    if (data.length > 0 && data.length >= rowIndex) {
      const menuId = data[rowIndex].id;
      openModal(<MenuForm menuId={menuId} />);
    }
  };

  const handleDeleteButtonClick = (tableMeta: any) => {
    const { rowIndex } = tableMeta;
    // console.log(tableMeta);
    if (data.length >= rowIndex) {
      openModal(<DeleteMenuForm menu={data[rowIndex]} />);
    }
  };

  return (
    <div style={{ width: "97%", margin: "auto" }}>
      <Segment loading={loadingMenu} vertical>
        <MuiThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title=""
            data={data}
            columns={columns}
            options={{
              filter: "false",
              download: "false",
              print: "false",
              viewColumns: "false",
              responsive: "standard",
              selectableRows: "none",
              rowsPerPage: 10,
              rowsPerPageOptions: [10, 15, 20],
              count: data.length,
              page: 0,
              // onRowsDelete: handleRowsDelete,
              // onTableChange: handleTableChange,
              setTableProps: () => {
                return { size: "small" };
              },
            }}
          />
        </MuiThemeProvider>
      </Segment>
    </div>
  );
};

export default observer(MenuList);
