import PendingPayoutEmptyPanel from "./PendingPayoutEmptyPanel";
import PendingPayoutTopMenu from "./PendingPayoutTopMenu";

const PendingPayoutLayout = () => {
  return (
    <div>
      <div>
        <PendingPayoutTopMenu />
      </div>
      <section>
        <PendingPayoutEmptyPanel />
      </section>
    </div>
  );
};

export default PendingPayoutLayout;
