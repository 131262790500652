import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Container } from "semantic-ui-react";
import FoodForm from "../forms/FoodForm";
import FoodList from "../tables/FoodList";

const VendorFoods = () => {
  const [editMode, setEditMode] = useState(false);
  const [foodId, setFoodId] = useState<string | undefined>(undefined);
  return (
    <Container>
      {editMode ? (
        <FoodForm
          foodId={foodId}
          setEditMode={setEditMode}
          setFoodId={setFoodId}
        />
      ) : (
        <FoodList setFoodId={setFoodId} setEditMode={setEditMode} />
      )}
    </Container>
  );
};

export default observer(VendorFoods);
