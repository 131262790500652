import React from "react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const OrdersEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="No Orders Found"
      subtitle="Find and sort orders. Here you can perform orders actions"
      color="olive"
    />
  );
};

export default OrdersEmptyPanel;
