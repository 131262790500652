import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Menu from "../../../../../app/models/menu";
import { useStore } from "../../../../../app/store/store";

interface Props {
  menu: Menu;
}

const DeleteMenuForm = ({ menu }: Props) => {
  const {
    modalStore: { closeModal },
    restaurantStore: { deleteMenu, deletingFood: deleting },
  } = useStore();

  const handleDeletMenu = () => {
    deleteMenu(menu.id!)
      .then(() => closeModal())
      .catch((error) => closeModal());
  };

  return (
    <>
      <Modal.Header>
        <Header
          textAlign="center"
          color="grey"
          as="h4"
          content={"Delete Menu".toUpperCase()}
          style={{ padding: "10px 0 5px 0" }}
        />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following menu?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {menu.title}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          labelPosition="left"
          floated="left"
          type="button"
          negative
          content="No"
          onClick={() => closeModal()}
          style={{ width: "130px" }}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          loading={deleting}
          positive
          content="Yes"
          type="button"
          onClick={handleDeletMenu}
          style={{ width: "130px" }}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteMenuForm);
