import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { observer } from "mobx-react-lite";

interface Props {
  setCropper: (cropper: Cropper) => void;
  imagePreview: string;
  initialAspectRatio: number;
  aspectRation: number;
}

const PhotoCropper = ({
  setCropper,
  imagePreview,
  initialAspectRatio,
  aspectRation,
}: Props) => {
  return (
    <Cropper
      src={imagePreview}
      style={{ height: 200, width: "100%" }}
      initialAspectRatio={initialAspectRatio}
      aspectRatio={aspectRation}
      preview=".img-preview"
      guides={false}
      viewMode={1}
      autoCropArea={1}
      background={false}
      onInitialized={(cropper) => setCropper(cropper)}
    />
  );
};

export default observer(PhotoCropper);
