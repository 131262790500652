import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import Chops2uTextInput from "../../../../app/common/forms/Chops2uTextInput";
import { ChangePasswordFormValues } from "../../../../app/models/change-password";
import { useStore } from "../../../../app/store/store";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const {
    userStore: { user, changePassword, submitting },
  } = useStore();

  const InitValues: ChangePasswordFormValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: "",
  };

  const validate = Yup.object({
    currentPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    confirmNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  });

  const handleFormSubmit = (
    values: ChangePasswordFormValues,
    { setErrors, resetForm }: FormikHelpers<ChangePasswordFormValues>
  ) => {
    // console.log(values)
    changePassword({ ...values, email: user?.email })
      .then(() => {
        toast.success("Password changed successfully.");
        resetForm();
      })
      .catch((error) => setErrors({ error: error.response.data }));
  };

  return (
    <>
      <Header as="h2" color="olive">
        <Header.Content>Change Password</Header.Content>
        <Header.Subheader>Account access and security</Header.Subheader>
      </Header>
      <Formik
        initialValues={InitValues}
        validationSchema={validate}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
          <Form onSubmit={handleSubmit} className="ui form">
            <Segment vertical style={{ width: "30em" }}>
              <Chops2uTextInput
                name="currentPassword"
                type="password"
                label="Current Password:"
                placeholder="Enter your current password"
              />

              <Chops2uTextInput
                name="newPassword"
                type="password"
                label="New Password:"
                placeholder="Enter new password"
              />

              <Chops2uTextInput
                name="confirmNewPassword"
                type="password"
                label="Confirm Password:"
                placeholder="Confirm password"
              />

              <ErrorMessage
                name="error"
                render={() => (
                  <Label
                    style={{ marginBottom: "15px" }}
                    basic
                    color="red"
                    content={errors.error}
                  />
                )}
              />

              <div className="field">
                <Button
                  loading={isSubmitting && submitting}
                  disabled={!isValid && dirty}
                  color="olive"
                  content="Reset Password"
                  type="submit"
                />
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default observer(ChangePassword);
