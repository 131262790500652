import { Container, Grid, Header, ItemHeader, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

/** Login Page **/
export const FixedHeightGrid = styled(Grid)`
  height: 100vh;
`;

/** Common **/
export const PageWrapper = styled(Container)`
  width: 100% !important;
  padding: 1em 2em 1.5em 2em;
  overflow-y: auto;
`;

export const TopMenu = styled(Menu)`
  border-radius: 0;
  z-index: 1000;
`;

export const MenuContainer = styled(Container)`
  width: 95%;
  height: 60px;
`;

/** Admin Sibe Navigator */
export const SideNav = styled.div`
  display: flex;
  flex-direction: column;
  background: #1b1c1d;
`;

export const SideNavLogo = styled.div`
  flex: 0 0 90px;
  padding: 30px 10px 10px 20px;
  background-color: #333;
  border-bottom: 1.5px solid #b5cc18;
`;

export const SideNavMenu = styled.div`
  flex: 0 0 calc(100vh - 175px);
  margin: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
`;

export const SideNavFooter = styled.div`
  flex: 0 0 85px;
  padding-top: 10px;
  padding-left: 1.5em;
  padding-bottom: 3em;
  height: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  border-top: 1.5px solid #b5cc18;
  background-color: #333;
`;

export const MenuItemHeader = styled(ItemHeader)`
  color: white;
  padding-left: 10px;
  font-size: 0.8em;
  font-weight: bold;
`;

/** Admin Top Menu */
// export const TopMenu = styled(Menu)`
//   border-radius: 0;
//   margin-bottom: 0;
// `;

export const MenuItemsContainer = styled(Container)`
  width: 95%;
  height: 60px;
  display: flex;
  justify-content: space-between;
`;

export const MenuHeader = styled(Header)`
  font-weight: 200;
`;
