import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { useStore } from "../../../../../app/store/store";
import { observer } from "mobx-react-lite";

const InviteStaffForm = () => {
  const {
    modalStore: { closeModal },
  } = useStore();
  const validate = Yup.object({
    firstName: Yup.string().required("'First Name' is a required field"),
    lastName: Yup.string().required("'Last Name' is a required field"),
    email: Yup.string().required("Email is a required field").email(),
    phoneNumber: Yup.string()
      .required("'Mobile Number' is a required field")
      .phone(),
    roleId: Yup.string().required(),
    password: Yup.string().required("Password is a required field"),
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleId: "",
    password: "",
    error: "",
  });

  useEffect(() => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      roleId: "",
      password: "",
      error: "",
    });
  }, [setFormData]);

  return (
    <Grid>
      <Grid.Column
        style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validate}
          onSubmit={(values) => console.log(values)}
        >
          {({ handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit} className="ui form">
              <Segment color="green" attached="top">
                <div>
                  <Header
                    textAlign="center"
                    size="small"
                    disabled
                    content={"Invite a Staff".toUpperCase()}
                    style={{ padding: "10px 0 5px 0" }}
                  />
                </div>
              </Segment>
              <Segment attached clearing>
                {/* First Name Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      First Name
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uTextInput
                      name="firstName"
                      placeholder="Given Name"
                    />
                  </div>
                </div>

                {/* Last Name Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uTextInput
                      name="lastName"
                      placeholder="Family Name"
                    />
                  </div>
                </div>

                {/* Email Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Email
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uTextInput
                      name="email"
                      placeholder="username@domainname.com"
                    />
                  </div>
                </div>

                {/* Phone Number Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Mobile Number
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uTextInput
                      name="phoneNumber"
                      placeholder="08012345678"
                    />
                  </div>
                </div>

                {/* Role Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Role
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uSelectInput
                      name="roleId"
                      options={[]}
                      placeholder="Select a Role"
                    />
                  </div>
                </div>

                {/* Password Field */}
                <div className="fields">
                  <div className="four wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Password
                    </label>
                  </div>
                  <div className="twelve wide field">
                    <Chops2uTextInput
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
              </Segment>
              <Segment attached="bottom" clearing secondary>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: "1em",
                  }}
                >
                  <Button
                    basic
                    color="orange"
                    // disabled={(!isValid && dirty) || isSubmitting}
                    // loading={isSubmitting}
                    type="button"
                    content="Cancel"
                    onClick={closeModal}
                    style={{ width: "130px" }}
                  />
                  <Button
                    positive
                    // disabled={(!isValid && dirty) || isSubmitting}
                    // loading={isSubmitting}
                    type="submit"
                    floated="right"
                    content="Send an Invite"
                    style={{ width: "130px" }}
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(InviteStaffForm);
