import { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import BalancePanel from "./BalancePanel";

interface Props {
  setPageTitle: (title: string) => void;
}

const BalancePageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Balance");
  }, [setPageTitle]);

  return (
    <div style={{ overflowX: "hidden"}}>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <Grid.Column style={{ width: "300px" }}>
          <BalancePanel />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default BalancePageLayout;
