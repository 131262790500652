import { useEffect, useState } from "react";
import useQuery from "../../../app/common/util/hooks";
import { useStore } from "../../../app/store/store"
import agent from "../../../app/api/agent";
import { toast } from "react-toastify";
import { Button, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import VendorLoginForm from "../login/VendorLoginForm";

const VendorConfirmEmail = () => {
    const { modalStore } = useStore();
    const email = useQuery().get("email") as string;
    const token = useQuery().get("token") as string;

    const Status = {
        Verifying: "Verifying",
        Success: "Success",
        Failed: "Failed"
    }

  const [status, setStatus] = useState(Status.Verifying);

  useEffect(() => {
    if (email && token) {
      agent.Account.verifyEmail(token, email)
        .then(() => setStatus(Status.Success))
        .catch(() => setStatus(Status.Failed));
    }
  }, [Status.Success, Status.Failed, token, email])
  
  
  const handleConfirmEmailResend = () => {
    agent.Account.resendVendorEmailConfirm(email)
      .then(() => {
        toast.success("Verification email resent - please check your email.");
      })
      .catch((error) => console.log(error));
  }

  const getBody = () => {
    switch (status) {
      case Status.Verifying: return (
        <p>Verifying...</p>
      );
      case Status.Success: return (
        <>
          <p>Email has been verified. You can now login.</p>
          <Button primary onClick={() => modalStore.openModal(<VendorLoginForm />)} content="Login" />
        </>
      );
      case Status.Failed: return (
        <>
          <p>
            Email verification failed. You can try resending the verification
            link to your email.
          </p>
          <Button primary onClick={handleConfirmEmailResend} content="Resend Email" />
        </>
      );
    }
  }
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 550 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Segment placeholder textAlign="center">
          <Header icon color="olive">
            <Icon name="envelope" />
            Email Verification
          </Header>
          <Segment.Inline>{getBody()}</Segment.Inline>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
export default VendorConfirmEmail