import SectionFooter from "../home/SectionFooter";
import PageMenu from "./PageMenu";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
  return (
    <div>
      <PageMenu />
      <Outlet />
      <SectionFooter />
    </div>
  );
};

export default PageLayout;
