import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Menu from "../models/menu";

export default class MenuStore {
  menuRegistry = new Map<string, Menu>();
  loading = false;
  submitting = false;
  deleting = false;

  constructor() {
    makeAutoObservable(this);
  }

  private getMenu = (id: string) => {
    return this.menuRegistry.get(id);
  };

  //
  // Get an array of all menus
  get Menus() {
    return Array.from(this.menuRegistry.values());
  }

  //
  // Get an array of all menu title
  get MenuTitleList() {
    return Array.from(this.menuRegistry.values())
      .map((menu) => menu.title);
  }

  //
  // Get an array of menu as input of selectoption
  get MenuOptions() {
    return Array.from(this.menuRegistry.values())
      .map((menu) => ({ key: menu.id!, text: menu.title, value: menu.id! }));
  }

  //
  // load all vendor menus 
  loadMenus = async (vendorId: string) => {
    this.loading = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ vendorId }));
    try {
      let menus = await agent.Menus.list(params);
      runInAction(() => {
        this.menuRegistry.clear();
        menus.forEach((menu) => {
          this.menuRegistry.set(menu.id!, menu);
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  //
  // load all vendor menus for front page display
  loadFrontPageMenus = async (vendorId: string) => {
    this.loading = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ vendorId }));
    try {
      let menus = await agent.Menus.listFrontPage(params);
      runInAction(() => {
        this.menuRegistry.clear();
        menus.forEach((menu) => {
          this.menuRegistry.set(menu.id!, menu);
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  //
  // load menu details
  loadMenu = async (id: string) => {
    this.loading = true;
    let menu = this.getMenu(id);
    if (menu) {
      return menu;
    } else {
      try {
        menu = await agent.Menus.details(id);
        if (menu) {
          runInAction(() => {
            this.menuRegistry.set(menu!.id!, menu!);
            this.loading = false;
          });
        }
        return menu;
      } catch (error) {
        console.log(error);
        runInAction(() => (this.loading = false));
      }
    }
  };

  create = async (menu: Menu) => {
    this.submitting = true;
    try {
      let result = await agent.Menus.create(menu);
      if (result) {
        runInAction(() => {
          this.menuRegistry.set(result.id!, result);
        });
      }
      runInAction(() => (this.submitting = false));
      return result;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  update = async (id: string, menu: Menu) => {
    this.submitting = true;
    try {
      let updatedMenu = await agent.Menus.update(id, menu);
      runInAction(() => {
        if (updatedMenu) {
          this.menuRegistry.set(updatedMenu.id!, updatedMenu);
        }
        this.submitting = false;
      });
      return updatedMenu;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  delete = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Menus.delete(id);
      runInAction(() => {
        this.menuRegistry.delete(id);
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };

  deleteMany = async (ids: string[]) => {
    this.deleting = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ id: ids }));
    try {
      await agent.Menus.deleteMany(params);
      runInAction(() => {
        ids.forEach((id) => this.menuRegistry.delete(id));
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };
}
