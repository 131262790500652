import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import ChopperList from "./ChopperList";
import ChoppersEmptyPanel from "./ChoppersEmptyPanel";
import ChoppersTopMenu from "./ChoppersTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const ChoppersLayoutPage = ({ setPageTitle }: Props) => {
  const {
    chopperStore: { loadChoppers, choppers },
  } = useStore();

  useEffect(() => {
    setPageTitle("Choppers");
    loadChoppers();
  }, [setPageTitle, loadChoppers]);

  return (
    <section>
      <div>
        <ChoppersTopMenu />
      </div>
      <section>
        {choppers && choppers.length > 0 ? (
          <ChopperList data={choppers} />
        ) : (
          <ChoppersEmptyPanel />
        )}
      </section>
    </section>
  );
};

export default observer(ChoppersLayoutPage);
