import { useState } from "react";
import VendorAccountDetailsPage from "../detailedPages/VendorAccountDetailsPage";

const VendorAccount = () => {
  const [editMode, setEditMode] = useState(false);
  return (
    <VendorAccountDetailsPage setEditMode={setEditMode} editMode={editMode} />
  );
};

export default VendorAccount;
