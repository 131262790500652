import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";

interface Props {
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  optional?: boolean;
  readonly?: boolean;
  hints?: string;
}

const Chops2uTextInput = (props: Props) => {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      disabled={props.disabled}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {props.label && (
        <label style={{ textAlign: "left" }}>{props.label}</label>
      )}
      <input {...field} {...props} readOnly={props.readonly} />
      {props.hints && (
        <span style={{ fontSize: "10px", color: "GrayText", marginTop: "5px" }}>
          {props.hints}
        </span>
      )}
      {meta.touched && meta.error ? (
        <Label pointing style={{ float: "left" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export default Chops2uTextInput;
