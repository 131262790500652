import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import MenuForm from "../forms/MenuForm";
import MenuList from "../tables/MenuList";

const VendorMenus = () => {
  const {
    vendorStore: { loadVendor },
    commonStore: { setAdminPageTitle },
  } = useStore();
  const [editMode, setEditMode] = useState(false);
  const [menuId, setMenuId] = useState<string | undefined>(undefined);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadVendor(id).then((vendor) => {
        setAdminPageTitle("Vendor: " + vendor!.title);
      });
    }
  }, [id, loadVendor, setAdminPageTitle]);

  return (
    <Container>
      {editMode ? (
        <Container text>
          <MenuForm menuId={menuId} setEditMode={setEditMode} />
        </Container>
      ) : (
        <MenuList setMenuId={setMenuId} setEditMode={setEditMode} />
      )}
    </Container>
  );
};

export default observer(VendorMenus);
