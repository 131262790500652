import { observer } from "mobx-react-lite";
import { Icon, Image, List } from "semantic-ui-react";
import { useState } from "react";
import { useStore } from "../../../../app/store/store";
import { toCurrency } from "../../../../app/common/util/util";

import { ShoppingCartItem } from "../../../../app/models/shopping-cart";
import { Customise } from "../../cart/cartStyles";
import CustomiseOrder from "../../vendors/details/customise/CustomiseOrder";
import {
  CartButton,
  CartQuantity,
} from "../../cart/components/componentsStyles";
import {
  ButtonWrapperCircular,
  FoodPrice,
  FoodTitle,
  ListItem,
  ListItemDescription,
  TitleWrapper,
  Wrapper,
} from "./cart-styles";

interface Props {
  item: ShoppingCartItem;
}

const CheckoutCartItem = ({ item }: Props) => {
  const {
    shoppingCartStore: {
      addToCart,
      addingToCart,
      removingFromCart,
      removeFromCart,
    },
    modalStore: { openModal },
  } = useStore();

  const [target, setTarget] = useState("");

  const handleAddToCartButton = async (
    id: number,
    foodId: string,
    associatedItems: ShoppingCartItem[]
  ) => {
    const foodIds = associatedItems.map((item) => item.foodId);
    setTarget(`${id} plus`);
    addToCart(foodId, foodIds)
      .then(() => setTarget(""))
      .catch((error) => {
        setTarget("");
        console.log(error);
      });
  };

  const handleRemoveFromCartButton = async (id: number) => {
    setTarget(`${id} minus`);
    removeFromCart(id)
      .then(() => setTarget(""))
      .catch((error) => {
        setTarget("");
        console.log(error);
      });
  };

  return (
    <ListItem key={item.id}>
      <Image size="mini" src={item.image || "/images/food-placeholder.jpg"} />
      <List.Content>
        <ListItemDescription>
          <Wrapper>
            <TitleWrapper>
              <FoodTitle title={item.title}>{item.title}</FoodTitle>
              {item.isGroupedParentFood && (
                <Customise
                  onClick={() =>
                    openModal(
                      <CustomiseOrder
                        foodId={item.foodId}
                        cartId={item.id.toString()}
                        associatedItems={item.associatedItems}
                      />,
                      "tiny"
                    )
                  }
                >
                  Customise <Icon name="angle right" size="small" />
                </Customise>
              )}
            </TitleWrapper>

            <div>
              <ButtonWrapperCircular>
                <CartButton
                  loading={removingFromCart && target === `${item.id} minus`}
                  onClick={() => handleRemoveFromCartButton(item.id)}
                  icon={
                    item.quantity === 1 ? (
                      <Icon
                        name="trash alternate outline"
                        size="small"
                        color="olive"
                      />
                    ) : (
                      <Icon name="minus" size="small" color="olive" />
                    )
                  }
                />
                <CartQuantity>{item.quantity}</CartQuantity>
                <CartButton
                  loading={addingToCart && target === `${item.id} plus`}
                  onClick={() =>
                    handleAddToCartButton(
                      item.id,
                      item.foodId,
                      item.associatedItems
                    )
                  }
                  icon={<Icon name="plus" size="small" color="olive" />}
                />
              </ButtonWrapperCircular>
            </div>
            <FoodPrice>{toCurrency(item.totalPrice)}</FoodPrice>
          </Wrapper>
        </ListItemDescription>
      </List.Content>
    </ListItem>
  );
};
export default observer(CheckoutCartItem);
