import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

interface Props extends Partial<ReactDatePickerProps> {
  optional?: boolean;
}

export default function Chops2uDateInput(props: Props) {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {props.title && (
        <label style={{ textAlign: "left" }}>{props.title}</label>
      )}
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || new Date()}
        onChange={(value) => helpers.setValue(value)}
      />
      {meta.touched && meta.error ? (
        <Label pointing style={{ float: "left" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
