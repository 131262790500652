import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../app/store/store";

interface Props {
  mobile?: boolean;
}

const SectionLocations = ({ mobile }: Props) => {
  const {
    locationStore: { loadLocations, LocationsByName: locationsByName },
  } = useStore();

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  return (
    <Segment vertical style={{ minHeight: "350px", border: "none" }}>
      <Container textAlign="center">
        <Header
          as={mobile ? "h3" : "h2"}
          style={{
            marginTop: mobile ? "0.3em" : "1em",
            marginBottom: mobile ? "15px" : "25px",
          }}
        >
          We deliver to
        </Header>
        <Grid columns={3} style={mobile ? { paddingLeft: "15px" } : {}}>
          {locationsByName &&
            locationsByName.length > 0 &&
            locationsByName.map((v, i) => (
              <Grid.Column
                textAlign={mobile ? "left" : "center"}
                key={i}
                style={{
                  color: "#777",
                  fontWeight: 600,
                  fontSize: mobile ? "0.7em" : "1em",
                  letterSpacing: mobile ? "1px" : "2px",
                  padding: "3px 0",
                }}
              >
                {v.name.toUpperCase()}
              </Grid.Column>
            ))}
        </Grid>
      </Container>
    </Segment>
  );
};

export default observer(SectionLocations);
