import {
  BikeFormValues,
  BikeMakeFormValues,
  BikeRegistrationFormValues,
} from "../models/bike";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Bike, { BikeMake } from "../models/bike";

export default class BikeStore {
  bikeRegistry = new Map<string, Bike>();
  makeRegistry = new Map<string, BikeMake>();
  loading = false;
  submitting = false;
  deleting = false;

  loadingMakes = false;
  submittingMake = false;
  deletingMake = false;

  constructor() {
    makeAutoObservable(this);
  }

  //#region bikeRegion
  get bikes() {
    return Array.from(this.bikeRegistry.values());
  }

  loadBikes = async () => {
    this.loading = true;
    try {
      let results = await agent.Bikes.list();
      runInAction(() => {
        this.bikeRegistry.clear();
        results.forEach((bike) => {
          this.bikeRegistry.set(bike.id!, bike);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadBike = async (id: string) => {
    this.loading = true;
    try {
      let result = await agent.Bikes.details(id);
      runInAction(() => {
        this.bikeRegistry.set(result.id!, result);
        this.loading = false;
      });
      return result;
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  create = async (values: BikeFormValues) => {
    this.submitting = true;
    try {
      let result = await agent.Bikes.create(values);
      runInAction(() => {
        this.bikeRegistry.set(result.id!, result);
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  update = async (id: string, values: BikeFormValues) => {
    this.submitting = true;
    try {
      let result = await agent.Bikes.update(id, values);
      runInAction(() => {
        this.bikeRegistry.set(result.id!, result);
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  delete = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Bikes.delete(id);
      runInAction(() => {
        this.bikeRegistry.delete(id);
        this.deleting = false;
      });
    } catch (error) {
      runInAction(() => (this.deleting = false));
      console.log(error);
    }
  };

  register = async (id: string, values: BikeRegistrationFormValues) => {
    this.submitting = true;
    try {
      let bike = await agent.Bikes.register(id, values);
      runInAction(() => {
        this.bikeRegistry.set(bike.id!, bike);
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  assignRider = async (bikeId: string, chopperId: string) => {
    this.submitting = true;
    try {
      let bike = await agent.Bikes.assignRider(bikeId, chopperId);
      runInAction(() => {
        if (bike) {
          this.bikeRegistry.set(bike.id!, bike);
        }
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };
  //#endregion

  //#region bikeMakeRegion
  get makes() {
    return Array.from(this.makeRegistry.values());
  }

  loadMakes = async () => {
    this.loadingMakes = true;
    try {
      let list = await agent.Bikes.listMake();
      runInAction(() => {
        this.makeRegistry.clear();
        list.forEach((make) => {
          make = { ...make, text: make.name, value: make.id! };
          this.makeRegistry.set(make.id!, make);
        });
        this.loadingMakes = false;
      });
    } catch (error) {
      runInAction(() => (this.loadingMakes = false));
      console.log(error);
    }
  };

  loadMake = async (id: string) => {
    this.loadingMakes = true;
    try {
      let result = await agent.Bikes.detailsMake(id);
      runInAction(() => {
        this.makeRegistry.set(result.id!, result);
        this.loadingMakes = false;
      });
      return result;
    } catch (error) {
      runInAction(() => (this.loadingMakes = false));
      console.log(error);
    }
  };

  createMake = async (values: BikeMakeFormValues) => {
    this.submittingMake = true;
    try {
      let result = await agent.Bikes.createMake(values);
      runInAction(() => {
        this.makeRegistry.set(result.id!, result);
        this.submittingMake = false;
      });
    } catch (error) {
      runInAction(() => (this.submittingMake = false));
      throw error;
    }
  };

  deleteMake = async (id: string) => {
    this.deletingMake = true;
    try {
      await agent.Bikes.deleteMake(id);
      runInAction(() => {
        this.makeRegistry.delete(id);
        this.deletingMake = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deletingMake = false));
    }
  };
  // #endregion
}
