import { Formik, Form, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Header,
  Segment,
  Button,
  Message,
  Image
} from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uTextInputIcon from "../../../app/common/forms/Chops2uTextInputIcon";
import { useStore } from "../../../app/store/store";
import ValidationErrors from "../../frontpage/errors/ValidationErrors";

const VendorLoginPage = () => {
  const {
    userStore: { login, logining, IsLogedIn },
  } = useStore();

  const validate = Yup.object({
    email: Yup.string().trim().email().required(),
    password: Yup.string().trim().required()
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (IsLogedIn) navigate("/store", { replace: true });
  }, [IsLogedIn, navigate]);

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Formik
          validationSchema={validate}
          onSubmit={(values, { setErrors }) => {
            login(values)
              .then(() => navigate("/store", { replace: true }))
              .catch((error) => {
                console.log(error);
                setErrors({ error: error.response.data });
              });
          }}
          initialValues={{ email: "", password: "", error: null }}
        >
          {({ handleSubmit, isSubmitting, dirty, errors, isValid }) => (
            <Form className="ui form error" onSubmit={handleSubmit}>
              <Segment stacked style={{ marginTop: "1em" }}>
                <Header
                  size="tiny"
                  disabled
                  content={"Store login".toUpperCase()}
                />
                <Chops2uTextInputIcon
                  name="email"
                  icon="mail"
                  placeholder="info@chops2u.com"
                />

                <Chops2uTextInputIcon
                  name="password"
                  icon="lock"
                  type="password"
                  placeholder="Password"
                />

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>

                <div className="field">
                  <Button
                    loading={isSubmitting && logining}
                    color="olive"
                    fluid
                    type="submit"
                    size="large"
                  >
                    Login
                  </Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
        <Message>
          New to us? <Link to="/store/register-vendor">Sign Up</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default observer(VendorLoginPage);
