import React from "react";
import Chops2uEmptyRecord from "../../../app/common/components/Chops2uEmptyRecord";

const OrderEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="Order Has Not Been Assign Yet!"
      subtitle="Please see orders assign to you for delivery here."
      color="olive"
    />
  );
};

export default OrderEmptyPanel;
