import { Button, Container, Menu } from "semantic-ui-react";

const PayoutTopMenu = () => {
  return (
    <Menu
      attached="top"
      borderless
      style={{ borderRadius: "0", height: "65px" }}
    >
      <Container style={{ width: "95%" }}>
        <Menu.Item position="right">
          <Button positive content="Request Payout" />
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default PayoutTopMenu;
