import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import DeliveriesEmptyPanel from "./DeliveriesEmptyPanel";
import DeliveryList from "./DeliveryList";

interface Props {
  setPageTitle: (title: string) => void;
}

const DeliveriesPageLayout = ({ setPageTitle }: Props) => {
  const {
    restaurantStore: { restaurant, loadShipments, ShipmentsByDate },
  } = useStore();

  useEffect(() => {
    setPageTitle("Deliveries");
  }, [setPageTitle]);

  useEffect(() => {
    if (restaurant?.id) {
      loadShipments(restaurant.id);
    }
  }, [restaurant?.id, loadShipments]);

  return (
    <div style={{ overflowX: "hidden" }}>
      {ShipmentsByDate?.length > 0 ? (
        <DeliveryList data={ShipmentsByDate} />
      ) : (
        <DeliveriesEmptyPanel />
      )}
    </div>
  );
};

export default observer(DeliveriesPageLayout);
