import { Container, Header, Segment } from "semantic-ui-react";
import Chops2uFieldDisplay from "../../../../../app/common/components/Chops2uFieldDisplay";

const VendorOwnerDetailsPage = () => {
  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Header as="h2" attached="top" color="olive">
        Vendor Onwner
      </Header>
      <Segment attached className="display" clearing>
        <Chops2uFieldDisplay fieldTitle="Full Name" fieldContent="" />

        <Chops2uFieldDisplay fieldTitle="Email" fieldContent="" />

        <Chops2uFieldDisplay fieldTitle="Phone Number" fieldContent="" />
      </Segment>
    </Container>
  );
};

export default VendorOwnerDetailsPage;
