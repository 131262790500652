import { observer } from "mobx-react-lite";
import { Button, Segment, Header } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import Location from "../../../../../app/models/location";

interface Props {
  location: Location;
}

const LocationDeleteForm = (props: Props) => {
  const { modalStore, locationStore } = useStore();

  // const sleep = (delay: number) => {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, delay);
  //   });
  // };

  const handleDeleteCommand = () => {
    locationStore
      .delete(props.location.id!)
      .then(() => modalStore.closeModal());
  };

  return (
    <Segment.Group>
      <Segment style={{ padding: "1.2em 1em" }}>
        <Header as="h3" color="olive" content="Delete Location" />
      </Segment>
      <Segment style={{ padding: "1.5em" }}>
        <p>Are you sure you want to delete the following record?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {props.location.name}
        </Header>
      </Segment>
      <Segment
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
          padding: "1em",
        }}
      >
        <Button
          icon="cancel"
          labelPosition="left"
          negative
          content="No"
          onClick={modalStore.closeModal}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          positive
          loading={locationStore.submitting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(LocationDeleteForm);
