import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Divider, Header, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { ProfileFormValues } from "../../../../app/models/profile";
import { useEffect, useState } from "react";
import Chops2uTextInput from "../../../../app/common/forms/Chops2uTextInput";
import Chops2uTextAreaInput from "../../../../app/common/forms/Chops2uTextAreaInput";
import { useStore } from "../../../../app/store/store";
import { toast } from "react-toastify";

const ProfilePage = () => {
  const {
    profileStore: { loadProfile, profile, loadingProfile, update },
    userStore: { user },
  } = useStore();

  const validate = Yup.object({
    fullName: Yup.string().required(),
    bio: Yup.string().notRequired(),
  });

  const [InitValues, setInitValues] = useState<ProfileFormValues>({
    fullName: "",
    email: "",
    phoneNumber: "",
    bio: "",
    error: "",
  });

  useEffect(() => {
    if (user) {
      loadProfile(user.email)
        .then(() =>
          setInitValues({
            fullName: profile?.fullName!,
            email: profile?.email!,
            phoneNumber: profile?.phoneNumber!,
            bio: profile?.bio,
            error: "",
          })
        )
        .catch((error) => console.log(error));
    }
  }, [user, loadProfile, setInitValues, profile]);

  const handleFormSubmit = (
    values: ProfileFormValues,
    { setErrors }: FormikHelpers<ProfileFormValues>
  ) => {
    if (profile) {
      update(values)
        .then(() => toast.success("Profile Successfully Updated"))
        .catch((error) => setErrors({ error: error.response.date }));
    }
  };

  return (
    <>
      <Header as="h2" color="olive">
        <Header.Content>Public Profile</Header.Content>
        <Header.Subheader>Add information about yourself</Header.Subheader>
      </Header>

      <Formik
        initialValues={InitValues}
        validationSchema={validate}
        onSubmit={(values) => console.log(values)}
        enableReinitialize
      >
        {({ handleSubmit, dirty, isValid, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit} className="ui form">
            <Segment vertical>
              <Chops2uTextInput
                name="email"
                disabled={true}
                label="Email:"
                placeholder="Your email address"
              />

              <ErrorMessage
                name="error"
                render={() => (
                  <Label
                    style={{ marginBottom: "15px" }}
                    basic
                    color="red"
                    content={errors.error}
                  />
                )}
              />
            </Segment>
          </Form>
        )}
      </Formik>
      <Divider />
      <Formik
        initialValues={InitValues}
        validationSchema={validate}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ handleSubmit, dirty, isValid, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit} className="ui form">
            <Segment vertical>
              <Chops2uTextInput
                name="fullName"
                label="Full Name:"
                placeholder="Enter your full name"
              />

              <Chops2uTextInput
                name="phoneNumber"
                label="Telephone:"
                placeholder="Your phone number"
              />

              <Chops2uTextAreaInput
                name="bio"
                label="Bio:"
                rows={3}
                placeholder="Provide a brief description of yourself"
              />

              <ErrorMessage
                name="error"
                render={() => (
                  <Label
                    style={{ marginBottom: "15px" }}
                    basic
                    color="red"
                    content={errors.error}
                  />
                )}
              />

              <div className="field">
                <Button
                  loading={isSubmitting && loadingProfile}
                  disabled={!isValid && dirty}
                  color="olive"
                  content="Save Changes"
                  type="submit"
                />
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default observer(ProfilePage);
