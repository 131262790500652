import { Formik, Form, ErrorMessage } from "formik";
import {
  Grid,
  Header,
  Image,
  Button,
  Message,
  Segment,
  Modal,
  Container,
} from "semantic-ui-react";
import * as Yup from "yup";
import "yup-phone";
import { Link } from "react-router-dom";
import Chops2uTextInputIcon from "../../../../app/common/forms/Chops2uTextInputIcon";
import ValidationErrors from "../../../frontpage/errors/ValidationErrors";

const RegistrationPage = () => {
  const validationSchema = Yup.object({
    fullName: Yup.string().required("'First Name' is a required field."),
    email: Yup.string().trim().required().email(),
    phoneNumber: Yup.string().trim().required().phone(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <Modal.Content>
      <Segment vertical>
        <Container>
          <Grid
            textAlign="center"
            style={{ height: "100vh" }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 500 }}>
              <Image
                src="/images/logo.png"
                alt="chops2u"
                size="small"
                style={{
                  margin: "auto",
                  paddingBottom: "10px",
                }}
              />
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  error: null,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => console.log(values)}
              >
                {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                  <Form
                    className="ui form error"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <Segment
                      raised
                      stacked
                      style={{ marginTop: "1em" }}
                      clearing
                    >
                      <Header
                        size="tiny"
                        disabled
                        content={"Create an Account".toUpperCase()}
                      />

                      <Chops2uTextInputIcon
                        name="fullName"
                        icon="user"
                        placeholder="Full Name"
                      />

                      <Chops2uTextInputIcon
                        name="phoneNumber"
                        icon="phone"
                        placeholder="Telephone Number"
                      />

                      <Chops2uTextInputIcon
                        name="email"
                        icon="mail"
                        placeholder="E-mail"
                      />

                      <div className="equal width fields">
                        <Chops2uTextInputIcon
                          name="password"
                          icon="lock"
                          placeholder="Password"
                          type="password"
                        />

                        <Chops2uTextInputIcon
                          name="confirmPassword"
                          icon="unlock"
                          placeholder="Confirm Password"
                          type="password"
                        />
                      </div>

                      <ErrorMessage
                        name="error"
                        render={() => (
                          <ValidationErrors errors={errors.error} />
                        )}
                      />

                      <Button
                        disabled={(!isValid && dirty) || isSubmitting}
                        loading={isSubmitting}
                        color="olive"
                        content="Register"
                        type="submit"
                        fluid
                      />
                    </Segment>
                  </Form>
                )}
              </Formik>
              <Message>
                Already have an account? <Link to="/account/login">Log-in</Link>
              </Message>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    </Modal.Content>
  );
};

export default RegistrationPage;
