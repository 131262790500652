import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Header, Icon, Segment, Table } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Order, {
  AssignChopperFormValues,
} from "../../../../../app/models/order";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface Props {
  order: Order;
}

const validationSchema = Yup.object({
  chopperId: Yup.string().required("chopper is a required field."),
});

const emptyScheme = Yup.object({});

const AssignChopperForm = ({ order }: Props) => {
  const {
    modalStore: { closeModal },
    orderStore: { assignToChopper, unassignChopper, submitting },
    chopperStore: { loadChoppers, loading, choppers },
    shipmentStore: { loadOrderShipment },
  } = useStore();

  const [formValues, setFormValues] = useState<AssignChopperFormValues>({
    chopperId: "",
    error: null,
  });

  useEffect(() => {
    if (order.chopperAssigned) {
      loadOrderShipment(order.id).then((response) => {
        setFormValues({
          chopperId: response!.chopperId,
          error: null,
        });
      });
      console.log("Chopper Assigned");
    }
    loadChoppers();
  }, [order, loadChoppers, loadOrderShipment]);

  const handleFormSubmit = (
    values: AssignChopperFormValues,
    { setErrors }: FormikHelpers<AssignChopperFormValues>
  ) => {
    if (!order.chopperAssigned) {
      assignToChopper(order.id, values.chopperId!)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    } else {
      unassignChopper(order.id)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  const validate = order.chopperAssigned ? emptyScheme : validationSchema;

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validate}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, isValid, dirty, isSubmitting, errors }) => (
        <Form className="form ui" onSubmit={handleSubmit}>
          <Segment attached="top" color="olive">
            <Header
              as="h4"
              content="Assign Chopper"
              style={{ margin: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment attached>
            <Header as="h3">
              <Header.Content>{order.deliveryAddress?.name}</Header.Content>
              <Header.Subheader>
                <Icon name="phone" />
                {order.deliveryAddress?.telephone}
              </Header.Subheader>
              <Header.Subheader>
                <Icon name="point" />
                {order.deliveryAddress?.street +
                  ", " +
                  order.deliveryAddress?.location?.name}
              </Header.Subheader>
            </Header>
            <Table basic="very" style={{ marginTop: "2em" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Qty</Table.HeaderCell>
                  <Table.HeaderCell colSpan={2}>Item</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {order.items.map((item) => (
                  <Table.Row>
                    <Table.Cell>{item.quantity}</Table.Cell>
                    <Table.Cell colSpan={2}>{item.title}</Table.Cell>
                    <Table.Cell textAlign="right">{item.price}</Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell colSpan={3} textAlign="right">
                    Delivery Fee
                  </Table.Cell>
                  <Table.Cell textAlign="right">{order.deliveryFee}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={3} textAlign="right">
                    Total
                  </Table.Cell>
                  <Table.Cell textAlign="right">{order.orderTotal}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Chops2uSelectInput
              name="chopperId"
              label="Chopper"
              options={choppers}
              loading={loading && !order.chopperAssigned}
              disabled={order.chopperAssigned}
            />

            <div className="field">
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
            </div>

            {/* <div className="field">
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </div> */}
          </Segment>
          <Segment attached="bottom" secondary>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "1em",
              }}
            >
              <Button
                icon="cancel"
                labelPosition="left"
                color="orange"
                content="Cancel"
                onClick={closeModal}
              />
              <div>
                {order.chopperAssigned ? (
                  <Button
                    disabled={!isValid && dirty}
                    loading={isSubmitting && submitting}
                    icon="unlinkify"
                    labelPosition="left"
                    color="teal"
                    type="submit"
                    content="Un-assign"
                  />
                ) : (
                  <Button
                    disabled={!isValid && dirty}
                    loading={isSubmitting && submitting}
                    icon="linkify"
                    labelPosition="left"
                    color="olive"
                    type="submit"
                    content="Assign"
                  />
                )}
              </div>
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(AssignChopperForm);
