import { observer } from "mobx-react-lite";
import { Grid, Icon, Segment, SemanticCOLORS } from "semantic-ui-react";
import { timeLaps } from "../../../../app/common/util/util";
import Order from "../../../../app/models/order";

// const itemStyle = {
//   borderRadius: "0",
//   padding: "1em 0",
//   margin: "0 0",
//   marginBottom: "0",
//   background: "none",
//   border: "none",
//   borderBottom: "1px solid rgba(34,36,38,.15)",
//   marginLeft: "1em",
//   marginRight: "1em",
//   cursor: "pointer",
// };

// const itemActiveStyles = [
//   {
//     border: "none",
//     borderLeft: "0.5em solid #21ba45",
//     borderRight: "1em solid #fff",
//     background: "#fff",
//     paddingLeft: "0.5em",
//     marginLeft: "0",
//     marginRight: "0",
//     cursor: "default",
//   },
//   {
//     border: "none",
//     borderLeft: "0.5em solid #00b5ad",
//     borderRight: "1em solid #fff",
//     background: "#fff",
//     paddingLeft: "0.5em",
//     marginLeft: "0",
//     marginRight: "0",
//     cursor: "default",
//   },
//   {
//     border: "none",
//     borderLeft: "0.5em solid #f2711c",
//     borderRight: "1em solid #fff",
//     background: "#fff",
//     paddingLeft: "0.5em",
//     marginLeft: "0",
//     marginRight: "0",
//     cursor: "default",
//   },
//   {
//     border: "none",
//     borderLeft: "0.5em solid #b5cc18",
//     borderRight: "1em solid #fff",
//     background: "#fff",
//     paddingLeft: "0.5em",
//     marginLeft: "0",
//     marginRight: "0",
//     cursor: "default",
//   },
//   {
//     border: "none",
//     borderLeft: "0.5em solid red",
//     borderRight: "1em solid #fff",
//     background: "#fff",
//     paddingLeft: "0.5em",
//     marginLeft: "0",
//     marginRight: "0",
//     cursor: "default",
//   },
// ];

interface Props {
  order: Order;
  selectedOrder?: Order;
  loadOrder: (orderId: string) => void;
}

const OrderCellItem = ({ order, selectedOrder, loadOrder }: Props) => {
  const handleItemClick = (orderId: string) => {
    loadOrder(orderId);
  };

  const colors: SemanticCOLORS[] = [
    "orange",
    "teal",
    "purple",
    "grey",
    "green",
  ];

  const orderStatus = [
    "Prepared",
    "Preparing",
    "Ready",
    "InTransit",
    "Delivered",
    "Cancelled",
  ];

  // const activeStyle = itemActiveStyles[order.status];

  return (
    <section onClick={() => handleItemClick(order.id)}>
      <Segment
        style={{ borderRadius: "0px", marginBottom: "10px", cursor: "pointer" }}
      >
        <Grid>
          <Grid.Column width={7}>{order.deliveryAddress?.name}</Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            {order.status}
          </Grid.Column>
          <Grid.Column textAlign="right" width={4}>
            {timeLaps(order.createdOnUtc.toString())}
          </Grid.Column>
          <Grid.Column width={2}>
            {selectedOrder?.id === order.id ? (
              <Icon
                color={colors[orderStatus.indexOf(order.status)]}
                style={{ float: "right" }}
                name="chevron right"
              />
            ) : (
              ""
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </section>
  );
};

export default observer(OrderCellItem);
