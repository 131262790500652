import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Container, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { PagingParams } from "../../../../app/models/pagination";
import { useStore } from "../../../../app/store/store";

// Components import
import VendorList from "./lists/VendorList";
import VendorEmptyList from "./lists/VendorEmptyList";
import VendorListPlaceholder from "./placeholders/VendorListPlaceholder";

const VendorsDashboard = () => {
  const { vendorStore } = useStore();
  const {
    FrontPageVendors,
    loadingFrontPage,
    loadFrontPageVendors,
    pagination,
    setPagingParams,
  } = vendorStore;
  const [loadingNext, setLoadingNext] = useState(false);

  const handleLoadingNext = () => {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.page + 1));
    loadFrontPageVendors().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    loadFrontPageVendors();
  }, [loadFrontPageVendors]);

  return (
    <Segment vertical style={{ paddingTop: "2em" }}>
      <Container>
        <Header as="h4" color="olive" content="Explore our vendors" />
        {loadingFrontPage && !loadingNext ? (
          <VendorListPlaceholder />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadingNext}
            hasMore={
              !loadingNext &&
              !!pagination &&
              pagination!.page < pagination!.totalPages
            }
            initialLoad={false}
          >
            {FrontPageVendors && FrontPageVendors.length > 0 ? (
              <VendorList vendors={FrontPageVendors} />
            ) : (
              <VendorEmptyList />
            )}
          </InfiniteScroll>
        )}
        <Grid>
          <Grid.Column>
            <Loader active={loadingNext} />
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
};

export default observer(VendorsDashboard);
