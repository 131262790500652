import { observer } from "mobx-react-lite";
import { Button, Segment } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";
import { useStore } from "../../../../../../app/store/store";
import OrderDetailsBodyContent from "./OrderDetailsBodyContent";
import OrderDetailsBodyFooter from "./OrderDetailsBodyFooter";
import OrderDetailsBodyHeader from "./OrderDetailsBodyHeader";

interface Props {
  order: Order;
}

const OrderDetailsBody = ({ order }: Props) => {
  const {
    restaurantStore: {
      confirmOrder,
      finishOrder,
      confirmOrderPickup,
      submittingOrderStatus,
    },
  } = useStore();
  const buttonArray = {
    "Placed": <Button
      color="green"
      attached="bottom"
      onClick={() => handleConfirmButton()}
      style={{ borderRadius: "0", padding: "1.5em", letterSpacing: "1px" }}
    >
      CONFIRM ORDER
    </Button>,
    "Preparing": <Button
      color="teal"
      attached="bottom"
      onClick={() => handleFinishButton()}
      style={{ borderRadius: "0", padding: "1.5em", letterSpacing: "1px" }}
    >
      CONFIRM ORDER IS READY
    </Button>,
    "Ready": <Button
      color="orange"
      attached="bottom"
      disabled={order.status !== "InTransit"}
      onClick={() => handleOrderPickupButton()}
      style={{ borderRadius: "0", padding: "1.5em", letterSpacing: "1px" }}
    >
      CONFIRM PICKUP
    </Button>,
    "InTransit": null,
    "Delivered": null,
    "Cancelled": null
  };

  const handleConfirmButton = () => confirmOrder(order.id);
  const handleFinishButton = () => finishOrder(order.id);
  const handleOrderPickupButton = () => confirmOrderPickup(order.id);

  return (
    <>
      <Segment
        loading={submittingOrderStatus}
        attached
        style={{ padding: "2em" }}
      >
        <OrderDetailsBodyHeader order={order} />
        <OrderDetailsBodyContent order={order} />
        <OrderDetailsBodyFooter order={order} />
      </Segment>
      {buttonArray[order.status]}
    </>
  );
};

export default observer(OrderDetailsBody);
