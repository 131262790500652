import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import City from "../models/city";

export default class CityStore {
  cityRegistry = new Map<string, City>();
  loading = false;
  loadingCity = false;
  submitting = false;
  deleting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get Cities() {
    return Array.from(this.cityRegistry.values());
  }

  loadCities = async () => {
    this.loading = true;
    try {
      let cities = await agent.Cities.list();
      console.log(cities);
      runInAction(() => {
        this.cityRegistry.clear();
        cities.forEach((city) => {
          city = { ...city, value: city.id, text: city.name };
          this.cityRegistry.set(city.id!, city);
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  loadCity = async (id: string) => {
    let city = this.getCity(id);
    if (city) {
      return city;
    } else {
      this.loadingCity = true;
      try {
        city = await agent.Cities.details(id);
        runInAction(() => {
          if (city) this.cityRegistry.set(city.id!, city);
          this.loadingCity = false;
        });
        return city;
      } catch (error) {
        console.log(error);
        runInAction(() => (this.loadingCity = false));
      }
    }
  };

  private getCity = (id: string) => {
    return this.cityRegistry.get(id);
  };

  create = async (city: City) => {
    this.submitting = true;
    try {
      let newCity = await agent.Cities.create(city);
      runInAction(() => {
        if (newCity) this.cityRegistry.set(newCity.id!, newCity);
        this.submitting = false;
      });
      return newCity;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  update = async (id: string, city: City) => {
    this.submitting = true;
    try {
      let newCity = await agent.Cities.update(id, city);
      runInAction(() => {
        if (newCity) this.cityRegistry.set(newCity.id!, newCity);
        this.submitting = false;
      });

      return newCity;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  delete = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Cities.delete(id);
      runInAction(() => {
        this.cityRegistry.delete(id);
        this.deleting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };
}
