import { observer } from "mobx-react-lite";
import { Button, Container, Menu } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import MenuForm from "./forms/MenuForm";

const menuStyle = {
  borderRadius: "0",
};

const MenusTopMenu = () => {
  const {
    modalStore: { openModal },
  } = useStore();
  const handleButtonClick = () => {
    openModal(<MenuForm />, "mini");
  };
  return (
    <Menu attached="top" borderless style={menuStyle}>
      <Container style={{ width: "95%" }}>
        <Menu.Item position="right">
          <div>
            <Button
              positive
              icon="plus"
              labelPosition="left"
              content="New Menu"
              onClick={handleButtonClick}
            />
          </div>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(MenusTopMenu);
