import { VendorFormValues } from "../models/vendor";
import Pagination, { PagingParams } from "../models/pagination";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Vendor, { BankAccountFormValues, OpeningInfo } from "../models/vendor";

export default class VendorStore {
  selectedVendor: Vendor | undefined = undefined;
  vendorsRegistry = new Map<string, Vendor>();
  vendorsNearbyRegistry = new Map<string, Vendor>();
  frontPageVendorRegistry = new Map<string, Vendor>();
  loadingInitial = true;
  loading = false;
  loadingNearby = false;
  loadingFrontPage = false;
  submitting = false;
  deleting = false;
  pagination: Pagination | null = null;
  nearbyPagination: Pagination | null = null;
  pagingParams = new PagingParams();
  uploading = false;
  updatingPhoto = false;
  deletingPhoto = false;

  loadingOpeningInfos = false;
  updatingOpeningInfos = false;
  deletingOpeningInfos = false;

  loadingBankDetails = false;
  submittingBankAccount = false;

  constructor() {
    makeAutoObservable(this);
  }

  get VendorsAll() {
    return Array.from(this.vendorsRegistry.values());
  }

  get FrontPageVendors() {
    return Array.from(this.frontPageVendorRegistry.values());
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("page", this.pagingParams.page.toString());
    params.append("perPage", this.pagingParams.perPage.toString());
    return params;
  }

  setPagingParams = (pagingParam: PagingParams) => {
    this.pagingParams = pagingParam;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setLoadingFrontPage = (state: boolean) => {
    this.loadingFrontPage = state;
  }

  setNearbyPagination = (pagination: Pagination) => {
    this.nearbyPagination = pagination;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setLoadingNearby = (state: boolean) => {
    this.loadingNearby = state;
  };

  setSubmitting = (state: boolean) => {
    this.submitting = state;
  };

  private getVendor = (id: string) => {
    return this.vendorsRegistry.get(id);
  };

  loadNearbyVendors = async (locationId: string) => {
    this.setLoadingNearby(true);
    let params = this.axiosParams;
    params.append("filter", JSON.stringify({ locationId: locationId }));
    try {
      let result = await agent.Vendors.list(params);
      runInAction(() => {
        this.vendorsNearbyRegistry.clear();
        result.data.forEach((vendor) => {
          this.vendorsNearbyRegistry.set(vendor.id, vendor);
        });
      });
      this.setNearbyPagination(result.pagination);
      this.setLoadingNearby(false);
    } catch (error) {
      this.setLoadingNearby(false);
      console.log(error);
    }
  };

  loadFrontPageVendors = async () => {
    this.setLoadingFrontPage(true);
    let params = this.axiosParams;
    params.append("filter", JSON.stringify({ "active": true, "published": true }));
    try {
      let result = await agent.Vendors.list(params);
      runInAction(() => {
        // this.frontPageVendorRegistry.clear();
        result.data.forEach((vendor) => {
          this.frontPageVendorRegistry.set(vendor.id, vendor);
        });
      });
      this.setPagination(result.pagination);
      this.setLoadingFrontPage(false);
    } catch (error) {
      this.setLoadingFrontPage(false);
      console.log(error);
    }
  };

  loadVendors = async () => {
    this.setLoadingInitial(true);
    try {
      let result = await agent.Vendors.list(this.axiosParams);
      runInAction(() => {
        result.data.forEach((vendor) => {
          vendor.categoryIds = vendor.categories.map(
            (category) => category.categoryId!
          );
          this.vendorsRegistry.set(vendor.id, vendor);
        });
      });
      this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadVendor = async (id: string) => {
    this.setLoadingInitial(true);
    let vendor = this.getVendor(id);
    if (vendor) {
      this.setLoadingInitial(false);
      return vendor;
    } else {
      try {
        vendor = await agent.Vendors.details(id);
        vendor.categoryIds = vendor.categories.map(
          (category) => category.categoryId!
        );
        runInAction(() => {
          this.vendorsRegistry.set(vendor!.id, vendor!);
          this.selectedVendor = vendor;
        });
        this.setLoadingInitial(false);
        return vendor;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  create = async (vendor: Vendor) => {
    this.setSubmitting(true);
    try {
      let newVendor = await agent.Vendors.create(vendor);
      runInAction(() => {
        this.vendorsRegistry.set(newVendor.id, newVendor);
        this.selectedVendor = newVendor;
      });
      this.setSubmitting(false);
    } catch (error) {
      this.setSubmitting(false);
      console.log(error);
    }
  };

  update = async (id: string, vendor: VendorFormValues) => {
    this.setSubmitting(true);
    try {
      let result = await agent.Vendors.update(id, vendor);
      runInAction(() => {
        this.vendorsRegistry.set(result.id, result);
        this.selectedVendor = result;
      });
      this.setSubmitting(false);
    } catch (error) {
      this.setSubmitting(false);
      console.log(error);
    }
  };

  delete = async (id: string) => {
    this.deleting = true;
    try {
      await agent.Vendors.delete(id);
      runInAction(() => {
        this.vendorsRegistry.delete(id);
        this.selectedVendor = undefined;
      });
      runInAction(() => (this.deleting = false));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deleting = false));
    }
  };

  uploadPhoto = async (id: string, file: Blob) => {
    this.uploading = true;
    try {
      const photo = await agent.Vendors.uploadPhoto(id, file);
      let vendor = await this.loadVendor(id);
      vendor!.image = photo.url;
      runInAction(() => {
        this.vendorsRegistry.set(vendor!.id, vendor!);
        this.uploading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.uploading = false));
    }
  };

  updatePhoto = async (vendor: Vendor, file: Blob) => {
    this.updatingPhoto = true;
    try {
      var photo = await agent.Vendors.updatePhoto(vendor.id, file);
      vendor.image = photo.url;
      runInAction(() => {
        this.vendorsRegistry.set(vendor.id, vendor);
        this.updatingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.updatingPhoto = false));
    }
  };

  deletePhoto = async (id: string) => {
    this.deletingPhoto = true;
    try {
      await agent.Vendors.deletePhoto(id);
      runInAction(() => {
        const vendor = this.vendorsRegistry.get(id);
        vendor!.image = undefined;
        this.vendorsRegistry.set(id, vendor!);
        this.deletingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deletingPhoto = false));
    }
  };

  loadOpeningInfos = async (id: string) => {
    this.loadingOpeningInfos = true;
    try {
      let openingInfos = await agent.Vendors.listOpeningInfos(id);
      runInAction(() => (this.loadingOpeningInfos = false));
      return openingInfos;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loadingOpeningInfos = false));
    }
  };

  addOrUpdateOpeningInfos = async (id: string, openingInfos: OpeningInfo[]) => {
    this.updatingOpeningInfos = true;
    try {
      await agent.Vendors.addUpdateOpeningInfos(id, openingInfos);
      runInAction(() => (this.updatingOpeningInfos = false));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.updatingOpeningInfos = false));
    }
  };

  deleteOpeningInfos = async (id: string) => {
    this.deletingOpeningInfos = true;
    try {
      await agent.Vendors.deleteOpeningInfos(id);
      runInAction(() => (this.deletingOpeningInfos = false));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.deletingOpeningInfos = false));
    }
  };

  addOrUpdateBankAccount = async (
    id: string,
    bankAccount: BankAccountFormValues
  ) => {
    this.submittingBankAccount = true;
    try {
      let account = await agent.Vendors.addOrUpdateBankAccount(id, bankAccount);
      this.submittingBankAccount = false;
      return account;
    } catch (error) {
      runInAction(() => (this.submittingBankAccount = false));
      throw error;
    }
  };

  getBankDetails = async (bankAccountId: string) => {
    this.loadingBankDetails = true;
    try {
      let account = await agent.Vendors.getBankAccount(bankAccountId);
      this.loadingBankDetails = false;
      return account;
    } catch (error) {
      runInAction(() => (this.loadingBankDetails = false));
      console.log(error);
    }
  };
}
