import { useEffect } from "react";
import { Container, Header } from "semantic-ui-react";

interface Props {
  setPageTitle: (title: string) => void;
}

const LogsPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Logs");
  }, [setPageTitle]);

  return (
    <Container vertical>
      <Header content="Audit Logs" />
    </Container>
  );
};

export default LogsPageLayout;
