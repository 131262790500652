import React, { useEffect } from "react";
import Chops2uEmptyRecord from "../../../app/common/components/Chops2uEmptyRecord";

interface ShipmentsLayoutPageProps {
  setPageTitle: (title: string) => void;
}

const ShipmentsLayoutPage = ({ setPageTitle }: ShipmentsLayoutPageProps) => {
  useEffect(() => {
    setPageTitle("Shipments");
  }, [setPageTitle]);

  return (
    <Chops2uEmptyRecord
      color="olive"
      title="Not Shipments Records Yet!"
      subtitle="See all shipment history here"
    />
  );
};

export default ShipmentsLayoutPage;
