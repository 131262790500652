import { observer } from "mobx-react-lite";
import { Image } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";

const AccountCard = () => {
  const {
    userStore: { user },
  } = useStore();
  return (
    <Image
      circular
      style={{ width: "10em" }}
      src={user?.image || "/images/user.png"}
    />
  );
};
export default observer(AccountCard);
