import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Segment, Ref, Sticky } from "semantic-ui-react";
import Vendor from "../../../../app/models/vendor";
import { useStore } from "../../../../app/store/store";
import Cart from "../../cart/Cart";
import ConfirmRefreshCart from "../../cart/ConfirmRefreshCart";
import VendorBanner from "./banner/VendorBanner";
import VendorDetailsFoodList from "./food/VendorDetailsFoodList";
import VendorDetailsFoodMenu from "./VendorDetailsFoodMenu";

const pageStyle = {
  // marginTop: "4em",
  border: "none",
};

const VendorDetails = () => {
  const {
    vendorStore: { loadVendor },
    menuStore: { Menus, loadFrontPageMenus, MenuTitleList },
    shoppingCartStore: { shoppingCart },
  } = useStore();
  const { id } = useParams();
  const contextRef = useRef(null);

  const [vendor, setVendor] = useState<Vendor>({
    id: "",
    title: "",
    description: "",
    address: "",
    published: false,
    active: false,
    state: "",
    city: "",
    telephoneNumber: "",
    email: "",
    isOpenNow: false,
    location: undefined,
    categories: [],
  });
  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    if (id) {
      loadVendor(id)
        .then((vendor) => {
          setVendor(vendor!);
        })
        .catch((error) => console.log(error));
      loadFrontPageMenus(id);
    }
  }, [id, loadVendor, loadFrontPageMenus]);

  return (
    <Ref innerRef={contextRef}>
      <Segment vertical style={pageStyle}>
        <ConfirmRefreshCart />
        <VendorBanner vendor={vendor!} />
        <Grid relaxed stackable columns={2} style={{ marginTop: "1em" }}>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Grid relaxed="very" columns="equal" reversed="mobile" stackable>
              <Grid.Column
                computer={5}
                tablet={5}
                only="computer tablet"
                style={{ borderRight: "1px solid #ccc" }}
              >
                <Sticky offset={75} context={contextRef}>
                  <VendorDetailsFoodMenu
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    menus={MenuTitleList}
                  />
                </Sticky>
              </Grid.Column>
              <Grid.Column computer={11} tablet={11} mobile={16}>
                <VendorDetailsFoodList
                  menus={Menus}
                  isOpenNow={vendor.isOpenNow!}
                  setActiveMenu={setActiveMenu}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <Sticky offset={70} context={contextRef}>
              <Cart shoppingCart={shoppingCart} />
            </Sticky>
          </Grid.Column>
        </Grid>
      </Segment>
    </Ref>
  );
};

export default observer(VendorDetails);
