import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./app/layout/styles.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import { store, StoreContext } from "./app/store/store";
import { Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import HistoryRouter from "./app/navigator/HistoryRouter";
import { createMedia } from "@artsy/fresnel";
import { ThemeProvider } from "styled-components";
import { theme } from "./infrastructure/theme/theme";

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

export const history = createBrowserHistory();

// Remove React.StrictMode hoc
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StoreContext.Provider value={store}>
      <HistoryRouter history={history}>
        <MediaContextProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </MediaContextProvider>
      </HistoryRouter>
    </StoreContext.Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
