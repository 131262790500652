import CartButton from "./CartButton";
import LocationMenu from "../LocationMenu";
import { observer } from "mobx-react-lite";

const MobileFooterMenu = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        zIndex: 1001,
        borderTop: "1px solid rgb(224,224,224, 0.9)",
      }}
    >
      <div style={{ paddingRight: "10px", borderRight: "1px solid rgb(229, 228, 226)", }}>
        <LocationMenu />
      </div>
      <div
        style={{
          paddingLeft: "10px",
          width: "50px",
          alignItems: "center"
        }}
      >
        <CartButton mobile={true} />
      </div>
      {/* <div style={{ width: "50px", alignItems: "center", border: "1px solid rgb(229, 228, 226)"}}>
        <Icon name="like" color="olive" />
      </div> */}
    </div>
  );
}
export default observer(MobileFooterMenu);