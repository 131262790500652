import useQuery from "../../../../app/common/util/hooks";
import { useStore } from "../../../../app/store/store";
import { Button, Grid, Header, Image, Segment } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Chops2uTextInputIcon from "../../../../app/common/forms/Chops2uTextInputIcon";
import ValidationErrors from "../../../frontpage/errors/ValidationErrors";
import { ResetPasswordFormValues } from "../../../../app/models/reset-password";
import { observer } from "mobx-react-lite";

const ResetPassword = () => {
  const {
    userStore: { resetPassword: reset, logining },
  } = useStore();
  const email = useQuery().get("email") as string;
  const token = useQuery().get("token") as string;

  const navigate = useNavigate();

  const validate = Yup.object({
    newPassword: Yup.string().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const InitialValues: ResetPasswordFormValues = {
    email,
    newPassword: "",
    confirmPassword: "",
    token,
    error: null,
  };

  const handleResetPassword = (
    values: ResetPasswordFormValues,
    { setErrors }: FormikHelpers<ResetPasswordFormValues>
  ) => {
    reset(values)
      .then(() => navigate("/", { replace: true }))
      .catch((error) => {
        console.log(error);
        setErrors({ error: error.response.data });
      });
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Formik
          validationSchema={validate}
          onSubmit={handleResetPassword}
          initialValues={InitialValues}
        >
          {({ handleSubmit, isSubmitting, dirty, errors, isValid }) => (
            <Form className="ui form error" onSubmit={handleSubmit}>
              <Segment raised stacked style={{ marginTop: "1em" }}>
                <Header
                  size="tiny"
                  disabled
                  content={"Reset Your Password".toUpperCase()}
                />

                <Chops2uTextInputIcon
                  name="newPassword"
                  type="password"
                  icon="lock"
                  placeholder="Enter a New Password"
                />

                <Chops2uTextInputIcon
                  name="confirmPassword"
                  type="password"
                  icon="lock"
                  placeholder="Confirm New Password"
                />

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>

                <div className="field" style={{ padding: "15px 0px 0px" }}>
                  <Button
                    loading={isSubmitting && logining}
                    color="olive"
                    fluid
                    size="large"
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
        {/* <Message>
          <div>
            New to us? <Link to="/account/register">Sign Up</Link>
          </div>
          <div>
            <Link to="/account/forgot">Forgot Password</Link>
          </div>
        </Message> */}
      </Grid.Column>
    </Grid>
  );
};
export default observer(ResetPassword);
