import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import VendorForm from "./forms/VendorForm";
import VendorAccount from "./routes/VendorAccount";
import VendorDetails from "./routes/VendorDetails";
import VendorDetailsLayout from "./routes/VendorDetailsLayout";
import VendorFoods from "./routes/VendorFoods";
import VendorMenus from "./routes/VendorMenus";
import VendorOpeningHours from "./routes/VendorOpeningHours";
import VendorOwner from "./routes/VendorOwner";
import VendorPhoto from "./routes/VendorPhoto";
import VendorList from "./tables/VendorList";

interface Props {
  setPageTitle: (title: string) => void;
}

const VendorsLayoutPage = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Vendors");
  }, [setPageTitle]);

  return (
    <section>
      <Routes>
        <Route index element={<VendorList />} />
        <Route path="manage" element={<VendorForm />} />
        <Route element={<VendorDetailsLayout />}>
          <Route path={":id/info"} element={<VendorDetails />} />
          <Route path={":id/photo"} element={<VendorPhoto />} />
          <Route path={":id/openinghours"} element={<VendorOpeningHours />} />
          <Route path={":id/menus"} element={<VendorMenus />} />
          <Route path={":id/foods"} element={<VendorFoods />} />
          <Route path={":id/owner"} element={<VendorOwner />} />
          <Route path={":id/account"} element={<VendorAccount />} />
        </Route>
      </Routes>
    </section>
  );
};

export default VendorsLayoutPage;
