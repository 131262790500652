import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button, Header } from "semantic-ui-react";
import { toCurrency } from "../../../../app/common/util/util";
import Payment, { PaymentStatus } from "../../../../app/models/payment";
import CancelPaymentForm from "./forms/CancelPaymentForm";
import { useStore } from "../../../../app/store/store";
import { PageWrapper } from "../../administrator.style";

interface Props {
  data: Payment[];
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const PaymentList = ({ data }: Props) => {
  const {
    modalStore: { openModal },
  } = useStore();
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { textAlign: "right", minWidth: "70px", maxWidth: "70px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "70px", maxWidth: "70px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
      },
    },
    {
      name: "orderNumber",
      label: "Order ID",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "requestedOnUtc",
      label: "Date",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return format(new Date(value), "dd-MMM-yyyy");
        },
        setCellProps: () => ({
          style: { minWidth: "115px", maxWidth: "115px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "115px", maxWidth: "115px" },
        }),
      },
    },
    {
      name: "payerName",
      label: "Customer",
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "140px", maxWidth: "140px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "140px", maxWidth: "140px" },
        }),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        filter: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return PaymentStatus[value];
        },
      },
    },
    {
      name: "orderTotalAmount",
      label: "Order Amount",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    {
      name: "amountPaid",
      label: "Amount Paid",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    {
      name: "commissionFee",
      label: "Commission",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    {
      name: "deliveryFee",
      label: "Delivery Fee",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
      },
    },
    // {
    //   name: "salesTax",
    //   label: "Sales Tax",
    //   setCellProps: () => ({
    //     style: { float: "right" },
    //   }),
    //   options: {
    //     sort: false,
    //     filter: false,
    //     customHeadLabelRender: headerLabelRenderer,
    //     customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
    //       return toCurrency(value);
    //     },
    //   },
    // },
    {
      name: "vendorSales",
      label: "Net Sales",
      setCellProps: () => ({
        style: { float: "right" },
      }),
      options: {
        sort: false,
        filter: false,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toCurrency(value);
        },
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          const { rowIndex } = tableMeta;
          return (
            <Button
              icon="cancel"
              basic
              color="yellow"
              circular
              // disabled={
              //   ordersByDate[tableMeta.rowIndex].status ===
              //   OrderStatusOptions.Placed
              // }
              size="mini"
              onClick={() => handleButtonCancel(rowIndex)}
            />
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const handleButtonCancel = (rowIndex: any) => {
    openModal(<CancelPaymentForm payment={data[rowIndex]} />);
  };

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default observer(PaymentList);
