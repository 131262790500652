import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Header } from "semantic-ui-react";
import Chopper from "../../../../app/models/chopper";
import { PageWrapper } from "../../administrator.style";

interface Props {
  data: Chopper[];
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const ChopperList = ({ data }: Props) => {
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "40px", maxWidth: "40px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "40px", maxWidth: "40px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "phoneNumber",
      label: "Telephone",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "bikeMake",
      label: "Bike",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "serialNumber",
      label: "Serial No.",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "plateNumber",
      label: "Plate No.",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "bikeStatus",
      label: "Vehicle Status",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
  ];

  // function handleEditButtonClick(tableMeta: any) {
  //   const { rowIndex } = tableMeta;
  //   // openModal(<CategoryForm id={data[rowIndex].id} />);
  // }

  // function handleDeleteButtonClick(tableMeta: any) {
  //   const { rowIndex } = tableMeta;
  //   console.log(tableMeta);
  //   // openModal(<DeleteCategoryForm category={data[rowIndex]} />);
  // }

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default observer(ChopperList);
