import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";

interface Props {
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
}

const Chops2uCheckbox = (props: Props) => {
  const [field, meta, helper] = useField(props.name);
  return (
    <Form.Field
      className="ui checkbox"
      error={meta.touched && !!meta.error}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <input
        {...field}
        type="checkbox"
        disabled={props.disabled}
        value={field.value}
        checked={field.value === true}
        onBlur={() => helper.setTouched(true)}
      />
      {props.label && (
        <label style={{ textAlign: "left" }}>{props.label}</label>
      )}
      {meta.touched && meta.error ? (
        <Label style={{ float: "left" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export default Chops2uCheckbox;
