import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Header, Segment } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Bike, { AssignRiderFormVelues } from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface Props {
  bike: Bike;
}

const validate = Yup.object({
  chopperId: Yup.string().required(),
});

const AssignRiderForm = ({ bike }: Props) => {
  const {
    bikeStore: {
      assignRider,
      submitting
    },
    chopperStore: { UnassignedChoppers, loadUnassignedChoppers, loadingUnassigned },
    modalStore: { closeModal },
  } = useStore();

  useEffect(() => {
    loadUnassignedChoppers();
    if (bike) {
      setData((prev) => ({ ...prev, bike: bike }));
    }
  }, [loadUnassignedChoppers, bike]);

  const [data, setData] = useState<AssignRiderFormVelues>({
    bike: null,
    chopperId: "",
    error: null,
  });

  const handleFormSubmit = (
    values: AssignRiderFormVelues,
    { setErrors }: FormikHelpers<AssignRiderFormVelues>
  ) => {
    if (bike.id) {
      assignRider(bike.id, values.chopperId)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={handleFormSubmit}
      validationSchema={validate}
      enableReinitialize
    >
      {({ values, dirty, isSubmitting, isValid, errors }) => (
        <Form className="ui form">
          <Segment attached="top" color="olive">
            <Header
              as="h4"
              content="Assign Chopper"
              style={{ margin: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment attached>
            <Chops2uTextInput name="bike.make.name" label="Bike" disabled />

            <Chops2uTextInput
              name="bike.serialNumber"
              label="Bike Serial No."
              disabled
            />

            <Chops2uTextInput
              name="bike.plateNumber"
              label="Bike Plate No."
              disabled
            />

            <Chops2uSelectInput
              name="chopperId"
              label="Chopper"
              options={UnassignedChoppers}
              loading={loadingUnassigned}
            />

            <div className="field">
              {errors.error ? (
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              ) : null}
            </div>
          </Segment>
          <Segment attached="bottom" secondary>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon="cancel"
                type="button"
                color="orange"
                labelPosition="left"
                content="Cancel"
                onClick={closeModal}
              />

              <Button
                icon="check"
                disabled={(!isValid && dirty) || isSubmitting}
                loading={submitting}
                color="olive"
                type="submit"
                labelPosition="left"
                content="Submit"
              />
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(AssignRiderForm);
