import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Header, Segment } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { BikeFormValues } from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface Props {
  id?: string;
}

const validate = Yup.object({
  makeId: Yup.string().required("'bike make' is a required field"),
  serialNumber: Yup.string().required("'serial number' is a required field"),
  chasisNumber: Yup.string().required("'chasis number' is a required field"),
});

const BikeForm = ({ id }: Props) => {
  const {
    bikeStore: {
      create,
      update,
      submitting,
      loadBike,
      loading,
      loadMakes,
      makes,
      loadingMakes,
    },
    modalStore: { closeModal },
  } = useStore();
  const [bike, setBike] = useState<BikeFormValues>({
    make: undefined,
    makeId: "",
    serialNumber: "",
    chasisNumber: "",
    status: "Parked",
    registrations: [],
    error: null,
  });

  useEffect(() => {
    loadMakes();
    if (id) {
      loadBike(id).then((result) => {
        setBike((prev) => ({ ...prev, ...result }));
      });
    }
  }, [id, loadMakes, loadBike]);

  const handleFormSubmit = (
    values: BikeFormValues,
    { setErrors }: FormikHelpers<BikeFormValues>
  ) => {
    if (id) {
      update(id, values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    } else {
      create(values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Formik
      initialValues={bike}
      onSubmit={handleFormSubmit}
      validationSchema={validate}
      enableReinitialize
    >
      {({ values, handleSubmit, dirty, isValid, isSubmitting, errors }) => (
        <Form className="form ui" onSubmit={handleSubmit}>
          <Segment attached="top" color="olive" loading={loading}>
            <Header
              as="h4"
              content={id ? "Edit Bike" : "Add Bike"}
              style={{ margin: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment attached>
            <Chops2uSelectInput
              name="makeId"
              label="Bike Make"
              options={makes}
              loading={loadingMakes}
            />

            <Chops2uTextInput
              name="serialNumber"
              label="Serial Number"
              placeholder="Bike Serial"
            />

            <Chops2uTextInput
              name="chasisNumber"
              label="Chasis Number"
              placeholder="Bike Chasis Number"
            />

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div className="field">
              {errors.error ? (
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              ) : null}
            </div>
          </Segment>
          <Segment attached="bottom" secondary>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon="cancel"
                type="button"
                color="orange"
                labelPosition="left"
                content="Cancel"
                onClick={closeModal}
              />

              <Button
                icon="check"
                disabled={!isValid && dirty}
                loading={isSubmitting && submitting}
                color="olive"
                type="submit"
                labelPosition="left"
                content="Submit"
              />
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(BikeForm);
