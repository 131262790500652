import React from "react";

interface PayoutPageLayoutProps {
  setPageTitle: (title: string) => void;
}

const PayoutsLayoutPage = ({ setPageTitle }: PayoutPageLayoutProps) => {
  return <div>PayoutsLayoutPage</div>;
};

export default PayoutsLayoutPage;
