import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import {
  Menu,
  Container,
  Dropdown,
  Icon,
  Header,
  DropdownHeader,
} from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import StoreTopMenuPanel from "../StoreTopMenuPanel";
import { StoreMenu } from "../store.style";

interface Props {
  title: string;
}

const StoreTopMenu = ({ title }: Props) => {
  const {
    userStore: { IsLogedIn, logout },
  } = useStore();

  return (
    <StoreMenu size="large" borderless>
      <Container>
        <Menu.Item position="left">
          <Header as="h4" content={title} />
        </Menu.Item>
        <Menu.Item position="right">
          <Menu.Item>
            <Icon color="green" size="large" name="bell outline" />
          </Menu.Item>
          {IsLogedIn && (
            <Menu.Item>
              <Icon color="green" size="large" name="user circle outline" />
              <Dropdown
                // text={user?.firstName.toLowerCase()}
                direction="left"
                icon={<Icon name="caret down" />}
              >
                <Dropdown.Menu
                  style={{ marginTop: "5px", borderRadius: "0", zIndex: 1000 }}
                >
                  <DropdownHeader>
                    <StoreTopMenuPanel />
                  </DropdownHeader>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/store/support">
                    <Icon name="shield" color="green" />
                    Technical Support
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => logout()}>
                    <Icon name="power" color="green" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}
        </Menu.Item>
      </Container>
    </StoreMenu>
  );
};

export default observer(StoreTopMenu);
