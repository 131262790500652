import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { useStore } from "../../../../../../app/store/store";
import OrderCellList from "./items/OrderCellList";

const ActionPanel = () => {
  const {
    restaurantStore: {
      OrdersPlaced: ordersActionRequired,
      OrdersInKitchen: ordersInKitchen,
      OrdersForPickup: ordersReadyForPickup,
      selectedOrder,
      loadOrder,
    },
  } = useStore();
  return (
    <Fragment>
      <div style={{ paddingLeft: "0.005em" }}>
        {ordersActionRequired?.length > 0 && (
          <OrderCellList
            orders={ordersActionRequired}
            selectedOrder={selectedOrder}
            loadOrder={loadOrder}
            title="Action Required"
          />
        )}
      </div>
      <div>
        {ordersInKitchen?.length > 0 && (
          <OrderCellList
            orders={ordersInKitchen}
            selectedOrder={selectedOrder}
            loadOrder={loadOrder}
            title="In Kitchen"
          />
        )}
      </div>
      <div>
        {ordersReadyForPickup?.length > 0 && (
          <OrderCellList
            orders={ordersReadyForPickup}
            selectedOrder={selectedOrder}
            loadOrder={loadOrder}
            title="Ready for Pickup"
          />
        )}
      </div>
    </Fragment>
  );
};

export default observer(ActionPanel);
