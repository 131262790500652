export default interface Payment {
    id?: string;
    orderNumber?: string;
    cartId: string;
    transactionReference: string;
    gatewayId: string;
    gatewayTransactionId?: string;
    gatewayTransactionReference?: string;
    requestedOnUtc: string;
    currency: Currency;
    title: string;
    description?: string;
    type: PaymentType;
    orderSubTotal: number;
    otherCharges: number;
    deliveryFee: number;
    salesTax: number;
    commissionFee: number;
    vendorSales: number;
    orderDiscount: number;
    orderTotalAmount: number;
    amountPaid: number;
    additionalInfo?: string;
    status: PaymentStatus;
    statusChangedOnUtc: string;
    payerId?: string;
    payerName: string;
    payerTelephone: string;
    payerEmail: string;
    callbackUrl?: string;
    vendorId?: string;
    vendor?: string;
    createdOnUtc?: string;
}

export interface CancelPayment {
    transactionRef: string;
    reason: string;
}

export interface ConfirmPayment {
    orderNumber: string;
}

export interface PaymentResponse {
    cardId: string;
    transactionReference: string;
    gatewayId: string;
    status: string;
    failureReason?: string;
}

export interface VerifyPaymentResponse {
    transactionRef: string;
    status: string;
}

export enum PaymentStatus {
    Pending,
    Authorized,
    Successful,
    Failed,
    Cancelled,
    Reversed
}

export type Currency = "USD" | "NGN" | "EUR";

export type PaymentType = "P" | "R" | "O";