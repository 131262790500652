import { Field, FieldArray, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import { timeList } from "../../../../../app/common/options/time-list";
import { OpeningInfo } from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";

interface Props {
  setEditMode: (state: boolean) => void;
}

interface FormValues {
  id?: string;
  openingInfos: OpeningInfo[];
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const VendorOpeningInfoForm = ({ setEditMode }: Props) => {
  const {
    vendorStore: {
      loadOpeningInfos,
      addOrUpdateOpeningInfos,
      updatingOpeningInfos,
    },
  } = useStore();
  const [formData, setFormData] = useState<FormValues>({
    id: "",
    openingInfos: [
      {
        id: "",
        vendorId: "",
        dayOfWeek: 0,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 1,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 2,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 3,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 4,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 5,
        open: "false",
        openAt: "",
        closeAt: "",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 6,
        open: "false",
        openAt: "",
        closeAt: "",
      },
    ],
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadOpeningInfos(id).then((result) =>
        setFormData((prev) => ({ ...prev, openingInfos: result! }))
      );
    }
  }, [id, loadOpeningInfos]);

  const handleFormSubmit = async (values: FormValues) => {
    // The select option can only assign a string value. So the 'open' value for the OpeningInfo object
    // will be a string instead of a boolean. I am re-copy the objets in the array to change the
    // string value to a boolean before sending the objects with the request.
    let openingInfos: OpeningInfo[] = values.openingInfos;
    // openingInfos = openingInfos.map((openingInfo) => ({
    //   ...openingInfo,
    //   open: openingInfo.open,
    // }));

    // Saving the Opening Hour informations
    //---------------------------------------------
    await addOrUpdateOpeningInfos(id!, openingInfos);
    setEditMode(false);
  };

  return (
    <div>
      <Container text style={{ marginTop: "1.5em" }}>
        <Formik
          initialValues={formData}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ values, handleSubmit, errors, touched, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Segment attached="top">
                <Header
                  as="h2"
                  color="olive"
                  content="Vendor Opening Info Form"
                />
              </Segment>
              <Segment attached>
                <Grid>
                  <Grid.Row columns={6}>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={3}>
                      <div className="field">
                        <label>Open</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div className="field">
                        <label>Opening Time</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div className="field">
                        <label>Closing Time</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={1}></Grid.Column>
                  </Grid.Row>
                  <FieldArray name="openingInfos">
                    {() => (
                      <>
                        {values.openingInfos &&
                          values.openingInfos.length > 0 &&
                          values.openingInfos.map((openingInfo, index) => (
                            <Grid.Row key={index} columns={6}>
                              <Grid.Column width={1}></Grid.Column>
                              <Grid.Column width={3}>
                                <div className="field">
                                  <label>
                                    {daysOfWeek[openingInfo.dayOfWeek!]}
                                  </label>
                                </div>
                              </Grid.Column>
                              <Grid.Column width={3}>
                                <Field
                                  as="select"
                                  name={`openingInfos.${index}.open`}
                                >
                                  <option key="open" value="true">
                                    Open
                                  </option>
                                  <option key="close" value="false">
                                    Close
                                  </option>
                                </Field>
                              </Grid.Column>
                              <Grid.Column width={4}>
                                <Field
                                  as="select"
                                  name={`openingInfos.${index}.openAt`}
                                >
                                  {timeList.map((time) => {
                                    return (
                                      <option key={time.key} value={time.value}>
                                        {time.text}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </Grid.Column>
                              <Grid.Column width={4}>
                                <Field
                                  as="select"
                                  name={`openingInfos.${index}.closeAt`}
                                >
                                  {timeList.map((time) => {
                                    return (
                                      <option key={time.key} value={time.value}>
                                        {time.text}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </Grid.Column>
                              <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                          ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>
                {/* <div className="field">{JSON.stringify(values, null, 2)}</div> */}
              </Segment>
              <Segment secondary attached="bottom" clearing>
                <Button
                  type="button"
                  basic
                  color="orange"
                  content="Cancel"
                  style={{ width: "10em" }}
                  onClick={() => setEditMode(false)}
                />
                <Button
                  color="olive"
                  basic
                  type="submit"
                  loading={updatingOpeningInfos}
                  floated="right"
                  content="Submit"
                  style={{ width: "10em" }}
                />
              </Segment>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default observer(VendorOpeningInfoForm);
