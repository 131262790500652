import { Card } from "semantic-ui-react";
import VendorPlaceholderCard from "./VendorPlaceholderCard";

const VendorListPlaceholder = () => {
  return (
    <Card.Group itemsPerRow={3} stackable>
      <VendorPlaceholderCard key="1" />
      <VendorPlaceholderCard key="2" />
      <VendorPlaceholderCard key="3" />
      <VendorPlaceholderCard key="4" />
      <VendorPlaceholderCard key="5" />
      <VendorPlaceholderCard key="6" />
    </Card.Group>
  );
};

export default VendorListPlaceholder;
