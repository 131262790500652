import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Container, Grid, Segment } from "semantic-ui-react";
import AccountSideNav from "./AccountSideNav";
import AddressBookPage from "./addressbook/AddressBookPage";
import FavoritesPage from "./favorites/FavoritesPage";
import ChangePassword from "./security/ChangePassword";
import OrdersLayoutPage from "./orders/OrdersLayoutPage";
import ProfilePage from "./profile/ProfilePage";
import VendorsPage from "./vendors/VendorsPage";
import { Media } from "../../..";
import ProfilePhoto from "./profile/ProfilePhoto";

// const sideNavStyle = {
//   // background: "#767676",
//   display: "flex",
//   flex: "0 1 250px",
//   flexDirection: "column" as "column",
//   // border: "1px solid #333"
// };
// const contentStyle = {
//   // width: "calc(100% - 250px)",
//   flex: "1 1 calc(100vh - 120px)",
//   marginLeft: "5px",
//   // display: "flex",
//   // border: "1px solid #333"
// };

// const containerStyle = {
//   display: "flex",
//   width: "940px",
//   margin: "auto",
//   marginTop: "1.5em",
//   gap: "5px",
// }

const segmentStyle = {
  // minHeight: "calc(100vh - 120px)",
  flex: "1",
  overFlowY: "auto" as "auto",
  scrollbarColor: "darkgray gray",
  scrollbarWidth: "thin" as "thin",
  // backgroundColor: "rgba(0,0,0,0.05)",
};

interface Props {
  mobile?: boolean;
}

const AccountLayout = ({ mobile }: Props) => {
  return (
    <>
      <Media greaterThan="mobile">
        <Container style={{ marginTop: "2.5em" }}>
          <Grid>
            <Grid.Column computer={4} tablet={4} only="computer tablet">
              <AccountSideNav />
            </Grid.Column>
            <Grid.Column computer={10} tablet={10} mobile={16}>
              <Segment style={segmentStyle}>
                <Routes>
                  <Route index element={<OrdersLayoutPage />} />
                  <Route path="profile" element={<ProfilePage />} />
                  <Route path="photo" element={<ProfilePhoto />} />
                  <Route path="orders" element={<VendorsPage />} />
                  <Route path="favorites" element={<FavoritesPage />} />
                  <Route path="security" element={<ChangePassword />} />
                  <Route path="address-book" element={<AddressBookPage />} />
                  {/* <Route path="close-account" element={<CloseAccountPage />} /> */}
                </Routes>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </Media>
      <Media at="mobile">
        <Container style={{ marginTop: "4.5em" }}>
          <Grid>
            <Grid.Column mobile={16}>
              <Segment style={segmentStyle}>
                <Routes>
                  <Route index element={<OrdersLayoutPage mobile={true} />} />
                  <Route path="profile" element={<ProfilePage />} />
                  <Route path="photo" element={<ProfilePhoto />} />
                  <Route path="vendors" element={<VendorsPage />} />
                  <Route path="favorites" element={<FavoritesPage />} />
                  <Route path="security" element={<ChangePassword />} />
                  <Route path="address-book" element={<AddressBookPage />} />
                  {/* <Route path="close-account" element={<CloseAccountPage />} /> */}
                </Routes>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </Media>
    </>
  );
};

export default observer(AccountLayout);
