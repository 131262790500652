import { observer } from "mobx-react-lite";
import { Header } from "semantic-ui-react";

const VendorsPage = () => {
    return (
        <>
            <Header as="h2" color="olive">Vendors</Header>
        </>
    );
}

export default observer(VendorsPage);