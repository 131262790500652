import { observer } from "mobx-react-lite";
import ShoppingCart from "../../../app/models/shopping-cart";
import EmptyCart from "./EmptyCart";
import StockedCart from "./StockedCart";
import { CartWrapper } from "./cartStyles";

interface Props {
  shoppingCart: ShoppingCart | null;
}

const Cart = ({ shoppingCart }: Props) => {
  return (
    <CartWrapper>
      {shoppingCart === null ? (
        <EmptyCart />
      ) : (
        <StockedCart shoppingCart={shoppingCart} />
      )}
    </CartWrapper>
  );
};

export default observer(Cart);
