import { Container, Header, Icon, Menu } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";
import { observer } from "mobx-react-lite";

interface Props {
  order: Order;
}

const OrderDetailsTop = ({ order }: Props) => {
  return (
    <Menu
      borderless
      style={{ borderRadius: "0", marginBottom: "0", borderShadow: "none" }}
    >
      <Container style={{ width: "95%" }}>
        <Menu.Item>{"Order #" + order.orderNumber}</Menu.Item>
        <Menu.Item>
          {order.items.length}
          {order.items.length > 0 ? " items" : " item"}
        </Menu.Item>
        <Menu.Item position="right">
          <Header as="h5">
            <Header.Subheader>Customer</Header.Subheader>
            <Header.Content>{order.deliveryAddress?.name}</Header.Content>
            <div
              style={{
                display: "flex",
                gap: "0px",
                fontWeight: "normal",
                fontSize: "0.9em",
                letterSpacing: "1px",
              }}
            >
              <Icon name="phone square" style={{ marginRight: "3px" }} />{" "}
              {order.deliveryAddress?.telephone}
            </div>
          </Header>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(OrderDetailsTop);
