import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import City from "../../../../app/models/city";
import { useStore } from "../../../../app/store/store";
import LocationDeleteForm from "./forms/LocationDeleteForm";
import LocationForm from "./forms/LocationForm";
import { PageWrapper } from "../../administrator.style";

const LocationList = () => {
  const {
    locationStore: {
      LocationsByCity: LocationsByCounty,
      loadingLocations: loadingRecord,
      loadLocations,
    },
    modalStore: { openModal },
  } = useStore();

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        customBodyRender: (value: City, tableMeta: any, updatedValue: any) => {
          return value ? value.name : "";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2em" }}
            >
              <Button
                icon="edit"
                color="blue"
                basic
                circular
                size="mini"
                onClick={() => handleEditCommand(tableMeta)}
              />
              <Button
                icon="delete"
                color="red"
                basic
                circular
                size="mini"
                onClick={() => handleDeleteCommand(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  function handleRowClick(rowData: any, rowMeta: any) {
    const { dataIndex } = rowMeta;
    console.log(rowData);
    console.log(dataIndex);
  }

  // function handleTableChange(action: string, tableState: any) {
  //   if (action === "changePage") {
  //     let currentPage = tableState.page + 1;
  //     console.log("Go to page", currentPage);
  //   }
  // }

  function handleRowsDelete(rowsDeleted: any, newTableData: any) {
    console.log(rowsDeleted);
    console.log(newTableData);
  }

  function headerLabelRenderer(columnMeta: any) {
    const { label } = columnMeta;
    return <Header as="h5" content={label} />;
  }

  function handleDeleteCommand(tableMeta: any) {
    const { rowIndex } = tableMeta;
    openModal(
      <LocationDeleteForm location={LocationsByCounty[rowIndex]} />,
      "mini"
    );
  }

  // function handleActionClick(e: SyntheticEvent, data: any) {
  //   console.log(data);
  // }

  function handleEditCommand(tableMeta: any) {
    const { rowIndex } = tableMeta;
    openModal(<LocationForm id={LocationsByCounty[rowIndex].id} />);
  }

  return (
    <PageWrapper>
      <Segment vertical clearing>
        <Button
          floated="right"
          color="olive"
          icon="plus"
          content="Add Location"
          onClick={() => openModal(<LocationForm />)}
        />
      </Segment>
      <Segment vertical loading={loadingRecord}>
        <MuiThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={null}
            data={LocationsByCounty}
            columns={columns}
            options={{
              filter: "false",
              download: "false",
              print: "false",
              viewColumns: "false",
              responsive: "standard",
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 10, 15],
              onRowsDelete: handleRowsDelete,
              onRowClick: handleRowClick,
              setTableProps: () => {
                return { size: "small" };
              },
            }}
          />
        </MuiThemeProvider>
      </Segment>
    </PageWrapper>
  );
};

export default observer(LocationList);
