import { Formik, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import {
  Segment,
  Container,
  Header,
  Form,
  Button,
  Modal,
  Message,
} from "semantic-ui-react";
import * as Yup from "yup";
import "yup-phone";
import Chops2uTextInput from "../../../../app/common/forms/Chops2uTextInput";
import { useStore } from "../../../../app/store/store";
import ValidationErrors from "../../../frontpage/errors/ValidationErrors";
import LoginForm from "../login/LoginForm";

export default observer(function RegistrationForm() {
  const { userStore, modalStore } = useStore();

  const validationSchema = Yup.object({
    fullName: Yup.string().required("'Full Name' is a required field."),
    email: Yup.string().trim().required().email(),
    phoneNumber: Yup.string().trim().required().phone(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleLinkClick = () => {
    modalStore.openModal(<LoginForm />);
  };

  return (
    <Modal.Content style={{ padding: "1rem 1.5rem" }}>
      <Segment vertical>
        <Container>
          <Formik
            initialValues={{
              fullName: "",
              phoneNumber: "",
              email: "",
              password: "",
              confirmPassword: "",
              error: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors }) =>
              userStore
                .register(values)
                .then(() => modalStore.closeModal())
                .catch((error) => setErrors({ error }))
            }
          >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
              <>
                <Form
                  className="ui form error"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="field">
                    <Header
                      disabled
                      textAlign="center"
                      size="tiny"
                      content={"Create an Account".toUpperCase()}
                    />
                  </div>

                  <Chops2uTextInput
                    name="fullName"
                    placeholder="Name"
                    label="Full Name"
                  />

                  <Chops2uTextInput
                    name="phoneNumber"
                    placeholder="Telephone Number"
                    label="Telephone Number"
                  />

                  <Chops2uTextInput
                    name="email"
                    placeholder="username@domain.com"
                    label="Email"
                  />

                  <div className="equal width fields">
                    <Chops2uTextInput
                      name="password"
                      placeholder="your password"
                      label="Password"
                      type="password"
                    />
                    <Chops2uTextInput
                      name="confirmPassword"
                      placeholder="repeat password"
                      label="Confirm Password"
                      type="password"
                    />
                  </div>

                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />

                  <Button
                    disabled={(!isValid && dirty) || isSubmitting}
                    loading={isSubmitting}
                    color="olive"
                    content="Register"
                    type="submit"
                    fluid
                  />
                  {/* <Divider horizontal>Or</Divider>
                  <Button
                    as={FacebookLogin}
                    appId=""
                    onSuccess={(response: any) =>
                      console.log("Login success: ", response)
                    }
                    onFail={(response: any) =>
                      console.log("Register fail: ", response)
                    }
                    fluid
                    color="facebook"
                  >
                    <Icon name="facebook" />
                    Continue with facebook
                  </Button> */}
                  <Message style={{ textAlign: "center" }} size="small">
                    Already have an account?{" "}
                    <button
                      onClick={handleLinkClick}
                      style={{
                        color: "#4183c4",
                        cursor: "pointer",
                        border: "none",
                        padding: "0",
                        borderRadius: "0",
                        boxShadow: "0 0 0 0",
                        backgroundColor: "transparent",
                      }}
                    >
                      Log-in
                    </button>
                    .
                  </Message>
                  {/* <Message style={{ textAlign: "center" }} size="small">
                    By registering you have accepted our{" "}
                    <a href="/pages/termsandconditions">Terms & Conditions</a>{" "}
                    and our <a href="/pages/privacypolicy">Privacy Policy</a>.
                  </Message> */}
                </Form>
              </>
            )}
          </Formik>
        </Container>
      </Segment>
    </Modal.Content>
  );
});
