import { Outlet } from "react-router-dom";
import SecondaryMenu from "../menus/SecondaryMenu";

const VendorDetailsLayout = () => {
  return (
    <section>
      <div>
        <SecondaryMenu />
      </div>
      <div>
        <Outlet />
      </div>
    </section>
  );
};

export default VendorDetailsLayout;
