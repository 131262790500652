import isValid from "date-fns/isValid";
import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import {
  VendorCategory,
  VendorFormValues,
} from "../../../../../app/models/vendor";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

const ProfileForm = () => {
  const {
    restaurantStore: {
      restaurant,
      loadStore,
      loadingStore,
      updateStore,
      submittingFood: submitting,
    },
    categoryStore: { loadCategories, categoriesByTitle, loadingCategories },
    locationStore: {
      loadLocations,
      loading: loadingLocation,
      LocationsByCity: locationsByCity,
    },
  } = useStore();

  const validate = Yup.object({
    title: Yup.string().required(),
    description: Yup.string().required(),
    address: Yup.string().required(),
    telephoneNumber: Yup.string().required().phone(),
    email: Yup.string().required().email(),
    locationId: Yup.number().required().moreThan(0),
    categoryIds: Yup.array().min(
      1,
      "Category field must have atleast a value."
    ),
  });

  const [vendor, setVendor] = useState<VendorFormValues>({
    id: "",
    title: "",
    description: "",
    address: "",
    state: "",
    city: "",
    active: true,
    telephoneNumber: "",
    email: "deensadiq@chops2u.com",
    locationId: "",
    isOpenNow: false,
    categories: [],
    categoryIds: [],
    error: null,
  });

  useEffect(() => {
    loadCategories();
    loadLocations();
  }, [loadCategories, loadLocations]);

  useEffect(() => {
    if (restaurant) setVendor({ ...restaurant, error: "" });
  }, [restaurant, setVendor]);

  const handleFormSubmit = async (
    values: VendorFormValues,
    { setErrors }: FormikHelpers<VendorFormValues>
  ) => {
    values.categories = values.categoryIds!.map<VendorCategory>((categoryId) =>
      Object.assign({ categoryId: categoryId, vendorId: values.id })
    );
    // console.log(values);
    updateStore(values.id, values)
      .then(() => loadStore())
      .catch((error) => setErrors({ error }));
  };

  return (
    <Grid>
      <Grid.Column
        style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Formik
          initialValues={vendor}
          enableReinitialize
          validationSchema={validate}
          onSubmit={handleFormSubmit}
        >
          {({ values, dirty, isSubmitting, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="ui form">
              <Segment loading={loadingStore} color="green" attached="top">
                <div>
                  <Header
                    textAlign="center"
                    size="small"
                    disabled
                    content={"Store Profile".toUpperCase()}
                    style={{ padding: "10px 0 5px 0" }}
                  />
                </div>
              </Segment>
              <Segment attached clearing>
                {/* Email Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Email
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput
                      name="email"
                      placeholder="username@chops2u.com"
                      disabled
                    />
                  </div>
                </div>

                {/* Location Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Location
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uSelectInput
                      name="locationId"
                      loading={loadingLocation}
                      options={locationsByCity}
                      placeholder="Location"
                    />
                  </div>
                </div>

                {/* Title Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Title
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput name="title" placeholder="Title" />
                  </div>
                </div>

                {/* Description Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Description
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextAreaInput
                      rows={2}
                      name="description"
                      placeholder="Brief Description"
                    />
                  </div>
                </div>

                {/* Categories Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Category
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uSelectInput
                      name="categoryIds"
                      loading={loadingCategories}
                      options={categoriesByTitle}
                      placeholder="Please state the type of food you provide"
                      multiple={true}
                    />
                  </div>
                </div>

                {/* Address Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Address
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput name="address" placeholder="address" />
                  </div>
                </div>

                {/* Telephone Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    >
                      Telephone
                    </label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uTextInput
                      name="telephoneNumber"
                      placeholder="Telephone"
                    />
                  </div>
                </div>

                {/* Status Field */}
                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    ></label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uCheckbox
                      name="active"
                      label="Set Store Active to start receiving order"
                      placeholder="active"
                    />
                  </div>
                </div>

                <div className="fields">
                  <div className="three wide field">
                    <label
                      style={{
                        float: "right",
                        lineHeight: "38px",
                      }}
                    ></label>
                  </div>
                  <div className="thirteen wide field">
                    <Chops2uCheckbox
                      name="published"
                      label="Publishe to front page status of the store"
                      placeholder="published"
                      disabled={true}
                    />
                  </div>
                </div>

                {/* <div className="field">
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                </div> */}

                {/* Form Errors */}
                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
              </Segment>
              <Segment attached="bottom" secondary clearing>
                <div>
                  <Button
                    disabled={(!isValid && dirty) || isSubmitting}
                    loading={submitting}
                    positive
                    type="submit"
                    floated="right"
                    content="Save Changes"
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(ProfileForm);
