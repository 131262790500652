import { Formik, Form, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link, useNavigate, useLocation, Location } from "react-router-dom";
import {
  Grid,
  Header,
  Segment,
  Button,
  Message,
  Image,
} from "semantic-ui-react";
import Chops2uTextInputIcon from "../../../app/common/forms/Chops2uTextInputIcon";
import { useStore } from "../../../app/store/store";
import ValidationErrors from "../../frontpage/errors/ValidationErrors";
import { FixedHeightGrid } from "../administrator.style";

const LoginPage = () => {
  const {
    userStore: { login, logining, IsLogedIn },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location.state as { from: Location })?.from?.pathname || "/administrator";

  useEffect(() => {
    if (IsLogedIn) navigate(from, { replace: true });
  }, [IsLogedIn, navigate, from]);

  return (
    <FixedHeightGrid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
        <Formik
          onSubmit={(values, { setErrors }) => {
            login(values)
              .then(() => navigate("/administrator", { replace: true }))
              .catch((error) =>
                setErrors({ error: "Invalid username or password." })
              );
          }}
          initialValues={{ email: "", password: "", error: null }}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form className="ui form error" onSubmit={handleSubmit}>
              <Segment stacked style={{ marginTop: "1em" }}>
                <Header size="tiny" disabled content={"login".toUpperCase()} />
                <Chops2uTextInputIcon
                  name="email"
                  icon="mail"
                  placeholder="info@chops2u.com"
                />

                <Chops2uTextInputIcon
                  name="password"
                  icon="lock"
                  type="password"
                  placeholder="Password"
                />

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>

                <div className="field">
                  <Button
                    loading={isSubmitting && logining}
                    color="olive"
                    fluid
                    size="large"
                  >
                    Login
                  </Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
        <Message>
          New to us? <Link to="/register">Sign Up</Link>
        </Message>
      </Grid.Column>
    </FixedHeightGrid>
  );
};

export default observer(LoginPage);
