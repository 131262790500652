import { observer } from "mobx-react-lite";
import { Card, Grid } from "semantic-ui-react";
import { timeLaps } from "../../../../../../../app/common/util/util";
import Order from "../../../../../../../app/models/order";

const itemStyle = {
  borderRadius: "0",
  padding: "1em 0",
  margin: "0 0",
  marginBottom: "0",
  background: "none",
  border: "none",
  borderBottom: "1px solid rgba(34,36,38,.15)",
  marginLeft: "1em",
  marginRight: "1em",
  cursor: "pointer",
};

const itemActiveStyles = {
  Placed: {
    border: "none",
    borderLeft: "0.5em solid #21ba45",
    borderRight: "1em solid #fff",
    background: "#fff",
    paddingLeft: "0.5em",
    marginLeft: "0",
    marginRight: "0",
    cursor: "default",
  },
  Preparing: {
    border: "none",
    borderLeft: "0.5em solid #00b5ad",
    borderRight: "1em solid #fff",
    background: "#fff",
    paddingLeft: "0.5em",
    marginLeft: "0",
    marginRight: "0",
    cursor: "default",
  },
  Ready: {
    border: "none",
    borderLeft: "0.5em solid #f2711c",
    borderRight: "1em solid #fff",
    background: "#fff",
    paddingLeft: "0.5em",
    marginLeft: "0",
    marginRight: "0",
    cursor: "default",
  },
  InTransit: {
    border: "none",
    borderLeft: "0.5em solid #b5cc18",
    borderRight: "1em solid #fff",
    background: "#fff",
    paddingLeft: "0.5em",
    marginLeft: "0",
    marginRight: "0",
    cursor: "default",
  },
  Delivered: {
    border: "none",
    borderLeft: "0.5em solid red",
    borderRight: "1em solid #fff",
    background: "#fff",
    paddingLeft: "0.5em",
    marginLeft: "0",
    marginRight: "0",
    cursor: "default",
  },
  Cancelled: {},
};

interface Props {
  order: Order;
  selectedOrder?: Order;
  loadOrder: (orderId: string) => void;
}

const OrderCellItem = ({ order, selectedOrder, loadOrder }: Props) => {
  const handleItemClick = (orderId: string) => {
    loadOrder(orderId);
  };

  const activeStyle = itemActiveStyles[order.status];

  return (
    <section
      onClick={() => handleItemClick(order.id)}
      style={
        order.id !== selectedOrder?.id
          ? itemStyle
          : { ...itemStyle, ...activeStyle }
      }
    >
      <Card.Description>
        <Grid>
          <Grid.Column width={8}>{order.deliveryAddress?.name}</Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            {order.status}
          </Grid.Column>
          <Grid.Column textAlign="right" width={5}>
            {timeLaps(order.createdOnUtc.toString())}
          </Grid.Column>
        </Grid>
      </Card.Description>
    </section>
  );
};

export default observer(OrderCellItem);
