import { observer } from "mobx-react-lite";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  Menu,
  Container,
  Icon,
  Dropdown,
  Button,
  Image,
} from "semantic-ui-react";
import LoginForm from "../../../features/frontpage/account/login/LoginForm";
import RegistrationForm from "../../../features/frontpage/account/registration/RegistrationForm";
import { useStore } from "../../store/store";
import LocationMenu from "../LocationMenu";
import CartButton from "./CartButton";
import { Media } from "../../..";

const DesktopTopNav = () => {
  const {
    userStore: { user, IsLogedIn, logout, IsLocationSet },
    modalStore,
  } = useStore();

  const navigate = useNavigate();

  return (
    <Media greaterThan="mobile">
      <Menu fixed="top" size="large" compact style={{ zIndex: 1000 }}>
        <Container>
          <Menu.Item
            as={NavLink}
            exact
            to="/"
            header
            style={{ height: "65px" }}
          >
            <Image as={Link} to="/" src="/images/logo.png" size="small" />
          </Menu.Item>
          <Menu.Item as={NavLink} to="/vendors">
            Vendors
          </Menu.Item>
          {/* <Menu.Item as={NavLink} to="/errors" content="Errors" /> */}
          {IsLocationSet && (
            <Menu.Menu
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {<LocationMenu />}
            </Menu.Menu>
          )}
          <Menu.Item position="right">
            {IsLogedIn ? (
              <Menu.Item style={{ paddingTop: "0", paddingBottom: "0" }}>
                <Icon name="user circle outline" color="olive" size="large" />
                <Dropdown direction="left" text={user?.fullName}>
                  <Dropdown.Menu style={{ width: "200px", marginTop: "10px" }}>
                    <Dropdown.Item as={Link} to={"/account/profile"}>
                      <Icon name="user" color="olive" />
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={"/account"}>
                      <Icon name="cart" color="olive" />
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={"/account/manage-account"}>
                      <Icon name="user plus" color="olive" />
                      Manage Account
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        logout();
                        navigate("/vendors");
                      }}
                    >
                      <Icon name="power" color="olive" />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            ) : (
              <Menu.Item style={{ paddingTop: "0", paddingBottom: "0" }}>
                <div style={{ display: "flex", columnGap: "0px" }}>
                  <Button
                    size="small"
                    circular
                    onClick={() => modalStore.openModal(<LoginForm />)}
                    style={{ minWidth: "55px", backgroundColor: "#fff" }}
                  >
                    Login
                  </Button>
                  <Button
                    size="small"
                    circular
                    color="olive"
                    onClick={() => modalStore.openModal(<RegistrationForm />)}
                    style={{ minWidth: "80px" }}
                  >
                    Sign Up
                  </Button>
                </div>
              </Menu.Item>
            )}
            <Menu.Item style={{ paddingTop: "4px", paddingBottom: "0" }}>
              <CartButton />
            </Menu.Item>
          </Menu.Item>
        </Container>
      </Menu>
    </Media>
  );
};

export default observer(DesktopTopNav);
