import { observer } from "mobx-react-lite";
import Food from "../../../../../app/models/food";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Header,
  List,
  Modal,
  Radio,
  Segment,
} from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import { useEffect, useState } from "react";
import {
  CustomiseContainer,
  CustomiseListContent,
  CustomiseListHeader,
  HeaderLabel,
} from "./customeStyle";
import { GroupingOption } from "../../../../../app/models/menu";
import { toCurrency } from "../../../../../app/common/util/util";
import { ShoppingCartItem } from "../../../../../app/models/shopping-cart";

interface Props {
  foodId: string;
  cartId?: string;
  associatedItems: ShoppingCartItem[];
}

interface TemplateProps {
  [key: string]: InnerTemplateProps;
}

interface InnerTemplateProps {
  [key: string]: Food[];
}

interface CheckState {
  key: string;
  value: boolean;
}

const CustomiseOrder = ({ foodId, cartId, associatedItems }: Props) => {
  const {
    foodStore: { loadFood, getAssociatedFoods, loadingAssociatedFoods },
    shoppingCartStore: { addToCart, updateCartItem },
    modalStore: { closeModal },
  } = useStore();

  const [addingCustomFood, setAddingCustomFood] = useState(false);
  const [food, setFood] = useState<Food | undefined>(undefined);
  const [pageTitle, setPageTitle] = useState("Customise");
  const [foodAmout, setFoodAmout] = useState(0);
  const [associatedFoods, setAssociatedFoods] = useState<Food[]>([]);
  const [formTemplate, setFormTemplate] = useState<TemplateProps>({});
  const [openModal, setOpenModal] = useState(false);

  const [checkState, setCheckState] = useState<CheckState[]>([]);

  useEffect(() => {
    loadFood(foodId)
      .then((result) => {
        setFood(result);
        setFoodAmout(result!.price);
        setPageTitle("Customise `" + result!.title + "`");
      })
      .catch((error) => console.log(error));
  }, [foodId, loadFood]);

  useEffect(() => {
    if (food!!) {
      getAssociatedFoods(foodId).then((associatedFoods) => {
        const updatedCheckState: CheckState[] = associatedFoods!.map(
          (food) => ({
            key: food.id!,
            value: false,
          })
        );
        setFormTemplate(createFormTemplate(associatedFoods!));
        setCheckState(updatedCheckState);
        setAssociatedFoods(associatedFoods!);
      });
    }
  }, [foodId, food, getAssociatedFoods]);

  useEffect(() => {
    if (cartId && associatedItems.length > 0 && checkState.length > 0) {
      associatedItems.forEach((item) => {
        setCheckState((prev) => {
          const newCheckedState = prev.map((state) => {
            if (state.key === item.foodId)
              return { key: item.foodId, value: true };
            return state;
          });

          return newCheckedState;
        });

        setFoodAmout((prev) => prev + item.totalPrice);
      });
    }
  }, [cartId, associatedItems, checkState.length, setCheckState]);

  const createFormTemplate = (foodArray: Food[]) => {
    return foodArray.reduce((template: TemplateProps, currentFood) => {
      let menuTitle = currentFood.menuTitle!;

      if (currentFood.groupingOption === GroupingOption.requiredSingle) {
        template["radio"] = template["radio"] ?? {};
        template["radio"][menuTitle] = template["radio"][menuTitle] ?? [];
        template["radio"][menuTitle].push(currentFood);

        return template;
      } else {
        template["checkbox"] = template["checkbox"] ?? {};
        template["checkbox"][menuTitle] = template["checkbox"][menuTitle] ?? [];
        template["checkbox"][menuTitle].push(currentFood);

        return template;
      }
    }, {});
  };

  const isRequiredFoodSelected = () => {
    let bolCheckValue = true;

    const selectedKeys = checkState.reduce(
      (accumulator: string[], currenItem) => {
        let newAcc: string[] = accumulator ?? [];
        if (currenItem.value === true) newAcc.push(currenItem.key);

        return newAcc;
      },
      []
    );

    for (const key in formTemplate["radio"]) {
      let ids = formTemplate["radio"][key].map((item) => item.id!);

      let bolCheck = ids.some((id) => selectedKeys.indexOf(id) > -1);

      bolCheckValue = bolCheckValue && bolCheck;
    }

    return bolCheckValue;
  };

  const isRequiredMenuSelected = (menuTitle: string) => {
    const values = formTemplate["radio"][menuTitle].map((item) => item.id!);

    if (checkState.some((state) => values.includes(state.key) && state.value))
      return true;

    return false;
  };

  const handleCheckState = (key: string) => {
    let stateVal = false;

    if (checkState && checkState.length)
      stateVal = checkState.filter((item) => item.key === key)[0].value;

    return stateVal;
  };

  const handleCheckboxOnChange = (key: string) => {
    const updatedCheckState = checkState.map((item) =>
      item.key !== key ? item : { key, value: !item.value }
    );

    setCheckState(updatedCheckState);

    const totalPrice = updatedCheckState.reduce((sum, currentState) => {
      if (currentState.value === true)
        return (
          sum +
          associatedFoods.filter((item) => item.id === currentState.key)[0]
            .price
        );

      return sum;
    }, 0);

    setFoodAmout(food!.price + totalPrice);
  };

  const handleRadioOnChange = (e: any, data: CheckboxProps) => {
    const { value, name } = data;
    const values = formTemplate["radio"][name!].map((item) => item.id!);

    const updatedCheckState = checkState.map((item) => {
      if (values.indexOf(item.key) !== -1) {
        return value === item.key
          ? { key: item.key, value: true }
          : { key: item.key, value: false };
      }

      return item;
    });

    setCheckState(updatedCheckState);

    const totalPrice = updatedCheckState.reduce((sum, currentState) => {
      if (currentState.value === true)
        return (
          sum +
          associatedFoods.filter((item) => item.id === currentState.key)[0]
            .price
        );

      return sum;
    }, 0);

    setFoodAmout(food!.price + totalPrice);
  };

  const handleAddToCart = () => {
    if (!isRequiredFoodSelected()) {
      setOpenModal(true);
    } else {
      setAddingCustomFood(true);
      const selectedFoodIds = checkState.reduce<string[]>(
        (accumulator, currentValue) => {
          if (currentValue.value === true)
            accumulator = [...accumulator, currentValue.key];
          return accumulator;
        },
        []
      );

      if (cartId) {
        updateCartItem(cartId, selectedFoodIds)
          .then(() => {
            setAddingCustomFood(false);
            closeModal();
          })
          .catch((error) => console.log(error));
      } else {
        addToCart(foodId, selectedFoodIds)
          .then(() => {
            setAddingCustomFood(false);
            closeModal();
          })
          .catch((error) => {
            setAddingCustomFood(false);
            console.log(error);
          });
      }
    }
  };

  const getBody = (template: TemplateProps) => {
    let result: JSX.Element | null = null;

    for (const key in template["radio"]) {
      result = (
        <>
          <>{result}</>
          <List.Item key={key}>
            <List.Content>
              <List celled relaxed>
                <CustomiseListHeader>
                  {"Choose Your " + key}
                  <HeaderLabel
                    color={isRequiredMenuSelected(key) ? "green" : "orange"}
                  >
                    Required
                  </HeaderLabel>
                </CustomiseListHeader>
                {template["radio"][key].map(({ title, price, id }, index) => (
                  <List.Item key={id!}>
                    <CustomiseListContent floated="left">
                      <Radio
                        id={`radio-button-${id!}`}
                        name={key}
                        value={id!}
                        checked={handleCheckState(id!)}
                        onChange={handleRadioOnChange}
                      />
                    </CustomiseListContent>
                    <CustomiseListContent floated="left">
                      {title}
                    </CustomiseListContent>
                    <CustomiseListContent floated="right">
                      {toCurrency(price)}
                    </CustomiseListContent>
                  </List.Item>
                ))}
              </List>
            </List.Content>
          </List.Item>
        </>
      );
    }

    for (const key in template["checkbox"]) {
      result = (
        <>
          <>{result}</>
          <List.Item key={key}>
            <List.Content>
              <List celled relaxed>
                <CustomiseListHeader>
                  {"Choose Your " + key}
                  <HeaderLabel color="grey">{"(optional)"}</HeaderLabel>
                </CustomiseListHeader>
                {template["checkbox"][key].map(
                  ({ title, price, id }, index) => (
                    <List.Item key={id!}>
                      <CustomiseListContent floated="left">
                        <Checkbox
                          id={`custome-checkbox-${id!}`}
                          name={title}
                          value={title}
                          checked={handleCheckState(id!)}
                          onChange={() => handleCheckboxOnChange(id!)}
                        />
                      </CustomiseListContent>
                      <CustomiseListContent floated="left">
                        {title}
                      </CustomiseListContent>
                      <CustomiseListContent floated="right">
                        {toCurrency(price)}
                      </CustomiseListContent>
                    </List.Item>
                  )
                )}
              </List>
            </List.Content>
          </List.Item>
        </>
      );
    }

    return <List>{result}</List>;
  };

  return (
    <Segment.Group>
      <Modal
        closeIcon
        open={openModal}
        onClose={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
        size="mini"
      >
        <Modal.Content>Required food is not selected</Modal.Content>
        <Modal.Actions>
          <Button content="Ok" onClick={() => setOpenModal(false)} />
        </Modal.Actions>
      </Modal>
      <Segment attached="bottom">
        <Header as="h4" color="olive" content={pageTitle} />
      </Segment>
      <Segment loading={loadingAssociatedFoods} attached>
        <CustomiseContainer>{getBody(formTemplate)}</CustomiseContainer>
      </Segment>
      <Segment attached="bottom" clearing>
        <Button
          loading={addingCustomFood}
          color="olive"
          fluid
          onClick={() => handleAddToCart()}
        >
          {"Add To Cart - " + toCurrency(foodAmout, 0)}
        </Button>
      </Segment>
    </Segment.Group>
  );
};
export default observer(CustomiseOrder);
