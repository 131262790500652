import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import BikeList from "./BikeList";
import BikesEmptyPanel from "./BikesEmptyPanel";
import BikesTopMenu from "./BikesTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const BikesLayoutPage = ({ setPageTitle }: Props) => {
  const {
    bikeStore: { loadBikes, bikes },
  } = useStore();

  useEffect(() => {
    setPageTitle("Bikes");
    loadBikes();
  }, [setPageTitle, loadBikes]);

  return (
    <section>
      <div>
        <BikesTopMenu />
      </div>
      <section>
        {bikes && bikes.length > 0 ? (
          <BikeList data={bikes} />
        ) : (
          <BikesEmptyPanel />
        )}
      </section>
    </section>
  );
};

export default observer(BikesLayoutPage);
