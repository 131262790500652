import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import CategoryEmptyPanel from "./CategoryEmptyPanel";
import CategoryList from "./CategoryList";
import CategoryTopMenu from "./CategoryTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

function CategoryLayoutPage({ setPageTitle }: Props) {
  const {
    categoryStore: { loadCategories, categoriesByTitle },
  } = useStore();

  useEffect(() => {
    setPageTitle("Categories");
    loadCategories();
  }, [setPageTitle, loadCategories]);

  return (
    <section>
      <div>
        <CategoryTopMenu />
      </div>
      <section>
        {categoriesByTitle && categoriesByTitle.length > 0 ? (
          <CategoryList data={categoriesByTitle} setPageTitle={setPageTitle} />
        ) : (
          <CategoryEmptyPanel />
        )}
      </section>
    </section>
  );
}

export default observer(CategoryLayoutPage);
