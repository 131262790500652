import { observer } from "mobx-react-lite";
import { Grid, List, ListContent, ListItem } from "semantic-ui-react";
import { OrderItem } from "../../../../../../app/models/order";
import { toCurrency } from "../../../../../../app/common/util/util";

interface Props {
  item: OrderItem;
  key: number;
}

const OrderDetailsItem = ({ item, key }: Props) => {
  return (
    <>
      <List.Item key={key}>
        <List.Content verticalAlign="middle">
          <List.Description>
            <Grid>
              <Grid.Column width={11}>{item.title}</Grid.Column>
              <Grid.Column width={2} textAlign="center">
                {item.quantity}
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                {toCurrency(item.price)}
              </Grid.Column>
            </Grid>
          </List.Description>
          {item.associatedItems && item.associatedItems.length > 0 && (
            <List size="small">
              {item.associatedItems.map((assocItem) => (
                <ListItem>
                  <ListContent>
                    <Grid>
                      <Grid.Column width={11}>{assocItem.title}</Grid.Column>
                      <Grid.Column width={2} textAlign="center">
                        {assocItem.quantity}
                      </Grid.Column>
                      <Grid.Column width={3} textAlign="right"></Grid.Column>
                    </Grid>
                  </ListContent>
                </ListItem>
              ))}
            </List>
          )}
        </List.Content>
      </List.Item>
    </>
  );
};

export default observer(OrderDetailsItem);
