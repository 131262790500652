import { observer } from "mobx-react-lite";
import { Button, Header, Modal } from "semantic-ui-react";
import Category from "../../../../../app/models/category";
import { useStore } from "../../../../../app/store/store";

interface Props {
  category: Category;
}

const DeleteCategoryForm = ({ category }: Props) => {
  const {
    categoryStore: { delete: deleteCategory, deleting },
    modalStore: { closeModal },
  } = useStore();

  const handleDeleteCommand = () => {
    deleteCategory(category.id!).then(() => closeModal());
  };

  return (
    <>
      <Modal.Header>
        <Header as="h4" content="Delete Category" />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the following Category?</p>
        <Header as="h5" style={{ marginTop: "0em" }}>
          {category.name}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          color="orange"
          labelPosition="left"
          floated="left"
          content="No"
          onClick={closeModal}
          style={{ width: "110px" }}
        />
        <Button
          icon="checkmark"
          labelPosition="left"
          color="olive"
          loading={deleting}
          content="Yes"
          onClick={() => handleDeleteCommand()}
          style={{ width: "110px" }}
        />
      </Modal.Actions>
    </>
  );
};

export default observer(DeleteCategoryForm);
