import { observer } from "mobx-react-lite";
import { useDropzone } from "react-dropzone";
import { Button, Card, Container, Grid, Header, Input, Segment } from "semantic-ui-react";



const ProfilePhoto = () => {
  const { getInputProps, getRootProps } = useDropzone();
  return (
    <Segment vertical>
      <Header color="olive" as="h2">
        Photo
        <Header.Subheader>Add a photo of yourself for your profile</Header.Subheader>
      </Header>
      <Grid>
        <Grid.Column width={16}>
          <Container style={{ border: "1px solid #ccc", borderRadius: "5px"}}>
            <Card style={{ width: "15em"}} centered image="/images/user.png" />
          </Container>
        </Grid.Column>
        <Grid.Column width={16}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Input placeholder="Click here to select a file" fluid action={{ icon: "upload", content: "Upload Image"}} />
          </div>
        </Grid.Column>
        <Grid.Column width={16}>
          <Button color="olive" content="Save" />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(ProfilePhoto);
