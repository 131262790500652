import { Container, Header, List } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const PhishingAndFraud = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">Phishing and Fraud</Header>
      <p>
        At Chops2u, we are committed to providing a safe and secure environment
        for our users. We recognize the importance of protecting our customers
        from phishing attempts and fraudulent activities. This policy outlines
        our guidelines and procedures for identifying and addressing phishing
        and fraud incidents on our platform.
      </p>
      <List ordered>
        <List.Item>
          <List.Header>Definition of Phishing and Fraud</List.Header>
          <List.Content>
            <p>
              Phishing: Phishing refers to the fraudulent practice of sending
              emails, messages, or any form of communication that appears to be
              from a legitimate source, with the intention of tricking
              individuals into revealing sensitive information such as
              passwords, credit card details, or personal information.
            </p>
            <p>
              Fraud: Fraud encompasses any deceptive or dishonest act carried
              out with the intent to gain unauthorized access, deceive, or
              financially exploit our users, merchants, or the platform itself.
            </p>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>User Awareness and Education</List.Header>
          <List as="ol" type="a">
            <List.Item>
              We will regularly communicate with our users to raise awareness
              about phishing and fraud risks, providing tips on how to identify
              and avoid such incidents.
            </List.Item>
            <List.Item>
              Educational resources, including blog posts, FAQ sections, and
              customer support channels, will be provided to inform users about
              common phishing and fraud tactics.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Identification and Reporting</List.Header>
          <List as="ol">
            <List.Item>
              Our platform will implement advanced security measures, including
              anti-phishing and fraud detection systems, to identify and
              mitigate potential threats.
            </List.Item>
            <List.Item>
              Users are encouraged to report any suspicious activity, phishing
              attempts, or fraudulent behavior they encounter on our platform
              through designated channels, such as a dedicated email address or
              an in-app reporting feature.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Investigation and Response</List.Header>
          <List>
            <List.Item>
              Upon receiving reports of phishing or fraud, our dedicated
              security team will promptly investigate the incident.
            </List.Item>
            <List.Item>
              We will take appropriate actions, including temporarily suspending
              accounts suspected of involvement in phishing or fraudulent
              activities, while the investigation is ongoing.
            </List.Item>
            <List.Item>
              If fraudulent activity is confirmed, we will take immediate steps
              to prevent further harm, such as blocking access to affected
              accounts and notifying the affected parties.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Customer Support and Assistance</List.Header>
          <List>
            <List.Item>
              We will provide timely and responsive customer support to address
              any concerns or queries related to phishing or fraud incidents.
            </List.Item>
            <List.Item>
              Our support team will work closely with affected users to assist
              them in recovering their accounts, restoring any lost funds, and
              securing their personal information.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Collaboration with Law Enforcement</List.Header>
          <List>
            <List.Item>
              In cases involving severe fraud or criminal activity, we will
              cooperate with law enforcement agencies and provide them with the
              necessary information and assistance for their investigations.
            </List.Item>
            <List.Item>
              We will maintain open lines of communication with relevant
              authorities to ensure swift and effective action against
              perpetrators of phishing and fraud.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Continuous Improvement</List.Header>
          <List>
            <List.Item>
              We are committed to regularly reviewing and updating our phishing
              and fraud policies and procedures to adapt to evolving threats and
              enhance our security measures.
            </List.Item>
            <List.Item>
              We will leverage technological advancements, industry best
              practices, and user feedback to strengthen our defenses against
              phishing and fraud attempts.
            </List.Item>
          </List>
        </List.Item>
      </List>
      <p>
        By implementing this phishing and fraud policy, we aim to maintain the
        trust and confidence of our users while ensuring a secure and enjoyable
        experience on our food ordering and delivery platform.
      </p>
    </Container>
  );
};

export default PhishingAndFraud;
