import { Button, Header, Icon, Message, Segment } from "semantic-ui-react";
import { CheckoutContainer } from "./checkout-styles";
import { useNavigate } from "react-router-dom";

const CheckoutError = () => {
  // ==================================================
  // route navigator
  // ==================================================
  const navigate = useNavigate();
  return (
    <CheckoutContainer text>
      <Message
        icon="exclamation triangle"
        content="A server error stopped your order from being placed. Please try to place your order again."
        negative
      />
      <Segment placeholder>
        <Header icon>
          <Icon name="box" />
        </Header>
        <Segment.Inline>
          <Button onClick={() => navigate("/checkout")} color="olive">
            Back to Checkout
          </Button>
        </Segment.Inline>
      </Segment>
    </CheckoutContainer>
  );
};
export default CheckoutError;
