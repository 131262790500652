import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import MenuItemText from "../../../common/MenuItemText";
import PageTopMenu from "../../../common/PageTopMenu";
import { VendorMenuItem } from "../vendor.style";

const SecondaryMenu = () => {
  const { id } = useParams();
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState("");

  return (
    <PageTopMenu>
      <VendorMenuItem
        name="info"
        color="olive"
        active={location.pathname.indexOf("info") >= 0}
        onClick={() => setActiveMenu("info")}
        content={<MenuItemText text="Details" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/info`}
      />
      <VendorMenuItem
        name="openinghours"
        color="olive"
        active={location.pathname.indexOf("openinghours") >= 0}
        onClick={() => setActiveMenu("openinghours")}
        content={<MenuItemText text="Opening Hours" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/openinghours`}
      />
      <VendorMenuItem
        name="photo"
        color="olive"
        active={location.pathname.indexOf("photo") >= 0}
        onClick={() => setActiveMenu("photo")}
        content={<MenuItemText text="Photo" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/photo`}
      />
      <VendorMenuItem
        name="menus"
        color="olive"
        active={activeMenu === "menus"}
        onClick={() => setActiveMenu("menus")}
        content={<MenuItemText text="Menus" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/menus`}
      />
      <VendorMenuItem
        name="foods"
        color="olive"
        active={activeMenu === "foods"}
        onClick={() => setActiveMenu("foods")}
        content={<MenuItemText text="Foods" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/foods`}
      />
      <VendorMenuItem
        name="owner"
        color="olive"
        active={activeMenu === "owner"}
        onClick={() => setActiveMenu("owner")}
        content={<MenuItemText text="Owner" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/owner`}
      />
      <VendorMenuItem
        name="account"
        color="olive"
        active={activeMenu === "account"}
        onClick={() => setActiveMenu("account")}
        content={<MenuItemText text="Account" color="olive" />}
        as={Link}
        to={`/administrator/vendors/${id}/account`}
      />
    </PageTopMenu>
  );
};

export default SecondaryMenu;
