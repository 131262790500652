import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import UsersList from "./UsersList";
import UsersEmptyPanel from "./UsersEmptyPanel";
import { useStore } from "../../../../app/store/store";

const UsersLayoutPage = () => {
  const {
    accountManagerStore: { loadUsers, Users },
  } = useStore();

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <section>
      {Users && Users.length > 0 ? (
        <UsersList data={Users} />
      ) : (
        <UsersEmptyPanel />
      )}
    </section>
  );
};

export default observer(UsersLayoutPage);
