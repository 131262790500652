import { Segment } from "semantic-ui-react";
import { CartText, CartTitle, EmptyCartImage } from "./cart-styles";

const EmptyCart = () => {
  return (
    <>
      <Segment>
        <EmptyCartImage src="/images/cart/empty-cart.png" />
        <CartTitle>Your cart is empty</CartTitle>
        <CartText>Add Item to get started</CartText>
      </Segment>
      <div style={{ fontSize: "12px" }}>
        <a href={`/vendors`}>Back to Shopping</a>
      </div>
    </>
  );
};
export default EmptyCart;
