import { Header } from "semantic-ui-react";
import OrderList from "./OrderList";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/store/store";
import OrderEmptyPanel from "./OrderEmptyPanel";

interface Props {
  mobile?: boolean;
}

const OrdersLayoutPage = ({ mobile }: Props) => {
  const {
    userStore: { Orders, createHubConnection, clearOrders },
  } = useStore();

  useEffect(() => {
    createHubConnection();

    return () => {
      clearOrders();
    };
  }, [createHubConnection, clearOrders]);

  return (
    <>
      <Header as="h2" color="olive">
        Orders
      </Header>
      {Orders?.length > 0 ? (
        <OrderList mobile={mobile} />
      ) : (
        <OrderEmptyPanel
          title="No orders yet"
          subtitle="You have not make any order."
          color="olive"
        />
      )}
    </>
  );
};

export default observer(OrdersLayoutPage);
