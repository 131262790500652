import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Segment } from "semantic-ui-react";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { useStore } from "../../../../../app/store/store";
import * as Yup from "yup";
import Food, { FoodType } from "../../../../../app/models/food";

interface Props {
  foodId?: string;
  setEditMode: (state: boolean) => void;
  setFoodId: (foodId?: string) => void;
}

const FoodForm = ({ foodId, setEditMode, setFoodId }: Props) => {
  const {
    menuStore: { loadMenus, MenuOptions },
    foodStore: { loadFood, loading, create, update, submitting },
  } = useStore();
  const [food, setFood] = useState<Food>({
    title: "",
    description: "",
    price: 0,
    otherCharges: 0,
    weight: 0,
    published: true,
    deleted: false,
    type: FoodType.simple,
    visibleIndividually: false,
    menuId: "",
    vendorId: "",
  });
  const { id } = useParams();

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    price: Yup.number()
      .min(100, "price must be greater than or equal to 100 naira")
      .required(),
    menuId: Yup.string().required(),
  });

  useEffect(() => {
    if (id) {
      setFood((prev) => ({ ...prev, vendorId: id }));
      loadMenus(id);
    }

    if (foodId) {
      loadFood(foodId).then((food) => setFood(food!));
    }
  }, [id, loadMenus, foodId, loadFood]);

  const handleFormSubmit = async (values: any, { setErrors }: any) => {
    if (values.id) {
      let updatedFood = { ...values, updatedOnUtc: undefined };
      await update(values.id, updatedFood)
        .then(() => {
          setFoodId(undefined);
          setEditMode(false);
        })
        .catch((error) => setErrors({ error }));
    } else {
      let newFood = { ...values, vendorId: id };
      await create(newFood).then(() => setEditMode(false));
    }
  };

  return (
    <Container text>
      <Segment>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          initialValues={food}
          enableReinitialize
        >
          {({ values, handleSubmit, isSubmitting, isValid, dirty }) => (
            <Form className="form ui" onSubmit={handleSubmit}>
              <Segment loading={loading} vertical clearing>
                <Chops2uSelectInput
                  name="menuId"
                  label="Menu"
                  options={MenuOptions}
                  multiple={false}
                />
                <Chops2uTextInput name="title" label="Title *" />
                <Chops2uTextAreaInput
                  name="description"
                  label="Description"
                  rows={2}
                />
                <Chops2uTextInput name="price" label="Price (NGN) *" />
                <Chops2uTextInput name="weight" label="Weight" />
                <Chops2uCheckbox name="published" label="Published food" />
                <div className="field">{JSON.stringify(values, null, 2)}</div>
                <div className="field">
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    basic
                    size="small"
                    floated="left"
                    color="orange"
                    content="Cancel"
                    onClick={() => setEditMode(false)}
                    style={{ width: "10em" }}
                  />
                  <Button
                    disabled={(!isValid && dirty) || isSubmitting}
                    loading={submitting}
                    type="submit"
                    basic
                    size="small"
                    color="olive"
                    floated="right"
                    content="Save"
                    style={{ width: "10em" }}
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
};

export default observer(FoodForm);
