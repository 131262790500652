import { observer } from "mobx-react-lite";
import { Card, Grid, Segment, Statistic } from "semantic-ui-react";

const AdminDashboard = () => {
  return (
    <div style={{ minHeight: "calc(100vh - 60px)" }}>
      <Segment vertical style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Card.Group itemsPerRow={4}>
          <Card>
            <Card.Content>
              <Card.Header>Weekly Sales</Card.Header>
              <Card.Meta>
                <Statistic value="-12.56%" size="mini" color="orange" />
              </Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header>Total Orders</Card.Header>
              <Card.Meta>
                <Statistic value="126" size="mini" color="green" />
              </Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header>Registered Users</Card.Header>
              <Card.Meta>
                <Statistic value="103" size="mini" color="teal" />
              </Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header>Active Vendors</Card.Header>
              <Card.Meta>
                <Statistic value="16" size="mini" color="violet" />
              </Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>

        <Card.Group itemsPerRow={2}>
          <Card>
            <Card.Content>
              <Card.Header></Card.Header>
              <Card.Meta></Card.Meta>
              <Card.Description></Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header></Card.Header>
              <Card.Meta></Card.Meta>
              <Card.Description></Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
        <Card.Group itemsPerRow={3}>
          <Card fluid>
            <Card.Content>
              <Card.Header>Matthew Harris</Card.Header>
              <Card.Meta>Co-Worker</Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Matthew Harris</Card.Header>
              <Card.Meta>Co-Worker</Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Matthew Harris</Card.Header>
              <Card.Meta>Co-Worker</Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
            <Grid.Column width={4}></Grid.Column>
          </Grid.Row>
        </Grid>

        <Card.Group itemsPerRow={2}>
          <Card>
            <Card.Content>
              <Card.Header>Matthew Harris</Card.Header>
              <Card.Meta>Co-Worker</Card.Meta>
              <Card.Description>
                Matthew is a pianist living in Nashville.
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>
    </div>
  );
};

export default observer(AdminDashboard);
