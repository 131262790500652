import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Chops2uTextAreaInput from "../../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import Menu from "../../../../../app/models/menu";
import * as Yup from "yup";
import Chops2uCheckbox from "../../../../../app/common/forms/Chops2uCheckbox";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

interface Props {
  menuId?: string;
  setEditMode: (state: boolean) => void;
}

const MenuForm = ({ menuId, setEditMode }: Props) => {
  const {
    menuStore: { create, update, loadMenu, submitting },
  } = useStore();
  const [menu, setMenu] = useState<Menu>({
    title: "",
    description: "",
    displayOrder: 0,
    published: false,
    deleted: false,
    foods: [],
  });

  const { id } = useParams();

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    displayOrder: Yup.number().required().moreThan(0),
  });

  useEffect(() => {
    if (menuId) {
      loadMenu(menuId).then((result) => {
        setMenu(result!);
      });
    }

    return () => {
      setMenu({
        title: "",
        description: "",
        displayOrder: 0,
        published: false,
        deleted: false,
        foods: [],
      });
    };
  }, [menuId, loadMenu]);

  const handleFormSubmit = async (values: Menu) => {
    if (values.id) {
      let updatedMenu = { ...values, updatedOnUtc: undefined };
      update(values.id, updatedMenu).then(() => handleCancelClick());
    } else {
      let newMenu = {
        ...values,
        vendorId: id,
      };
      create(newMenu).then(() => setEditMode(false));
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        initialValues={menu}
        enableReinitialize
      >
        {({ handleSubmit, dirty, isValid, isSubmitting, values }) => (
          <>
            <Form className="form ui" onSubmit={handleSubmit}>
              <Segment attached="top">
                <Header as="h2" color="olive" content="Menu Form" />
              </Segment>
              <Segment attached>
                <Chops2uTextInput
                  name="title"
                  label="Title *"
                  placeholder="Title"
                />
                <Chops2uTextAreaInput
                  name="description"
                  label="Description"
                  placeholder="Give a description"
                  rows={2}
                />

                <Chops2uTextInput
                  name="displayOrder"
                  label="Display Order *"
                  placeholder="Display Order"
                />

                <Chops2uCheckbox name="published" label="Publish food menu" />
                {/* <div className="field">{JSON.stringify(values, null, 2)}</div> */}
              </Segment>
              <Segment secondary attached="bottom" clearing>
                <Button
                  floated="left"
                  type="button"
                  disabled={isSubmitting}
                  basic
                  color="orange"
                  content="Cancel"
                  onClick={() => handleCancelClick()}
                  style={{ width: "10em" }}
                />
                <Button
                  floated="right"
                  disabled={(dirty && !isValid) || isSubmitting}
                  loading={submitting}
                  type="submit"
                  basic
                  color="olive"
                  content="Save"
                  style={{ width: "10em" }}
                />
              </Segment>
            </Form>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default observer(MenuForm);
