import { observer } from "mobx-react-lite";
import FlutterwaveButton from "./payment/buttons/FlutterwaveButton";
import PayOnDeliveryButton from "./payment/buttons/PayOnDeliveryButton";
import Address from "../../../app/models/address";

interface IProp {
  paymentMethodId: string;
  paymentOption: number | string | undefined;
  editState: boolean;
  deliveryAddress: Address | null;
}

const CheckoutButton = ({
  paymentOption,
  editState,
  paymentMethodId,
  deliveryAddress,
}: IProp) => {
  return (
    <div>
      <p style={{ fontSize: "12px", color: "#aaaaaa" }}>
        By completing your order, you agree to these{" "}
        <a href="/termsandconditions">Terms of Service.</a>
      </p>
      {/* <p style={{ fontSize: "12px", color: "#aaaaaa", marginTop: "1em" }}>
        You will be proceeding to flutterwave secured payment to complete your
        transaction.
      </p> */}
      {/* <FlutterwaveButton
        data={checkoutFormValues}
        formState={formState}
        paymentMethodId={paymentMethodId}
      /> */}
      {paymentOption === "1" ? (
        <FlutterwaveButton
          editState={editState}
          paymentMethodId={paymentMethodId}
          deliveryAddress={deliveryAddress}
        />
      ) : (
        <PayOnDeliveryButton
          editState={editState}
          paymentMethodId={paymentMethodId}
          deliveryAddress={deliveryAddress}
        />
      )}
      {/* <Button disabled={!formState} color="olive" fluid size="large">
        Process Payment
      </Button> */}
    </div>
  );
};

export default observer(CheckoutButton);
