import { observer } from "mobx-react-lite";
import { Dropdown, Menu } from "semantic-ui-react";
import { MenuContainer, TopMenu } from "../../administrator.style";

const PayoutsTopMenu = () => {
  return (
    <TopMenu attached="top" borderless>
      <MenuContainer>
        <Menu.Item>
          <Dropdown labeled text={"Filter"} icon="filter">
            <Dropdown.Menu></Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </MenuContainer>
    </TopMenu>
  );
};

export default observer(PayoutsTopMenu);
