import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import OrderDashboard from "./dashboard/OrderDashboard";
import OrderEmptyPanel from "./OrderEmptyPanel";

interface Props {
  setPageTitle: (title: string) => void;
}

const OrdersPageLayout = ({ setPageTitle }: Props) => {
  const {
    restaurantStore: { restaurant, HasActiveOrder, createHubConnection, clearOrders },
  } = useStore();

  useEffect(() => {
    setPageTitle("Orders");
  }, [setPageTitle]);

  useEffect(() => {
    if (restaurant?.id) {
      createHubConnection(restaurant.id);
    }

    return () => {
      clearOrders();
    }
  }, [restaurant?.id, createHubConnection, clearOrders]);

  return (
    <div style={{ overflowX: "hidden"}}>
      {HasActiveOrder ? <OrderDashboard /> : <OrderEmptyPanel />}
    </div>
  );
};

export default observer(OrdersPageLayout);
