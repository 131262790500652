import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
  Divider,
  Image,
  Icon,
} from "semantic-ui-react";

interface Props {
  mobile?: boolean;
}

const SectionFooter = ({ mobile }: Props) => {
  let location = useLocation();
  return (
    <Segment
      inverted
      vertical
      style={
        mobile
          ? { marginTop: "5px", paddingTop: "20px" }
          : {
              margin: "1em 0 0 0",
              padding: "5em 0em",
              position: "relative",
              bottom: 0,
              left: 0,
              right: 0,
            }
      }
    >
      <Container textAlign="left">
        <Grid inverted>
          <Grid.Column computer={4} table={10} mobile={10}>
            <Image
              src="/images/logo-gray.png"
              style={{
                height: "auto",
                width: mobile ? "120px" : "215px",
                marginTop: mobile ? "0px" : "-25px",
              }}
            />
            <List style={{ color: "grey" }}>
              <List.Item
                style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
              >
                Chops2u Services
              </List.Item>
              <List.Item
                style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
              >
                No. 13 Road 69B
              </List.Item>
              <List.Item
                style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
              >
                6th Avenue, Gwarinpa
              </List.Item>
              <List.Item
                style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
              >
                Speak with us: +234 9167-6666-80
              </List.Item>
            </List>
            <div style={{ display: "flex", gap: "5px" }}>
              <a href="https://www.twitter.com/chops2u_">
                <Icon color="grey" name="twitter" circular inverted />
              </a>
              <a href="https://www.facebook.com/chops2u">
                <Icon color="grey" name="facebook f" circular inverted />
              </a>
              <a href="https://www.instagram.com/chops2u.ng">
                <Icon color="grey" name="instagram" circular inverted />
              </a>
              <a href="https://www.linkedin.com/company/chops2u">
                <Icon color="grey" name="linkedin" circular inverted />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={6}>
            <Header
              style={{ marginTop: "8px" }}
              inverted
              as="h4"
              content="COMPANY"
            />
            <List
              link
              inverted
              style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
            >
              <List.Item
                active={location.pathname.indexOf("aboutus") >= 0}
                as={Link}
                to="aboutus"
              >
                About us
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("pricing") >= 0}
                as={Link}
                to="pricing"
              >
                Pricing
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("career") >= 0}
                as={Link}
                to="aboutus"
              >
                Career
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={4} tablet={10} mobile={10}>
            <Header inverted as="h4" content="CONTACT" />
            <List
              link
              inverted
              style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
            >
              <List.Item
                active={location.pathname.indexOf("contactus") >= 0}
                as={Link}
                to="contactus"
              >
                Contact Us
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("partnerwithus") >= 0}
                as={Link}
                to="partnerwithus"
              >
                Partner with us
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("ridewithus") >= 0}
                as={Link}
                to="ridewithus"
              >
                Ride with us
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={6}>
            <Header inverted as="h4" content="LEGAL" />
            <List
              link
              inverted
              style={mobile ? { fontSize: "0.9em" } : { fontSize: "1em" }}
            >
              <List.Item
                active={location.pathname.indexOf("termsandconditions") >= 0}
                as={Link}
                to="termsandconditions"
              >
                Terms & Conditions
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("payoutpolicy") >= 0}
                as={Link}
                to="payoutpolicy"
              >
                Payout Policy
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("privacypolicy") >= 0}
                as={Link}
                to="privacypolicy"
              >
                Privacy Policy
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("cookiepolicy") >= 0}
                as={Link}
                to="cookiepolicy"
              >
                Cookie Policy
              </List.Item>
              {/* <List.Item
                active={location.pathname.indexOf("offerterms") >= 0}
                as={Link}
                to="/pages/offerterms"
              >
                Offer Terms
              </List.Item> */}
              <List.Item
                active={location.pathname.indexOf("phishingandfraud") >= 0}
                as={Link}
                to="phishingandfraud"
              >
                Phishing & Fraud
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
        <Divider inverted section />
        <Grid>
          <Grid.Column textAlign="center" width={16}>
            {/* <div style={{ alignItems: "center" }}></div> */}
            <Image
              src="/images/logo-chrome.png"
              size="tiny"
              style={{ margin: "auto" }}
            />
            <List
              horizontal
              inverted
              divided
              link
              size="small"
              verticalAlign="middle"
            >
              <List.Item
                active={location.pathname.indexOf("contactus") >= 0}
                as={Link}
                to="contactus"
              >
                Contact Us
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("termsandconditions") >= 0}
                as={Link}
                to="termsandconditions"
              >
                Terms and Conditions
              </List.Item>
              <List.Item
                active={location.pathname.indexOf("privacypolicy") >= 0}
                as={Link}
                to="privacypolicy"
              >
                Privacy Policy
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
};

export default observer(SectionFooter);
