import {
  Segment,
  Grid,
  Header,
  Button,
  Image,
  Container,
} from "semantic-ui-react";
import Vendor from "../../../../../app/models/vendor";

interface Props {
  vendor: Vendor;
  setEditMode: (state: boolean) => void;
}

const VendorPhotoDetailsPage = ({ vendor, setEditMode }: Props) => {
  const handlePhotoDelete = async (photoId: string) => {
    console.log(photoId);
    setEditMode(false);
  };
  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Segment>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header
                icon="image"
                as="h5"
                color="olive"
                content={"Image: " + vendor.title}
              />
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Image fluid src={vendor.image || "/Images/placeholder.png"} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="orange"
                disabled={
                  vendor.image === null ||
                  vendor.image === "" ||
                  vendor.image === undefined
                }
                basic
                fluid
                content="Delete Photo"
                onClick={() => handlePhotoDelete(vendor!.image!)}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                color="olive"
                basic
                fluid
                content="Edit Photo"
                onClick={() => setEditMode(true)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default VendorPhotoDetailsPage;
