import { RegisterVendorFormValues } from "./../models/user";
import {
  VendorBankAccount,
  BankAccountFormValues,
  OpeningInfo,
  TimeZone,
  VendorFormValues
} from "./../models/vendor";
import Location, { LocationFormValues } from "../models/location";
import { PaginatedResult } from "./../models/pagination";
import { store } from "./../store/store";
import Category, { CategoryFormValues } from "./../models/category";
import axios, { AxiosError, AxiosResponse } from "axios";
import Vendor from "../models/vendor";
import { toast } from "react-toastify";
import User, { UserFormValues } from "../models/user";
import { Photo, Profile } from "../models/profile";
import County from "../models/county";
import Menu from "../models/menu";
import Food, { FoodPhoto } from "../models/food";
import { ShoppingCartItem } from "../models/shopping-cart";
import City from "../models/city";
import Checkout from "../models/checkout";
import { UserRole, UserAccount } from "../models/user-account";
import { history } from "../..";
import Order from "../models/order";
import Bike, {
  BikeMake,
  BikeFormValues,
  BikeMakeFormValues,
  BikeRegistrationFormValues,
} from "../models/bike";
import Chopper from "../models/chopper";
import Shipment from "../models/shipment";
import Bank, { BankFormValues } from "../models/bank";
import Gateway from "../models/gateway";
import Payment, { PaymentResponse } from "../models/payment";
import { ChopperRegistrationFormValues } from "../../features/chopper/ChopperRegistrationPage";
import Address from "../models/address";
import AppUser from "../models/app-user";
import ResetPassword from "../models/reset-password";
import ChangePassword from "../models/change-password";
import ChangeEmail from "../models/change-email";

export const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  if (process.env.NODE_ENV === "development") sleep(1000);

  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000);

    const pagination = response.headers["pagination"];

    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );

      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    // console.log(error);
    // console.log(error.response!);
    const { data, status, config, headers } = error.response as AxiosResponse;

    switch (status) {
      case 400:
        if (typeof data === "string") {
          // console.log("Inside string test");
          toast.error(data);
          break;
        }

        if (Array.isArray(data.errors) && config.method === "post") {
          const modalStateErrors: string[] = [];
          data.errors.forEach((err: any) => {
            modalStateErrors.push(err["description"]);
          });
          return Promise.reject(modalStateErrors.flat());
        }

        if (data.errors.hasOwnProperty("id") && config.method === "get") {
          toast.error("not found");
          history.push("/not-found");
          console.log(data.errors);
        }

        if (data?.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }

          return Promise.reject(modalStateErrors.flat());
        }
        break;
      case 401:
        if (
          status === 401 &&
          headers["www-authenticate"]?.startsWith('Bearer')
        ) {
          if (store.userStore.IsLogedIn) store.userStore.logout();
          toast.error("Session expired - please login again.");
        } else {
          toast.error("unauthorized");
        }
        break;
      case 403:
        if (status === 403 && data === "") {
          toast.error("You dont have the privilege to perform this process.");
        } else {
          toast.error("Forbidden");
        }
        break;
      case 404:
        if (config.method === "get" && config.url === "/addresses/default") break;
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) =>
    axios.get<T>(url, { withCredentials: true }).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body, { withCredentials: true }).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(url, body, { withCredentials: true }).then(responseBody),
  delete: <T>(url: string) =>
    axios.delete<T>(url, { withCredentials: true }).then(responseBody),
};

const Vendors = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<PaginatedResult<Vendor[]>>("/vendors", {
        params,
        withCredentials: true,
      })
      .then(responseBody),
  details: (id: string) => requests.get<Vendor>(`/vendors/${id}`),
  create: (vendor: Vendor) => requests.post<Vendor>("/vendors", vendor),
  update: (id: string, vendor: VendorFormValues) =>
    requests.put<Vendor>(`/vendors/${id}`, vendor),
  delete: (id: string) => requests.delete<void>(`/vendors/${id}`),
  uploadPhoto: async (id: string, file: Blob) => {
    const formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .put<Photo>(`/photos/vendors/${id}`, formData, {
        headers: { "Content-type": "multipart/form-data" },
        withCredentials: true,
      });
    return responseBody(response);
  },
  updatePhoto: async (id: string, file: Blob) => {
    const formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .post<Photo>(`/photos/vendors/${id}`, formData, {
        headers: { "Content-type": "multipart/form-data" },
        withCredentials: true,
      });
    return responseBody(response);
  },
  deletePhoto: (id: string) => requests.delete<void>(`/photos/vendors/${id}`),
  listOpeningInfos: (id: string) =>
    requests.get<OpeningInfo[]>(`/vendors/openingInfos/${id}`),
  addUpdateOpeningInfos: (id: string, openingInfo: OpeningInfo[]) =>
    requests.post<void>(`/vendors/openingInfos/${id}`, openingInfo),
  deleteOpeningInfos: (id: string) =>
    requests.delete<void>(`/vendors/openingInfos/${id}`),
  addOrUpdateBankAccount: (id: string, bankAccount: BankAccountFormValues) =>
    requests.post<VendorBankAccount>(`/vendors/bankAccount/${id}`, bankAccount),
  getBankAccount: (bankAccountId: string) =>
    requests.get<VendorBankAccount>(`/vendors/bankAccount/${bankAccountId}`),
};

const Categories = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Category[]>("/categories", { params, withCredentials: true })
      .then(responseBody),
  details: (id: string) => requests.get<Category>(`/categories/${id}`),
  create: (category: CategoryFormValues) =>
    requests.post<Category>("/categories", category),
  update: (id: string, category: CategoryFormValues) =>
    requests.put<Category>(`/categories/${id}`, category),
  delete: (id: string) => requests.delete<void>(`/categories/${id}`),
};

const Cities = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<City[]>("Cities", { params, withCredentials: true })
      .then(responseBody),
  details: (id: string) => requests.get<City>(`/cities/${id}`),
  create: (city: City) => requests.post<City>("/cities", city),
  update: (id: string, city: City) => requests.put<City>(`/cities/${id}`, city),
  delete: (id: string) => requests.delete<void>(`cities/${id}`),
};

const Locations = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Location[]>("/locations", { params, withCredentials: true })
      .then(responseBody),
  detail: (id: string) => requests.get<Location>(`/locations/${id}`),
  create: (location: LocationFormValues) =>
    requests.post<Location>("/locations", location),
  update: (id: string, location: LocationFormValues) =>
    requests.put<Location>(`/locations/${id}`, location),
  delete: (id: string) => requests.delete<void>(`/locations/${id}`),
  deleteMany: (params: URLSearchParams) =>
    axios
      .delete<void>("/locations", { params, withCredentials: true })
      .then(responseBody),
};

const Account = {
  getCurrentUser: () => requests.get<User>("/account/currentUser"),
  getUserRestaurant: () => requests.get<Vendor>("/account/userStore"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  fbLogin: (accessToken: string) => requests.post<User>(`/account/fbLogin?accessToken=${accessToken}`, {}),
  register: (user: UserFormValues) =>
    requests.post<User>("/account/register", user),
  refreshToken: () => requests.post<User>("/account/refreshToken", {}),
  registerVendor: (vendor: RegisterVendorFormValues) =>
    requests.post<User>("/account/registerVendor", vendor),
  registerChopper: (user: ChopperRegistrationFormValues) =>
    requests.post<User>("/account/registerChopper", user),
  verifyEmail: (token: string, email: string) => requests.post<void>(`/account/verifyEmail?token=${token}&email=${email}`, {}),
  resendEmailConfirm: (email: string) => requests.get<void>(`/account/resendEmailConfirmationLink?email=${email}`),
  resendVendorEmailConfirm: (email: string) => requests.get<void>(`/store/resendVendorEmailConfirmLink?email=${email}`),
  forgotPassword: (email: string) => requests.post<void>(`/account/forgot?email=${email}`, {}),
  adminResetPassword: (values: ResetPassword) => requests.post<void>("/account/adminReset", values),
  resetPassword: (values: ResetPassword) => requests.post<User>("/account/reset", values),
  changePassword: (values: ChangePassword) => requests.post<void>('/account/changePassword', values),
  changeEmail: (values: ChangeEmail) => requests.post<void>('/account/changeEmail', values)
};

const AccountManager = {
  list: () => requests.get<AppUser[]>("/accountManager/users"),
  details: (email: string) => requests.get<User>(`/accountManager/${email}`),
  roleList: () => requests.get<UserRole[]>("/accountManager/roles"),
  roleDetails: (roleName: string) =>
    requests.get<UserRole>(`/accountManager/roles/${roleName}`),
  createRole: (roleName: string) =>
    requests.post<UserRole>(`/accountManager/roles/${roleName}`, {}),
  deleteRole: (roleName: string) =>
    requests.delete<void>(`/accountManager/roles/${roleName}`),
  listAccountType: () => requests.get<UserAccount[]>("/accountManager/accountTypes"),
  accountTypeDetails: (id: string) => requests.get<UserAccount>(`/accountManager/accountTypes/${id}`),
  addRoleToAccountType: (accountTypeId: string, roleName: string) =>
    requests.post<void>(`/accountManager/accountTypes/${accountTypeId}/addRole/${roleName}`, {}),
  removeRoleOnAccountType: (accountTypeId: string, roleName: string) =>
    requests.post<void>(`/accountManager/accountTypes/${accountTypeId}/reoveRole/${roleName}`, {}),
};

const Profiles = {
  get: (email: string) => requests.get<Profile>(`/profiles/${email}`),
  update: (profile: Profile) => requests.post<Profile>(`/profiles/update`, profile),
  uploadPhoto: async (file: Blob) => {
    const formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .put<Photo>("/photos/users", formData, {
        headers: { "Content-type": "multipart/form-data" },
        withCredentials: true,
      });
    return responseBody(response);
  },
  addUpdatePhoto: async (file: Blob) => {
    const formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .post<Photo>(`/photos/users`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
    return responseBody(response);
  },
  setMainPhoto: (id: string) =>
    requests.post<void>(`/photos/users/${id}/setmain`, {}),
  deletePhoto: (id: string) => requests.delete<void>(`/photos/users/${id}`),
  getAddresses: () => requests.get<Address[]>("/addresses"),
  getDefaultAddress: () => requests.get<Address>("/addresses/default"),
  getAddress: (id: string) => requests.get<Address>(`/addresses/${id}`),
  addAddress: (address: Address) => requests.post<Address>("/addresses", address),
  updateAddress: (address: Address) => requests.put<Address>("/addresses", address),
  deleteAddress: (id: string) => requests.delete(`/addresses/${id}`)
};

const Counties = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<County[]>("/counties", { params, withCredentials: true })
      .then(responseBody),
};

const Menus = {
  list: (params: URLSearchParams) =>
    axios
      .get<Menu[]>("/menus", { params, withCredentials: true })
      .then(responseBody),
  listFrontPage: (params: URLSearchParams) =>
    axios
      .get<Menu[]>("/menus/vendor", { params, withCredentials: true })
      .then(responseBody),
  details: (id: string) => requests.get<Menu>(`/menus/${id}`),
  create: (menu: Menu) => requests.post<Menu>("/menus", menu),
  update: (id: string, menu: Menu) => requests.put<Menu>(`/menus/${id}`, menu),
  delete: (id: string) => requests.delete<void>(`/menus/${id}`),
  deleteMany: (params: URLSearchParams) =>
    axios
      .delete<void>("/menus", { params, withCredentials: true })
      .then(responseBody),
};

const Foods = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Food[]>("/foods", {
        params,
        withCredentials: true,
      })
      .then(responseBody),
  details: (id: string) => requests.get<Food>(`/foods/${id}`),
  create: (food: Food) => requests.post<Food>("/foods", food),
  update: (id: string, food: Food) => requests.put<Food>(`/foods/${id}`, food),
  delete: (id: string) => requests.delete<void>(`/foods/${id}`),
  deleteMany: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .delete<void>("/foods", { params, withCredentials: true })
      .then(responseBody),
  uploadPhoto: async (id: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .post<FoodPhoto>(`/photos/Foods/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
    return responseBody(response);
  },
  deletePhoto: (id: string) => requests.delete<void>(`/photos/Foods/${id}`),
  getAssociatedFoods: (groupedParentFoodId: string) =>
    requests.get<Food[]>(`/foods/Grouping/${groupedParentFoodId}?predicate=associated`)
};

const Carts = {
  getCartId: () => requests.get<string>("/carts/GetIdentifyer"),
  getItems: (cartId: string) =>
    requests.get<ShoppingCartItem[]>(`/carts/Items/${cartId}`),
  getItemsByVendor: (vendorId: string) =>
    requests.get<ShoppingCartItem[]>(`/carts/Items/Vendor/${vendorId}`),
  getItemsByUser: () => requests.get<ShoppingCartItem[]>(`/carts/Items/User`),
  getAssociatedItems: (id: string) => requests.get<ShoppingCartItem[]>(`/carts/AssociatedItems/${id}`),
  addToCart: (foodId: string, associatedFoodIds: string[]) =>
    requests.post<ShoppingCartItem>(`/carts/AddToCart/${foodId}`, associatedFoodIds),
  updateCart: (id: string, associatedFoodIds: string[]) =>
    requests.put<ShoppingCartItem>(`/carts/UpdateCartItem/${id}`, associatedFoodIds),
  removeFromCart: (id: number) =>
    requests.put<void>(`/carts/RemoveFromCart/${id}`, {}),
  delete: (cartId: string) => requests.delete<void>(`/carts/${cartId}`),
  checkout: (data: Checkout) =>
    requests.post<void>("/carts/Checkout", data)
};

const Payments = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios.get<Payment[]>("/payments", { params, withCredentials: true }).then(responseBody),
  getGateways: () => requests.get<Gateway[]>("/payments/gateways"),
  getTransactionRef: () => requests.get<string>("/payments/getTransactionReference"),
  begin: (payment: Payment) => requests.post<PaymentResponse>("/payments/begin", payment),
  cancel: (txnRef: string, reason: string) =>
    requests.post<void>("/payments/cancel", { transactionRef: txnRef, reason }),
}

const AccountTypes = {
  list: () => requests.get<UserAccount[]>("/accountManager/accountTypes"),
  details: (id: string) =>
    requests.get<UserAccount>(`/accountManager/accountTypes/${id}`),
  addRole: (id: string, roleName: string) =>
    requests.post<void>(
      `/accountManager/accountTypes/${id}/addRole/${roleName}`,
      {}
    ),
  removeRole: (id: string, roleName: string) =>
    requests.delete<void>(
      `/accountManager/accountTypes/${id}/removeRole/${roleName}`
    ),
};

const TimeZones = {
  list: () => requests.get<TimeZone[]>("/timeZone"),
};

const Orders = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Order[]>("/orders", { params, withCredentials: true })
      .then(responseBody),
  details: (id: string) => requests.get<Order>(`/orders/${id}`),
  StoreList: (
    storeId: string,
    params: URLSearchParams = new URLSearchParams()
  ) =>
    axios
      .get<Order[]>(`/orders/store/${storeId}`, {
        params,
        withCredentials: true,
      })
      .then(responseBody),
  confirm: (orderId: string) => requests.post<void>(`/orders/confirm/${orderId}`, {}),
  finished: (orderId: string) => requests.post<void>(`/orders/finish/${orderId}`, {}),
  collect: (orderId: string) => requests.post<void>(`/orders/collect/${orderId}`, {}),
  delivered: (orderId: string) => requests.post<void>(`/orders/deliver/${orderId}`, {}),
  cancel: (orderId: string) => requests.post<void>(`/orders/cancel/${orderId}`, {}),
  delete: (orderId: string) => requests.delete<void>(`/orders/delete/${orderId}`),
  assignChopper: (orderId: string, chopperId: string, comment: string = "") =>
    requests.post<void>(`/orders/assignChopper/${orderId}?chopperId=${chopperId},comment=${comment}`, {}),
  reassignChopper: (orderId: string, chopperId: string, comment: string = "") =>
    requests.post<void>(`/orders/reassignChopper/${orderId}?chopperId=${chopperId},comment=${comment}`, {}),
  removeChopper: (orderId: string) =>
    requests.delete<void>(`/orders/removeChopper/${orderId}`),
};

const Bikes = {
  list: () => requests.get<Bike[]>("/bikes"),
  details: (id: string) => requests.get<Bike>(`/bikes/${id}`),
  create: (bike: BikeFormValues) => requests.post<Bike>("/bikes", bike),
  update: (id: string, bike: BikeFormValues) =>
    requests.put<Bike>(`/bikes/${id}`, bike),
  delete: (id: string) => requests.delete<void>(`/bikes/${id}`),
  register: (id: string, registration: BikeRegistrationFormValues) =>
    requests.post<Bike>(`/bikes/registration/${id}`, registration),
  assignRider: (id: string, chopperId: string) =>
    requests.post<Bike>(`/bikes/assignRider/${id}?chopperId=${chopperId}`, {}),
  removeRider: (id: string) =>
    requests.delete<void>(`/bikes/assignRider/${id}`),
  listMake: () => requests.get<BikeMake[]>("/bikes/makes"),
  detailsMake: (id: string) => requests.get<BikeMake>(`/bikes/make/${id}`),
  createMake: (make: BikeMakeFormValues) =>
    requests.post<BikeMake>("/bikes/makes", make),
  deleteMake: (id: string) => requests.delete<void>(`/bikes/makes/${id}`)
  // listChoppers: () => requests.get<Chopper[]>("/bikes/listChoppers"),
};

const Banks = {
  list: () => requests.get<Bank[]>("/banks"),
  details: (id: string) => requests.get<Bank>(`/banks/${id}`),
  create: (bank: BankFormValues) => requests.post<Bank>("/banks", bank),
  update: (id: string, bank: BankFormValues) =>
    requests.put<Bank>(`/banks/${id}`, bank),
  delete: (id: string) => requests.delete<void>(`/banks/${id}`),
};

const Choppers = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Chopper[]>("/choppers", { params, withCredentials: true })
      .then(responseBody),
  listUnassigned: () => requests.get<Chopper[]>("/choppers/unassigned"),
  orders: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Order[]>("/choppers/orders", { params, withCredentials: true })
      .then(responseBody),
  shipments: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Shipment[]>("/choppers/shipments", {
        params,
        withCredentials: true,
      })
      .then(responseBody),
};

const Shipments = {
  list: (params: URLSearchParams = new URLSearchParams()) =>
    axios
      .get<Shipment[]>("/shipments", { params, withCredentials: true })
      .then(responseBody),
  details: (id: string) => requests.get<Shipment>(`/shipments/${id}`),
  cancent: (id: string, comment: string) => requests.post<void>(`/shipments/${id}?comment=${comment}`, {}),
  delete: (id: string, comment: string) => requests.delete<void>(`/shipments/${id}?comment=${comment}`),
  orderShipment: (orderId: string) => requests.get<Shipment>(`/shipments/orderShipment/${orderId}`),
  StoreList: (
    vendorId: string,
    params: URLSearchParams = new URLSearchParams()
  ) =>
    axios
      .get<Shipment[]>(`/shipments/store/${vendorId}`, {
        params,
        withCredentials: true,
      })
      .then(responseBody),
};

const agent = {
  Vendors,
  Categories,
  Cities,
  Locations,
  Account,
  Profiles,
  Counties,
  Menus,
  Foods,
  Carts,
  AccountManager,
  AccountTypes,
  TimeZones,
  Orders,
  Bikes,
  Banks,
  Choppers,
  Shipments,
  Payments
};

export default agent;
