import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  Button,
  Grid,
  Header,
  Image,
  Modal,
  SemanticCOLORS,
} from "semantic-ui-react";
import Food from "../../../../../app/models/food";
import { useStore } from "../../../../../app/store/store";
import PhotoCropper from "./PhotoCropper";
import PhotoDropzone from "./PhotoDropzone";

interface Props {
  food: Food;
  color: SemanticCOLORS;
  setEditMode: (state: boolean) => void;
}
const FoodImageUploadForm = ({ food, setEditMode, color }: Props) => {
  const {
    foodStore: { uploadPhoto, uploading },
    modalStore,
  } = useStore();
  const [files, setFiles] = useState<any[]>([]);
  const [cropper, setCropper] = useState<Cropper>();

  function OnCrop() {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((file) => {
        uploadPhoto(food.id!, file!).then(() => {
          setEditMode(false);
          modalStore.closeModal();
        });
      });
    }
  }

  function handleCancelButton() {
    setEditMode(false);
    modalStore.closeModal();
  }

  return (
    <Modal.Content>
      <div>
        <Grid>
          <Grid.Column width={16}>
            <Grid.Row>
              <Header
                as="h5"
                color={color}
                icon="image"
                content={food.title + " Photo Upload"}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header as="h5" color="grey" content="Image" />
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Image
                  src={food.image || "/Images/placeholder.png"}
                  style={
                    files && files.length > 0
                      ? { display: "none" }
                      : { display: "block" }
                  }
                />
                <PhotoDropzone setFiles={setFiles} />
                {files && files.length > 0 && (
                  <PhotoCropper
                    setCropper={setCropper}
                    imagePreview={files[0].preview}
                    initialAspectRatio={1}
                    aspectRation={1}
                  />
                )}
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header color="grey" as="h5" content="Preview" />
              <div
                className="img-preview"
                style={{
                  width: "100%",
                  minHeight: "163px",
                  border: "1px dashed grey",
                  overflow: "hidden",
                }}
              ></div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Button
                onClick={() => handleCancelButton()}
                disabled={uploading}
                basic
                fluid
                color="orange"
                content="Cancel"
                size="large"
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Button
                onClick={OnCrop}
                loading={uploading}
                basic
                fluid
                color={color}
                content="Save"
                size="large"
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    </Modal.Content>
  );
};

export default observer(FoodImageUploadForm);
