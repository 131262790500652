import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Menu, Container, Header, Icon, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import ChopperTopMenuPanel from "./ChopperTopMenuPanel";

const menuStyles = {
  borderRadius: 0,
  zIndex: 1000,
  width: "cacl(100vw - 250px)",
  marginBottom: "0",
};

interface ChopperTopMenuProps {
  title: string;
}

const ChopperTopMenu = ({ title }: ChopperTopMenuProps) => {
  const {
    userStore: { IsLogedIn, logout },
  } = useStore();
  return (
    <Menu size="large" borderless style={menuStyles}>
      <Container
        style={{
          width: "95%",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Menu.Item position="left">
          <Header as="h4" content={title} />
        </Menu.Item>
        <Menu.Item position="right">
          <Menu.Item>
            <Icon color="olive" size="large" name="bell outline" />
          </Menu.Item>
          {IsLogedIn && (
            <Menu.Item>
              <Icon color="olive" size="large" name="user circle outline" />
              <Dropdown
                // text={user?.firstName.toLowerCase()}
                direction="left"
                icon={<Icon name="caret down" />}
              >
                <Dropdown.Menu style={{ marginTop: "20px", borderRadius: "0" }}>
                  <Dropdown.Header
                    style={{ backgroundColor: "gray", paddingTop: "0" }}
                  >
                    <ChopperTopMenuPanel />
                  </Dropdown.Header>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/store/support">
                    <Icon name="shield" color="green" />
                    Technical Support
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => logout()}>
                    <Icon name="power" color="green" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(ChopperTopMenu);
