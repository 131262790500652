import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Icon, MenuHeader } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import { MenuItemsContainer, TopMenu } from "./administrator.style";

interface AdminTopMenuProps {
  pageTitle: string;
}

const AdminTopMenu = ({ pageTitle }: AdminTopMenuProps) => {
  const {
    userStore: { user, logout, IsLogedIn },
  } = useStore();

  return (
    <TopMenu size="large" borderless>
      <MenuItemsContainer>
        <Menu.Item>
          <MenuHeader as="h4" content={pageTitle} />
        </Menu.Item>
        {IsLogedIn && (
          <Menu.Item>
            <Icon size="large" color="olive" name="user circle outline" />
            <Dropdown text={user?.fullName.toLowerCase()}>
              <Dropdown.Menu direction="left" style={{ marginTop: "1em" }}>
                <Dropdown.Item key="user" as={Link} to={`/profiles/bob`}>
                  <Icon name="user" color="olive" />
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item key="logout" onClick={logout}>
                  <Icon name="power" color="olive" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}
      </MenuItemsContainer>
    </TopMenu>
  );
};

export default observer(AdminTopMenu);
