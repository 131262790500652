import { MenuContainer, TopMenu } from "../administrator.style";

interface IProps {
  children: React.ReactNode;
}

const PageTopMenu = ({ children }: IProps) => {
  return (
    <TopMenu attached="top" borderless>
      <MenuContainer>{children}</MenuContainer>
    </TopMenu>
  );
};
export default PageTopMenu;
