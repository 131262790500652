import { Link } from "react-router-dom";
import { Container, Dropdown, Icon, Menu, Header } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import StoreTopMenuPanel from "../StoreTopMenuPanel";
import { observer } from "mobx-react-lite";

interface IProps {
  title: string;
  setVisibility: (state: boolean) => void;
}

const StoreTopMenuMobile = ({ title, setVisibility }: IProps) => {
  const {
    userStore: { IsLogedIn, logout },
  } = useStore();
  // const navigate = useNavigate();
  return (
    <Menu
      fixed="top"
      size="small"
      compact
      style={{
        zIndex: 1000,
      }}
    >
      <Container>
        <Menu.Item position="left">
          <Menu.Item onClick={() => setVisibility(true)}>
            <Icon color="green" name="sidebar" />
          </Menu.Item>
          <Menu.Item>
            <Header as="h4" content={title} />
          </Menu.Item>
        </Menu.Item>
        <Menu.Item position="right">
          <Menu.Item>
            <Icon color="green" size="large" name="bell outline" />
          </Menu.Item>
          {IsLogedIn && (
            <Menu.Item>
              <Icon color="green" size="large" name="user circle outline" />
              <Dropdown
                // text={user?.firstName.toLowerCase()}
                direction="left"
                icon={<Icon name="caret down" />}
              >
                <Dropdown.Menu
                  style={{ marginTop: "20px", borderRadius: "0", zIndex: 1000 }}
                >
                  <Dropdown.Header
                    style={{ backgroundColor: "gray", paddingTop: "0" }}
                  >
                    <StoreTopMenuPanel />
                  </Dropdown.Header>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/store/support">
                    <Icon name="shield" color="green" />
                    Technical Support
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => logout()}>
                    <Icon name="power" color="green" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(StoreTopMenuMobile);
