import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const ChoppersEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="No Chopper Register Yet!"
      subtitle="See Active Choppers and assign Bikes and Orders to them."
      color="olive"
    />
  );
};

export default ChoppersEmptyPanel;
