import { styled } from "styled-components";

export const OrderQuantity = styled.span`
  vertical-align: top;
  display: inline-block;
  width: 15% !important;
`;

export const OrderTitle = styled.span`
  display: inline-block;
  width: 85% !important;
  white-space: nowrap;
`;