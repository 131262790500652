import { Grid, Segment } from "semantic-ui-react";
import Order from "../../../../../../app/models/order";
import { toCurrency } from "../../../../../../app/common/util/util";

interface Props {
  order: Order;
}

const OrderDetailsBodyFooter = ({ order }: Props) => {
  return (
    <Segment vertical>
      <Grid
        relaxed
        style={{ fontWeight: 600, fontSize: "13px", letterSpacing: "1px" }}
      >
        <Grid.Row style={{ borderRadius: "0", padding: "1em 0 0 0" }}>
          <Grid.Column width={7} textAlign="left">
            {order.items.length} total item
          </Grid.Column>
          <Grid.Column width={5} textAlign="right">
            Items Subtotal
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {toCurrency(order.orderSubTotal)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ borderRadius: "0", padding: "5px 0 0 0" }}>
          <Grid.Column width={7} textAlign="left"></Grid.Column>
          <Grid.Column width={5} textAlign="right">
            Delivery Fee
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {toCurrency(order.deliveryFee)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ borderRadius: "0", padding: "5px 0 0 0" }}>
          <Grid.Column width={7} textAlign="left"></Grid.Column>
          <Grid.Column width={5} textAlign="right">
            Total
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {toCurrency(order.orderTotal)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default OrderDetailsBodyFooter;
