import styled from "styled-components";

/** Dashboad */
export const DashboardWrapper = styled.section`
  display: flex;
`;

export const ActiveOrderSection = styled.section`
  flex: 2;
  height: calc(100vh - 62px);
  box-shadow: 1px 0 2px 0 rgba(34,36,38,.15);
  border-left: 1px solid rgba(34,36,38,.15);
  /* backgroundColor: rgba(180, 192, 164, 0.3); */
`;

export const OrderDetailsSection = styled.section`
  flex: 3;
`;