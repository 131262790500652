import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Container, Icon, Button } from "semantic-ui-react";
import ChopperSidebarBanner from "./ChopperSidebarBanner";

const ChopperSidebar = () => {
  const [target, setTarget] = useState("home");

  const location = useLocation();

  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name?: string
  ) => {
    if (name !== undefined) setTarget(name);
  };

  return (
    <>
      <div style={{ flex: "0 0 60px", backgroundColor: "gray" }}>
        <ChopperSidebarBanner />
      </div>
      <div
        style={{
          flex: "1 0 auto",
          overflowY: "auto",
          scrollbarColor: "darkgray gray",
          scrollbarWidth: "thin",
          backgroundColor: "#767676",
        }}
      >
        <Menu
          fluid
          borderless
          vertical
          inverted
          size="small"
          color="grey"
          style={{
            border: "none",
            boxShadow: "none",
            height: "auto",
            borderRadius: "0",
          }}
        >
          <Container>
            <Menu.Item
              as={Link}
              to="/chopper"
              name="current-orders"
              active={location.pathname === "/chopper"}
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              {/* <Icon
                name="dashboard"
                style={{ float: "left", marginRight: "0.6em" }}
              /> */}
              <Button size="mini" compact color="olive">Current Orders</Button>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/chopper/shipments"
              name="shipments"
              active={
                target === "shipments" ||
                location.pathname === "/chopper/shipments"
              }
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <Icon
                name="shipping fast"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Shipment History
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/chopper/payments"
              name="payouts"
              active={
                target === "payouts" || location.pathname === "/chopper/payments"
              }
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <Icon
                name="money"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Collected Payments
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/chopper/vehicle"
              name="support"
              active={
                target === "support" ||
                location.pathname.indexOf("/chopper/vehicle") >= 0
              }
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <Icon
                name="motorcycle"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Vehicle
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/chopper/settings"
              name="settings"
              active={
                target === "settings" ||
                location.pathname.indexOf("/chopper/settings") >= 0
              }
              onClick={(e, { name }) => handleItemClick(e, name)}
            >
              <Icon
                name="settings"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Settings
            </Menu.Item>
          </Container>
        </Menu>
      </div>
      <div
        style={{
          flex: "0 0 80px",
          marginTop: "0",
          paddingTop: "1em",
          paddingLeft: "1.5em",
          paddingBottom: "3em",
          height: "auto",
          overflowY: "auto",
          scrollbarColor: "darkgray gray",
          scrollbarWidth: "thin",
          backgroundColor: "gray",
        }}
      ></div>
    </>
  );
};

export default ChopperSidebar;
