import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import DeleteRoleForm from "./forms/DeleteRoleForm";
import RoleForm from "./forms/RoleForm";
import { UserRole } from "../../../../app/models/user-account";
import { PageWrapper } from "../../administrator.style";

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const RoleList = () => {
  const {
    accountManagerStore: { userRoles, loadRoles },
    modalStore: { openModal },
  } = useStore();

  const columns = [
    {
      name: "name",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <Button
              icon="delete"
              basic
              circular
              color="orange"
              size="mini"
              onClick={() => handleDeleteButtonClick(tableMeta)}
            />
          );
        },
        customHeadLabelRender: headerLabelRenderer,
      },
    },
  ];

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  function handleDeleteButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    let role: UserRole = userRoles[rowIndex];
    // console.log(tableMeta);
    // console.log(role);
    openModal(<DeleteRoleForm role={role} />);
  }

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  const handleAddButtonClick = () => {
    // console.log("Add Role");
    openModal(<RoleForm />);
  };

  return (
    <PageWrapper>
      <div className="ui form">
        <div className="field">
          <Button
            icon="plus"
            color="olive"
            content="Add Role"
            floated="right"
            labelPosition="left"
            onClick={() => handleAddButtonClick()}
          />
        </div>
        <div className="field">
          <Segment vertical>
            <MuiThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                title={null}
                data={userRoles}
                columns={columns}
                options={{
                  filter: "false",
                  download: "false",
                  print: "false",
                  viewColumns: "false",
                  responsive: "standard",
                  rowsPerPage: 10,
                  rowsPerPageOptions: [10],
                  count: userRoles.length,
                  page: 0,
                  setTableProps: () => {
                    return { size: "small" };
                  },
                }}
              />
            </MuiThemeProvider>
          </Segment>
        </div>
      </div>
    </PageWrapper>
  );
};

export default observer(RoleList);
