import { useField } from "formik";
import { Form, Icon, Label } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface Props {
  name: string;
  icon: SemanticICONS | undefined;
  label?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
}

const Chops2uTextInputIcon = ({
  name,
  label,
  icon,
  placeholder,
  disabled,
  type,
}: Props) => {
  const [field, meta] = useField(name);
  return (
    <Form.Field
      label={label}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="ui left icon input">
        <Icon size="small" name={icon} />
        <input
          {...field}
          name={name}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
        />
      </div>
      {meta.touched && meta.error ? (
        <Label pointing style={{ float: "left" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export default Chops2uTextInputIcon;
