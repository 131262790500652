import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

// component import
import SectionBanner from "./SectionBanner";
import SectionFooter from "./SectionFooter";
import SectionLocations from "./SectionLocations";
import DesktopContainerHomePage from "./containers/DesktopContainerHomePage";
import MobileContainerHomePage from "./containers/MobileContainerHomePage";
import { useStore } from "../../app/store/store";
import { useEffect } from "react";

const HomePage = () => {
  const {
    locationStore: { loadLocations },
    userStore: { IsLogedIn, IsLocationSet },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    loadLocations();

    if (IsLogedIn || IsLocationSet) navigate("/vendors");

  }, [loadLocations, IsLogedIn, IsLocationSet, navigate]);

  return (
    <>
      <DesktopContainerHomePage>
        <SectionBanner />
        <SectionLocations />
        <SectionFooter />
      </DesktopContainerHomePage>
      <MobileContainerHomePage>
        <SectionBanner mobile={true} />
        <SectionLocations mobile={true} />
        <SectionFooter mobile={true} />
      </MobileContainerHomePage>
      {/* <SectionLocations /> */}
    </>
  );
};

export default observer(HomePage);
