import { observer } from "mobx-react-lite";
import { List } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import { MenuList } from "./store.style";

const StoreTopMenuPanel = () => {
  const {
    userStore: { user },
    restaurantStore: { restaurant },
  } = useStore();
  return (
    <MenuList
      inverted
      // style={{
      //   paddingTop: "1em",
      //   paddingBottom: "1em",
      //   marginTop: "-1em",
      //   fontSize: "1.2em",
      //   zIndex: 1001,
      // }}
    >
      <List.Item key="name">{user?.fullName}</List.Item>
      <List.Item key="email">{user?.email}</List.Item>
      <List.Item key="title">{restaurant?.title}</List.Item>
    </MenuList>
  );
};

export default observer(StoreTopMenuPanel);
