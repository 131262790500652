import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../app/store/store";
import ShipmentList from "./ShipmentList";
import ShipmentsEmptyPanel from "./ShipmentsEmptyPanel";
import ShipmentsTopMenu from "./ShipmentsTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const ShipmentsLayoutPage = ({ setPageTitle }: Props) => {
  const {
    shipmentStore: { ShipmentsByDate, loadShipments },
  } = useStore();

  useEffect(() => {
    setPageTitle("Shipments");
    loadShipments();
  }, [setPageTitle, loadShipments]);

  return (
    <section>
      <div>
        <ShipmentsTopMenu />
      </div>
      <section>
        {ShipmentsByDate && ShipmentsByDate.length > 0 ? (
          <ShipmentList data={ShipmentsByDate} />
        ) : (
          <ShipmentsEmptyPanel />
        )}
      </section>
    </section>
  );
};

export default observer(ShipmentsLayoutPage);
