import { Grid, Header, Segment } from "semantic-ui-react";

const headerStyle = {
  color: "#fff",
};

const subheaderStyle = {
  color: "#bbb",
};

interface Props {
  isNowOpen: boolean;
}

const VendorRating = ({ isNowOpen }: Props) => {
  return (
    <Segment vertical style={{ width: "100%" }}>
      <Grid inverted divided columns="equal" style={{ paddingTop: "1em" }}>
        <Grid.Column style={{ paddingTop: "0", paddingBottom: "0" }}>
          <Header as="h4">
            <Header.Content style={headerStyle}>4.0</Header.Content>
            <Header.Subheader style={subheaderStyle}>
              500+ Ratings
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column style={{ paddingTop: "0", paddingBottom: "0" }}>
          {isNowOpen ? (
            <Header as="h4">
              <Header.Content style={headerStyle}>45 mins</Header.Content>
              <Header.Subheader style={subheaderStyle}>
                Delivery Time
              </Header.Subheader>
            </Header>
          ) : (
            <Header as="h4">
              <Header.Content style={headerStyle}>Closed</Header.Content>
              <Header.Subheader style={subheaderStyle}>
                For Delivery
              </Header.Subheader>
            </Header>
          )}
        </Grid.Column>
        <Grid.Column style={{ paddingTop: "0", paddingBottom: "0" }}>
          <Header as="h4">
            <Header.Content style={headerStyle}>N500</Header.Content>
            <Header.Subheader style={subheaderStyle}>
              Delivery Cost
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default VendorRating;
