import { Container, Header } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const AboutUs = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">
        About Us
      </Header>
      <p>
        Welcome to Chops2u, your premier platform for excellent food delivery
        services, a wide variety of local culinary delights, and an opportunity
        for food vendors and entrepreneurs to showcase their culinary
        masterpiece. We are passionate about connecting food lovers with the
        vibrant flavors of their community and providing a seamless dining
        experience right at their doorstep.
      </p>
      <p>
        At Chops2u we believe that great food should be easily accessible to
        everyone. That's why we are building a collection of diverse food
        vendors, each offering their unique culinary creations. From traditional
        recipes to innovative creations from up-and-coming chefs, we have
        something to satisfy every craving and cater to every taste bud.
      </p>
      <p>
        We believe in the power of community and are dedicated to providing a
        platform for local food vendors and entrepreneurs to showcase their
        businesses. By partnering with us, these talented individuals can reach
        a broader audience, grow their customer base, and thrive in the
        competitive food industry.
      </p>
      <p><b>Our Values:</b></p>
      <ol>
        
        <li>
        <p>Excellent Service Delivery</p>
          We take pride in our commitment to delivering exceptional service to
          our customers. From the moment you place your order to the moment it
          arrives at your door, we ensure a smooth and efficient process,
          focused on your satisfaction.
        </li>
        <li>
        <p>Local Cuisine</p>
          We celebrate the rich tapestry of local cuisines and work to bring you
          an unparalleled variety of flavors. Whether you're in the mood for
          classic comfort food, ethnic delicacies, or trendy fusion dishes,
          you'll find something to tantalize your taste buds.
        </li>
        <li>
        <p>Build with humility</p>
          We put collective success before individual achievements. We aim to
          promote and preserve the unique flavors and traditions of local
          cuisine.
        </li>
      </ol>
      <p>
        Join us in celebrating the diverse and delicious local food scene.
        Whether you're a food enthusiast seeking new culinary adventures, a
        vendor looking to expand your reach, or simply someone in need of a
        flavorful meal, Chops2u is here to fulfill your cravings.
      </p>
      <p>
        Contact us today and embark on a gastronomic journey that celebrates the
        exceptional flavors of your community. Let us bring the local food
        experience to your doorstep, while supporting and empowering local food
        vendors and entrepreneurs.
      </p>
      <p>
        Discover, savor, and experience the best of your neighborhood with
        Chops2u!
      </p>
    </Container>
  );
};

export default AboutUs;
