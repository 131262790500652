import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Header } from "semantic-ui-react";
import { BikeMake } from "../../../../app/models/bike";
import { useStore } from "../../../../app/store/store";
import DeleteMakeForm from "./forms/DeleteMakeForm";
import MakeForm from "./forms/MakeForm";
import { PageWrapper } from "../../administrator.style";

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

interface Props {
  data: BikeMake[];
}

const MakeList = ({ data }: Props) => {
  const {
    modalStore: { openModal },
  } = useStore();
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2em" }}
            >
              <Button
                icon="edit"
                basic
                color="olive"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />
              <Button
                icon="delete"
                basic
                color="orange"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  function handleEditButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    openModal(<MakeForm id={data[rowIndex].id} />, "mini");
  }

  function handleDeleteButtonClick(tableMeta: any) {
    const { rowIndex } = tableMeta;
    console.log(tableMeta);
    // openModal(<DeleteCategoryForm category={data[rowIndex]} />);
    openModal(<DeleteMakeForm make={data[rowIndex]} />, "mini");
  }

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });
  return (
    <PageWrapper>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </PageWrapper>
  );
};

export default MakeList;
