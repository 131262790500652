import { Segment } from "semantic-ui-react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { date: "13-12-21", sales: 2245.45, orders: 2, ats: 1126.25 },
  { date: "14-12-21", sales: 15420.25, orders: 8, ats: 1927.53 },
  { date: "15-12-21", sales: 26845.6, orders: 9, ats: 2982.84 },
  { date: "16-12-21", sales: 56400.2, orders: 20, ats: 2820.01 },
  { date: "17-12-21", sales: 38500.63, orders: 22, ats: 1750.02 },
  { date: "18-12-21", sales: 19850.5, orders: 10, ats: 1985.05 },
  { date: "19-12-21", sales: 14940.35, orders: 7, ats: 2134.33 },
];

const GraphPanel = () => {
  return (
    <Segment>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={1020} height={300} data={data}>
          <Line type="monotone" dataKey="sales" stroke="#8867df" />
          <Line type="monotone" dataKey="ats" stroke="#569f3a" />
          <CartesianGrid stroke="#ccc" strokeDasharray="4 4" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Segment>
  );
};

export default GraphPanel;
