import Offer from "../../models/offer";

export const Offers: Offer[] = [
  // {
  //   title: "New User 50%",
  //   description: "50% off up to N2000 on your first order",
  //   code: "TRYNEW",
  // },
  // {
  //   title: "Above N1000 orders",
  //   description: "30% off up to N3000 on orders above N1000",
  //   code: "PARTY",
  // },
  {
    title: "N500 Delivery Fee Promo",
    description: "Flat fee to any location. Ends 30-Jun-2023. SIGN UP NOW!"
  }
];
