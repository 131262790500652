import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import {
  MenuItemHeader,
  SideNav,
  SideNavFooter,
  SideNavLogo,
  SideNavMenu,
} from "./administrator.style";
import { SideNavMenuIcon } from "../features.style";

const AdminSidebar = () => {
  const [target, setTaget] = useState("dashboard");

  const location = useLocation();

  function handleItemClick(e: any, name?: string) {
    if (name !== undefined) setTaget(name);
  }

  return (
    <SideNav>
      <SideNavLogo>
        <Image
          as={Link}
          to="/"
          src="/images/logo.png"
          alt="chops2u"
          size="small"
          style={{
            margin: "auto",
            paddingBottom: "10px",
          }}
        />
      </SideNavLogo>
      <SideNavMenu>
        <Menu
          fluid
          color="black"
          vertical
          borderless
          inverted
          style={{ border: "none", boxShadow: "none", minHeight: "100%" }}
        >
          <Menu.Item
            as={Link}
            to="/administrator"
            name="dashboard"
            key="dashboard"
            active={location.pathname === "/administrator"}
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="dashboard" />
            Dashboard
          </Menu.Item>
          <MenuItemHeader>COMMERCE</MenuItemHeader>
          <Menu.Item
            as={Link}
            to="/administrator/categories"
            name="categories"
            key="categories"
            active={
              target === "categories" ||
              location.pathname.indexOf("/administrator/categories") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="list alternate outline" />
            Categories
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/bike-makes"
            name="bike-makes"
            key="bike-makes"
            active={
              target === "bike-makes" ||
              location.pathname.indexOf("/administrator/bike-makes") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="bookmark" />
            Bike Make
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/bikes"
            name="bikes"
            key="bikes"
            active={
              target === "bikes" ||
              location.pathname.indexOf("/administrator/bikes") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="motorcycle" />
            Bikes
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/vendors"
            name="vendors"
            key="vendors"
            active={
              target === "vendors" ||
              location.pathname.indexOf("/administrator/vendors") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="shopping basket" />
            Vendors
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/choppers"
            name="choppers"
            key="choppers"
            active={
              target === "choppers" ||
              location.pathname.indexOf("/administrator/choppers") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="shipping fast" />
            Choppers
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/orders"
            name="orders"
            key="orders"
            active={
              target === "orders" ||
              location.pathname.indexOf("/administrator/orders") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="cart" />
            Orders
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/shipments"
            name="shipments"
            key="shipments"
            active={
              target === "shipments" ||
              location.pathname.indexOf("/administrator/shipments") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="shipping" />
            Shipments
          </Menu.Item>
          <MenuItemHeader>TRANSACTIONS</MenuItemHeader>
          <Menu.Item
            as={Link}
            to="/administrator/payments"
            name="payments"
            key="payments"
            active={
              target === "payments" ||
              location.pathname.indexOf("/administrator/payments") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="money" />
            Payments
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/refunds"
            name="refunds"
            key="refunds"
            active={
              target === "refunds" ||
              location.pathname.indexOf("/administrator/refunds ") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="undo" />
            Refunds
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/payouts"
            name="payouts"
            key="payouts"
            active={
              target === "payouts" ||
              location.pathname.indexOf("/administrator/payouts") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="box" />
            Withdrawals/Payouts
          </Menu.Item>
          <MenuItemHeader>PLACE</MenuItemHeader>
          <Menu.Item
            as={Link}
            to="/administrator/states"
            name="states"
            key="states"
            active={
              target === "states" ||
              location.pathname.indexOf("/administrator/states") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="map" />
            States
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/cities"
            name="cities"
            key="cities"
            active={
              target === "cities" ||
              location.pathname.indexOf("/administrator/cities") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="map signs" />
            Cities
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/locations"
            name="locations"
            key="locations"
            active={
              target === "locations" ||
              location.pathname.indexOf("/administrator/locations") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="map marker alternate" />
            Locations
          </Menu.Item>
          <MenuItemHeader>ACCOUNT</MenuItemHeader>
          <Menu.Item
            as={Link}
            to="/administrator/account-manager/users"
            name="users"
            key="users"
            active={
              target === "users" ||
              location.pathname.indexOf(
                "/administrator/account-manager/users"
              ) >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="group" />
            Users
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/account-manager/accountTypes"
            name="accountTypes"
            key="accountTypes"
            active={
              target === "accountTypes" ||
              location.pathname.indexOf(
                "/administrator/account-manager/accountTypes"
              ) >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="lock" />
            Account Types
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/administrator/account-manager/roles"
            name="roles"
            key="roles"
            active={
              target === "roles" ||
              location.pathname.indexOf(
                "/administrator/account-manager/roles"
              ) >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="chess rock" />
            Roles
          </Menu.Item>
          <MenuItemHeader>SUPPORT</MenuItemHeader>
          <Menu.Item
            as={Link}
            to="/administrator/settings/support"
            name="support"
            key="support"
            active={
              target === "support" ||
              location.pathname.indexOf("/administrator/settings/support") >= 0
            }
            onClick={(e, { name }) => handleItemClick(e, name)}
          >
            <SideNavMenuIcon name="shield" />
            Tickets
          </Menu.Item>
        </Menu>
      </SideNavMenu>
      <SideNavFooter>Footer</SideNavFooter>
    </SideNav>
  );
};

export default AdminSidebar;
