import styled from "styled-components";
import { Container, Header, List, ListHeader } from "semantic-ui-react";

export const CustomiseContainer = styled(Container)`
  min-height: 170px;
  max-height: 250px;
  padding: 0px 15px;
  overflow-y: scroll;
`;

export const CustomiseList = styled(List)`
    margin: ${(props) => props.theme.Spacing[2]};
`;

export const CustomiseListHeader = styled(ListHeader)`
  font-size: ${props => props.theme.Size[3]};
  letter-spacing: ${props => props.theme.Spacing[1]};
  padding-bottom: ${props => props.theme.Spacing[5]};
`;

export const CustomiseListContent = styled(List.Content)`
  color: gray;
  font-size: ${props => props.theme.Size[3]};
  letter-spacing: ${props => props.theme.Spacing[0]};
`;

export const CustomiseSpacer = styled.div`
  width: 100%;
  padding: 0px 10px;
`;

export const HeaderLabel = styled(Header).attrs({ size: "tiny" })`
  /* color: gray; */
  float: right;
  /* font-size: 12px !important; */
  font-weight: 300 !important;
`;

