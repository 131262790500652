import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router";
import { useStore } from "../store/store";

// Component import
import LoadingComponent from "./LoadingComponent";
import HomePage from "../../features/home/HomePage";
import AppLayout from "./AppLayout";
import PageLayout from "../../features/pages/PageLayout";
import RequireAuth from "./Auth/RequireAuth";
import AdminLayout from "../../features/administrator/AdminLayout";
import StoreLayoutPage from "../../features/store/StoreLayoutPage";
import FrontpageLayout from "../../features/frontpage/FrontpageLayout";
import NotFound from "../../features/frontpage/errors/NotFound";
import Unauthorized from "../../features/frontpage/errors/Unauthorized";
import TestErrors from "../../features/frontpage/errors/TestErrors";
import ServerError from "../../features/frontpage/errors/ServerError";
import ChopperLayoutPage from "../../features/chopper/ChopperLayoutPage";
import VendorRegistrationPage from "../../features/store/register/VendorRegistrationPage";
import ChopperRegistrationPage from "../../features/chopper/ChopperRegistrationPage";
import VendorRegisterSuccess from "../../features/store/register/VendorRegisterSuccess";
import VendorConfirmEmail from "../../features/store/register/VendorConfirmEmail";
import RequireStoreAuth from "./Auth/RequireStoreAuth";
import VendorLoginPage from "../../features/store/login/VendorLoginPage";
import AboutUs from "../../features/pages/components/AboutUs";
import Careers from "../../features/pages/components/Careers";
import ContactUs from "../../features/pages/components/ContactUs";
import CookiePolicy from "../../features/pages/components/CookiePolicy";
import DownloadApp from "../../features/pages/components/DownloadApp";
import HelpAndSupport from "../../features/pages/components/HelpAndSupport";
import OfferTerms from "../../features/pages/components/OfferTerms";
import PartnerWithUs from "../../features/pages/components/PartnerWithUs";
import PhishingAndFraud from "../../features/pages/components/PhishingAndFraud";
import PrivacyPolicy from "../../features/pages/components/PrivacyPolicy";
import PayoutPolicy from "../../features/pages/components/PayoutPolicy";
import Pricing from "../../features/pages/components/Pricing";
import TermsAndConditions from "../../features/pages/components/TermsAndConditions";
import RideWithUs from "../../features/pages/components/RideWithUs";
import VendorDetails from "../../features/frontpage/vendors/details/VendorDetails";
import VendorsDashboard from "../../features/frontpage/vendors/dashboard/VendorsDashboard";
import Checkout from "../../features/frontpage/checkout/Checkout";
import CheckoutSuccess from "../../features/frontpage/checkout/CheckoutSuccess";
import CheckoutError from "../../features/frontpage/checkout/CheckoutError";
import AccountType from "../models/user-account";
import LoginPage from "../../features/administrator/login/LoginPage";
import ForgotPassword from "../../features/frontpage/account/login/ForgotPassword";
import ResetPassword from "../../features/frontpage/account/login/ResetPassword";
import ResetPasswordSuccess from "../../features/frontpage/account/login/ResetPasswordSuccess";
import RegistrationPage from "../../features/frontpage/account/registration/RegistrationPage";
import RegisterSuccess from "../../features/frontpage/account/registration/RegisterSuccess";
import ConfirmEmail from "../../features/frontpage/account/registration/ConfirmEmail";
import AccountLayout from "../../features/frontpage/account/AccountLayout";

// Authenticated Users
const authUsers = [
  AccountType.Foodie,
  AccountType.Staffer,
  AccountType.StoreOwner,
  AccountType.Chopper,
  AccountType.Manager,
  AccountType.Admin,
  AccountType.SuperAdmin,
];

// Administrative Users
const adminUsers = [AccountType.SuperAdmin, AccountType.Admin];

// Store Staff
const storeStaff = [AccountType.StoreOwner, AccountType.Staffer];

// Riders
const choppers = [AccountType.Chopper, AccountType.Manager];

function App() {
  const { userStore, commonStore, shoppingCartStore } = useStore();

  useEffect(() => {
    /*
     * Check if user logged in and session is not expired then
     * re-log user.
     */
    if (commonStore.token) {
      userStore.getUser().finally(() => {
        commonStore.setAppLoaded();
      });
    } else {
      commonStore.setAppLoaded();
    }

    if (commonStore.deliveryLocation) {
      userStore.getDeliveryLocation();
    }

    shoppingCartStore.loadIncompletedCart();
  }, [commonStore, userStore, shoppingCartStore]);

  if (!commonStore.appLoaded) <LoadingComponent content="Loading app..." />;

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />
        <Route path="account/login" element={<LoginPage />} />
        <Route path="account/forgot" element={<ForgotPassword />} />
        <Route path="account/reset" element={<ResetPassword />} />
        <Route
          path="account/reset-success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="account/register" element={<RegistrationPage />} />
        <Route path="account/register-success" element={<RegisterSuccess />} />
        <Route path="account/verifyEmail" element={<ConfirmEmail />} />
        <Route path="store/login" element={<VendorLoginPage />} />
        <Route path="store/register" element={<VendorRegistrationPage />} />
        <Route
          path="store/register-success"
          element={<VendorRegisterSuccess />}
        />
        <Route path="store/verifyEmail" element={<VendorConfirmEmail />} />
        <Route path="chopper/register" element={<ChopperRegistrationPage />} />
        <Route path="unauthorize" element={<Unauthorized />} />

        <Route element={<PageLayout />}>
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="career" element={<Careers />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="cookiepolicy" element={<CookiePolicy />} />
          <Route path="downloadapp" element={<DownloadApp />} />
          <Route path="helpandsupport" element={<HelpAndSupport />} />
          <Route path="offerterms" element={<OfferTerms />} />
          <Route path="partnerwithus" element={<PartnerWithUs />} />
          <Route path="phishingandfraud" element={<PhishingAndFraud />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="payoutpolicy" element={<PayoutPolicy />} />
          <Route path="ridewithus" element={<RideWithUs />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="termsandconditions" element={<TermsAndConditions />} />
        </Route>

        {/* application frontpage */}
        <Route element={<FrontpageLayout />}>
          <Route path="vendors" element={<VendorsDashboard />} />
          <Route path="vendors/:id" element={<VendorDetails />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="checkout-success" element={<CheckoutSuccess />} />
          <Route path="checkout-failure" element={<CheckoutError />} />
          <Route path="errors" element={<TestErrors />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="server-error" element={<ServerError />} />

          <Route element={<RequireAuth allowedRoles={authUsers} />}>
            <Route path="account/*" element={<AccountLayout />} />
          </Route>
        </Route>
        {/* Pages */}
        {/* <Route path="pages/*" element={<PageLayout />} /> */}
        {/* Content Pages */}

        {/* Authenticated Routes */}
        <Route element={<RequireAuth allowedRoles={adminUsers} />}>
          <Route path="administrator/*" element={<AdminLayout />} />
        </Route>

        <Route element={<RequireStoreAuth allowedRoles={storeStaff} />}>
          <Route path="store/*" element={<StoreLayoutPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={choppers} />}>
          <Route path="chopper/*" element={<ChopperLayoutPage />} />
        </Route>

        <Route path="not-Found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default observer(App);
