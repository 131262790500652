import React from "react";
import { Segment } from "semantic-ui-react";

const VendorEmptyList = () => {
  return (
    <Segment style={{ minHeight: "33.5vh" }} vertical>
      <p style={{ color: "grey" }}>
        No Vendors found based on your search criteria
      </p>
    </Segment>
  );
};

export default VendorEmptyList;
