import { Container, Header, List } from "semantic-ui-react";
import { toCurrency } from "../../../app/common/util/util";

const pageStyle = {
  marginTop: "6em",
};

const PayoutPolicy = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">Payout Policy</Header>
      <p>
        At Chops2u, we provide a reliable and streamlined payout policy to
        ensure timely and convenient payments for our partner restaurants. Our
        policy aims to strike a balance between regular payouts and financial
        stability.
      </p>
      <Header as="h5">Payout Frequency</Header>
      <List as="ul">
        <List.Item as="li">
          Daily Payouts: We offer daily payouts to restaurants for the orders
          processed through our platform.
        </List.Item>
        <List.Item as="li">
          Minimum Payout Threshold: Payouts will be initiated when the
          restaurant's balance reaches or exceeds {toCurrency(5000,2)}. This threshold helps
          ensure efficient processing and reduces transaction costs.
        </List.Item>
      </List>
      <Header as="h5">Payment Methods</Header>
      <List as="ul">
        <List.Item as="li">
          Bank Transfer: Payouts are made directly to the restaurant's
          designated bank account. We require restaurants to provide accurate
          banking information to facilitate seamless transfers.
        </List.Item>
      </List>
      <Header as="h5">Payout Schedule</Header>
      <List as="ul">
        <List.Item as="li">
          Payout Timing: Once the minimum payout threshold of {toCurrency(5000, 2)} is met, the
          payout will be initiated within 4 hours after the end of each business
          day.
        </List.Item>
        <List.Item as="li">
          Weekends and Holidays: Payouts are processed on all business days,
          excluding weekends and public holidays. In such cases, payouts will be
          processed on the next business day.
        </List.Item>
      </List>
      <Header as="h5">Billing and Invoicing</Header>
      <List as="ul">
        <List.Item as="li">
          Invoices: We provide detailed invoices to restaurants, outlining the
          commission and any applicable taxes for each order. Invoices are
          accessible through your account dashboard for easy reference and
          record-keeping.
        </List.Item>
        <List.Item as="li">
          Billing Support: If you have any billing-related questions or issues,
          our dedicated support team is available to assist you.
        </List.Item>
      </List>
      <Header as="h5">Terms and Conditions</Header>
      <List as="ul">
        <List.Item as="li">
          Our payout policy is subject to the terms and conditions outlined in
          our agreement with restaurants. By using our platform, you agree to
          adhere to these terms.
        </List.Item>
      </List>
    </Container>
  );
};

export default PayoutPolicy;
