import { observer } from "mobx-react-lite";
import Order from "../../../../app/models/order";
import {
  Grid,
  Header,
  Icon,
  List,
  Segment,
  SemanticCOLORS,
} from "semantic-ui-react";
import { timeLaps, toCurrency } from "../../../../app/common/util/util";
import OrderListItem from "./OrderListItem";
import { useEffect, useState } from "react";

interface IProps {
  order: Order;
  mobile?: boolean;
}

const OrderBlock = ({ order, mobile }: IProps) => {
  const [selectedColor, setSelectedColor] = useState<
    SemanticCOLORS | undefined
  >(undefined);

  useEffect(() => {
    if (order && order.status) {
      switch (order.status) {
        case "Placed":
          setSelectedColor("orange");
          break;
        case "Preparing":
          setSelectedColor("teal");
          break;
        case "Ready":
          setSelectedColor("green");
          break;
        case "InTransit":
          setSelectedColor("brown");
          break;
        case "Delivered":
          setSelectedColor("grey");
          break;
        case "Cancelled":
          setSelectedColor("black");
          break;
      }
    }
  }, [order]);

  return (
    <Segment.Group>
      <Segment color={selectedColor} attached="top" secondary clearing>
        <Header floated="left" size="tiny">
          <Header.Content>{`Order: # ${order.orderNumber}`}</Header.Content>
          <Header.Subheader>{timeLaps(order.createdOnUtc)}</Header.Subheader>
          <Header.Subheader>{`Status: ${order.status}`}</Header.Subheader>
        </Header>
        <Header textAlign="right" floated="right" size="tiny">
          <Header.Content>{order.vendorName}</Header.Content>
          <Header.Subheader>{order.vendorAddress}</Header.Subheader>
          <Header.Subheader>{order.vendorLocation}</Header.Subheader>
        </Header>
      </Segment>
      <Segment attached>
        <Grid stackable>
          <Grid.Column width={4}>
            <Header color="grey" size="tiny">
              Order Address
            </Header>
            <List>
              <List.Item>{order.deliveryAddress.name}</List.Item>
              <List.Item>
                <Icon name="phone" /> {order.deliveryAddress.telephone}
              </List.Item>
              <List.Item>
                <Icon name="point" /> {order.deliveryAddress.street}
              </List.Item>
              <List.Item>
                <Icon /> {order.deliveryAddress.location?.name}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={12}>
            <Header color="grey" size="tiny">
              Order Summary
            </Header>
            <List verticalAlign="middle" divided>
              {order.items.map((orderItem) => (
                <OrderListItem orderItem={orderItem} />
              ))}
              <List.Item>
                <List.Content floated="right">
                  {toCurrency(order.orderSubTotal, 2)}
                </List.Content>
                <List.Content floated="left">Items Subtotal</List.Content>
              </List.Item>
              {order.otherCharges > 0 && (
                <List.Item>
                  <List.Content floated="right">
                    {toCurrency(order.otherCharges, 2)}
                  </List.Content>
                  <List.Content floated="left">Container Charges</List.Content>
                </List.Item>
              )}
              <List.Item>
                <List.Content floated="right">
                  {toCurrency(order.deliveryFee, 2)}
                </List.Content>
                <List.Content floated="left">Delivery Fee</List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Header size="tiny">{toCurrency(order.orderTotal, 2)}</Header>
                </List.Content>
                <List.Content floated="left">
                  <Header size="tiny">Order Total</Header>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};
export default observer(OrderBlock);
