import { Grid, Header, Icon, SemanticCOLORS } from "semantic-ui-react";

interface Props {
  title: string;
  subtitle: string;
  color?: SemanticCOLORS;
}

const Chops2uEmptyRecord = ({ title, subtitle, color }: Props) => {
  return (
    <div>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{
          minHeight: "calc(100vh - 120px)",
          height: "100%"
        }}
      >
        <Grid.Column width={16}>
          <Header as="h4" icon>
            <Icon name="folder outline" size="huge" color={color} />
            <Header.Content>{title}</Header.Content>
            <Header.Subheader
              style={{ width: "500px", padding: "10px 0 5px 0" }}
            >
              {subtitle}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Chops2uEmptyRecord;
