import { observer } from "mobx-react-lite";
import { Menu, Dropdown, Button } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import BikeForm from "./forms/BikeForm";
import PageTopMenu from "../../common/PageTopMenu";

const BikesTopMenu = () => {
  const {
    modalStore: { openModal },
  } = useStore();

  const handleButtonClick = () => {
    openModal(<BikeForm />, "mini");
  };

  return (
    <PageTopMenu>
      <Menu.Item>
        <Dropdown labeled text={"Filter"} icon="filter">
          <Dropdown.Menu></Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item position="right">
        <div>
          <Button
            color="olive"
            icon="plus"
            labelPosition="left"
            content="New Bikes"
            onClick={handleButtonClick}
          />
        </div>
      </Menu.Item>
    </PageTopMenu>
  );
};

export default observer(BikesTopMenu);
