import { observer } from "mobx-react-lite";
import { Button, Container, Menu } from "semantic-ui-react";
import { useStore } from "../../../../app/store/store";
import FoodForm from "./forms/FoodForm";

const buttonStyle = {
  borderRaius: "0",
};

const FoodTopMenu = () => {
  const {
    modalStore: { openModal },
  } = useStore();
  const handleButtonClick = () => {
    openModal(<FoodForm />, "tiny");
  };
  return (
    <Menu style={buttonStyle} attached="top" borderless>
      <Container style={{ width: "95%" }}>
        <Menu.Item position="right">
          <div>
            <Button
              positive
              icon="plus"
              labelPosition="left"
              content="New Food"
              onClick={handleButtonClick}
            />
          </div>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(FoodTopMenu);
