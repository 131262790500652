import { observer } from "mobx-react-lite";
import { Header } from "semantic-ui-react";

const AddressBookPage = () => {
  return (
    <>
      <Header as="h2" color="olive">
        Address Book
      </Header>
    </>
  );
};

export default observer(AddressBookPage);
