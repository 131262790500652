import { observer } from "mobx-react-lite";
import { Header, Icon, Segment } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import { RestaurentName, SideNavBannerHeader } from "./store.style";

const StoreSidebarBanner = () => {
  const {
    restaurantStore: { restaurant },
  } = useStore();
  return (
    <Segment vertical>
      <SideNavBannerHeader size="tiny" inverted>
        <Icon name="shipping fast" />
        <Header.Content style={{ fontSize: "1.3em" }}>Chops2u</Header.Content>
      </SideNavBannerHeader>
      <RestaurentName as="h5" inverted>
        <Header.Subheader>{restaurant?.title}</Header.Subheader>
      </RestaurentName>
    </Segment>
  );
};

export default observer(StoreSidebarBanner);
