import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ModalComponent from "../common/modals/ModalComponent";
import ScrollToTop from "./ScrollToTop";

const AppLayout = () => {
  return (
    <main className="App">
      <ScrollToTop />
      <ToastContainer position="bottom-right" hideProgressBar />
      <ModalComponent />
      <Outlet />
    </main>
  );
};

export default AppLayout;
