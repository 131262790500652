import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Order from "../models/order";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { store } from "./store";

export default class OrderStore {
  selectedOrder: Order | null = null;
  ordersRegistry = new Map<string, Order>();
  hubConnection: HubConnection | null = null;
  loading = false;
  submitting = false;
  deleting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get OrdersByDate() {
    return Array.from(this.ordersRegistry.values())
      .filter((order) => (order.status === "Ready") || (order.status === "Preparing") || (order.status === "Placed") || (order.status === "InTransit"))
      .sort((a, b) => new Date(a.createdOnUtc).getTime() - new Date(b.createdOnUtc).getTime()
    );
  }

  createHubConnection = () => {
    const hubBaseUrl = process.env.REACT_APP_ORDERHUB_URL!;
    if (store.userStore.user) {
      this.loading = true;
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(hubBaseUrl, {
          accessTokenFactory: () => store.userStore.user?.token!
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
      
      this.hubConnection
        .start()
        .catch(error => console.log("Error Establishing hub connection: ", error));
      
      this.hubConnection.on("LoadOrders", (orders: Order[]) => {
        runInAction(() => {
          this.ordersRegistry.clear();
          orders.forEach((order) => this.ordersRegistry.set(order.id, order));
          this.loading = false;
        })
      });

      this.hubConnection.on("ReceiveOrder", (order: Order) => {
        runInAction(() => this.ordersRegistry.set(order.id, order));
      })

      this.hubConnection.on("BroadcastOrderDelete", (order: Order) => {
        runInAction(() => this.ordersRegistry.set(order.id, order));
      })
    }
  }

  stopHubConnection = () => {
    this.hubConnection?.stop()
      .catch(error => console.log("Error Stopping connection: ", error));
  }

  clearOrders = () => {
    this.ordersRegistry.clear();
    this.stopHubConnection();
  }

  loadOrders = async () => {
    this.loading = true;
    try {
      let orders = await agent.Orders.list();
      runInAction(() => {
        this.ordersRegistry.clear();
        orders.forEach((order) => {
          this.ordersRegistry.set(order.id, order);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadOrder = async (orderId: string) => {
    this.loading = true;
    try {
      let order = await agent.Orders.details(orderId);
      runInAction(() => {
        this.selectedOrder = order;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  confirm = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("ConfirmOrder", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      runInAction(() => (this.submitting = false));
      console.log(error);
    }
  };

  finish = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("FinishOrder", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  collect = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("CollectOrder", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  deliver = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("DeliveredOrder", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  cancel = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("CancelOrder", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  delete = async (orderId: string) => {
    this.deleting = true;
    try {
      await this.hubConnection?.invoke("DeleteOrder", orderId);
      runInAction(() => this.deleting = false);
    } catch (error) {
      console.log(error);
      runInAction(() => this.deleting = false);
    }
  }

  assignToChopper = async (orderId: string, chopperId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("AssignChopper", orderId, chopperId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      runInAction(() => (this.submitting = false));
      throw error;
    }
  };

  unassignChopper = async (orderId: string) => {
    this.submitting = true;
    try {
      await this.hubConnection?.invoke("UnassignedChopper", orderId);
      runInAction(() => this.submitting = false);
    } catch (error) {
      console.log(error)
      runInAction(() => (this.submitting = false));
    }
  };
}
