import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Gateway from "../models/gateway";
import Payment, { PaymentStatus } from "../models/payment";

export default class PaymentStore {
    paymentRegistry = new Map<string, Payment>();
    vendorPaymentRegistry = new Map<string, Payment>();
    gatewayRegistry = new Map<string, Gateway>();
    loading = false;
    loadingStore = false;
    submitting = false;

    constructor() {
        makeAutoObservable(this);
    }

    get PaymentsByDate() {
        return Array.from(this.paymentRegistry.values())
            .sort((a, b) => (new Date(a.requestedOnUtc)).getTime() - (new Date(b.requestedOnUtc)).getTime())
    }

    get StorePayments() {
        return Array.from(this.vendorPaymentRegistry.values());
    }

    get Gateways() {
        return Array.from(this.gatewayRegistry.values());
    }

    getTxRef = async () => {
        this.loading = true;
        try {
            let transRef = await agent.Payments.getTransactionRef();
            runInAction(() => this.loading = false);
            return transRef;
        } catch (error) {
            runInAction(() => this.loading = false);
            console.log(error);
        }
    }

    loadGateways = async () => {
        this.loading = true;
        try {
            let gateways = await agent.Payments.getGateways();

            // Remove this section to allow for other payment methods
            // -----------------------------------------------------
            gateways = gateways.filter(a => a.key === "1");
            // -----------------------------------------------------
            runInAction(() => {
                gateways.forEach((gateway) => {
                    this.gatewayRegistry.set(gateway.key, gateway);
                });
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => this.loading = false);
            console.log(error);
        }
    }

    loadPayments = async () => {
        this.loading = true;
        try {
            let payments = await agent.Payments.list();
            runInAction(() => {
                payments.forEach(payment => {
                    this.paymentRegistry.set(payment.id!, payment);
                })
                this.loading = false;
            })
        } catch (error) {
            runInAction(() => this.loading = false);
            console.log(error);
        }
    }

    loadStorePayments = async (vendorId: string) => {
        let params = new URLSearchParams();
        params.append("filter", JSON.stringify({ vendorId, "status": "Successful" }));
        this.loadingStore = true;
        try {
            let payments = await agent.Payments.list(params);
            runInAction(() => {
                payments.forEach(payment => this.vendorPaymentRegistry.set(payment.id!, payment));
                this.loadingStore = false;
            })
        } catch (error) {
            runInAction(() => this.loadingStore = false);
            console.log(error);
        }
    }

    begin = async (payment: Payment) => {
        this.submitting = true;
        try {
            console.log(payment);
            let response = await agent.Payments.begin(payment);
            runInAction(() => this.submitting = false);
            return response;
        } catch (error) {
            runInAction(() => this.submitting = false);
            throw error;
        }
    }

    cancel = async (transactionRef: string, reason: string) => {
        try {
            this.submitting = true;
            await agent.Payments.cancel(transactionRef, reason);
            runInAction(() => {
                let payment = this.PaymentsByDate
                    .filter((payment) => payment.transactionReference === transactionRef)[0];
                payment.status = PaymentStatus.Cancelled;
                this.paymentRegistry.set(payment.id!, payment);
            })
        } catch (error) {
            runInAction(() => this.submitting = false);
            console.log(error);
        }
    }
}