import { Container, Header, List } from "semantic-ui-react";

const pageStyle = {
  marginTop: "6em",
};

const PrivacyPolicy = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">Privacy Policy</Header>
      <p>Effective Date: 8th December 2021</p>
      <p>
        This Privacy Policy describes how Chops2u Services ("we," "us," or
        "our") collects, uses, shares, and protects the personal information of
        users ("you" or "your") who access and use our food ordering and
        delivery platform ("Platform"). We are committed to safeguarding your
        privacy and ensuring the confidentiality of your personal information.
        By accessing and using our Platform, you agree to the terms and
        conditions outlined in this Privacy Policy.
      </p>
      <List ordered>
        <List.Item>
          <List.Header>Information We Collect</List.Header>
          <List>
            <List.Item>
              Personal Information: We may collect personal information from
              you, such as your name, address, contact number, email address,
              payment details, and other information necessary to provide our
              services.
            </List.Item>
            <List.Item>
              Order Information: We collect information about the orders you
              place through our Platform, including the food items selected,
              delivery address, order preferences, and any special instructions
              provided.
            </List.Item>
            <List.Item>
              Usage Information: We automatically collect certain information
              when you access and use our Platform, such as your IP address,
              device information, browser type, operating system, and usage data
              to improve our services and enhance your experience.
            </List.Item>
            <List.Item>
              Location Information: With your consent, we may collect and
              process location information to provide you with relevant
              services, such as displaying nearby restaurants and estimating
              delivery times.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Use of Information</List.Header>
          <List>
            <List.Item>
              Provision of Services: We use your personal information to
              facilitate the ordering and delivery of food, process payments,
              respond to your inquiries, and provide customer support.
            </List.Item>
            <List.Item>
              Improving Our Services: We may use the collected information to
              analyze trends, monitor usage patterns, personalize content, and
              enhance the functionality and user experience of our Platform.
            </List.Item>
            <List.Item>
              Marketing and Communication: With your consent, we may send you
              promotional offers, updates, and marketing communications related
              to our services. You can opt-out of these communications at any
              time.
            </List.Item>
            <List.Item>
              4 Legal Compliance: We may use your information to comply with
              applicable laws, regulations, legal processes, or enforce our
              terms of service and protect our rights, property, or safety, or
              that of our users.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Information Sharing</List.Header>
          <List>
            <List.Item>
              Service Providers: We may share your personal information with
              trusted third-party service providers who assist us in operating
              our Platform, processing payments, delivering orders, analyzing
              data, and providing support services. These service providers are
              obligated to maintain the confidentiality and security of your
              information.
            </List.Item>
            <List.Item>
              Business Partners: We may share aggregated and anonymized
              information with our business partners, advertisers, or other
              third parties for marketing, research, or analytical purposes.
            </List.Item>
            <List.Item>
              Legal Obligations: We may disclose your personal information if
              required by law, legal process, or governmental request, or to
              protect our rights, property, or safety, or that of our users.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <List.Header>Data Security</List.Header>
          We employ reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is 100% secure. Therefore, while we strive to
          protect your information, we cannot guarantee its absolute security.
        </List.Item>
        <List.Item>
          <List.Header>Data Retention</List.Header>
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </List.Item>
        <List.Item>
          <List.Header>Children's Privacy</List.Header>
          Our Platform is not directed to individuals under the age of 18. We do
          not knowingly collect or maintain personal information from children.
          If we become aware that we have collected personal information from a
          child without parental consent, we will take steps to remove the
          information promptly.
        </List.Item>
        <List.Item>
          <List.Header>Your Rights</List.Header>
          You have the right to access, correct, update, and delete your
          personal information. You may also have the right to object to the
          processing of your personal information, restrict its processing, or
          request data portability
        </List.Item>
      </List>
    </Container>
  );
};

export default PrivacyPolicy;
