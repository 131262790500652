import { useEffect, useState } from "react";
import { Button, Grid, Header, Segment, Image } from "semantic-ui-react";
import PhotoDropzone from "../../../../administrator/commerce/vendors/ImageUploads/PhotoDropzone";
import PhotoCropper from "../../../../administrator/commerce/vendors/ImageUploads/PhotoCropper";
import { useStore } from "../../../../../app/store/store";
import { observer } from "mobx-react-lite";
import { PhotoPreview, PhotoSection, Wrapper700 } from "../../../store.style";

const UploadPhotoForm = () => {
  const {
    restaurantStore: { restaurant, loadStore, updateStorePhoto, uploading },
  } = useStore();

  const [url, setUrl] = useState("/Images/placeholder.png");
  const [files, setFiles] = useState<any[]>([]);
  const [cropper, setCropper] = useState<Cropper>();

  useEffect(() => {
    let imageUrl = restaurant?.image || "/Images/placeholder.png";
    setUrl(imageUrl);
  }, [restaurant?.image, setUrl]);

  function OnCrop() {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((file) => {
        if (restaurant) {
          updateStorePhoto(restaurant, file!).then(() => loadStore());
        }
      });
    }
  }

  const handleCancelButton = () => {
    setFiles([]);
    const imageUrl = restaurant?.image || "/Images/placeholder.png";
    setUrl(imageUrl);
  };

  return (
    <Grid>
      <Grid.Column>
        <Wrapper700>
          <Segment color="green" attached="top" clearing>
            <div>
              <Header
                textAlign="center"
                size="small"
                disabled
                content={"Store Banner".toUpperCase()}
              />
            </div>
          </Segment>

          <Segment attached>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h5" color="grey" content="Banner Image" />
                  <PhotoSection>
                    <Image
                      src={url}
                      style={
                        files && files.length > 0
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    />
                    <PhotoDropzone setFiles={setFiles} />
                    {files && files.length > 0 && (
                      <PhotoCropper
                        setCropper={setCropper}
                        imagePreview={files[0].preview}
                        initialAspectRatio={2 / 1}
                        aspectRation={2 / 1}
                      />
                    )}
                  </PhotoSection>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header color="grey" as="h5" content="Preview" />
                  <PhotoPreview className="img-preview"></PhotoPreview>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment attached="bottom" clearing secondary>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <Button
                onClick={handleCancelButton}
                disabled={uploading}
                basic
                color="orange"
                content="Cancel"
                style={{ width: "135px" }}
              />
              <Button
                onClick={OnCrop}
                loading={uploading}
                positive
                content="Upload Photo"
                style={{ width: "135px" }}
              />
            </div>
          </Segment>
        </Wrapper700>
      </Grid.Column>
    </Grid>
  );
};

export default observer(UploadPhotoForm);
