import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Segment } from "semantic-ui-react";
import { useStore } from "../../../app/store/store";
import ChopperDashboard from "./dashboard/ChopperDashboard";
import OrderEmptyPanel from "./OrderEmptyPanel";

interface OrdersLayoutPageProps {
  setPageTitle: (title: string) => void;
}

const OrdersLayoutPage = ({ setPageTitle }: OrdersLayoutPageProps) => {
  const {
    chopperStore: { loadOrders, loadOrder, loadingOrders, ordersByDate },
  } = useStore();
  useEffect(() => {
    setPageTitle("Current Order(s)");
    loadOrders();
  }, [setPageTitle, loadOrders]);

  return (
    <Segment
      loading={loadingOrders}
      vertical
      style={{ paddingTop: "0", paddingBottom: "0" }}
    >
      {ordersByDate && ordersByDate.length > 0 ? (
        <ChopperDashboard orders={ordersByDate} setPageTitle={setPageTitle} loadOrder={loadOrder} />
      ) : (
        <OrderEmptyPanel />
      )}
    </Segment>
  );
};

export default observer(OrdersLayoutPage);
