import { observer } from "mobx-react-lite";
import { OrderItem } from "../../../../app/models/order";
import { Image, List } from "semantic-ui-react";
import { toCurrency } from "../../../../app/common/util/util";
import { OrderQuantity, OrderTitle } from "./order-styles";

interface IProps {
  orderItem: OrderItem;
  mobile?: boolean;
}

const OrderListItem = ({ orderItem, mobile }: IProps) => {
  return (
    <List.Item>
      <List.Content floated="right">
        {toCurrency(orderItem.totalPrice, 2)}
      </List.Content>
      <Image avatar src={orderItem.image || "/images/food-placeholder.jpg"} />
      <List.Content>
        <OrderQuantity>{orderItem.quantity}</OrderQuantity>
        <OrderTitle>
          {orderItem.title}
          {orderItem.associatedItems &&
            orderItem.associatedItems.length > 0 && (
              <List size="mini" divided>
                {orderItem.associatedItems.map((item) => (
                  <List.Item>
                    <List.Content floated="right">
                      {toCurrency(item.totalPrice)}
                    </List.Content>
                    <List.Content>{item.title}</List.Content>
                  </List.Item>
                ))}
              </List>
            )}
        </OrderTitle>
      </List.Content>
    </List.Item>
  );
};
export default observer(OrderListItem);
