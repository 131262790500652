import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Order from "../../../../app/models/order";
import OrderCellList from "../List/OrderCellList";
import OrderDetails from "../OrderDetails";

interface Props {
  setPageTitle: (title: string) => void;
  orders: Order[];
  loadOrder: (orderId: string) => void;
}

const ChopperDashboard = ({ setPageTitle, orders, loadOrder }: Props) => {
  useEffect(() => {
    setPageTitle("Current Order(s)");
  }, [setPageTitle]);

  return (
    <section style={{ display: "flex" }}>
      <div
        style={{
          flex: "3",
          height: "calc(100vh - 62px)",
          boxShadow: "1px 0 2px 0 rgba(34,36,38,.15)",
          borderLeft: "1px solid rgba(34,36,38,.15)",
          // backgroundColor: "rgba(180, 192, 164, 0.3)",
        }}
      >
        <OrderCellList title="" orders={orders} loadOrder={loadOrder} />
      </div>
      <div style={{ flex: "3" }}>
        <OrderDetails order={orders[0]} />
      </div>
    </section>
  );
};

export default observer(ChopperDashboard);
