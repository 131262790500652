import { Image, Container } from "semantic-ui-react";
import { CartName, EmptyCartText } from "./cartStyles";

const EmptyCart = () => {
  return (
    <Container>
      <CartName>Empty Cart</CartName>
      <Image src="/images/cart/empty-cart.png" />
      <EmptyCartText textAlign="center" as="h4">
        Your cart is empty
      </EmptyCartText>
      <EmptyCartText textAlign="center" as="h4">
        Add items to get started
      </EmptyCartText>
    </Container>
  );
};

export default EmptyCart;
