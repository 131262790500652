import React from "react";
import { Label } from "semantic-ui-react";

interface Props {
  style: {};
}

const VendorClosedLabel = ({ style }: Props) => {
  return (
    <Label color="red" style={style}>
      Closed
    </Label>
  );
};

export default VendorClosedLabel;
