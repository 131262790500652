import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Header, Segment } from "semantic-ui-react";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { BikeMakeFormValues } from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";

interface Props {
  id?: string;
}

const validate = Yup.object({
  name: Yup.string().required(),
});

const MakeForm = ({ id }: Props) => {
  const {
    bikeStore: { createMake, loadMake, submittingMake, loadingMakes },
    modalStore: { closeModal },
  } = useStore();

  const [make, setMake] = useState<BikeMakeFormValues>({
    name: "",
    error: null,
  });

  useEffect(() => {
    if (id) {
      loadMake(id).then((result) => {
        setMake((prev) => ({ ...prev, ...result }));
      });
    }
  }, [id, loadMake]);

  const handleFormSubmit = (
    values: BikeMakeFormValues,
    { setErrors }: FormikHelpers<BikeMakeFormValues>
  ) => {
    if (id) {
      console.log(values);
    } else {
      createMake(values)
        .then(() => closeModal())
        .catch((error) => setErrors({ error }));
    }
  };

  return (
    <Formik
      initialValues={make}
      onSubmit={handleFormSubmit}
      validationSchema={validate}
      enableReinitialize
    >
      {({ values, handleSubmit, dirty, isValid, isSubmitting, errors }) => (
        <Form className="form ui" onSubmit={handleSubmit}>
          <Segment attached="top" color="olive" loading={loadingMakes}>
            <Header
              as="h4"
              content={id ? "Edit Bike" : "Add Bike"}
              style={{ margin: "10px 0 5px 0" }}
            />
          </Segment>
          <Segment attached>
            <Chops2uTextInput
              name="name"
              label="Name"
              placeholder="Bike Name"
            />
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div className="field">
              {errors.error ? (
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              ) : null}
            </div>
          </Segment>
          <Segment attached="bottom" secondary>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon="cancel"
                type="button"
                color="orange"
                labelPosition="left"
                content="Cancel"
                onClick={closeModal}
              />

              <Button
                icon="check"
                disabled={!isValid && dirty}
                loading={isSubmitting && submittingMake}
                color="olive"
                type="submit"
                labelPosition="left"
                content="Submit"
              />
            </div>
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(MakeForm);
