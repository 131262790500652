import { useEffect } from "react";
import { Segment } from "semantic-ui-react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";
import { useStore } from "../../../../app/store/store";
import PaymentList from "./PaymentList";
import { observer } from "mobx-react-lite";

interface Props {
  setPageTitle: (title: string) => void;
}

const SalesPageLayout = ({ setPageTitle }: Props) => {
  const {
    paymentStore: { StorePayments, loadStorePayments, loadingStore },
    restaurantStore: { restaurant },
  } = useStore();

  useEffect(() => {
    setPageTitle("Sales");
    if (restaurant?.id) loadStorePayments(restaurant.id);
  }, [setPageTitle, restaurant, loadStorePayments]);

  return (
    <Segment vertical loading={loadingStore} style={{ overflowX: "hidden"}}>
      {(StorePayments?.length > 0) ? (
        <PaymentList data={StorePayments} />
      ) : (
        <Chops2uEmptyRecord
          title="No Sales Yet!"
          subtitle="Find all vendor sales record here."
          color="green"
        />
      )}
    </Segment>
  );
};

export default observer(SalesPageLayout);
