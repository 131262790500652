import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Container, Header } from "semantic-ui-react";
import Shipment from "../../../../app/models/shipment";
import { format } from "date-fns";

interface Props {
  data: Shipment[];
}

const mainContentStyle = {
  width: "96%",
  marginTop: "1em",
};

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const DeliveryList = ({ data }: Props) => {
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "40px", maxWidth: "40px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "40px", maxWidth: "40px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "chopperName",
      label: "Chopper",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.fullName;
        // },
      },
    },
    {
      name: "chopperPhone",
      label: "Telephone",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.phoneNumber;
        // },
      },
    },
    {
      name: "creadedOnUtc",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return format(new Date(value), "dd-MMM-yyyy");
        },
      },
    },
    {
      name: "orderNumber",
      label: "Order Number",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.orderNumber;
        // },
      },
    },
    {
      name: "status",
      label: "Order Status",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.status;
        // },
      },
    },
    {
      name: "numberOfItems",
      label: "Items",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return value.items.length;
        // },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  return (
    <Container style={mainContentStyle}>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={null}
          data={data}
          columns={columns}
          options={{
            filter: "false",
            download: "false",
            print: "false",
            viewColumns: "false",
            selectableRows: "none",
            responsive: "standard",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20],
            count: data.length,
            page: 0,
            // onRowsDelete: handleRowsDelete,
            // onTableChange: handleTableChange,
            setTableProps: () => {
              return { size: "small" };
            },
          }}
        />
      </MuiThemeProvider>
    </Container>
  );
};

export default DeliveryList;
