import { observer } from "mobx-react-lite";
import { Dropdown, Header, Icon, Label } from "semantic-ui-react";
import { useStore } from "../store/store";
import LocationTypeahead from "./LocationTypeahead";
import { useState } from "react";

const bannerStyle = {
  padding: "0.5em 1em",
  border: "none",
  marginLeft: "20px",
  // backgroundColor: "rgb(238, 238, 238)",
  color: "#888",
  backgroundColor: "#ffffff",
};

const LocationMenu = () => {
  const {
    userStore: { deliveryLocation },
  } = useStore();
  
  const [open, setOpen] = useState(false);

  

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {deliveryLocation ? (
        <>
          <Label basic style={bannerStyle}>
            <Header
              icon={<Icon size="small" name="point" />}
              color="olive"
              size="tiny"
              subheader={"Delivering To " + deliveryLocation?.name}
            />
          </Label>
          <Dropdown
            onBlur={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            onChange={(e) => setOpen(true)}
            multiple
            basic
            icon={<Icon color="olive" name="chevron down" />}
          >
            <Dropdown.Menu style={{ padding: "0px" }} direction="left">
              <Dropdown.Item style={{ padding: "0px" }} active={false}>
                <LocationTypeahead setOpen={setOpen} />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(LocationMenu);
