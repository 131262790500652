import React from "react";
import Chops2uEmptyRecord from "../../../../app/common/components/Chops2uEmptyRecord";

const CategoryEmptyPanel = () => {
  return (
    <Chops2uEmptyRecord
      title="No Categories Yet"
      subtitle="Please add categories as part of Configuration"
      color="olive"
    />
  );
};

export default CategoryEmptyPanel;
