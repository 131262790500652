import { Formik, Form, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import { useStore } from "../../../../../app/store/store";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { UserRole } from "../../../../../app/models/user-account";

const validationSchema = Yup.object({
  name: Yup.string().required(),
});

const RoleForm = () => {
  const {
    accountManagerStore: { submitting, createRole },
    modalStore: { closeModal },
  } = useStore();
  const [role, setRole] = useState<UserRole>({
    name: "",
    isSystem: false,
  });

  useEffect(() => {
    setRole({
      name: "",
      isSystem: false,
    });
  }, []);

  const handleCancelClick = () => {
    // console.log("Cancel Button");
    closeModal();
  };

  return (
    <Formik
      initialValues={role}
      onSubmit={(values, { setErrors }) =>
        createRole(values.name).catch((errors) => setErrors({ name: errors }))
      }
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, isSubmitting, dirty, isValid, errors }) => (
        <Form className="ui form" onSubmit={handleSubmit}>
          <Segment attached="top">
            <Header as="h2" color="olive" content="Add Role" />
          </Segment>
          <Segment attached>
            <Chops2uTextInput
              name="name"
              label="Name"
              placeholder="Role Name"
            />

            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors.name} />}
            />
          </Segment>
          <Segment attached="bottom" clearing>
            <Button
              floated="left"
              type="button"
              disabled={isSubmitting}
              basic
              color="orange"
              content="Cancel"
              onClick={() => handleCancelClick()}
              style={{ width: "10em" }}
            />
            <Button
              floated="right"
              disabled={(dirty && !isValid) || isSubmitting}
              loading={submitting}
              type="submit"
              basic
              color="olive"
              content="Save"
              style={{ width: "10em" }}
            />
          </Segment>
        </Form>
      )}
    </Formik>
  );
};

export default observer(RoleForm);
