import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import PayoutsEmptyPage from "./PayoutsEmptyPage";
import PayoutsTopMenu from "./PayoutsTopMenu";

interface Props {
    setPageTitle: (title: string) => void;
}

const PayoutsLayoutPage = ({ setPageTitle }: Props) => {
    
    useEffect(() => {
        setPageTitle("Payouts");
    }, [setPageTitle]);
    
    return (
        <section>
            <PayoutsTopMenu />
            <PayoutsEmptyPage />
        </section>
    )
}

export default observer(PayoutsLayoutPage);