import { isValid } from "date-fns";
import { Formik, ErrorMessage, FormikHelpers } from "formik";
import { Button, Container, Form, Header, Segment } from "semantic-ui-react";
import Chops2uTextInput from "../../../../../app/common/forms/Chops2uTextInput";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { any } from "prop-types";
import AppUser from "../../../../../app/models/app-user";
import { useStore } from "../../../../../app/store/store";
import * as Yup from "yup";
import { ResetPasswordFormValues } from "../../../../../app/models/reset-password";
import { observer } from "mobx-react-lite";

interface Props {
  user: AppUser;
}

const validate = Yup.object({
  newPassword: Yup.string().required("'Password' is a required field."),
  confirmPassword: Yup.string()
    .required("'Confirm Password' is a required field.")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});

function ResetPasswordForm({ user }: Props) {
  const {
    modalStore: { closeModal },
    userStore: { adminResetPassword, submitting },
  } = useStore();

  const formData: ResetPasswordFormValues = {
    email: user.email,
    newPassword: "",
    confirmPassword: "",
    token: "TKJ6785THY675WTY00THYED456THFY76",
    error: any,
  };

  const handleFormSubmit = (
    values: ResetPasswordFormValues,
    { setErrors }: FormikHelpers<ResetPasswordFormValues>
  ) => {
    // console.log(values);
    adminResetPassword(values)
      .then(() => closeModal())
      .catch((error) => {
        const { status, data } = error.response;
        if (status && status === 403) {
          setErrors({ error: "You cannot perform the following request." });
        } else {
          setErrors({ error: data });
        }
      });
  };

  return (
    <Container>
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={validate}
        onSubmit={handleFormSubmit}
      >
        {({ dirty, isSubmitting, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit} className="ui form">
            <Segment padded color="purple" attached="top">
              <Header
                size="tiny"
                disabled
                textAlign="center"
                content={"Reset Account Password".toUpperCase()}
              />
            </Segment>
            <Segment padded attached clearing>
              <div className="field">
                <p>Enter a new password for {user.email}</p>
              </div>

              <Chops2uTextInput
                name="newPassword"
                placeholder="Password"
                type="password"
              />

              <Chops2uTextInput
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
              />

              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
            </Segment>
            <Segment attached="bottom" clearing>
              <div>
                <Button
                  floated="left"
                  style={{ width: "150px" }}
                  onClick={() => closeModal()}
                  type="button"
                  content="Cancel"
                />
                <Button
                  disabled={(!isValid && dirty) || submitting}
                  loading={isSubmitting && submitting}
                  floated="right"
                  color="purple"
                  content="Reset Passowrd"
                  type="submit"
                />
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
export default observer(ResetPasswordForm);
