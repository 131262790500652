import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../../../app/store/store";
import DeleteMenuForm from "../forms/DeleteMenuForm";
import { PageWrapper } from "../../../administrator.style";

interface Props {
  setEditMode: (state: boolean) => void;
  setMenuId: (menuId?: string) => void;
}

function checkboxRender(value: any, tableData: any, updatedValue: any) {
  return <input type="checkbox" checked={value} />;
}

function headerLabelRenderer(columnMeta: any) {
  const { label } = columnMeta;
  return <Header as="h5" content={label} />;
}

const MenuList = ({ setEditMode, setMenuId }: Props) => {
  const {
    menuStore: { loadMenus, Menus: getMenus },
    modalStore: { openModal },
  } = useStore();
  const { id } = useParams();
  const columns = [
    {
      name: "sl",
      label: "S/No",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: headerLabelRenderer,
        setCellProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        setCellHeaderProps: () => ({
          style: { minWidth: "30px", maxWidth: "30px" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        readonly: true,
        customBodyRender: checkboxRender,
        customHeadLabelRender: headerLabelRenderer,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updatedValue: any) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "2em",
                justifyContent: "center",
              }}
            >
              <Button
                icon="edit"
                basic
                color="olive"
                circular
                size="mini"
                onClick={() => handleEditButtonClick(tableMeta)}
              />
              <Button
                icon="delete"
                basic
                color="orange"
                circular
                size="mini"
                onClick={() => handleDeleteButtonClick(tableMeta)}
              />
            </div>
          );
        },
        customHeadLabelRender: (columnMeta: any) => {
          const { label } = columnMeta;
          return <Header textAlign="center" as="h5" content={label} />;
        },
      },
    },
  ];

  const getMuiTheme = createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF0000",
          paddingBlockStart: 10,
        },
        paper: {
          boxShadow: "none",
          border: "1px solid rgba(34,36,38,.15)",
        },
      },
    },
  });

  useEffect(() => {
    if (id) {
      loadMenus(id);
    }
  }, [id, loadMenus]);

  const handleEditButtonClick = (tableMeta: any) => {
    const { rowIndex } = tableMeta;
    // console.log(tableMeta);
    if (getMenus && getMenus.length > 0) {
      const menuId = getMenus[rowIndex].id;
      // console.log(menuId!);
      setMenuId(menuId);
      setEditMode(true);
    }
  };

  const handleDeleteButtonClick = (tableMeta: any) => {
    const { rowIndex } = tableMeta;
    // console.log(tableMeta);
    openModal(
      <DeleteMenuForm menu={getMenus[rowIndex]} setEditMode={setEditMode} />,
      "mini"
    );
  };

  return (
    <PageWrapper>
      <div className="ui form">
        <div className="field">
          <Button
            icon="plus"
            color="olive"
            content="Add Menu"
            onClick={() => setEditMode(true)}
            floated="right"
          />
        </div>
        <div className="field">
          <Segment vertical>
            <MuiThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                title={null}
                data={getMenus}
                columns={columns}
                options={{
                  filter: "false",
                  download: "false",
                  print: "false",
                  viewColumns: "false",
                  selectableRows: "none",
                  responsive: "standard",
                  rowsPerPage: 10,
                  rowsPerPageOptions: [10, 15, 20],
                  count: getMenus.length,
                  page: 0,
                  // onRowsDelete: handleRowsDelete,
                  // onTableChange: handleTableChange,
                  setTableProps: () => {
                    return { size: "small" };
                  },
                }}
              />
            </MuiThemeProvider>
          </Segment>
        </div>
      </div>
    </PageWrapper>
  );
};

export default observer(MenuList);
