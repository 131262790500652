import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Location, { LocationFormValues } from "../models/location";

export default class LocationStore {
  locationRegistry = new Map<string, Location>();
  loadingLocations = false;
  loading = false;
  submitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get Locations() {
    return Array.from(this.locationRegistry.values());
  }

  get LocationsByName() {
    return Array.from(this.locationRegistry.values()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get LocationsByCity() {
    return Array.from(this.locationRegistry.values())
      .sort((a, b) => a.city!.name!.localeCompare(b.city?.name!));
  }

  // setUserLocation = async (location: Location | null) => {
  //   try {
  //     await store.userStore.setLocation(location);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  loadLocations = async () => {
    this.loading = true;
    try {
      let locations = await agent.Locations.list();
      runInAction(() => {
        this.locationRegistry.clear();
        locations.forEach((location) => {
          location = {
            ...location,
            value: location.id,
            text: location.name,
          };
          this.locationRegistry.set(location.id!, location);
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loading = false));
    }
  };

  loadCityLocations = async (cityId: string) => {
    this.loading = true;
    const params = new URLSearchParams();
    params.append("filter", JSON.stringify({ cityId: cityId }));
    try {
      let locations = await agent.Locations.list(params);
      runInAction(() => {
        this.locationRegistry.clear();
        locations.forEach((location) => {
          location = { ...location, text: location.name, value: location.id };
          this.locationRegistry.set(location.id!, location);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };

  loadLocation = async (id: string) => {
    this.loadingLocations = true;
    let location = this.getLocation(id);
    if (location) {
      this.loadingLocations = false;
      return location;
    } else {
      try {
        location = await agent.Locations.detail(id);
        runInAction(() => {
          if (location) this.locationRegistry.set(location.id!, location);
          this.loadingLocations = false;
        });
        return location;
      } catch (error) {
        console.log(error);
        runInAction(() => (this.loadingLocations = false));
      }
    }
  };

  private getLocation = (id: string) => {
    return this.locationRegistry.get(id);
  };

  create = async (location: LocationFormValues) => {
    this.submitting = true;
    try {
      console.log(location);
      let result = await agent.Locations.create(location);
      runInAction(() => {
        this.locationRegistry.set(result.id!, result);
        this.submitting = false;
      });
      return result;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  update = async (id: string, location: LocationFormValues) => {
    this.submitting = true;
    try {
      let updatedLocation = await agent.Locations.update(id, location);
      runInAction(() => {
        this.locationRegistry.set(updatedLocation.id!, updatedLocation);
        this.submitting = false;
      });
      return updatedLocation;
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };

  delete = async (id: string) => {
    this.submitting = true;
    try {
      await agent.Locations.delete(id);
      runInAction(() => {
        this.locationRegistry.delete(id);
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.submitting = false));
    }
  };
}
