import { Card, Container, Grid, Header, List, Segment } from "semantic-ui-react";
import VendorRegistrationForm from "../../store/register/VendorRegistrationForm";

const pageStyle = {
  marginTop: "6em",
};

const PartnerWithUs = () => {
  return (
    <section>
      <section id="banner"></section>
      <section id="content">
        <Container text style={pageStyle}>
          <Header color="olive" as="h1">
            Partner With Us
          </Header>

          <section id="introduction">
            <p>
              At <b style={{ color: "#B5CC18"}}>Chops2u</b>, we strive to provide a seamless food ordering and
              delivery experience for our customers. We are constantly expanding
              our network of partner restaurants to offer a wide variety of
              cuisines to our customers.
            </p>
            <p>
              Partnering with us not only increases your restaurant's visibility
              but also opens up new avenues for growth and success in the
              digital marketplace.
            </p>
          </section>

          <Segment vertical basic id="why-partner">
            <h2>Why Partner with Us?</h2>
            <Card.Group itemsPerRow={3}>
              <Card>
                <Card.Content>
                  <Card.Header style={{ fontSize: "1.1em" }}>
                    Wide Customer Reach
                  </Card.Header>
                  <Card.Description>
                    Join our platform to tap into a large and diverse customer
                    base. Increase your restaurant's exposure and attract new
                    customers from your local area.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header style={{ fontSize: "1.1em" }}>
                    Marketing and Promotion
                  </Card.Header>
                  <Card.Description>
                    We actively promote our partner restaurants through targeted
                    online advertisements and social media campaigns to ensure
                    maximum visibility and brand exposure.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header style={{ fontSize: "1.1em" }}>
                    Streamline Operations
                  </Card.Header>
                  <Card.Description>
                    Our dedicated support team is always available to assist you
                    with any technical issues, order management, or customer
                    service queries you may have.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </Segment>

          <Segment placeholder id="partner-benefits">
            <h2>Partner Benefits</h2>
            <List as="ul">
              <List.Item as="li">
                <h3>Increased Sales</h3>
                <p>
                  By joining <b style={{ color: "#B5CC18" }}>Chops2u</b>, you
                  can experience a significant boost in your restaurant's sales
                  and revenue. Gain access to a large customer base actively
                  looking for dining options.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Enhanced Online Presence</h3>
                <p>
                  Establish a strong online presence and reach a wider audience.
                  Our platform helps you increase brand visibility, attract new
                  customers, and build customer loyalty.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Data Insights</h3>
                <p>
                  Gain valuable data insights and analytics to make informed
                  business decisions. Our platform provides you with actionable
                  data to optimize your operations and menu offerings.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Flexible Partnership Models</h3>
                <p>
                  We offer various partnership models to suit your business
                  needs, including commission-based and subscription-based
                  options. Choose the model that aligns with your goals and
                  preferences.
                </p>
              </List.Item>
            </List>
          </Segment>

          <Segment vertical id="how-to-partner">
            <h2>How to Partner with Us</h2>
            <List as="ol">
              <List.Item as="li">
                <h3>Application Process</h3>
                <p>
                  Submit an application by filling out our online form or
                  contacting our partnership representative directly. Provide us
                  with your restaurant details and menu offerings.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Evaluation and Approval</h3>
                <p>
                  We will review your application, considering factors such as
                  menu variety, quality, and location. Upon approval, we will
                  notify you of your successful partnership.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Onboarding and Integration</h3>
                <p>
                  Our team will assist you in integrating your menu and setting
                  up your account on our platform. We provide comprehensive
                  training to ensure a smooth onboarding process.
                </p>
              </List.Item>
              <List.Item as="li">
                <h3>Partnership Agreement</h3>
                <p>
                  Once the onboarding process is complete, we will require you
                  to sign a partnership agreement that outlines the terms and
                  conditions of our collaboration.
                </p>
              </List.Item>
            </List>
          </Segment>
          <Segment vertical id="register">
            <h2>Register</h2>
            <Grid textAlign="center" verticalAlign="middle">
              <Grid.Column>
                <VendorRegistrationForm />
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment vertical id="contact">
            <h2>Contact Us</h2>
            <p>
              For further inquiries or to initiate the partnership process,
              please reach out to our partnership team:
            </p>
            <ul>
              <li>Email: support@chops2u.com</li>
              <li>Phone: +234 9167-6666-80</li>
            </ul>
          </Segment>
        </Container>
      </section>
    </section>
  );
};

export default PartnerWithUs;
