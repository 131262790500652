import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import Chops2uSelectInput from "../../../../../app/common/forms/Chops2uSelectInput";
import isValid from "date-fns/isValid";
import ValidationErrors from "../../../../frontpage/errors/ValidationErrors";
import { timeList } from "../../../../../app/common/options/time-list";
import { useStore } from "../../../../../app/store/store";
import { OpeningInfo } from "../../../../../app/models/vendor";
import { observer } from "mobx-react-lite";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const openState = [
  { key: 1, text: "Close", value: "false" },
  { key: 2, text: "Open", value: "true" },
];

interface FormValues {
  id?: string;
  openingInfos: OpeningInfo[];
  error: "";
}

const OpeningHoursForm = () => {
  const {
    restaurantStore: {
      restaurant,
      loadStore,
      loadStoreOpeningHours,
      updateOpeningInfo,
      submittingFood: submitting,
    },
  } = useStore();
  const validationSchema = Yup.object({});
  const [formData, setFormData] = useState<FormValues>({
    id: "",
    openingInfos: [
      {
        id: "",
        vendorId: "",
        dayOfWeek: 0,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 1,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 2,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 3,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 4,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 5,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
      {
        id: "",
        vendorId: "",
        dayOfWeek: 6,
        open: "false",
        openAt: "00:00:00",
        closeAt: "00:00:00",
      },
    ],
    error: "",
  });

  useEffect(() => {
    if (restaurant?.id) {
      loadStoreOpeningHours(restaurant.id).then((openinHours) => {
        setFormData({
          id: restaurant.id,
          openingInfos: openinHours!,
          error: "",
        });
      });
    }
  }, [restaurant?.id, setFormData, loadStoreOpeningHours]);

  return (
    <Grid>
      <Grid.Column
        style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            updateOpeningInfo(values.id!, values.openingInfos)
              .then(() => loadStore())
              .catch((error) => setErrors({ error }));
          }}
        >
          {({ values, dirty, isSubmitting, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="ui form">
              <Segment attached="top" color="green">
                <div>
                  <Header
                    textAlign="center"
                    size="small"
                    disabled
                    content={"Store Opening Hours".toUpperCase()}
                    style={{ padding: "10px 0 5px 0" }}
                  />
                </div>
              </Segment>
              <Segment attached clearing>
                {/* Column Titles */}
                <div className="fields">
                  <div className="three wide field"></div>
                  <div className="three wide field">
                    <label
                      style={{
                        lineHeight: "38px",
                      }}
                    >
                      State
                    </label>
                  </div>
                  <div className="five wide field">
                    <label
                      style={{
                        lineHeight: "38px",
                      }}
                    >
                      Opening Time
                    </label>
                  </div>
                  <div className="five wide field">
                    <label
                      style={{
                        lineHeight: "38px",
                      }}
                    >
                      Closing Time
                    </label>
                  </div>
                </div>

                {/* fieldArray */}
                <FieldArray name="openingInfos">
                  {() => (
                    <>
                      {values?.openingInfos?.map((openingInfo, index) => (
                        <div key={index} className="fields">
                          <div className="three wide field">
                            <label
                              style={{
                                float: "right",
                                lineHeight: "38px",
                              }}
                            >
                              {daysOfWeek[openingInfo.dayOfWeek]}
                            </label>
                          </div>
                          <div className="three wide field">
                            <Chops2uSelectInput
                              name={`openingInfos.${index}.open`}
                              options={openState}
                              placeholder=""
                            />
                          </div>
                          <div className="five wide field">
                            <Chops2uSelectInput
                              name={`openingInfos.${index}.openAt`}
                              options={timeList}
                              placeholder=""
                            />
                          </div>
                          <div className="five wide field">
                            <Chops2uSelectInput
                              name={`openingInfos.${index}.closeAt`}
                              options={timeList}
                              placeholder=""
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>

                {/* <div className="field">
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                </div> */}

                <div className="field">
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </div>
              </Segment>
              <Segment attached="bottom" clearing secondary>
                <div>
                  <Button
                    disabled={!isValid && dirty}
                    loading={submitting}
                    positive
                    type="submit"
                    floated="right"
                    content="Save Changes"
                  />
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(OpeningHoursForm);
