import { observer } from "mobx-react-lite";
import { Button, Container, Header, Segment, Table } from "semantic-ui-react";
import Chops2uFieldDisplay from "../../../../../app/common/components/Chops2uFieldDisplay";
import Bike from "../../../../../app/models/bike";
import { useStore } from "../../../../../app/store/store";
import AssignRiderForm from "../forms/AssignRiderForm";
import BikeRegistrationForm from "../forms/BikeRegistrationForm";
import format from "date-fns/format";

interface Props {
  bike: Bike;
}

const BikeDetails = ({ bike }: Props) => {
  const {
    modalStore: { closeModal, openModal },
  } = useStore();

  const handleRegistration = () => {
    console.log("Bike Registration");
    openModal(<BikeRegistrationForm id={bike.id!} />, "mini");
  };

  const handleAssigningRider = () => {
    openModal(<AssignRiderForm bike={bike} />, "mini");
  };

  const handleRemovingRider = () => {
    console.log("Removing Rider");
  };
  return (
    <Container>
      <Segment attached="top" color="olive">
        <Header
          as="h4"
          content="Bike Details"
          style={{ margin: "10px 0 5px 0" }}
        />
      </Segment>
      <Segment attached>
        <Chops2uFieldDisplay fieldTitle="Make" fieldContent={bike.make!.name} />

        <Chops2uFieldDisplay
          fieldTitle="Serial Number"
          fieldContent={bike.serialNumber}
        />

        <Chops2uFieldDisplay
          fieldTitle="Chasis Number"
          fieldContent={bike.chasisNumber}
        />

        <Chops2uFieldDisplay
          fieldTitle="Plate Number"
          fieldContent={bike.plateNumber!}
        />

        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="5">Registration</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Expiry</Table.HeaderCell>
              <Table.HeaderCell>VIO Station</Table.HeaderCell>
              <Table.HeaderCell>Plate Number</Table.HeaderCell>
              <Table.HeaderCell>Telephone</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bike.registrations.map((registration) => (
              <Table.Row>
                <Table.Cell>{ format(new Date(registration.registeredOnUtc!), "dd-MMM-yyyy")}</Table.Cell>
                <Table.Cell>{format(new Date(registration.expiresOnUtc!), "dd-MMM-yyyy")}</Table.Cell>
                <Table.Cell>{registration.vioStation}</Table.Cell>
                <Table.Cell>{registration.plateNumber}</Table.Cell>
                <Table.Cell>{registration.telephone}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment attached="bottom" secondary>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            icon="cancel"
            labelPosition="left"
            color="orange"
            content="Close"
            onClick={closeModal}
          />
          <div style={{ display: "flex", columnGap: "1em" }}>
            <Button
              icon="check square outline"
              labelPosition="left"
              color="olive"
              content="Register"
              onClick={handleRegistration}
            />
            {((bike.status === "Running") || (bike.status === "FaultyRunning")) ? (
              <Button
                icon="unlinkify"
                labelPosition="left"
                color="purple"
                content="Remove Rider"
                onClick={handleRemovingRider}
              />
            ) : (
              <Button
                icon="linkify"
                labelPosition="left"
                color="teal"
                content="Assign Rider"
                onClick={handleAssigningRider}
              />
            )}
          </div>
        </div>
      </Segment>
    </Container>
  );
};

export default observer(BikeDetails);
