import { Link, useLocation } from "react-router-dom";
import { ItemHeader, Menu, Sidebar, Button, Icon, Container } from "semantic-ui-react"
import { observer } from "mobx-react-lite";

interface IProps {
    visibility: boolean;
    setVisibility: (state: boolean) => void;
}

const StoreSideNavMobile = ({ visibility, setVisibility }: IProps) => {

  const location = useLocation();

  return (
    <Sidebar
      as={Menu}
      onHide={() => setVisibility(false)}
      vertical
      animation="overlay"
      visible={visibility}
    >
      <Container>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Menu.Item>
              <ItemHeader>
                <div>
                  <Button
                    icon="cancel"
                    name="cancel"
                    onClick={() => setVisibility(false)}
                    style={{
                      float: "right",
                      backgroundColor: "transparent",
                      padding: "2px 0px",
                    }}
                  />
                </div>
              </ItemHeader>
              {/* <StoreSidebarBanner /> */}
            </Menu.Item>
          </div>
          <div
            style={{
              overflowY: "auto",
              scrollbarColor: "darkgray gray",
              scrollbarWidth: "thin",
            }}
          >
            <Menu.Item
              as={Link}
              to="/store/getStarted"
              name="getStarted"
              active={location.pathname === "/store/getStarted"}
            >
              <Icon
                name="globe"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Get Started
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store"
              name="home"
              active={location.pathname === "/store"}
            >
              <Icon
                name="dashboard"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Dashboard
            </Menu.Item>
            {/* COMMERCE SECTION */}
            <Menu.Item>
              <ItemHeader>COMMERCE</ItemHeader>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/menus"
              name="menus"
              active={location.pathname === "/store/menus"}
            >
              <Icon
                name="list alternate outline"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Menus
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/foods"
              name="foods"
              active={location.pathname === "/store/foods"}
            >
              <Icon
                name="food"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Foods
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/orders"
              name="orders"
              active={location.pathname === "/store/orders"}
            >
              <Icon
                name="cart"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Orders
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/deliveries"
              name="deliveries"
              active={location.pathname === "/store/deliveries"}
            >
              <Icon
                name="shipping fast"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Deliverises
            </Menu.Item>
            <Menu.Item>
              <ItemHeader>PAYMENTS</ItemHeader>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/sales"
              name="sales"
              active={location.pathname === "/store/sales"}
            >
              <Icon
                name="signal"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Sales
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/refunds"
              name="refunds"
              active={location.pathname === "/store/refunds"}
            >
              <Icon
                name="undo alternate"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Refunds
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/payouts"
              name="payouts"
              active={location.pathname === "/store/payouts"}
            >
              <Icon
                name="box"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Payouts
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/balance"
              name="balance"
              active={location.pathname === "/store/balance"}
            >
              <Icon
                name="trophy"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Balance
            </Menu.Item>
            <Menu.Item>
              <ItemHeader>SHOP</ItemHeader>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/settings"
              name="settings"
              active={location.pathname === "/store/settings"}
            >
              <Icon
                name="settings"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Settings
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/store/support"
              name="support"
              active={location.pathname === "/store/support"}
            >
              <Icon
                name="shield"
                style={{ float: "left", marginRight: "0.6em" }}
              />
              Support
            </Menu.Item>
          </div>
        </div>
      </Container>
    </Sidebar>
  );
}

export default observer(StoreSideNavMobile);