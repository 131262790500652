import { Link } from "react-router-dom";
import { Menu, Container } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import MenuItemText from "../../../administrator/common/MenuItemText";

const SettingsTopMenu = () => {
  const location = useLocation();
  return (
    <Menu
      attached="top"
      style={{ borderRadius: "0", backgroundColor: "white" }}
      fluid
      secondary
      pointing
    >
      <Container
        style={{
          height: "60px",
          width: "90%",
        }}
      >
        <Menu.Item
          name="profile"
          key="profile"
          active={"/store/settings" === location.pathname}
          as={Link}
          to="/store/settings"
          color="green"
          content={<MenuItemText text="Profile" color="green" />}
        />
        <Menu.Item
          name="openinghours"
          key="openinghours"
          active={"/store/settings/openinghours" === location.pathname}
          as={Link}
          to="/store/settings/openinghours"
          color="green"
          content={<MenuItemText color="green" text="Opening Hours" />}
        />
        <Menu.Item
          name="photo"
          key="photo"
          active={"/store/settings/photo" === location.pathname}
          as={Link}
          to="/store/settings/photo"
          color="green"
          content={<MenuItemText text="Photo" color="green" />}
        />
        <Menu.Item
          name="staff"
          key="staff"
          active={"/store/settings/staff" === location.pathname}
          as={Link}
          to="/store/settings/staff"
          color="green"
          content={<MenuItemText text="Staff" color="green" />}
        />
        <Menu.Item
          name="security"
          key="security"
          active={"/store/settings/security" === location.pathname}
          as={Link}
          to="/store/settings/security"
          color="green"
          content={<MenuItemText text="Security" color="green" />}
        />
        <Menu.Item
          name="account"
          key="account"
          active={"/store/settings/account" === location.pathname}
          as={Link}
          to="/store/settings/account"
          color="green"
          content={<MenuItemText text="Payout Account" color="green" />}
        />
      </Container>
    </Menu>
  );
};

export default SettingsTopMenu;
