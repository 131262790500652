import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Bank from "../models/bank";
export default class BankStore {
  bankRegistry = new Map<string, Bank>();
  loading = false;
  submitting = false;
  deleting = false;
  constructor() {
    makeAutoObservable(this);
  }

  get banksByName() {
    return Array.from(this.bankRegistry.values()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  loadBanks = async () => {
    this.loading = true;
    try {
      let banks = await agent.Banks.list();
      runInAction(() => {
        this.bankRegistry.clear();
        banks.forEach((bank) => {
          bank = { ...bank, text: bank.name, value: bank.id };
          this.bankRegistry.set(bank.id!, bank);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };
}
