import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import PageTopMenu from "../../common/PageTopMenu";

const ShipmentsTopMenu = () => {
  return (
    <PageTopMenu>
      <Menu.Item>
        <Dropdown labeled text={"Filter"} icon="filter">
          <Dropdown.Menu></Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </PageTopMenu>
  );
};

export default ShipmentsTopMenu;
