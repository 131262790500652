import { useState } from "react";
import VendorOpeningHoursDetailsPage from "../detailedPages/VendorOpeningHoursDetailsPage";
import VendorOpeningInfoForm from "../forms/VendorOpeningInfoForm";

const VendorOpeningHours = () => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      {editMode ? (
        <VendorOpeningInfoForm setEditMode={setEditMode} />
      ) : (
        <VendorOpeningHoursDetailsPage setEditMode={setEditMode} />
      )}
    </div>
  );
};

export default VendorOpeningHours;
