import { observer } from "mobx-react-lite";
import { Icon, List, Image, Grid } from "semantic-ui-react";
import { ShoppingCartItem } from "../../../app/models/shopping-cart";
import { useStore } from "../../../app/store/store";
import { CartButton, CartQuantity } from "./components/componentsStyles";
import { toCurrency } from "../../../app/common/util/util";
import { useState } from "react";
import {
  ButtonWrapperCircular,
  CartItemPrice,
  CartItemTitle,
  Customise,
  Description,
  ListItem,
  Title,
} from "./cartStyles";
import CustomiseOrder from "../vendors/details/customise/CustomiseOrder";

interface Props {
  item: ShoppingCartItem;
}

const CartItem = ({ item }: Props) => {
  // ===============================================================
  // store
  // ===============================================================
  const {
    shoppingCartStore: {
      addToCart,
      removeFromCart,
      addingToCart,
      removingFromCart,
    },
    modalStore: { openModal },
  } = useStore();

  const [currentTarget, setCurrentTarget] = useState("");

  // ===============================================================
  // methods
  // ===============================================================
  const handleAddButtonClick = (
    id: number,
    foodId: string,
    associatedItems: ShoppingCartItem[]
  ) => {
    let foodIds = associatedItems.map((item) => item.foodId);
    setCurrentTarget(`${id} plus`);
    addToCart(foodId, foodIds)
      .then(() => setCurrentTarget(""))
      .catch((error) => {
        setCurrentTarget("");
        console.log(error);
      });
  };

  const handleRemoveButtonClick = (id: number) => {
    setCurrentTarget(`${id} minus`);
    removeFromCart(id)
      .then(() => setCurrentTarget(""))
      .catch((error) => {
        setCurrentTarget("");
        console.log(error);
      });
  };

  return (
    <ListItem key={item.id}>
      <Image
        floated="right"
        size="mini"
        src={item.image || "/images/food-placeholder.jpg"}
      />
      <List.Content style={{ padding: "0" }}>
        <List.Description>
          <Grid verticalAlign="middle">
            <Grid.Column width={10}>
              <Description>
                <CartItemTitle>
                  <Title title={item.title}>{item.title}</Title>
                </CartItemTitle>
                {item.isGroupedParentFood && (
                  <Customise
                    onClick={() =>
                      openModal(
                        <CustomiseOrder
                          foodId={item.foodId}
                          cartId={item.id.toString()}
                          associatedItems={item.associatedItems}
                        />,
                        "tiny"
                      )
                    }
                  >
                    Customise <Icon name="angle right" size="small" />
                  </Customise>
                )}
                <CartItemPrice>{toCurrency(item.totalPrice)}</CartItemPrice>
              </Description>
            </Grid.Column>
            <Grid.Column width={6}>
              <ButtonWrapperCircular>
                <CartButton
                  name={`${item.id} minus`}
                  loading={
                    removingFromCart && currentTarget === `${item.id} minus`
                  }
                  icon={
                    item.quantity === 1 ? (
                      <Icon
                        color="olive"
                        name="trash alternate outline"
                        size="small"
                      />
                    ) : (
                      <Icon color="olive" name="minus" size="small" />
                    )
                  }
                  onClick={() => handleRemoveButtonClick(item.id)}
                />
                <CartQuantity>{item.quantity}</CartQuantity>
                <CartButton
                  name={`${item.id} plus`}
                  loading={addingToCart && currentTarget === `${item.id} plus`}
                  icon={<Icon color="olive" name="plus" size="small" />}
                  onClick={() =>
                    handleAddButtonClick(
                      item.id,
                      item.foodId,
                      item.associatedItems
                    )
                  }
                />
              </ButtonWrapperCircular>
            </Grid.Column>
          </Grid>
        </List.Description>
      </List.Content>
    </ListItem>
  );
};

export default observer(CartItem);
