import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./dashboard/AdminDashboard";
import VendorsLayoutPage from "./commerce/vendors/VendorsLayoutPage";
import LocationList from "./place/locations/LocationList";
import CategoryLayoutPage from "./commerce/categories/CategoryLayoutPage";
import BikesLayoutPage from "./commerce/bikes/BikesLayoutPage";
import ChoppersLayoutPage from "./commerce/choppers/ChoppersLayoutPage";
import OrdersLayoutPage from "./commerce/orders/OrdersLayoutPage";
import ShipmentsLayoutPage from "./commerce/shipments/ShipmentsLayoutPage";
import MakesLayoutPage from "./commerce/bikeMakes/MakesLayoutPage";
import PaymentsLayoutPage from "./transactions/payments/PaymentsLayoutPage";
import PayoutsLayoutPage from "./transactions/payouts/PayoutsLayoutPage";
import RefundsLayoutPage from "./transactions/refunds/RefundsLayoutPage";
import AccountManagerLayout from "./accountmanager/AccountManagerLayout";

interface IProps {
  setPageTitle: (title: string) => void;
}

const AdminRoutes = ({ setPageTitle }: IProps) => {
  return (
    <Routes>
      <Route index element={<AdminDashboard />} />
      <Route path="settings" element={<AccountManagerLayout />} />
      <Route
        path="payments"
        element={<PaymentsLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="refunds"
        element={<RefundsLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="payouts"
        element={<PayoutsLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="shipments"
        element={<ShipmentsLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="orders"
        element={<OrdersLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route path="account-manager/*" element={<AccountManagerLayout />} />
      <Route path="locations" element={<LocationList />} />

      <Route
        path="categories"
        element={<CategoryLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="bike-makes"
        element={<MakesLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="bikes"
        element={<BikesLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="choppers"
        element={<ChoppersLayoutPage setPageTitle={setPageTitle} />}
      />
      <Route
        path="vendors/*"
        element={<VendorsLayoutPage setPageTitle={setPageTitle} />}
      />
    </Routes>
  );
};
export default AdminRoutes;
