import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../app/store/store";
import ActionPanel from "./action/ActionPanel";
import OrderDetails from "./details/OrderDetails";
import {
  ActiveOrderSection,
  DashboardWrapper,
  OrderDetailsSection,
} from "../orders.style";

const OrderDashboard = () => {
  const {
    restaurantStore: { selectedOrder },
  } = useStore();
  return (
    <DashboardWrapper>
      <ActiveOrderSection>
        <ActionPanel />
      </ActiveOrderSection>
      <OrderDetailsSection>
        {selectedOrder && <OrderDetails order={selectedOrder} />}
      </OrderDetailsSection>
    </DashboardWrapper>
  );
};

export default observer(OrderDashboard);
