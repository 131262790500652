import React from "react";
import { Container, Header, Segment, Button } from "semantic-ui-react";
import Chops2uFieldDisplay from "../../../../../app/common/components/Chops2uFieldDisplay";

interface Props {
  setEditMode: (state: boolean) => void;
  editMode: boolean;
}

const VendorAccountDetailsPage = ({ setEditMode, editMode }: Props) => {
  return (
    <Container text style={{ marginTop: "1.5em" }}>
      <Header as="h2" attached="top" color="olive">
        Vendor Account
      </Header>
      <Segment attached className="display" clearing>
        <Chops2uFieldDisplay fieldTitle="Account Name" fieldContent="" />

        <Chops2uFieldDisplay
          fieldTitle="Account Number [NUBAN]"
          fieldContent=""
        />

        <Chops2uFieldDisplay fieldTitle="Bank" fieldContent="" />
      </Segment>
      <Segment secondary attached="bottom" clearing>
        <Button
          basic
          disabled={!editMode}
          color="olive"
          content="Cancel"
          floated="right"
          style={{ width: "10em" }}
        />
        <Button
          onClick={() => setEditMode(true)}
          basic
          color="olive"
          content="Edit"
          floated="right"
          style={{ width: "10em" }}
        />
      </Segment>
    </Container>
  );
};

export default VendorAccountDetailsPage;
