import { Container, Header, Segment } from "semantic-ui-react";
import ChopperRegistrationPage from "../../chopper/ChopperRegistrationPage";

const pageStyle = {
  marginTop: "6em",
};

const RideWithUs = () => {
  return (
    <Container text style={pageStyle}>
      <Header color="olive" as="h1">
        Join Our Delivery Team and Ride with Us!
      </Header>
      <p>
        Welcome Chops2u! Join our delivery team and embark on an exciting
        journey with our food ordering and delivery platform. By delivering food
        orders with care and ensuring timely deliveries, you'll play a crucial
        role in satisfying hungry customers.
      </p>
      <p>
        Join us today and start earning while exploring a path to growth and
        advancement.
      </p>
      <Segment vertical basic>
        <ChopperRegistrationPage />
      </Segment>
    </Container>
  );
};

export default RideWithUs;
