import { useEffect } from "react";
import RefundEmptyPanel from "./RefundEmptyPanel";
import RefundsTopMenu from "./RefundsTopMenu";

interface Props {
  setPageTitle: (title: string) => void;
}

const RefundPageLayout = ({ setPageTitle }: Props) => {
  useEffect(() => {
    setPageTitle("Refunds");
  }, [setPageTitle]);

  return (
    <div style={{ overflowX: "hidden"}}>
      <div>
        <RefundsTopMenu />
      </div>
      <section>
        <RefundEmptyPanel />
      </section>
    </div>
  );
};

export default RefundPageLayout;
