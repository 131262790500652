import { isValid } from "date-fns";
import { Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import * as Yup from "yup";
import Chops2uSelectInput from "../../../../app/common/forms/Chops2uSelectInput";
import Chops2uTextAreaInput from "../../../../app/common/forms/Chops2uTextAreaInput";
import Chops2uTextInput from "../../../../app/common/forms/Chops2uTextInput";
import ValidationErrors from "../../../frontpage/errors/ValidationErrors";
import { Wrapper700 } from "../../store.style";

const SupportTicket = () => {
  const validate = Yup.object({
    departmentId: Yup.string().required(),
    name: Yup.string().required(),
    contactEmail: Yup.string().required().email(),
    message: Yup.string().required(),
  });

  const [formData, setFormData] = useState({
    departmentId: "",
    name: "",
    contactEmail: "",
    message: "",
    error: "",
  });

  useEffect(() => {
    setFormData({
      departmentId: "",
      name: "",
      contactEmail: "",
      message: "",
      error: "",
    });
  }, [setFormData]);

  return (
    <Grid>
      <Grid.Column>
        <Wrapper700>
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validate}
            onSubmit={(values) => console.log(values)}
          >
            {({ dirty, isSubmitting, handleSubmit, errors }) => (
              <Form onSubmit={handleSubmit} className="ui form">
                <Segment color="green" attached="top" clearing>
                  {/* Department Field */}
                  <div className="fields">
                    <div className="four wide field">
                      <label
                        style={{
                          float: "right",
                          lineHeight: "38px",
                        }}
                      >
                        Department
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <Chops2uSelectInput
                        name="departmentId"
                        options={[
                          { key: 1, text: "Support", value: "1" },
                          { key: 2, text: "Sales", value: "2" },
                        ]}
                        placeholder="Select a Department"
                      />
                    </div>
                  </div>

                  {/* Name Field */}
                  <div className="fields">
                    <div className="four wide field">
                      <label
                        style={{
                          float: "right",
                          lineHeight: "38px",
                        }}
                      >
                        Name
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <Chops2uTextInput name="name" placeholder="Full Name" />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div className="fields">
                    <div className="four wide field">
                      <label
                        style={{
                          float: "right",
                          lineHeight: "38px",
                        }}
                      >
                        Contact Email
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <Chops2uTextInput
                        name="contactEmail"
                        placeholder="username@domainname.com"
                      />
                    </div>
                  </div>

                  {/* Message Field */}
                  <div className="fields">
                    <div className="four wide field">
                      <label
                        style={{
                          float: "right",
                          lineHeight: "38px",
                        }}
                      >
                        Message
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <Chops2uTextAreaInput
                        name="message"
                        placeholder=""
                        rows={5}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                  </div>
                </Segment>
                <Segment attached="bottom" clearing secondary>
                  <div>
                    <Button
                      positive
                      disabled={(!isValid && dirty) || isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                      floated="right"
                      content="Send"
                    />
                  </div>
                </Segment>
              </Form>
            )}
          </Formik>
        </Wrapper700>
      </Grid.Column>
    </Grid>
  );
};

export default SupportTicket;
