import { observer } from "mobx-react-lite";
import { Segment } from "semantic-ui-react";
import Order from "../../../../app/models/order";
import OrderCellItem from "./OrderCellItem";

interface Props {
  title: string;
  orders: Order[];
  selectedOrder?: Order;
  loadOrder: (orderId: string) => void;
}

// const listContainerStyle = {
//   borderRadius: "0",
//   margin: "0.5em 0",
//   background: "rgba(34,36,38,0.08)",
//   paddingBottom: "0.05em",
// };

const OrderCellList = ({ title, orders, selectedOrder, loadOrder }: Props) => {
  return (
    <Segment basic>
      <div>
        {orders.map((order, index) => (
          <OrderCellItem
            order={order}
            selectedOrder={selectedOrder}
            loadOrder={loadOrder}
            key={index}
          />
        ))}
      </div>
    </Segment>
  );
};

export default observer(OrderCellList);
